<section class="chronos-dialog--size-md">
  <div class="p-grid sample-input-modal">
    <form [formGroup]="modalForm" libEnterKeyHandler="submit_modal" class="sample-input-modal__container">
      <div class="p-grid">
        <div class="p-col-5" *ngIf="!(aqlQuantity > 0)"></div>
        <div
          class="p-col-5 sample-input-modal__input"
          [ngClass]="{ 'sample-input-modal__input--required': modalForm.get('aqlQuantity').invalid }"
          *ngIf="aqlQuantity > 0"
        >
          <label [for]="'change_quantity_input'" class="col-form-label">
            {{ 'SAMPLING.AQL' | translate }}
          </label>
          <lib-input-number [inputStyle]="INPUT_STYLE" formControlName="aqlQuantity" [unit]="unit" [showUnit]="true"></lib-input-number>
        </div>
        <div class="p-col-7"></div>
        <div
          class="p-col-5 sample-input-modal__input"
          [ngClass]="{ 'sample-input-modal__input--required': modalForm.get('tgsQuantity').invalid }"
          *ngIf="tgsQuantity > 0"
        >
          <label [for]="'change_quantity_input'" class="col-form-label">
            {{ 'SAMPLING.TGS' | translate }}
          </label>
          <lib-input-number [inputStyle]="INPUT_STYLE" formControlName="tgsQuantity" [unit]="unit" [showUnit]="true"></lib-input-number>
        </div>
        <div class="p-col-7"></div>
      </div>
    </form>

    <app-signature
      *ngIf="isSignatureEnabled"
      [isFromSampling]="true"
      [keepBorder]="true"
      (badgeScanned)="onBadgeScanned($event)"
      (employeeUnSelect)="onEmployeeUnSelected()"
    ></app-signature>
  </div>

  <lib-modal-footer
    [declineLabel]="'SAMPLING.CANCEL'"
    [submitLabel]="'SAMPLING.SUBMIT'"
    [whenLoadingTopic]="LOADING_TOPIC"
    (submitClicked)="submitModal()"
    (declineClicked)="closeModal()"
    [submitDisabled]="!(this.modalForm.valid && this.isSigntureScanned)"
    [showDeclineButton]="true"
  ></lib-modal-footer>
</section>
