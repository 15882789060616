/* tslint:disable */
/* eslint-disable */
export enum SettingValueType {
  Boolean = 'Boolean',
  Integer = 'Integer',
  String = 'String',
  DateTime = 'DateTime',
  TimeSpan = 'TimeSpan',
  Enum = 'Enum',
  EnumMultiSelect = 'EnumMultiSelect'
}
