import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Quantity } from 'projects/chronos-core-client/src/public-api';
import { MaterialService, MaterialType, MountContainerResponse, ScanContainerResponse, WorkCenterService } from 'chronos-core-client';

@Injectable({
  providedIn: 'root'
})
export class OverviewMaterialDsService {
  constructor(private workCenterService: WorkCenterService, private materialService: MaterialService) {}

  public scanIdentificationCode(workCenterId: number, identificationCode: string): Observable<ScanContainerResponse> {
    return this.workCenterService.scanContainer({
      workCenterId,
      body: {
        identificationCode
      }
    });
  }

  public mountContainer(
    workCenterId: number,
    productionOrderId: number,
    materialType?: MaterialType,
    identificationCode?: string,
    containerId?: number,
    whiteWasteQuantity?: Quantity
  ): Observable<MountContainerResponse> {
    return this.materialService.mountContainer({
      workCenterId,
      body: {
        containerId,
        identificationCode,
        productionOrderId,
        materialType,
        whiteWasteQuantity
      }
    });
  }
}
