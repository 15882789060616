import { Injectable } from '@angular/core';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { ActiveEmployeeRegistrationData, EmployeeRegistrationService } from 'chronos-core-client';
import { EmployeeStore } from './employee.store';
import { EmployeeFilters } from './employee.model';

@Injectable({ providedIn: 'root' })
export class EmployeeService {
  constructor(
    private activeWorkCenterService: ActiveWorkCenterService,
    private employeeRegistrationService: EmployeeRegistrationService,
    private employeeStore: EmployeeStore
  ) {}

  public getEmployeeRegistrationData(): void {
    this.employeeStore.setLoading(true);
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    this.employeeRegistrationService
      .getActiveEmployeeRegistrationData({ workCenterId })
      .subscribe((employees: ActiveEmployeeRegistrationData) => {
        this.employeeStore.update(employees);
        this.employeeStore.setLoading(false);
      });
  }

  public updateEmployees(activeEmployees: ActiveEmployeeRegistrationData): void {
    this.employeeStore.setLoading(true);
    this.employeeStore.update(activeEmployees);
    this.employeeStore.setLoading(false);
  }

  public updateFilters(filters: EmployeeFilters): void {
    this.employeeStore.updateFilters(filters);
  }

  public resetFilters(): void {
    this.employeeStore.resetFilters();
  }
}
