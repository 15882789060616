import { Component, Input, TemplateRef } from '@angular/core';
import { UiStateQuery } from '@app/core/global-state/ui-state/ui-state.query';
import { UiStateService } from '@app/core/global-state/ui-state/ui-state.service';
import { MaterialTableDataRowDisplayMode, MaterialTableTreeNode } from '@app/modules/mounting/models/material-table.model';
import { MountingService } from '@app/modules/mounting/services';
import { ArticleClassificationSize } from 'chronos-shared';

@Component({
  selector: 'app-trace-material-table',
  templateUrl: './trace-material-table.component.html',
  styleUrls: ['./trace-material-table.component.scss']
})
export class TraceMaterialTableComponent {
  public traceMaterialData: MaterialTableTreeNode[];
  public tableExpanded = false;

  ArticleClassificationSize = ArticleClassificationSize;
  MaterialTableDataRowDisplayMode = MaterialTableDataRowDisplayMode;

  @Input()
  public set tableData(tableData: MaterialTableTreeNode[]) {
    this.traceMaterialData = this.mountingService.setTableRowsExpandingStatus(tableData, this.traceMaterialData);

    this.uiStateQuery.getMountingTraceExpandedArticles().forEach((article) => {
      const tableRow = this.traceMaterialData.find((x) => x.data.materialBlockId === article);
      if (tableRow) {
        tableRow.expanded = true;
      }
    });
  }

  @Input() public buttonTemplate: TemplateRef<any>;

  constructor(private mountingService: MountingService, private uiStateService: UiStateService, private uiStateQuery: UiStateQuery) {}

  public onNodeExpand(data: any): void {
    this.uiStateService.setMountingTraceArticleVisible(data.node.data.materialBlockId);
  }

  public onNodeCollapse(data: any): void {
    this.uiStateService.setMountingTraceArticleHide(data.node.data.materialBlockId);
  }

  public expandCollapseTable(): void {
    const { tableData, tableExpandingStatus } = this.mountingService.expandCollapseTable(this.traceMaterialData, this.tableExpanded);

    this.tableExpanded = tableExpandingStatus;
    this.traceMaterialData = tableData;
  }

  public isAlternativeAvailable(data: any): boolean {
    return data.alternativeArticles.length > 0;
  }
}
