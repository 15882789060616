import { Component, Input } from '@angular/core';
import { StatusBarIcon } from '@app/modules/run-phase/models';
import { ProducedMaterialCheckStatus } from '@app/shared/models';
import { ProducedMaterialCheckState } from 'chronos-core-client';
@Component({
  selector: 'app-status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.scss']
})
export class StatusBarComponent {
  public readonly STATUS_ICON = StatusBarIcon;

  @Input() public wasteStatus: ProducedMaterialCheckStatus;
  @Input() public qaStatus: ProducedMaterialCheckStatus;
  @Input() public printStatus: ProducedMaterialCheckStatus;
  @Input() public forwardWaste: ProducedMaterialCheckStatus;

  public isForwardWasteCheck(): boolean {
    return this.forwardWaste.state !== ProducedMaterialCheckState.Disabled && this.forwardWaste.state !== ProducedMaterialCheckState.None;
  }
}
