import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ToolDsService } from '@app/core/data-services';
import { ToolSetupParameterState } from '@app/modules/setup-phase/models/parameters.model';
import { ToolsService } from '@app/modules/tools/services';
import { ToolSetup } from '@app/shared/models';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ArticleClassification } from 'chronos-core-client';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tool-setup',
  templateUrl: './tool-setup.component.html',
  styleUrls: ['./tool-setup.component.scss']
})
export class ToolSetupComponent implements OnDestroy {
  @Input()
  public set toolSetup(tool: ToolSetup) {
    this.toolSetupInfo = tool;
    this.setToolSetupState();
  }

  @Output() public formStatusEvent = new EventEmitter<boolean>();
  @Output() public renewParametersEvent = new EventEmitter<boolean>();

  public readonly state = ToolSetupParameterState;
  public toolSetupInfo: ToolSetup;
  public punchingPressureState = this.state.NotSet;
  public serialNumberState = this.state.NotSet;

  public set setPunchingPressureState(state: ToolSetupParameterState) {
    this.punchingPressureState = state;
    this.formValidation();
  }
  public set setSerialNumberState(state: ToolSetupParameterState) {
    this.serialNumberState = state;
    this.formValidation();
  }

  private subscriptions = new Subscription();

  constructor(
    private toolDsService: ToolDsService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private toolsService: ToolsService
  ) {}

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public savePunchingPressure(punchingPressure: number): void {
    if (!punchingPressure) {
      this.setPunchingPressureState = this.state.Incorrect;
    } else {
      this.toolDsService.setPunchingPressure(punchingPressure, this.toolSetupInfo.articleId).subscribe(
        () => {
          this.setPunchingPressureState = this.state.Correct;
        },
        () => {
          this.setPunchingPressureState = this.state.Incorrect;
        }
      );
    }
  }

  public mountTool(): void {
    this.toolsService.mountToolForActiveOrder(this.toolSetupInfo.identificationCode, null, this.toolSetupInfo.materialBlockId).subscribe(
      () => {
        this.setSerialNumberState = this.state.Correct;
      },
      () => {
        this.setSerialNumberState = this.state.Incorrect;
      }
    );
  }

  public openToolStockModal(): void {
    const tabName = 'ToolSetup';
    const modal = this.toolsService.openToolStockModal(this.toolSetupInfo.materialBlockId, tabName, this.toolSetupInfo.articleId);
    this.subscriptions.add(
      modal.onClose.subscribe((materialDataChanged) => {
        if (materialDataChanged) {
          this.renewParametersEvent.emit(true);
        }
      })
    );
  }

  public createDummyTool(): void {
    const modal = this.toolsService.createDummyTool(this.toolSetupInfo, ArticleClassification.Tool, null);

    this.handleDummyModal(modal);
  }

  public updateDummyTool() {
    const modal = this.toolsService.updateDummyTool(this.toolSetupInfo, ArticleClassification.Tool, null);

    this.handleDummyModal(modal);
  }

  private handleDummyModal(modal: DynamicDialogRef): void {
    this.subscriptions.add(
      modal.onClose.subscribe(() => {
        this.renewParametersEvent.emit(true);
      })
    );
  }

  private setToolSetupState(): void {
    if (this.toolSetupInfo.punchingPressure) {
      this.punchingPressureState = this.state.Correct;
    }
    if (this.toolSetupInfo.identificationCode) {
      this.serialNumberState = this.state.Correct;
      if (this.toolSetupInfo.isVirtualTool) {
        this.serialNumberState = this.state.Dummy;
      }
    }
    this.formValidation();
  }

  private formValidation(): void {
    const isFormValid =
      this.punchingPressureState === this.state.Correct &&
      (this.serialNumberState === this.state.Correct || this.serialNumberState === this.state.Dummy);
    this.formStatusEvent.emit(isFormValid);
  }
}
