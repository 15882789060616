/* tslint:disable */
/* eslint-disable */
export enum KpiIdentifier {
  Absence = 'Absence',
  Downtime = 'Downtime',
  ConsumptionPrimary = 'ConsumptionPrimary',
  DifferencePlan = 'DifferencePlan',
  GoodQuantity = 'GoodQuantity',
  NumberOfStoppers = 'NumberOfStoppers',
  TargetSpeed = 'TargetSpeed',
  Waste = 'Waste',
  SetupTime = 'SetupTime',
  RunTime = 'RunTime',
  Output1 = 'Output1',
  Output2 = 'Output2',
  KpiOrders = 'KpiOrders',
  ConsumptionSecondary = 'ConsumptionSecondary',
  ConsumptionSecondaryLamination = 'ConsumptionSecondaryLamination',
  NumberOfEmployees = 'NumberOfEmployees',
  Duration = 'Duration',
  BookedHours = 'BookedHours',
  EmployeeRegistration = 'EmployeeRegistration',
  NumberOfSetups = 'NumberOfSetups',
  NumberOfPunches = 'NumberOfPunches',
  AqlSamples = 'AqlSamples',
  TgsSamples = 'TgsSamples'
}
