import { Component, OnDestroy, OnInit } from '@angular/core';
import { PeriodicQaCheck, CreateManualQaChecksEntry, CreateManualQaCheckEntry } from 'chronos-core-client';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { QaCheckService } from '@app/core/services';
import { tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import moment from 'moment-mini';
import { SignatureHandlerService } from '@app/core/services/signature/signature-handler.service';
import { QaCheckType, SignatureConfig } from '@app/shared/models/signature';

@Component({
  selector: 'app-periodic-qa-check-modal',
  templateUrl: './periodic-qa-check-modal.component.html',
  styleUrls: ['./periodic-qa-check-modal.component.scss']
})
export class PeriodicQaCheckModalComponent implements OnInit, OnDestroy {
  public periodicQaCheck: PeriodicQaCheck = null;
  public isConfirmButtonEnabled = false;
  public manualQaChecksEntry: CreateManualQaChecksEntry;
  public isSignatureEnabled = false;
  public signatureConfig = {} as SignatureConfig;
  private subscriptions = new Subscription();
  private confirmButtonStatus = false;

  constructor(
    private ref: DynamicDialogRef,
    private conf: DynamicDialogConfig,
    public qaCheckService: QaCheckService,
    private signatureHandlerService: SignatureHandlerService
  ) {}

  public ngOnInit(): void {
    this.subscriptions.add(
      this.qaCheckService.getManualProductionQaChecks(this.conf.data.productionOrderId).subscribe((data) => {
        this.periodicQaCheck = data;
      })
    );

    this.isSignatureEnabled = this.signatureHandlerService.isQualityCheckSignatureEnabled();
    if (this.isSignatureEnabled) {
      this.checkSignatureIsPresent();
      this.signatureConfig = {
        isSignatureFeatureEnabled: this.isSignatureEnabled,
        qaCheckType: QaCheckType.Periodic
      };
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public submitModal(): void {
    this.subscriptions.add(
      this.qaCheckService.manualQaCheckParamsSubject$
        .pipe(
          tap((manualQaChekEntry: CreateManualQaChecksEntry) => {
            this.manualQaChecksEntry = manualQaChekEntry;
          })
        )
        .subscribe()
    );

    if (!this.manualQaChecksEntry) {
      const productionQaChecks: CreateManualQaCheckEntry[] = [];
      this.periodicQaCheck.productionQaChecks.every((qaCheckResult) =>
        productionQaChecks.push({
          productionQaCheckId: qaCheckResult.id,
          checkResult: qaCheckResult.checkResult,
          comment: qaCheckResult.comment,
          measurementResult: qaCheckResult.measurementResult
        })
      );
      this.qaCheckService.manualQaCheckParamsSubject.next({
        creationTime: moment().format('YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]'),
        productionQaChecks
      });
    }

    if (this.isSignatureEnabled) {
      this.subscriptions.add(
        this.signatureHandlerService.sharedQaInputData$.subscribe((data) => {
          this.manualQaChecksEntry.employeeId = data.employeeId;
          this.manualQaChecksEntry.isManualInput = data.hasManualInput;
          this.manualQaChecksEntry.comment = data.comment;
          this.manualQaChecksEntry.timestamp = data.timestamp;
        })
      );
    }

    this.qaCheckService.createManualQaChecks(this.manualQaChecksEntry).subscribe();
    this.ref.close();
  }

  public setConfirmButtonStatus(status: boolean): void {
    this.confirmButtonStatus = status;
    if (this.isSignatureEnabled) {
      this.isConfirmButtonEnabled = this.signatureConfig.isSignaturePresent && status;
    } else {
      this.isConfirmButtonEnabled = status;
    }
  }

  public formatFrequencyType(data: string): string {
    return `FREQUENCY_TYPE.${data.toUpperCase()}`;
  }

  // Check if the signature is present
  private checkSignatureIsPresent(): void {
    this.subscriptions.add(
      this.signatureHandlerService.signatureIsPresent$.subscribe((signatureIsPresent) => {
        this.signatureConfig.isSignaturePresent = signatureIsPresent;
        this.updateConfirmButtonStatus();
      })
    );
  }

  private updateConfirmButtonStatus() {
    this.isConfirmButtonEnabled = this.signatureConfig.isSignaturePresent && this.confirmButtonStatus;
  }
}
