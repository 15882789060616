import { Injectable } from '@angular/core';
import { WorkCenter, WorkCenterQaHistoryParameter, WorkCenterService, WorkCenterState, WorkCenterData } from 'chronos-core-client';
import { ListValue } from 'chronos-shared';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WorkCenterDsService {
  constructor(private workCenterService: WorkCenterService) {}

  public getWorkCenter(workCenterId: number): Observable<WorkCenter> {
    return this.workCenterService.getWorkCenter({ workCenterId });
  }

  public getWorkCenterState(workCenterId: number): Observable<WorkCenterState> {
    return this.workCenterService.getCurrentState({ workCenterId });
  }

  public getWorkCenters(): Observable<WorkCenterData[]> {
    return this.workCenterService.getWorkCenterList();
  }

  public getReasonOptions(workCenterId: number): Observable<ListValue[]> {
    return this.workCenterService.getWasteReasons({ workCenterId });
  }

  public getQaHistoryParameter(workCenterId: number): Observable<WorkCenterQaHistoryParameter> {
    return this.workCenterService.getQaHistoryParameterValue({ workCenterId });
  }

  public getWorkCenterDetailsByWorkCenterIds(workCenterIds: number[]): Observable<WorkCenter[]> {
    return this.workCenterService.getWorkCenters({ body: workCenterIds });
  }
}
