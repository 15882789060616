import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  MachineSchedule,
  ProductionOrderDeviationEntry,
  ProductionOrderProductionLoyalty,
  ProductionOrderService,
  DefaultTargetContainerQuantityInfo,
  Quantity,
  ProductionOrderSetupPhaseState,
  SuggestQuantityForLastProducedMaterialViewModel,
  OuterQuantityDetails,
  SearchOrdersViewModel,
  ManualTransportProcessInfo
} from 'chronos-core-client';
import { ActiveOrderService, ProductionOrderListService } from '@app/core/global-state';
import { tap } from 'rxjs/operators';
import { MachineScheduleDsService } from '@app/core/data-services/machine-schedule-ds';
import { ShiftFilterType } from '@app/shared/models';
import { ActiveWorkCenterService } from '@app/core/workcenter';

@Injectable({
  providedIn: 'root'
})
export class ProductionOrderDsService {
  constructor(
    private productionOrderService: ProductionOrderService,
    private machineScheduleDsService: MachineScheduleDsService,
    private productionOrderListService: ProductionOrderListService,
    private activeWorkCenterService: ActiveWorkCenterService,
    private activeOrderService: ActiveOrderService
  ) {}

  public setCheckedProductionOrder(productionOrderId: number, isChecked: boolean): Observable<void> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    if (isChecked) {
      return this.productionOrderService
        .checkProductionOrder({
          productionOrderId,
          body: {
            workCenterId
          }
        })
        .pipe(
          tap(() => {
            this.productionOrderListService.setCheckedProductionOrder(productionOrderId);
          })
        );
    } else {
      return this.productionOrderService
        .uncheckProductionOrder({
          productionOrderId,
          body: {
            workCenterId
          }
        })
        .pipe(
          tap(() => {
            this.productionOrderListService.setUncheckedProductionOrder(productionOrderId);
          })
        );
    }
  }

  public presetupProductionOrder(productionOrderId: number): Observable<void> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.productionOrderService.presetupProductionOrder({
      productionOrderId,
      body: {
        workCenterId
      }
    });
  }

  public getProductionLoyaltyReasons(productionOrderId: number): Observable<ProductionOrderProductionLoyalty> {
    return this.productionOrderService.getProductionLoyaltyReasons({ productionOrderId });
  }

  public setAsNextOrder(productionOrderId: number, body: ProductionOrderDeviationEntry): Observable<void> {
    return this.productionOrderService.setAsNextProductionOrder({
      productionOrderId,
      body
    });
  }

  public getProductionOrderListByShift(
    showFinished: boolean,
    shiftFilterType?: ShiftFilterType,
    calculateSecondaryMachineLocationKpi?: boolean
  ): Observable<MachineSchedule[]> {
    this.productionOrderListService.setLoading(true);
    return this.machineScheduleDsService
      .filterMachineScheduleList(showFinished, shiftFilterType, calculateSecondaryMachineLocationKpi)
      .pipe(
        tap((productionOrders) => {
          this.productionOrderListService.setProductionOrders(productionOrders);
        })
      );
  }

  public reopenProductionOrder(productionOrderId: number): Observable<void> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.productionOrderService
      .reopenProductionOrder({
        productionOrderId,
        body: {
          workCenterId
        }
      })
      .pipe(
        tap(() => {
          this.productionOrderListService.reopenProductionOrder(productionOrderId);
        })
      );
  }

  public resetProductionOrders(): Observable<void> {
    return this.productionOrderService.resetProductionOrders();
  }

  public cancelProductionOrder(productionOrderId: number): Observable<void> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();

    return this.productionOrderService
      .cancelProductionOrder({
        productionOrderId,
        body: {
          workCenterId
        }
      })
      .pipe(
        tap(() => {
          this.removeActiveOrder();
        })
      );
  }

  public removeActiveOrder(): void {
    this.activeOrderService.reset();
  }

  public getDefaultTargetContainerQuantityInfo(productionOrderId: number): Observable<DefaultTargetContainerQuantityInfo> {
    return this.productionOrderService.getDefaultTargetContainerQuantity({ productionOrderId });
  }

  public editProductionOrderSetupPhase(
    setupStartTime: string,
    setupEndTime: string,
    setupQuantity: Quantity,
    setupStartCounter: number,
    setupEndCounter: number,
    productionOrderId: number
  ): Observable<void> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.productionOrderService.editProductionOrderSetupPhase({
      productionOrderId,
      body: {
        workCenterId,
        setupEndTime,
        setupStartTime,
        setupStartCounter,
        setupEndCounter,
        setupQuantity
      }
    });
  }

  public getSetupPhaseState(
    productionOrderId: number,
    runId: number,
    calledFromApproveReport: boolean
  ): Observable<ProductionOrderSetupPhaseState> {
    return this.productionOrderService.getSetupPhaseState({
      productionOrderId,
      runId,
      calledFromApproveReport
    });
  }

  public cancelEndProduction(productionOrderId: number, duration: string, numberOfWorkers: number): Observable<void> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.productionOrderService.cancelEndProduction({
      productionOrderId,
      body: {
        workCenterId,
        duration,
        numberOfWorkers
      }
    });
  }

  public suggestQuantityForLastProducedMaterial(productionOrderId: number): Observable<SuggestQuantityForLastProducedMaterialViewModel> {
    return this.productionOrderService.suggestQuantityForLastProducedMaterial({ productionOrderId });
  }

  public getOuterViewQuantityDetails(productionOrderId: number): Observable<OuterQuantityDetails> {
    return this.productionOrderService.getOuterViewQuantityDetails({ productionOrderId });
  }

  public searchOrders(
    showOpen?: boolean,
    showFinished?: boolean,
    showEnded?: boolean,
    showActive?: boolean,
    externalProductionOrderIdFilter?: string
  ): Observable<SearchOrdersViewModel[]> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.productionOrderService.searchOrders({
      workCenterId,
      ShowOpen: showOpen,
      ShowFinished: showFinished,
      ShowEnded: showEnded,
      ShowActive: showActive,
      ExternalProductionOrderIdFilter: externalProductionOrderIdFilter
    });
  }

  public getManualTransportProcessInfo(productionOrderId: number): Observable<ManualTransportProcessInfo> {
    return this.productionOrderService.getManualTransportProcessInfo({ productionOrderId });
  }
}
