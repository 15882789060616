<section class="chronos-dialog--size-lg">
  <div class="p-grid p-nogutter kpi">
    <div class="kpi__container">
      <div>{{ 'PALLET_CARD.OUTPUT' | translate }}</div>
      <div class="kpi__container-values">
        <lib-pallet-number
          class="kpi__item"
          [palletNumber]="currentPallet.sequence"
          [classification]="currentPallet.article.classification"
        ></lib-pallet-number>
        <lib-pallet-kpi
          class="kpi__item"
          [kpiLabel]="'MOUNTING.SSCC' | translate"
          [kpiValue]="currentPallet.ssccCode"
          [compact]="true"
          [emphasizeText]="true"
          [leftAligned]="true"
          [isSsccCodeShort]="true"
        ></lib-pallet-kpi>
        <lib-pallet-kpi
          class="kpi__item"
          [kpiLabel]="'KPI_TILE.QUANTITY_ON_PALLET' | translate"
          [kpiValue]="currentPallet.containerQuantity | formatQuantity : false"
          [compact]="true"
          [emphasizeText]="true"
          [leftAligned]="true"
          [textAligned]="'end'"
        ></lib-pallet-kpi>
        <lib-pallet-kpi
          class="kpi__item"
          [kpiLabel]="'KPI_TILE.WASTE' | translate"
          [kpiValue]="waste | formatQuantity : false"
          [compact]="true"
          [emphasizeText]="true"
          [leftAligned]="true"
          [textAligned]="'end'"
        ></lib-pallet-kpi>
      </div>
    </div>
  </div>
  <app-qa-check-list-in-run
    [fields]="fields"
    [pallet]="currentPallet"
    (formValidationChange)="setConfirmButtonStatus($event)"
    [signatureConfig]="signatureConfig"
  ></app-qa-check-list-in-run>

  <app-signature *ngIf="isSignatureEnabled" [productionQaCheck]="fields" [keepBorder]="true"></app-signature>

  <lib-modal-footer
    [submitLabel]="'PALLET_CARD.QA_CONFIRM'"
    (submitClicked)="submitModal()"
    [submitDisabled]="!isConfirmButtonEnabled"
    [showDeclineButton]="false"
  ></lib-modal-footer>
</section>
