import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ShutdownService } from './shutdown.service';
import { ShutdownReason, ShutDownTimeType } from 'chronos-core-client';
import { Router } from '@angular/router';
import { nav, notificationTopic } from '@app/shared/utils';
import { Observable } from 'rxjs/internal/Observable';
import { LoadingNotificationService } from 'chronos-shared';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-shutdown',
  templateUrl: './shutdown.component.html',
  styleUrls: ['./shutdown.component.scss']
})
export class ShutdownComponent implements OnInit {
  public readonly SHUTDOWN_TIME_TYPE = ShutDownTimeType;
  public readonly LOADING_TOPIC = notificationTopic.footerShutdownAction;

  public shutdownGroup: UntypedFormGroup;
  public shutdownReasons$: Observable<ShutdownReason[]>;

  constructor(private shutdownService: ShutdownService, private router: Router) {}

  public ngOnInit(): void {
    this.shutdownReasons$ = this.shutdownService.getShutdownReasons();

    this.shutdownGroup = new UntypedFormGroup({
      shutDownReasonId: new UntypedFormControl('', Validators.required),
      shutDownEventTime: new UntypedFormControl('', Validators.required),
      infoText: new UntypedFormControl('')
    });
  }

  public submitShutdownForm(): void {
    LoadingNotificationService.publish(this.LOADING_TOPIC, true);

    const { shutDownEventTime, shutDownReasonId, infoText } = this.shutdownGroup.value;
    const eventTime = shutDownEventTime || this.SHUTDOWN_TIME_TYPE.None;

    this.shutdownService
      .shutdownMachine({
        id: shutDownReasonId,
        shutDownEventTime: eventTime,
        infoText
      })
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
        })
      )
      .subscribe();
  }

  public navigateToOrderList(): void {
    this.router.navigateByUrl(nav.routes.ordersList);
  }
}
