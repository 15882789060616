import { OuterLabelType, OuterPrintInfo, PrintLabelForOuterEntry } from 'chronos-core-client';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { OutputUnitDsService } from '@app/core/data-services/output-unit-ds/output-unit-ds.service';
import { Quantity } from 'projects/chronos-core-client/src/public-api';

@Injectable()
export class GluingService {
  constructor(private outputUnitDsService: OutputUnitDsService) {}

  public addOuterWasteAssignment(productionOrderId: number, waste: Quantity, maculature: Quantity, reasonId?: number): Observable<void> {
    return this.outputUnitDsService.addWasteAssignmentToOuter(productionOrderId, waste, maculature, reasonId);
  }

  public deleteOuterWasteAssignment(productionOrderId: number, wasteAssignmentId: number): Observable<void> {
    return this.outputUnitDsService.deleteWasteAssignmentFromOuter(productionOrderId, wasteAssignmentId);
  }

  public printOutputUnitLabel(productionOrderId: number, request: PrintLabelForOuterEntry): Observable<void> {
    return this.outputUnitDsService.printOutputUnitLabel(productionOrderId, request);
  }

  public changeQuantityOfOutputUnit(productionOrderId: number, newQuantity: number): Observable<void> {
    return this.outputUnitDsService.changeQuantityOfOutputUnit(productionOrderId, newQuantity);
  }

  public saveQuantityPerOuterAndCopies(
    productionOrderId: number,
    outerLabelType: OuterLabelType,
    quantity: number,
    numberCopies
  ): Observable<void> {
    return this.outputUnitDsService.saveQuantityPerOuterAndCopies(productionOrderId, outerLabelType, quantity, numberCopies);
  }
}
