import { Injectable } from '@angular/core';
import { ActiveProductionOrder, MachineSchedule, MachineScheduleService, ShiftFilter } from 'chronos-core-client';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { Observable } from 'rxjs';
import { ShiftFilterType } from '@app/shared/models';

@Injectable({
  providedIn: 'root'
})
export class MachineScheduleDsService {
  constructor(private machineScheduleService: MachineScheduleService, private activeWorkCenterService: ActiveWorkCenterService) {}

  public getMachineScheduleList(): Observable<MachineSchedule[]> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.machineScheduleService.getMachineSchedules({ workCenterId });
  }

  public showAllMachineScheduleList(): Observable<MachineSchedule[]> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.machineScheduleService.getAll({ workCenterId });
  }

  public filterMachineScheduleList(
    showFinished: boolean,
    shiftFilterType = ShiftFilterType.All,
    calculateSecondaryMachineLocationKpi = false
  ): Observable<MachineSchedule[]> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.machineScheduleService.getFiltered({
      workCenterId,
      ShowFinishedOrders: showFinished,
      ShiftFilterId: shiftFilterType,
      CalculateSecondaryMachineLocationKpi: calculateSecondaryMachineLocationKpi
    });
  }

  public getShiftFilters(): Observable<ShiftFilter[]> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.machineScheduleService.getShiftFilters({ workCenterId });
  }

  public getActiveProductionOrder(): Observable<ActiveProductionOrder> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.machineScheduleService.getActiveOrder({ workCenterId });
  }
}
