import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit } from '@angular/core';
import { AppSettingsQuery } from 'chronos-shared';
import { OuterLabelType } from 'chronos-core-client';
import { OuterInfo } from '@app/shared/models';
@Component({
  selector: 'app-print-label-header',
  templateUrl: './print-label-header.component.html',
  styleUrls: ['./print-label-header.component.scss']
})
export class PrintLabelHeaderComponent implements OnInit, AfterViewInit {
  @Input() public printerName: string;
  @Input() public printedLabels: number;
  @Input() public labelsTotal: number;
  @Input() public defaultQuantityPerOuter: number;
  @Input() public quantityPerOuter: number;
  @Input() public collapsible = false;
  @Input() public isCollapsed = true;
  @Input() public labelType: OuterLabelType;
  @Input() public numberOfCopies?: number;
  @Input() public isQuantityPerOuterValid? = false;
  @Input() public isBobbin = false;
  @Input() public producedMaterialId?: number;
  @Input() public slitCount?: number;
  @Input() public isPalletFinished?: boolean;
  @Input() public palletQuantity?: number;
  @Output() public toggleButtonClicked = new EventEmitter<boolean>();
  @Output() public quantityPerOuterChanged = new EventEmitter<OuterInfo>();
  @Output() public numberOfCopiesChanged = new EventEmitter<OuterInfo>();

  public INPUT_MAX_QUANTITY = 999999;
  public readonly INPUT_MIN_QUANTITY = 1;
  public readonly COPIES_MAX_QUANTITY = 99;
  public readonly COPIES_MIN_QUANTITY = 1;
  public readonly INPUT_STYLE = {
    width: '60px',
    textAlign: 'end'
  };
  public readonly OUTER_LABEL_TYPE = OuterLabelType;
  private oldQuantityPerOuter: number;
  public showInactive = false;

  constructor(private appSettingsQuery: AppSettingsQuery) {}

  public ngOnInit(): void {
    this.showInactive = this.appSettingsQuery.showInactiveElement();
    this.oldQuantityPerOuter = this.quantityPerOuter;

    this.setOuterLabelMaxQuantity();
  }

  public ngAfterViewInit(): void {
    if (this.isBobbin) {
      this.collapsible = true;
      this.isCollapsed = false;
      this.showInactive = false;
    }
  }

  public toggleContent(): void {
    this.isCollapsed = !this.isCollapsed;

    this.toggleButtonClicked.emit(this.isCollapsed);
  }

  public onQuantityPerOuterChange(): void {
    let quantity = this.quantityPerOuter ?? this.INPUT_MIN_QUANTITY;

    if (this.quantityPerOuter > this.INPUT_MAX_QUANTITY) quantity = this.INPUT_MAX_QUANTITY;

    if (this.labelType === OuterLabelType.Outer) {
      this.setQuanityForOuterLabel(quantity);
    } else {
      this.quantityPerOuterChanged.emit({ quantityPerOuter: quantity, numberCopies: this.numberOfCopies });
    }
  }

  public setQuanityForOuterLabel(quantity: number): void {
    if (quantity >= this.INPUT_MAX_QUANTITY) {
      quantity = this.INPUT_MAX_QUANTITY;
    }

    if (quantity <= this.INPUT_MIN_QUANTITY) {
      quantity = this.INPUT_MIN_QUANTITY;
    }

    if (this.oldQuantityPerOuter !== quantity) {
      this.oldQuantityPerOuter = quantity;
      this.quantityPerOuter = quantity;
      this.quantityPerOuterChanged.emit({
        quantityPerOuter: quantity,
        numberCopies: this.numberOfCopies,
        producedMaterialId: this.producedMaterialId
      });
    }
  }

  public onCopiesPerLabelChanged(): void {
    let copies = this.numberOfCopies ?? this.INPUT_MIN_QUANTITY;

    if (copies <= 0) {
      copies = this.INPUT_MIN_QUANTITY;
    }
    this.numberOfCopiesChanged.emit({
      quantityPerOuter: this.quantityPerOuter,
      numberCopies: copies,
      producedMaterialId: this.producedMaterialId
    });
  }

  public setOuterLabelMaxQuantity(): void {
    if (this.isBobbin) this.INPUT_MAX_QUANTITY = this.palletQuantity;
  }
}
