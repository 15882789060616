import { QaCheckService } from '@app/core/services/qa-check/qa-check.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { CreatePalletModalComponent } from '@app/modules/manual-mode/components/create-pallet-modal/create-pallet-modal.component';
import { OrderFinishModalComponent } from '@app/modules/manual-mode/components/order-finish-modal/order-finish-modal.component';
import { OrderFinishSimpleModalComponent } from '@app/modules/manual-mode/components/order-finish-simple-modal/order-finish-simple-modal.component';
import { ManualModeService } from '@app/modules/manual-mode/services/manual-mode/manual-mode.service';
import {
  OutputChangeBobbinQuantity,
  OutputChangeQuantity,
  OutputFinish,
  OutputFinishCheck,
  OutputLastMaterial
} from '@app/modules/run-phase/models';
import { CollapseExpandNotificationService, OutputContainerListService, OutputContainerService } from '@app/modules/run-phase/services';
import { OutputPalletsQuery, OutputPalletsService } from '@app/modules/run-phase/state';
import { TranslateService } from '@ngx-translate/core';
import {
  ActiveProductionOrder,
  CreateManualPalletData,
  ProducedMaterial,
  ProducedMaterialStatus,
  ProductionOrderStatus,
  QaCheck,
  Quantity,
  WorkCenterProductionMode,
  PeriodicQaCheck,
  KpiIdentifier,
  ArticleClassification,
  ManualMachineMode,
  ProductionOrderManualModeFinishingDataViewModel,
  ManualMachineCheckoutPageMode
} from 'chronos-core-client';
import { ListValue, LoadingNotificationService, ModalConfirmComponent } from 'chronos-shared';
import { DialogService } from 'primeng/dynamicdialog';
import * as R from 'ramda';
import { clone } from 'ramda';
import { Observable, Subscription, forkJoin, combineLatest } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { OutputQuantityChangeComponent } from '../output-quantity-change';
import { nav, notificationTopic } from '@app/shared/utils';
import { ContainerCollapseGroup } from '@app/modules/run-phase/components';
import { elastic } from '@app/shared/utils/elastic';
import { ParametersDsService, ProductionOrderDsService } from '@app/core/data-services';
import { Router } from '@angular/router';
import { PeriodicQaCheckModalComponent } from '../periodic-qa-check-modal/periodic-qa-check-modal.component';
import { ProducedMaterialLoadingMode } from 'projects/chronos-core-client/src/public-api';
import { ChangeBobbinQuantityComponent } from '@app/shared/modals';
import { ActiveOrderQuery } from '@app/core/global-state';
import { OrderFinishMultiUserModalComponent } from '@app/modules/manual-mode/components/order-finish-multiuser-modal/order-finish-multiuser-modal.component';

@Component({
  selector: 'app-output-container-list',
  templateUrl: './output-container-list.component.html',
  styleUrls: ['./output-container-list.component.scss']
})
export class OutputContainerListComponent implements OnInit, OnDestroy {
  @Input() public reasonOptions: ListValue[];
  @Input() public activeProductionOrder: ActiveProductionOrder;
  @Input() public grossQuantity: Quantity;

  @Output() public finishClicked = new EventEmitter<OutputFinish>();

  public arePalletsExpanded: boolean;
  public manualMode = false;
  private shiftDuration: string;
  public visiblePallets$: Observable<ProducedMaterial[]>;
  public otherPallets$: Observable<ProducedMaterial[]>;
  public otherPalletGroups$: Observable<ContainerCollapseGroup>;
  public isManualModeForContainer$: Observable<boolean>;
  public isCancelProductionOrder = false;
  public isManualCheckAvailable;
  public slitCount: number;
  public articleClassification: ArticleClassification;
  public manualMachineMode: ManualMachineMode;

  private activeProductionOrderId: number;
  private outputContainerGroup: ContainerCollapseGroup = {
    name: 'COLLAPSE.FINISHED',
    value: 0,
    of: 0
  };
  private subscriptions = new Subscription();
  private readonly MAX_PALLET_QUANTITY = 1000000;
  private readonly QUANTITY_POOL_LOADING_TOPIC = notificationTopic.quantityPoolAction;
  private readonly LOADING_TOPIC = notificationTopic.modalCancelProductionOrder;
  constructor(
    private activeWorkCenterService: ActiveWorkCenterService,
    private outputContainerService: OutputContainerService,
    private outputPalletsQuery: OutputPalletsQuery,
    private outputPalletsService: OutputPalletsService,
    private collapseExpandNotificationService: CollapseExpandNotificationService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private outputContainerListService: OutputContainerListService,
    private manualModeService: ManualModeService,
    private productionOrderDsService: ProductionOrderDsService,
    private router: Router,
    private qaCheckService: QaCheckService,
    private parametersDsService: ParametersDsService,
    private activeOrderQuery: ActiveOrderQuery
  ) {}

  public ngOnInit(): void {
    this.activeProductionOrderId = this.activeProductionOrder.productionOrderId;
    this.slitCount = this.activeProductionOrder.slitCount;
    this.isManualModeForContainer$ = this.outputContainerListService.isManualModeForContainer();
    this.subscriptions.add(
      this.outputContainerService.getOutputPallets(this.activeProductionOrderId, ProducedMaterialLoadingMode.All).subscribe()
    );
    this.subscriptions.add(
      this.outputContainerListService.getQaSetupValues(this.activeProductionOrderId).subscribe((setupValues) => {
        this.setupOutputPallets(setupValues);
      })
    );

    this.subscriptions.add(this.outputContainerService.getProducedMaterialNotifications().subscribe());

    this.subscriptions.add(
      this.qaCheckService.getManualProductionQaChecks(this.activeProductionOrderId).subscribe((data) => {
        this.manualProductionQAChecks(data);
      })
    );

    this.subscriptions.add(
      this.collapseExpandNotificationService.eventOutput$.subscribe((isExpanded) => (this.arePalletsExpanded = isExpanded))
    );
    this.subscriptions.add(
      this.outputContainerListService.getProductionMode().subscribe((mode) => {
        this.checkManualMode(mode);
      })
    );

    this.subscriptions.add(
      this.manualModeService.getAllKpis().subscribe((kpiData) => {
        this.shiftDuration = kpiData.find((kpi) => kpi.kpiIdentifier === KpiIdentifier.Duration)?.targetTime;
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private isPossibleToCancel(pallets: ProducedMaterial[]): boolean {
    return pallets.every((pallet) => pallet.runId !== this.activeProductionOrder.runId);
  }

  private setupOutputPallets(setupQaChecks: QaCheck[]): void {
    const outputPallets = combineLatest([this.outputPalletsQuery.outputPallets$, this.activeOrderQuery.activeOrderId$]).pipe(
      tap(([pallets]) => {
        this.outputContainerGroup.of = pallets.length || 0;
        this.articleClassification = pallets.length ? pallets[0]?.article?.classification 
        : this.activeOrderQuery.getArticleClassification();
      }),
      tap(([pallets]) => (this.isCancelProductionOrder = this.isPossibleToCancel(pallets))),
      map(([pallets]) =>
        pallets
          .filter((pallet) => pallet.productionOrderId === this.activeProductionOrderId)
          .map((pallet) => ({
            ...pallet,
            qaChecks: pallet.qaChecks.map((qaCheckItem) => ({
              ...qaCheckItem,
              qaCheck: this.getQaCheckHeader(setupQaChecks, qaCheckItem.qaCheckId)
            }))
          }))
      )
    );

    this.visiblePallets$ = outputPallets.pipe(
      map((pallets: ProducedMaterial[]) => pallets.filter((pallet) => this.isContainerVisible(pallet)))
    );

    this.otherPallets$ = outputPallets.pipe(
      map((pallets: ProducedMaterial[]) => pallets.filter((pallet) => !this.isContainerVisible(pallet)))
    );

    this.otherPalletGroups$ = this.otherPallets$.pipe(map((pallets: ProducedMaterial[]) => this.collectPalletCounts(pallets)));
  }

  private manualProductionQAChecks(setupQaChecks: PeriodicQaCheck): void {
    this.isManualCheckAvailable = !!(setupQaChecks.productionQaChecks?.length > 0);
  }

  public createPallet(): void {
    if (this.manualMachineMode === ManualMachineMode.Cutting || this.manualMachineMode === ManualMachineMode.ManualWorkingPlace) {
      this.openPalletCreationModal();
    } else {
      LoadingNotificationService.publish(this.QUANTITY_POOL_LOADING_TOPIC, true);
      this.outputContainerService
        .createOutputPallet(this.activeProductionOrderId)
        .pipe(
          finalize(() => {
            LoadingNotificationService.publish(this.QUANTITY_POOL_LOADING_TOPIC, false);
          })
        )
        .subscribe();
    }
  }

  public trackByFunction(index: number, outputPallet: ProducedMaterial): number {
    return outputPallet.containerId;
  }

  public onLastClick(lastMaterial: OutputLastMaterial): void {
    LoadingNotificationService.publish(lastMaterial.loadingTopic, true);
    this.outputContainerService
      .markPalletAsLast(lastMaterial.producedMaterialId, this.activeWorkCenterService.getWorkCenterId())
      .pipe(finalize(() => LoadingNotificationService.publish(lastMaterial.loadingTopic, false)))
      .subscribe(() => {
        this.otherPallets$ = this.otherPallets$.pipe(
          map((pallets: ProducedMaterial[]) => pallets.filter((pallet) => pallet.producedMaterialId !== lastMaterial.producedMaterialId))
        );
      });
  }

  public handleFinishedCheckChange(event: OutputFinishCheck): void {
    const trace = elastic.traceUiActivity('OutputContainerListComponent.handleFinishedCheckChange');
    LoadingNotificationService.publish(event.loadingTopic, true);

    let action: Observable<any>;
    if (event.isFinished) {
      action = this.outputContainerService.setPalletAsFinished(event.producedMaterialId, this.activeWorkCenterService.getWorkCenterId());
    } else {
      action = this.outputContainerService.setPalletAsOpened(event.producedMaterialId, this.activeWorkCenterService.getWorkCenterId());
    }

    action
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(event.loadingTopic, false);
          trace.end();
        }),
        catchError(() => this.outputContainerService.getOutputPallets(this.activeProductionOrderId, ProducedMaterialLoadingMode.All))
      )
      .subscribe({
        error: () => {
          this.outputPalletsService.updateStatus(event.producedMaterialId, event.producedMaterialStatus);
        }
      });
  }
  private collectPalletCounts(pallets: ProducedMaterial[]): ContainerCollapseGroup {
    return pallets.reduce((result, pallet) => {
      if (pallet.status === ProducedMaterialStatus.Finished) {
        result.value += 1;
      }
      return result;
    }, clone(this.outputContainerGroup));
  }

  public onFinishClicked(outputFinish: OutputFinish): void {
    if (this.manualMachineMode === ManualMachineMode.ManualWorkingPlace || this.manualMachineMode === ManualMachineMode.Cutting) {
      this.finishManualOrderModal(outputFinish, true);
      return;
    }

    if (this.outputPalletsQuery.areAllPalletsFinished()) {
      this.finishClicked.emit(outputFinish);
      return;
    }
  }

  public changeContainerQuantity(): void {
    const trace = elastic.traceUiActivity('OutputContainerListComponent.changeContainerQuantity');
    LoadingNotificationService.publish(this.QUANTITY_POOL_LOADING_TOPIC, true);
    this.outputContainerService
      .getQuantityPerPallet(this.activeProductionOrder.productionOrderId)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.QUANTITY_POOL_LOADING_TOPIC, false);
          trace.end();
        })
      )
      .subscribe((model) =>
        this.openChangeQuantityModal({
          productionOrderId: this.activeProductionOrder.productionOrderId,
          quantity: model.quantityPerPallet,
          classification: this.articleClassification // article classification
        })
      );
  }

  public changePalletQuantity(changeQuantityEvent: OutputChangeQuantity): void {
    if (this.manualMachineMode === ManualMachineMode.Cutting) {
      this.openPalletCreationModal(changeQuantityEvent);
    } else {
      this.openChangeQuantityModal(changeQuantityEvent);
    }
  }

  public cancelProductionOrder(): void {
    this.openCancelProductionOrderModal();
  }

  public openPeriodicQACheckDialog(): void {
    this.dialogService.open(PeriodicQaCheckModalComponent, {
      header: this.translateService.instant('RUN_PHASE.PERIODIC_QA_CHECK'),
      data: {
        productionOrderId: this.activeProductionOrderId
      }
    });
  }

  public openChangeBobbinQuantityDialog(outputBobbinChangeQuantity?: OutputChangeBobbinQuantity): void {
    const trace = elastic.traceUiActivity('OutputContainerListComponent.changeBobbinQuantity');

    forkJoin({
      containerQuantity: this.parametersDsService.getPalletQuantity(this.activeProductionOrder.productionOrderId),
      bobbinQuantity: this.parametersDsService.getBobbinQuantity(this.activeProductionOrder.productionOrderId)
    })
      .pipe(
        finalize(() => {
          trace.end();
        })
      )
      .subscribe(({ containerQuantity, bobbinQuantity }) => {
        this.dialogService.open(ChangeBobbinQuantityComponent, {
          header: this.translateService.instant('CHANGE_BOBBIN_LENGTH_DIALOG.CHANGE_BOBBIN_LENGTH'),
          data: {
            productionOrderId: this.activeProductionOrderId,
            bobbinQuantity: bobbinQuantity.bobbinQuantity,
            bobbinDetails: outputBobbinChangeQuantity,
            containerQuantity: containerQuantity.quantityPerPallet
          }
        });
      });
  }

  private openChangeQuantityModal(changeQuantityEvent: OutputChangeQuantity): void {
    const articleClassification = changeQuantityEvent.classification?.toLowerCase();
    this.dialogService.open(OutputQuantityChangeComponent, {
      header: this.translateService.instant('PALLET_CARD.CHANGE_QTY_PER_PALLET'),
      data: {
        grossQuantity: changeQuantityEvent.grossQuantity,
        isOuterView: false,
        isLastContainer: changeQuantityEvent.isLastContainer,
        changeQuantity: changeQuantityEvent,
        manualMachineMode: this.manualMachineMode,
        articleClassification
      }
    });
  }

  private isContainerVisible(outputPallet: ProducedMaterial): boolean {
    return outputPallet.status !== ProducedMaterialStatus.Finished || outputPallet.isLastContainer;
  }

  private getQaCheckHeader(setupQaChecks: QaCheck[], qaCheckId: number): QaCheck {
    return setupQaChecks.find((header) => header.id === qaCheckId);
  }

  public getIsExpandedWithDefaultValue(): boolean {
    return R.isNil(this.arePalletsExpanded) ? true : this.arePalletsExpanded;
  }

  private checkManualMode(mode: WorkCenterProductionMode): void {
    if (mode === WorkCenterProductionMode.Manual) {
      this.manualMode = true;
      this.subscriptions.add(
        this.outputContainerListService
          .getManualMachineMode()
          .pipe(
            tap((manualMachineMode) => {
              this.manualMachineMode = manualMachineMode;
            })
          )
          .subscribe()
      );
    }
  }

  private openPalletCreationModal(container?: OutputChangeQuantity): void {
    this.manualModeService.getPalletCreationData(this.activeProductionOrderId).subscribe((articleData) => {
      const palletQuantity = container?.producedMaterialId ? container?.quantity : articleData.containerQuantity;
      const modalAction = container?.producedMaterialId
        ? 'CREATE_PALLET_MODAL.CHANGE_PALLET_QUANTITY'
        : 'CREATE_PALLET_MODAL.CREATE_PALLET';
      const totalQuantity = container?.producedMaterialId ? this.getMaxValueAsQuantity() : this.getComparedQuantity();

      this.dialogService.open(CreatePalletModalComponent, {
        header: this.translateService.instant(modalAction, { articleClassification: container?.classification }),
        data: {
          article: this.manualModeService.mapArticleFromManualData(articleData),
          quantity: palletQuantity,
          maxQuantity: totalQuantity,
          ssccCode: container?.ssccCode || articleData.ssccCode || '-',
          sequenceNumber: container?.sequenceNumber || articleData.producedMaterialSequenceNumber,
          productionOrderId: this.activeProductionOrderId,
          producedMaterialId: container?.producedMaterialId,
          submitButtonLabel: modalAction
        }
      });
    });
  }

  private getComparedQuantity(): Quantity {
    return this.MAX_PALLET_QUANTITY > this.grossQuantity.value ? this.grossQuantity : this.getMaxValueAsQuantity();
  }

  private getMaxValueAsQuantity(): Quantity {
    return { value: this.MAX_PALLET_QUANTITY };
  }

  private openFinishManualCuttingOrderModal(
    outputFinish: OutputFinish,
    outputFinishData?: ProductionOrderManualModeFinishingDataViewModel,
    isFromPallet?: boolean
  ): void {
    this.dialogService.open(OrderFinishModalComponent, {
      header: this.translateService.instant('MANUAL_MODE.CONFIRM_TIMES'),
      data: {
        activeProductionOrder: this.activeProductionOrder,
        outputFinish,
        outputFinishData,
        isFromPallet
      }
    });
  }

  private openFinishOrderSimpleModal(
    articleData: CreateManualPalletData,
    sortingValues: ProductionOrderManualModeFinishingDataViewModel,
    outputFinish: OutputFinish,
    isFromPallet: boolean
  ): void {
    this.dialogService.open(OrderFinishSimpleModalComponent, {
      header: this.translateService.instant('MANUAL_MODE.FINISH_JOB'),
      data: {
        activeProductionOrder: this.activeProductionOrder,
        article: this.manualModeService.mapArticleFromManualData(articleData),
        submitLabel: 'MANUAL_MODE.FINISH_JOB',
        sortingValues,
        outputFinish,
        shiftDuration: this.shiftDuration,
        isFromPallet
      }
    });
  }

  private openFinishOrderMultiUserModal(
    articleData: CreateManualPalletData,
    sortingValues: ProductionOrderManualModeFinishingDataViewModel,
    outputFinish: OutputFinish,
    isFromPallet: boolean
  ): void {
    this.dialogService.open(OrderFinishMultiUserModalComponent, {
      header: this.translateService.instant('MANUAL_MODE.FINISH_JOB'),
      data: {
        activeProductionOrder: this.activeProductionOrder,
        article: this.manualModeService.mapArticleFromManualData(articleData),
        submitLabel: 'MANUAL_MODE.FINISH_JOB',
        sortingValues,
        outputFinish,
        isFromPallet
      }
    });
  }

  private finishManualOrderModal(outputFinish: OutputFinish, isFromPallet: boolean): void {
    LoadingNotificationService.publish(outputFinish.loadingTopic, true);
    this.manualModeService
      .getCombinedData(this.activeProductionOrderId)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(outputFinish.loadingTopic, false);
        })
      )
      .subscribe(([articleData, outputFinishData, employeeData]) => {
        const isMultiUserMode = outputFinishData.checkoutPageMode === ManualMachineCheckoutPageMode.MultiUser && employeeData?.length > 0;
        const isCuttingMode = this.manualMachineMode === ManualMachineMode.Cutting;

        if (isCuttingMode) {
          this.openFinishManualCuttingOrderModal(outputFinish, outputFinishData, isFromPallet);
        } else {
          const modalHandler = isMultiUserMode ? this.openFinishOrderMultiUserModal : this.openFinishOrderSimpleModal;
          modalHandler.call(this, articleData, outputFinishData, outputFinish, isFromPallet);
        }
      });
  }

  private openCancelProductionOrderModal(): void {
    if (this.manualMode) {
      this.dialogService
        .open(ModalConfirmComponent, {
          header: this.translateService.instant('MANUAL_CANCEL_MODAL.CANCEL_PRODUCTION_ORDER'),
          data: { question: this.translateService.instant('MANUAL_CANCEL_MODAL.CANCEL_PRODUCTION_ORDER_QUESTION'), acceptable: true }
        })
        .onClose.subscribe((isDialogClosed: boolean) => {
          this.submitCancelProductionOrderModal(isDialogClosed);
        });
    }
  }

  public submitCancelProductionOrderModal(isDialogClosed: boolean): void {
    if (isDialogClosed) {
      LoadingNotificationService.publish(this.LOADING_TOPIC, true);
      this.productionOrderDsService
        .cancelProductionOrder(this.activeProductionOrderId)
        .pipe(
          finalize(() => {
            LoadingNotificationService.publish(this.LOADING_TOPIC, false);
          })
        )
        .subscribe(() => {
          this.navigateAfterCancel();
        });
    }
  }

  private navigateAfterCancel(): void {
    this.router.navigate([nav.ordersList]);
  }
}
