import { Injectable } from '@angular/core';
import { ActiveOrderDsService } from '@app/core/data-services/active-order-ds/active-order-ds.service';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { CreateAndMountVirtualSecondaryContainerEntry, MaterialAvailableAtLocation, SecondaryMaterialService } from 'chronos-core-client';
import { ListValue } from 'chronos-shared';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToolDsService {
  constructor(
    private secondaryMaterialService: SecondaryMaterialService,
    private activeOrderDsService: ActiveOrderDsService,
    private activeWorkCenterService: ActiveWorkCenterService
  ) {}

  public setPunchingPressure(punchingPressure: number, articleId: number): Observable<void> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.secondaryMaterialService.setPunchingPressure({
      articleId,
      body: {
        punchingPressure,
        workCenterId
      }
    });
  }

  public mountToolForActiveOrder(identificationCode: string, containerId: number, materialBlockId: number): Observable<void> {
    return this.secondaryMaterialService.mountSecondaryContainer({
      workCenterId: this.activeWorkCenterService.getWorkCenterId(),
      body: {
        identificationCode,
        containerId,
        productionOrderId: this.activeOrderDsService.getActiveOrderId(),
        materialBlockId
      }
    });
  }

  public getVirtualToolReasons(): Observable<ListValue[]> {
    return this.secondaryMaterialService.getVirtualSecondaryContainerReasons();
  }

  public createVirtualTool(body: CreateAndMountVirtualSecondaryContainerEntry): Observable<void> {
    return this.secondaryMaterialService.createAndMountVirtualSecondaryContainer({
      workCenterId: this.activeWorkCenterService.getWorkCenterId(),
      body
    });
  }

  public replaceVirtualTool(mountedMaterialId: number, virtualContainerId: number, newContainerId: number): Observable<void> {
    return this.secondaryMaterialService.replaceVirtualSecondaryContainer({
      mountedMaterialId,
      body: {
        virtualContainerId,
        newContainerId,
        workCenterId: this.activeWorkCenterService.getWorkCenterId()
      }
    });
  }

  public getAvailableForAtLocation(): Observable<MaterialAvailableAtLocation> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.secondaryMaterialService.getMaterialAvailable({ workCenterId });
  }

  public getAvailableForAtLocationForArtice(articleId): Observable<MaterialAvailableAtLocation> {
    return this.secondaryMaterialService.getMaterialAvailableForArticle({
      workCenterId: this.activeWorkCenterService.getWorkCenterId(),
      articleId
    });
  }

  public consumeMaterial(containerId: number, quantity: number): Observable<void> {
    return this.secondaryMaterialService.consume({
      workCenterId: this.activeWorkCenterService.getWorkCenterId(),
      body: {
        containerId,
        quantity
      }
    });
  }

  public dismountSecondaryContainer(
    mountedMaterialId: number,
    shouldPrintLabel: boolean,
    containerId: number,
    remainingQuantity?: number,
    runId?: number,
    workCenterId?: number
  ): Observable<void> {
    return this.secondaryMaterialService.dismount({
      mountedMaterialId,
      body: {
        containerId,
        workCenterId: workCenterId ?? this.activeWorkCenterService.getWorkCenterId(),
        shouldPrintLabel,
        remainingQuantity,
        runId
      }
    });
  }
}
