import { Component, OnInit } from '@angular/core';
import { notificationTopic } from '@app/shared/utils';
import { TranslateService } from '@ngx-translate/core';
import { ApprovalReportService, Quantity, SetProductionQuantitiesEntry } from 'chronos-core-client';
import { LoadingNotificationService, LogService } from 'chronos-shared';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-modal-change-order-quantity',
  templateUrl: './modal-change-order-quantity.component.html',
  styleUrls: ['./modal-change-order-quantity.component.scss']
})
export class ModalChangeQuantityComponent implements OnInit {
  public readonly LOADING_TOPIC = notificationTopic.modalApproveChangeQuantity;

  public goodQuantity: Quantity;
  public waste: Quantity;
  public maculature: Quantity;
  public updatedGoodQuanity: number;
  public updatedWaste: number;
  public updatedMaculature: number;
  public isEnabled: true;
  public workCenterId: number;
  public approvalReportDate: string;
  public approvalReportLineId: number;

  public goodQuantitLabel: string;
  public wasteLabel: string;
  public maculatureLabel: string;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private approvalReportService: ApprovalReportService,
    private translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    this.goodQuantity = this.config.data?.goodQuantity;
    this.waste = this.config.data?.waste;
    this.maculature = this.config.data?.maculature;
    this.updatedWaste = this.waste?.value;
    this.updatedMaculature = this.maculature?.value;
    this.workCenterId = this.config.data?.workCenterId;
    this.approvalReportDate = this.config.data?.approvalReportDate;
    this.approvalReportLineId = this.config.data?.approvalReportLineId;

    this.goodQuantitLabel = this.translateService.instant('APPROVE.CHANGE_QUANTITY_FORM.GOOD_QUANTITY');
    this.wasteLabel = this.translateService.instant('APPROVE.CHANGE_QUANTITY_FORM.WASTE');
    this.maculatureLabel = this.translateService.instant('APPROVE.CHANGE_QUANTITY_FORM.MACULATURE');
  }

  public updateWaste(value: number) {
    this.updatedWaste = value;
  }

  public updateMaculature(value: number) {
    this.updatedMaculature = value;
  }

  public updateGoodQuantity(value: number) {
    this.updatedGoodQuanity = value;
  }

  public acceptModal(): void {
    LoadingNotificationService.publish(this.LOADING_TOPIC, true);

    this.approvalReportService
      .putApprovalReportProductionQuantities({
        workCenterId: this.workCenterId,
        approvalReportDate: this.approvalReportDate,
        approvalReportLineId: this.approvalReportLineId,
        body: {
          goodQuantity: this.updatedGoodQuanity,
          wasteQuantity: this.updatedWaste,
          maculatureQuantity: this.updatedMaculature
        }
      })
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
        })
      )
      .subscribe(() => {
        LogService.success('SUCCESS_MESSAGE.APPROVAL_QUANTITY_CHANGED');
        this.ref.close(true);
      });
  }

  public rejectModal(): void {
    this.ref.close(false);
  }
}
