import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { nav } from '@app/shared/utils';
import { Router } from '@angular/router';
import { AppSettingsQuery } from 'chronos-shared';
import { ActiveOrderQuery } from '@app/core/global-state';

@Component({
  selector: 'app-qa-log',
  templateUrl: './qa-log.component.html',
  styleUrls: ['./qa-log.component.scss']
})
export class QaLogComponent implements OnInit {
  public qaLogTabs: MenuItem[];
  public activeTab: MenuItem;

  constructor(private router: Router, private appSettingsQuery: AppSettingsQuery, private activeOrderQuery: ActiveOrderQuery) {}

  public ngOnInit(): void {
    const showInactive = this.appSettingsQuery.showInactiveElement();
    this.qaLogTabs = [];

    if (showInactive) {
      this.qaLogTabs.push({
        label: 'QA_LOG.QA_CHECKS',
        disabled: false,
        routerLink: nav.routes.qaLogChecks
      });
      this.qaLogTabs.push({
        label: 'QA_LOG.NC',
        disabled: false,
        routerLink: nav.routes.qaLogNc
      });
    }

    this.qaLogTabs.push({
      label: 'QA_LOG.QA_HISTORY',
      disabled: false,
      routerLink: nav.routes.qaLogHistory
    });

    this.qaLogTabs.push({
      label: 'QA_LOG.SAMPLING_LOG',
      disabled: false,
      routerLink: nav.routes.samplingLog
    });

    this.activeTab = this.getActiveTab(this.qaLogTabs);
  }

  private getActiveTab(tabs: MenuItem[]): MenuItem {
    return tabs.find((tab) =>
      this.router.isActive(tab.routerLink, { paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored' })
    );
  }
}
