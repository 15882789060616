/* tslint:disable */
/* eslint-disable */
export enum MachineState {
  Unknown = 'Unknown',
  Idle = 'Idle',
  Setup = 'Setup',
  Run = 'Run',
  Downtime = 'Downtime',
  ManualMachine = 'ManualMachine'
}
