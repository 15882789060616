import { Injectable } from '@angular/core';
import {
  Setting,
  SettingHierarchyViewModel,
  SettingValuesViewModel,
  SettingsService,
  SettingEntityInfoViewModel,
  SettingHistoryViewModel,
  UpdateSettingEntry,
  ExportSettingsResponse,
  SettingValueRaw
} from 'chronos-core-client';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingDsService {
  constructor(private settingsService: SettingsService) {}

  public getSettings(): Observable<Setting[]> {
    return this.settingsService.getSettings();
  }

  public getSettingValues(hierarchyType: string, hierarchyLevel: string, entityId: string): Observable<SettingValuesViewModel[]> {
    return this.settingsService.getSettingValues({
      hierarchyType,
      hierarchyLevel,
      entityId: encodeURIComponent(entityId)
    });
  }

  public getSettingHierarchy(hierarchyType: string): Observable<SettingHierarchyViewModel> {
    return this.settingsService.getSettingHierarchy({ hierarchyType });
  }

  public getSettingEntityInfo(hierarchyType: string, hierarchyLevel: string, entityId: string): Observable<SettingEntityInfoViewModel> {
    return this.settingsService.getSettingEntityInfo({
      hierarchyType,
      hierarchyLevel,
      entityId: encodeURIComponent(entityId)
    });
  }

  public getSettingHistory(
    settingId: number,
    hierarchyType: string,
    hierarchyLevel: string,
    entityId: string
  ): Observable<SettingHistoryViewModel[]> {
    return this.settingsService.getSettingHistory({
      settingId,
      hierarchyType,
      hierarchyLevel,
      entityId: encodeURIComponent(entityId)
    });
  }

  public updateSetting(settingId: number, hierarchyLevel: string, externalId: string, body: UpdateSettingEntry): Observable<void> {
    return this.settingsService.updateSetting({
      settingId,
      hierarchyLevel,
      externalId,
      body
    });
  }

  public importSettings(csvFile: string, updatedBy: string): Observable<void> {
    return this.settingsService.importSettings({
      body: {
        csvFile,
        updatedBy
      }
    });
  }

  public exportSettings(): Observable<ExportSettingsResponse> {
    return this.settingsService.exportSettings();
  }

  public updateSettingsBatch(
    hierarchyLevel: string,
    externalId: string,
    settings: Array<SettingValueRaw>,
    updatedBy: string
  ): Observable<void> {
    return this.settingsService.updateSettingsBatch({
      hierarchyLevel,
      externalId,
      body: {
        settingValuesRaw: settings,
        updatedBy
      }
    });
  }
}
