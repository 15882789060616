import { Injectable } from '@angular/core';
import {
  ActiveEmployeeRegistration,
  ActiveEmployeeRegistrationData,
  EmployeeInfo,
  EmployeeRegistrationHistory,
  EmployeeRegistrationLoginDetails,
  EmployeeRegistrationService,
  EmployeeRegistrationShift,
  EmployeeRole
} from 'chronos-core-client';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class EmployeeRegistrationDsService {
  constructor(private employeeRegistrationService: EmployeeRegistrationService) {}

  public getActiveLogin(workCenterId: number): Observable<ActiveEmployeeRegistration> {
    return this.employeeRegistrationService.getActiveLogin({ workCenterId });
  }

  public getActiveEmployeeRegistrationData(workCenterId: number): Observable<ActiveEmployeeRegistrationData> {
    return this.employeeRegistrationService.getActiveEmployeeRegistrationData({ workCenterId });
  }

  public getLoginDetails(workCenterId: number, employeeId: number): Observable<EmployeeRegistrationLoginDetails> {
    return this.employeeRegistrationService.getLoginDetails({
      workCenterId,
      employeeId
    });
  }

  public getShiftsForFilter(workCenterId: number): Observable<EmployeeRegistrationShift[]> {
    return this.employeeRegistrationService.getShiftsForFilter({ workCenterId });
  }

  public getShiftsForFilterApproval(workCenterId: number, approvalReportDate: string): Observable<EmployeeRegistrationShift[]> {
    return this.employeeRegistrationService.getShiftsForFilterApproval({
      workCenterId,
      approvalReportDate
    });
  }

  public getShiftsForFilterShiftReport(workCenterId: number, shiftId: number): Observable<EmployeeRegistrationShift[]> {
    return this.employeeRegistrationService.getShiftsForFilterShiftReport({
      workCenterId,
      shiftId
    });
  }

  public getHistory(workCenterId: number, shiftIds: number[]): Observable<EmployeeRegistrationHistory[]> {
    return this.employeeRegistrationService.getHistory({
      workCenterId,
      shiftIds
    });
  }

  public employeeLogin(
    employeeId: number,
    loginTime: string,
    note?: string,
    registrationPurpose?: string,
    role?: EmployeeRole,
    workCenterId?: number
  ): Observable<void> {
    return this.employeeRegistrationService.login({
      body: {
        employeeId,
        loginTime,
        note,
        registrationPurpose,
        role,
        workCenterId
      }
    });
  }

  public updateRegistration(employeeId: number, note?: string, role?: EmployeeRole, workCenterId?: number): Observable<void> {
    return this.employeeRegistrationService.updateRegistration({
      body: {
        employeeRegistrationId: employeeId,
        note,
        role,
        workCenterId
      }
    });
  }

  public employeeLogout(employeeId: number, workCenterId: number): Observable<void> {
    return this.employeeRegistrationService.logout({
      body: {
        employeeId,
        workCenterId
      }
    });
  }

  public getEmployees(): Observable<EmployeeInfo[]> {
    return this.employeeRegistrationService.getEmployees();
  }
}
