import { concat, Observable, of } from 'rxjs';
import {
  Outer,
  OuterLabelType,
  OuterPrintInfo,
  PrintLabelForOuterEntry,
  ProductionOrderService,
  WebSocketClientService
} from 'chronos-core-client';
import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { HubUrlsService } from '@app/core/utils';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { Quantity } from 'projects/chronos-core-client/src/public-api';

@Injectable({ providedIn: 'root' })
export class OutputUnitDsService {
  constructor(
    private webSocketClientService: WebSocketClientService,
    private hubUrlsService: HubUrlsService,
    private activeWorkCenterService: ActiveWorkCenterService,
    private productionOrderService: ProductionOrderService
  ) {}

  public getOutputUnitPrintLabelInfo(productionOrderId: number, outerLabelType?: OuterLabelType): Observable<OuterPrintInfo> {
    return this.productionOrderService.getPrintInfo({
      productionOrderId,
      outerLabelType
    });
  }

  public addWasteAssignmentToOuter(
    productionOrderId: number,
    waste: Quantity,
    maculature: Quantity,
    wasteReasonId?: number
  ): Observable<void> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.productionOrderService.addProductionOrderWasteAssignmentToOuter({
      productionOrderId,
      body: {
        maculature,
        waste,
        wasteReasonId,
        workCenterId
      }
    });
  }

  public deleteWasteAssignmentFromOuter(productionOrderId: number, wasteAssignmentId: number): Observable<void> {
    const workCenterId: number = this.activeWorkCenterService.getWorkCenterId();
    return this.productionOrderService.deleteProductionOrderWasteAssignmentFromOuter({
      productionOrderId,
      body: {
        workCenterId,
        wasteAssignmentId
      }
    });
  }

  public printOutputUnitLabel(productionOrderId: number, request: PrintLabelForOuterEntry): Observable<void> {
    return this.productionOrderService.printLabelForOuter({
      productionOrderId,
      body: request
    });
  }

  public getOutputUnitWithNotifications(productionOrderId: number): Observable<Outer> {
    return this.getOutputUnit(
      productionOrderId,
      OuterLabelType.Outer
     ).pipe(
      switchMap((outer) => concat(of(outer), this.getOutputUnitNotifications(productionOrderId)))
    );
  }

  public getOutputUnit(productionOrderId: number, outerLabelType: OuterLabelType): Observable<Outer> {
    return this.productionOrderService.getOuter({
      productionOrderId,
      outerLabelType
     });
  }

  private getOutputUnitNotifications(productionOrderId: number): Observable<Outer> {
    return this.webSocketClientService.getNotificationsForTopic<Outer>(this.hubUrlsService.getOutputUnit(productionOrderId));
  }

  public changeQuantityOfOutputUnit(productionOrderId: number, newQuantity: number): Observable<void> {
    return this.productionOrderService.changeOuterQuantity({
      productionOrderId,
      body: {
        newOuterQuantity: newQuantity,
        workCenterId: this.activeWorkCenterService.getWorkCenterId()
      }
    });
  }

  public saveQuantityPerOuterAndCopies(
    productionOrderId: number,
    outerLabelType: OuterLabelType,
    quantityPerOuter: number,
    numberOfCopies?: number
  ): Observable<void> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.productionOrderService.changeProductionOrderOuterInfo({
      productionOrderId,
      body: {
        quantityPerOuter,
        outerLabelType,
        numberCopies: numberOfCopies,
        workCenterId
      }
    });
  }
}
