<div class="presetup-modal chronos-dialog--size-md">
  <div class="presetup-modal__container p-d-flex">
    <span *ngIf="combinationIcon" class="combination-icon"><i [ngClass]="combinationIcon"></i></span>
    <app-product-order (click)="toggleContent()" [orderItem]="productionOrder" [isItemCollapsed]="itemCollapsed"></app-product-order>
  </div>
  <form [formGroup]="form" libEnterKeyHandler="submit_modal" class="presetup-modal__container presetup-modal__container--grow">
    <app-parameters [form]="form" [isPresetupParameter]="true"></app-parameters>
  </form>

  <section *ngIf="tableData && tableData.length > 0">
    <div class="p-row">
      <h1 class="page-header">{{ 'MOUNTING.TRACE_MOUNTING' | translate }}</h1>
      <app-trace-material-table [buttonTemplate]="buttonTemplate" [tableData]="tableData"></app-trace-material-table>
    </div>
    <div style="height: 400px"></div>
  </section>

  <ng-template #buttonTemplate let-rowData>
    <ng-container *ngIf="rowData.displayMode === MaterialTableDataRowDisplayMode.BillOfMaterial; else batchButton">
      <app-split-button class="overview__action" [items]="constructButtons(rowData)"></app-split-button>
    </ng-container>
    <ng-template #batchButton>
      <lib-dismount-button
        [loadingTopic]="LOADING_TOPIC + rowData.mountedMaterialId"
        (buttonClicked)="unassignTraceMaterial(rowData.mountedMaterialId)"
      ></lib-dismount-button>
    </ng-template>
  </ng-template>
</div>
<div class="presetup-modal__footer">
  <lib-modal-footer
    [submitLabel]="'ORDER_ITEM.CONFIRM_DATA'"
    (submitClicked)="onSubmitClick()"
    [showDeclineButton]="false"
  ></lib-modal-footer>
</div>
