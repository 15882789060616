import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { productionOrderStatusFlags, Status, taskStatusFlags } from '@app/shared/models';
import { ShiftReportItem } from '@app/core/global-state';
import { KpiIdentifier, ProductionOrderStatus, ShiftReportItemType } from 'chronos-core-client';

@Component({
  selector: 'app-order-item',
  templateUrl: './order-item.component.html',
  styleUrls: ['./order-item.component.scss']
})
export class OrderItemComponent implements OnInit, OnChanges {
  @Input() public content: 'full' | 'mainInfoOnly' = 'full';
  @Input() public shiftReportItem: ShiftReportItem;
  @Input() public isDowntime? = false;

  public readonly STATUS = Status;
  public readonly KPI_IDENTIFIER = KpiIdentifier;
  public readonly PRODUCTION_JOB_STATUS_FLAGS = productionOrderStatusFlags;
  public readonly TASK_STATUS_FLAGS = taskStatusFlags;

  public status: ProductionOrderStatus | Status;
  public violationIcon: string;

  public ngOnInit(): void {
    this.status =
      this.shiftReportItem.type === ShiftReportItemType.Downtime
        ? Status.Downtime
        : (this.shiftReportItem.productionOrder.status as ProductionOrderStatus);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.shiftReportItem) {
      this.violationIcon = this.hasViolation() ? 'icon-warning-solid icon--warning' : 'icon-checked-solid';
    }
  }

  private hasViolation(): boolean {
    return this.shiftReportItem.productionOrder?.kpiValues.some((kpi) => kpi.hasViolation);
  }
}
