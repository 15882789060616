import { Injectable } from '@angular/core';
import {
  QuantityPerPalletViewModel,
  BobbinQuantityViewModel,
  SetupParameterKind,
  SetupParameters,
  SetupParameterService,
  SystemParameterType
} from 'chronos-core-client';
import { Observable } from 'rxjs/internal/Observable';
import { ActiveWorkCenterService } from '@app/core/workcenter';
@Injectable({
  providedIn: 'root'
})
export class ParametersDsService {
  constructor(private setupParameterService: SetupParameterService, private activeWorkCenterService: ActiveWorkCenterService) {}

  public getSetupParameterKinds(productionOrderId: number): Observable<SetupParameterKind> {
    return this.setupParameterService.getSetupParameterKinds({ productionOrderId });
  }

  public setSetupParameterKind(setupParameterKindId: number, productionOrderId: number): Observable<void> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.setupParameterService.putSetupParameterKind({
      productionOrderId,
      body: {
        setupParameterKindId,
        workCenterId
      }
    });
  }

  public getSetupParameters(productionOrderId: number): Observable<SetupParameters> {
    return this.setupParameterService.getSetupParameters({ productionOrderId });
  }

  public setSetupParameter(
    setupParameterId: number,
    paramNumericValue: number,
    paramTextValue: string,
    systemParameterType: SystemParameterType,
    productionOrderId: number
  ): Observable<void> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.setupParameterService.putSetupParameter({
      productionOrderId,
      body: {
        numericValue: paramNumericValue,
        setupParameterId,
        systemParameterType,
        textValue: paramTextValue,
        workCenterId
      }
    });
  }

  public getBobbinQuantity(productionOrderId: number): Observable<BobbinQuantityViewModel> {
    return this.setupParameterService.getBobbinQuantity({ productionOrderId });
  }

  public getPalletQuantity(productionOrderId: number): Observable<QuantityPerPalletViewModel> {
    return this.setupParameterService.getQuantityPerPallet({ productionOrderId });
  }
}
