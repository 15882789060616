import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProductionOrderDsService, QaCheckDsService } from '@app/core/data-services';
import { UiStateQuery } from '@app/core/global-state';
import { ProductionOrderStatus, SamplingDataListViewModel, SearchOrdersViewModel } from 'chronos-core-client';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import * as R from 'ramda';

@Component({
  selector: 'app-sampling-log',
  templateUrl: './sampling-log.component.html',
  styleUrls: ['./sampling-log.component.scss']
})
export class SamplingLogComponent implements OnInit {
  private readonly SEARCH_FILTER_CHAR_LENGTH = 3;
  public productionOrders$: Observable<SearchOrdersViewModel[]>;
  public samplingDataListViewModel$: Observable<SamplingDataListViewModel[]>;
  public selectedProductionOrder: SearchOrdersViewModel;
  public initialProductionOrderIndex: number;
  public selectedOrder: number;
  private productionOrderIdSubject = new BehaviorSubject<number>(undefined);
  private productionOrderId$ = this.productionOrderIdSubject.asObservable();

  private searchListFilterSubject = new BehaviorSubject<string>('');
  private searchListFilter$: Observable<string> = this.searchListFilterSubject.asObservable();

  constructor(
    public productionOrderDsService: ProductionOrderDsService,
    public qaCheckDsService: QaCheckDsService,
    private uiStateQuery: UiStateQuery
  ) {}

  public ngOnInit(): void {
    this.productionOrders$ = combineLatest([
      this.productionOrderDsService.searchOrders(false, true, true, true),
      this.searchListFilter$
    ]).pipe(
      map(([orders, filtervalue]) => this.getFilteredList(orders, filtervalue)),
      tap((orders) => {
        this.selectActiveOrder(orders);
      })
    );

    this.samplingDataListViewModel$ = this.productionOrderId$.pipe(
      filter((productionOrderId) => !!productionOrderId),
      switchMap((productionOrderId) => this.qaCheckDsService.getSamplingDataForProducedMaterial(productionOrderId))
    );
  }

  public onOrderItemSelect(productionOrder: SearchOrdersViewModel): void {
    this.selectedProductionOrder = productionOrder;
    this.productionOrderIdSubject.next(productionOrder.productionOrderId);
  }

  public isItemActive(item: SearchOrdersViewModel): boolean {
    return item.productionOrderId === this.selectedProductionOrder?.productionOrderId;
  }

  public searchBarValueChanged(filterValue: string) {
    this.searchListFilterSubject.next(filterValue);
  }

  private getFilteredList(orders: SearchOrdersViewModel[], filterValue: string): SearchOrdersViewModel[] {
    if (filterValue.length >= this.SEARCH_FILTER_CHAR_LENGTH) {
      return orders.filter((x) => x.externalProductionOrderId.toLowerCase().includes(filterValue.toLowerCase()));
    } else {
      return orders;
    }
  }

  private selectActiveOrder(orders: SearchOrdersViewModel[]): void {
    const initialProductionOrderId = this.uiStateQuery.getDocumentOrderId();
    const activeOrder = initialProductionOrderId
      ? orders.find((order) => order.productionOrderId === initialProductionOrderId)
      : orders.find((order) => order.status === ProductionOrderStatus.Active);
    const selectedOrder = activeOrder || R.head(orders);
    this.selectedOrder = selectedOrder.productionOrderId;
    if (selectedOrder) {
      this.initialProductionOrderIndex = orders.findIndex((item) => item.productionOrderId === selectedOrder.productionOrderId);
      this.onOrderItemSelect(selectedOrder);
    }
  }
}
