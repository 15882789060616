import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ParameterBase, ParameterFieldType } from '@app/shared/models';
import { ListValue } from 'chronos-shared';
import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { ParametersBaseService, ParametersFactoryService } from '@app/core/services/parameters-factory';

@Component({
  selector: 'app-params-field',
  templateUrl: './params-field.component.html',
  styleUrls: ['./params-field.component.scss']
})
export class ParamsFieldComponent implements OnInit, OnDestroy {
  @Input() public parameterField: ParameterBase;
  @Input() public controlField: UntypedFormControl;

  public productionOrder: any;
  public dropdownOptions: ListValue[];

  public readonly PARAMETER_FIELD_TYPES = ParameterFieldType;

  private parametersService: ParametersBaseService;
  private saveField = new Subject<void>();
  private oldValue: string = null;

  constructor(private parametersFactoryService: ParametersFactoryService) {}

  public ngOnInit(): void {
    this.parametersService = this.parametersFactoryService.initParametersService();
    this.productionOrder = this.parametersService.productionOrder;
    this.saveField
      .pipe(
        filter(() => this.controlField.valid),
        switchMap(() => this.requestSaveField())
      )
      .subscribe(() => {
        this.storeOldValue();
      });
    this.initDropdown();
    this.storeOldValue();
  }

  public ngOnDestroy(): void {
    this.saveField.unsubscribe();
  }

  public shouldShowError(): boolean {
    return this.controlField.invalid && !this.controlField.errors.required;
  }

  public shouldShowWarning(): boolean {
    return this.controlField.invalid && this.controlField.errors.required;
  }

  public saveFieldOnBlur(): void {
    if (this.controlField.value !== this.oldValue) {
      this.saveField.next();
    }
  }

  private requestSaveField(): Observable<void> {
    return this.parametersService
      .setSetupParameter(Number(this.parameterField.id), this.controlField.value, this.parameterField.systemParameterType)
      .pipe(catchError(() => EMPTY));
  }

  private storeOldValue(): void {
    this.oldValue = this.controlField.value;
  }

  private initDropdown(): void {
    if (this.parameterField.controlType === this.PARAMETER_FIELD_TYPES.Dropdown) {
      this.dropdownOptions = this.parameterField.options;
    }
  }
}
