import { Injectable } from '@angular/core';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import {
  ContainersService,
  CreateManualPalletData,
  ManualMachineCheckoutPageMode,
  Quantity,
  RunEmployeeWorkTimeData
} from 'chronos-core-client';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContainersDsService {
  constructor(private containersService: ContainersService, private activeWorkCenterService: ActiveWorkCenterService) {}

  public addProducedMaterialWasteAssignment(
    producedMaterialId: number,
    maculature: Quantity,
    waste: Quantity,
    wasteReasonId?: number
  ): Observable<void> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.containersService.addWasteAssignmentToProducedMaterial({
      producedMaterialId,
      body: {
        workCenterId,
        wasteReasonId,
        maculature,
        waste
      }
    });
  }

  public deletePalletWasteAssignment(producedMaterialId: number, wasteAssignmentId: number): Observable<void> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.containersService.deleteWasteAssignmentFromProducedMaterial({
      producedMaterialId,
      body: {
        wasteAssignmentId,
        workCenterId
      }
    });
  }

  public getManualContainerCreationData(productionOrderId: number): Observable<CreateManualPalletData> {
    return this.containersService.getManualContainerCreationData({ productionOrderId });
  }

  public createOutputPalletFromPool(productionOrderId: number, containerTargetQuantity?: number): Observable<void> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.containersService.createContainerFromPool({
      productionOrderId,
      body: {
        workCenterId,
        containerTargetQuantity
      }
    });
  }

  public changeContainerQuantity(producedMaterialId: number, quantity: Quantity, changeForFollowingPallets = false): Observable<void> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.containersService.changeContainerQuantity({
      producedMaterialId,
      body: {
        changeForFollowingPallets,
        newContainerQuantity: quantity.value,
        newContainerQuantityUnitId: quantity.unitId,
        workCenterId
      }
    });
  }

  public printLabel(containerId: number, isReprint: boolean): Observable<void> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.containersService.printLabel({
      containerId,
      body: {
        isReprint,
        workCenterId
      }
    });
  }

  public switchManualOrder(
    manualMachineCheckoutPageMode: ManualMachineCheckoutPageMode,
    productionOrderId: number,
    producedMaterialId: number,
    orderEndTime: Date,
    orderStartTime?: Date,
    runEmployeeWorkTimes?: RunEmployeeWorkTimeData[],
    numberOfWorkers?: number,
    duration?: string
  ): Observable<void> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.containersService.switchManualProductionOrder({
      productionOrderId,
      body: {
        manualMachineCheckoutPageMode,
        endTime: orderEndTime.toISOString(),
        producedMaterialId,
        startTime: orderStartTime?.toISOString(),
        workCenterId,
        numberOfWorkers,
        duration,
        runEmployeeWorkTimes
      }
    });
  }

  public changeContainerBobbinQuantity(
    producedMaterialId: number,
    bobbinQuantity: number,
    bobbinQuantityUnit: string,
    changeFollowingPallets: boolean
  ): Observable<void> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.containersService.changeContainerBobbinQuantity({
      producedMaterialId,
      body: {
        newContainerBobbinQuantity: bobbinQuantity,
        newContainerBobbinQuantityUnitId: bobbinQuantityUnit,
        changeForFollowingPallets: changeFollowingPallets,
        workCenterId
      }
    });
  }

  public setOutputPoolBobbinQuantity(productionOrderId: number, bobbinQuantity: number): Observable<void> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.containersService.setContainerTargetBobbinQuantity({
      productionOrderId,
      body: {
        containerTargetBobbinQuantity: bobbinQuantity,
        workCenterId
      }
    });
  }
}
