import { Observable } from 'rxjs';
import { MountedMaterialForwardSearch, TraceabilityService } from 'chronos-core-client';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TraceabilityDsService {
  constructor(private traceabilityService: TraceabilityService) {}

  public getMountedMaterialsForForwardSearch(
    startDate: string,
    searchText: null | string,
    endDate: string
  ): Observable<MountedMaterialForwardSearch[]> {
    return this.traceabilityService.getMountedMaterialsForForwardSearch({
      startDate,
      searchText,
      endDate
    });
  }

  public getProductionOrdersForBackwardSearch(startDate: string, searchText: null | string, endDate: string) {
    return this.traceabilityService.getProductionOrdersForBackwardSearch({
      startDate,
      searchText,
      endDate
    });
  }

  public getMountedMaterialTraceForForwardSearch(productionOrderId: number, viewMode: string) {
    return this.traceabilityService.getMountedMaterialTraceForForwardSearch({
      productionOrderId,
      viewMode
    });
  }

  public getProductionOrderTraceForBackwardSearch(productionOrderId: number, viewMode: string) {
    return this.traceabilityService.getProductionOrderTraceForBackwardSearch({
      productionOrderId,
      viewMode
    });
  }
}
