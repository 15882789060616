import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { ArticleClassification } from 'chronos-core-client';

export enum ArticleClassificationSize {
  Small = 'small',
  Large = 'large'
}

@Component({
  selector: 'lib-article-classification',
  templateUrl: './article-classification.component.html',
  styleUrls: ['./article-classification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArticleClassificationComponent implements OnInit, OnChanges {
  @Input() public classification: string;
  @Input() public size: string = ArticleClassificationSize.Large;

  public palletType: string;
  public iconSize: string;

  public ngOnInit() {
    this.palletType = this.getPalletType();
    this.iconSize = this.getIconSize();
  }

  public ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes.classification && changes.classification.currentValue !== changes.classification.previousValue) {
      this.palletType = this.getPalletType();
    }
    if (changes.size && changes.size.currentValue !== changes.size.previousValue) {
      this.iconSize = this.getIconSize();
    }
  }

  public getPalletType(): string {
    switch (this.classification) {
      case ArticleClassification.Reel:
        return 'roll';
      case ArticleClassification.Sheet:
      case ArticleClassification.Product:
        return 'pallet';
      case ArticleClassification.Liquid:
        return 'liquid';
      case ArticleClassification.Tool:
        return 'tool';
      default:
        return 'container';
    }
  }

  public getIconSize() {
    switch (this.size) {
      case ArticleClassificationSize.Small:
        return 'classification__icon_small';
      case ArticleClassificationSize.Large:
        return 'classification__icon_large';
      default:
        return 'classification__icon_large';
    }
  }
}
