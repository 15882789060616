import { SetupField } from '@app/shared/models/parameters.model';
import {
  ParameterBase,
  ParameterCheck,
  ParameterDropdown,
  ParameterFieldType,
  ParameterQuantityPerPallet,
  ParameterBobbinQuantityPerPallet,
  ParameterRadio,
  ParameterTextbox
} from '../models/parameter';
import { SystemParameterType } from 'chronos-core-client';

function sortFields(a: ParameterBase, b: ParameterBase) {
  return a.order - b.order;
}

function mapSetupFields(setupField: SetupField): ParameterBase {
  if (setupField.systemParameterType === SystemParameterType.ContainerQuantity) {
    return new ParameterQuantityPerPallet({
      id: setupField.id,
      label: setupField.name,
      type: setupField.type,
      required: setupField.required,
      readonly: !setupField.canEdit,
      order: setupField.order,
      value: setupField.configuredNumericValue,
      defaultValue: setupField.defaultNumericValue,
      minValue: setupField.minimumValue,
      maxValue: setupField.maximumValue,
      systemParameterType: setupField.systemParameterType,
      controlType: ParameterFieldType.PalletQuantity
    });
  }

  if (setupField.systemParameterType === SystemParameterType.BobbinQuantity) {
    return new ParameterBobbinQuantityPerPallet({
      id: setupField.id,
      label: setupField.name,
      type: setupField.type,
      required: setupField.required,
      readonly: !setupField.canEdit,
      order: setupField.order,
      value: setupField.configuredNumericValue,
      defaultValue: setupField.defaultNumericValue,
      minValue: setupField.minimumValue,
      maxValue: setupField.maximumValue,
      systemParameterType: setupField.systemParameterType,
      controlType: ParameterFieldType.BobbinQuantity
    });
  }

  switch (setupField.type) {
    case ParameterFieldType.Number:
      return new ParameterTextbox({
        id: setupField.id,
        label: setupField.name,
        type: setupField.type,
        required: setupField.required,
        readonly: !setupField.canEdit,
        order: setupField.order,
        value: setupField.configuredNumericValue,
        defaultValue: setupField.defaultNumericValue,
        minValue: setupField.minimumValue,
        maxValue: setupField.maximumValue,
        systemParameterType: setupField.systemParameterType,
        controlType: ParameterFieldType.Number
      });

    case ParameterFieldType.Text:
      return new ParameterTextbox({
        id: setupField.id,
        label: setupField.name,
        type: setupField.type,
        required: setupField.required,
        readonly: !setupField.canEdit,
        order: setupField.order,
        value: setupField.configuredTextValue,
        defaultValue: setupField.defaultTextValue,
        minLength: setupField.textMinimumLength,
        maxLength: setupField.textMaximumLength,
        systemParameterType: setupField.systemParameterType,
        controlType: ParameterFieldType.Text
      });

    case ParameterFieldType.Dropdown:
      return new ParameterDropdown({
        id: setupField.id,
        label: setupField.name,
        type: setupField.type,
        options: setupField.listValue,
        required: setupField.required,
        readonly: !setupField.canEdit,
        order: setupField.order,
        value: setupField.configuredTextValue,
        defaultValue: setupField.defaultTextValue,
        systemParameterType: setupField.systemParameterType,
        controlType: ParameterFieldType.Dropdown
      });

    case ParameterFieldType.Check:
      return new ParameterCheck({
        id: setupField.id,
        label: setupField.name,
        type: setupField.type,
        required: setupField.required,
        readonly: !setupField.canEdit,
        order: setupField.order,
        value: setupField.configuredNumericValue,
        defaultValue: setupField.defaultNumericValue,
        systemParameterType: setupField.systemParameterType,
        controlType: ParameterFieldType.Check
      });

    case ParameterFieldType.Radio:
      return new ParameterRadio({
        id: setupField.id,
        label: setupField.name,
        type: setupField.type,
        required: setupField.required,
        readonly: !setupField.canEdit,
        order: setupField.order,
        value: setupField.configuredNumericValue,
        defaultValue: setupField.defaultNumericValue,
        options: setupField.listValue,
        systemParameterType: setupField.systemParameterType,
        controlType: ParameterFieldType.Radio
      });
  }
}

export { sortFields, mapSetupFields };
