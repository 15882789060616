import { Component, OnInit } from '@angular/core';
import { AppSettingsQuery, DismountingPalletInformation, LoadingNotificationService, LogService } from 'chronos-shared';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FinishPhaseService } from '@app/modules/finish-phase/services';
import { notificationTopic } from '@app/shared/utils';
import { finalize, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ManualMachineMode } from 'chronos-core-client';
import { ActiveOrderQuery } from '@app/core/global-state';

@Component({
  selector: 'app-dismount-container-modal',
  templateUrl: './dismount-container-modal.component.html',
  styleUrls: ['./dismount-container-modal.component.scss']
})
export class DismountContainerModalComponent implements OnInit {
  public dismountingPalletInformation: DismountingPalletInformation;
  public printLabel: boolean;
  public returnSheets: number;
  public isFormValid: boolean;
  public allowQuantityChange$: Observable<boolean>;
  public readonly LOADING_TOPIC = notificationTopic.modalDismountContainer;

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private finishPhaseService: FinishPhaseService,
    private appSettingsQuery: AppSettingsQuery,
    private activeOrderQuery: ActiveOrderQuery
  ) {}

  public ngOnInit(): void {
    this.dismountingPalletInformation = this.config.data?.dismountingPalletInformation;
    this.allowQuantityChange$ = this.activeOrderQuery.manualMachineMode$.pipe(
      mergeMap((val) => {
        return this.appSettingsQuery.allowQuantityChange$;
      })
    );
  }

  public dismountContainer(): void {
    LoadingNotificationService.publish(this.LOADING_TOPIC, true);
    this.finishPhaseService
      .dismountContainer(
        this.config.data.containerId,
        this.dismountingPalletInformation.mountedMaterialId,
        this.returnSheets,
        this.printLabel,
        this.dismountingPalletInformation.runId,
        this.dismountingPalletInformation.workCenterId
      )
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
        })
      )
      .subscribe(() => {
        LogService.success('SUCCESS_MESSAGE.MATERIAL_DISMOUNTED');
        this.ref.close(true);
      });
  }

  public onPrintLabelChange(printLabel: boolean): void {
    this.printLabel = printLabel;
  }

  public onReturnSheetsChange(returnSheets: number): void {
    this.returnSheets = returnSheets;
  }

  public onValidityChange(isValid: boolean): void {
    this.isFormValid = isValid;
  }

  public onCloseModal(): void {
    this.ref.close(false);
  }
}
