import { Injectable } from '@angular/core';
import { WorkCenterDetails } from '../models/speed-monitor.model';
import { Observable, BehaviorSubject } from 'rxjs';
import moment from 'moment-mini';

import {
  MachineService,
  MachineData,
  WorkCenterCachedService,
  WorkCenterService,
  WorkCenter,
  WebSocketClientService,
  WorkCenterData
} from 'chronos-core-client';

@Injectable({
  providedIn: 'root'
})
export class SpeedMonitorService {
  public workCenterDetails = new BehaviorSubject<WorkCenterDetails>(null);
  public requestTimeBrowser: string;

  constructor(
    private machineService: MachineService,
    private workCenterService: WorkCenterCachedService,
    private workCenterServiceObj: WorkCenterService,
    private webSocketClientService: WebSocketClientService
  ) {}

  public getWorkCenters() {
    return this.workCenterServiceObj.getWorkCenterList();
  }

  /* Get work center details */
  public getWorkCenterDetails(workCenterId: number): Observable<MachineData> {
    this.requestTimeBrowser = this.getCurrentTimeUTC();
    return this.machineService.getStatus({ workCenterId });
  }

  /* Get current WorkCenter */
  public getWorkCenter(workCenterId: number): Observable<WorkCenter> {
    return this.workCenterService.workCenterGetWorkCenter(workCenterId);
  }

  public getWorkCenterList(): Observable<WorkCenterData[]> {
    return this.workCenterService.workCenterGetWorkCenterList();
  }

  /* Get current time in UTC */
  public getCurrentTimeUTC() {
    const currentTime = new Date();
    return moment.utc(currentTime).format();
  }

  /* Duration Calculation for Downtime, Setup and Idle Mode */
  public getDuration(startTime: string, deltaTime: number): number {
    // Calculate duration
    const startTimeServer = moment.utc(startTime).format('DD/MM/YYYY HH:mm:ss');
    const currentTimeBrowser = moment.utc(this.getCurrentTimeUTC()).format('DD/MM/YYYY HH:mm:ss');
    const difference = moment(currentTimeBrowser, 'DD/MM/YYYY HH:mm:ss').diff(moment(startTimeServer, 'DD/MM/YYYY HH:mm:ss'));

    // Calculate actual duration
    const miliseconds = difference - deltaTime;

    return miliseconds >= 0 ? miliseconds : 0;
  }

  /* Calculate deltaTimeBrowserServer */
  public deltaTimeCalc(currentUtcTime: string, serverUtcTime: string): number {
    const responseTimeBrowser = moment.utc(currentUtcTime).format('DD/MM/YYYY HH:mm:ss');
    const responseTimeServer = moment.utc(serverUtcTime).format('DD/MM/YYYY HH:mm:ss');
    return moment(responseTimeBrowser, 'DD/MM/YYYY HH:mm:ss').diff(moment(responseTimeServer, 'DD/MM/YYYY HH:mm:ss'));
  }

  public getCounterNotifications(workCenterId: number): Observable<any> {
    return this.webSocketClientService.getNotificationsForTopic(`/WorkCenterCounter/${workCenterId}`);
  }

  public getStateNotifications(workCenterId: number): Observable<any> {
    this.requestTimeBrowser = this.getCurrentTimeUTC();
    return this.webSocketClientService.getNotificationsForTopic(`/WorkCenterState/${workCenterId}`);
  }
}
