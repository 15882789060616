import { Injectable } from '@angular/core';
import {
  ApprovalEntry,
  ApprovalReport,
  ApprovalReportConsumption,
  ApprovalReportService,
  ApprovalWorkCenter,
  InfoList,
  ApprovalReportDates,
  SetProductionQuantitiesEntry,
  SetSetupParameterEntry,
  SetSetupParameterKindEntry,
  SetupParameterKind,
  SetupParameters,
  ShiftInfoList,
  WebSocketClientService,
  WorkCenterGroup,
  WorkCenterGroupService,
  InterruptProductionOrderEntry,
  EditProductionOrderSetupPhaseEntry
} from 'chronos-core-client';
import { concat, Observable, of } from 'rxjs';
import { HubUrlsService } from '@app/core/utils';
import { switchMap } from 'rxjs/operators';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { Quantity } from 'projects/chronos-core-client/src/public-api';

@Injectable({
  providedIn: 'root'
})
export class ApproveDsService {
  constructor(
    private workCenterGroupService: WorkCenterGroupService,
    private approvalReportService: ApprovalReportService,
    private webSocketClientService: WebSocketClientService,
    private hubUrlsService: HubUrlsService,
    private activeWorkCenterService: ActiveWorkCenterService
  ) {}

  public getWorkCenterGroupsForActiveMachines(): Observable<WorkCenterGroup[]> {
    return this.workCenterGroupService.getWorkCenterGroupsForActiveMachines();
  }

  public setFinishedInterruptedOrder(
    workCenterId: number,
    isInterrupted: boolean,
    approvalReportDate: string,
    approvalReportLineId: number
  ): Observable<void> {
    return this.approvalReportService.interruptProductionOrder({
      workCenterId,
      approvalReportDate,
      approvalReportLineId,
      body: {
        isInterrupted
      }
    });
  }

  public getApprovalWorkCenters(workCenterGroupId: number, approvalReportDate: string): Observable<ApprovalWorkCenter[]> {
    return this.approvalReportService.getApprovalWorkCenters({
      workCenterGroupId,
      approvalReportDate
    });
  }

  public getApprovalReportWithNotifications(workCenterId: number, approvalReportDate: string): Observable<ApprovalReport> {
    return this.getApprovalReport(workCenterId, approvalReportDate).pipe(
      switchMap((report) => concat(of(report), this.getApprovalReportNotifications(workCenterId, approvalReportDate)))
    );
  }

  public getApprovalConsumptionData(
    workCenterId: number,
    approvalReportDate: string,
    approvalReportLineId: number
  ): Observable<ApprovalReportConsumption> {
    return this.approvalReportService.getProductionOrderConsumption({
      workCenterId,
      approvalReportDate,
      approvalReportLineId
    });
  }

  public confirmApprovalReport(workCenterId: number, approvalReportDate: string): Observable<void> {
    return this.approvalReportService.confirmApprovalReport({
      workCenterId,
      approvalReportDate,
      body: {
        isConfirmed: true
      }
    });
  }

  public putApprovalReportProductionQuantities(
    workCenterId: number,
    approvalReportDate: string,
    approvalReportLineId: number,
    goodQuantity: number,
    wasteQuantity: number,
    maculatureQuantity: number
  ): Observable<void> {
    return this.approvalReportService.putApprovalReportProductionQuantities({
      workCenterId,
      approvalReportDate,
      approvalReportLineId,
      body: {
        goodQuantity,
        wasteQuantity,
        maculatureQuantity
      }
    });
  }

  public getOpenApprovalReportDates(workCenterId: number): Observable<ApprovalReportDates> {
    return this.approvalReportService.getOpenApprovalReportDates({ workCenterId });
  }

  public getApprovalSetupParameterKinds(
    workCenterId: number,
    approvalReportLineId: number,
    approvalReportDate: string
  ): Observable<SetupParameterKind> {
    return this.approvalReportService.getApprovalSetupParameterKinds({
      workCenterId,
      approvalReportLineId,
      approvalReportDate
    });
  }

  public setApprovalReportSetupParameterKind(
    workCenterId: number,
    approvalReportLineId: number,
    approvalReportDate: string,
    setupParameterKindId: number
  ): Observable<void> {
    return this.approvalReportService.putApprovalReportSetupParameterKind({
      workCenterId,
      approvalReportLineId,
      approvalReportDate,
      body: {
        setupParameterKindId,
        workCenterId
      }
    });
  }

  public getApprovalSetupParameters(
    workCenterId: number,
    approvalReportLineId: number,
    approvalReportDate: string
  ): Observable<SetupParameters> {
    return this.approvalReportService.getApprovalSetupParameters({
      workCenterId,
      approvalReportLineId,
      approvalReportDate
    });
  }

  public setApprovalReportParameter(
    workCenterId: number,
    setupParameterId: number,
    approvalReportLineId: number,
    approvalReportDate: string,
    body: SetSetupParameterEntry
  ): Observable<void> {
    return this.approvalReportService.putApprovalReportSetupParameter({
      workCenterId,
      setupParameterId,
      approvalReportLineId,
      approvalReportDate,
      body
    });
  }

  public getShiftInfo(workCenterId: number, approvalReportDate: string): Observable<ShiftInfoList> {
    return this.approvalReportService.getShiftInfos({
      workCenterId,
      approvalReportDate
    });
  }

  public getProductionOrderInfo(workCenterId: number, approvalReportDate: string, productionOrderId: number): Observable<InfoList> {
    return this.approvalReportService.getProductionOrderInfos({
      workCenterId,
      productionOrderId,
      approvalReportDate
    });
  }

  public getDowntimeInfo(workCenterId: number, approvalReportDate: string, downtimeId: number): Observable<InfoList> {
    return this.approvalReportService.getDowntimeInfos({
      workCenterId,
      downtimeId,
      approvalReportDate
    });
  }

  private getApprovalReport(workCenterId: number, approvalReportDate: string): Observable<ApprovalReport> {
    return this.approvalReportService.getApprovalReport({
      workCenterId,
      approvalReportDate
    });
  }

  private getApprovalReportNotifications(workCenterId: number, approvalReportDate: string): Observable<ApprovalReport> {
    return this.webSocketClientService.getNotificationsForTopic<ApprovalReport>(
      this.hubUrlsService.getApprovalReport(workCenterId, approvalReportDate)
    );
  }

  public editApprovalReportSetupPhase(
    setupStartTime: string,
    setupEndTime: string,
    setupQuantity: Quantity,
    wasteQuantity: Quantity,
    maculatureQuantity: Quantity,
    setupStartCounter: number,
    setupEndCounter: number,
    approvalReportLineId: number,
    approvalReportDate: string,
    selectedWorkCenterId: number
  ): Observable<void> {
    const workCenterId = selectedWorkCenterId;
    const editSetUpPhaseEntry: EditProductionOrderSetupPhaseEntry = {
      workCenterId,
      setupStartTime,
      setupStartCounter,
      setupEndTime,
      setupEndCounter,
      setupQuantity,
      wasteQuantity,
      maculatureQuantity
    };

    return this.approvalReportService.editApprovalReportSetupPhase({
      workCenterId,
      approvalReportLineId,
      approvalReportDate,
      body: editSetUpPhaseEntry
    });
  }
}
