import { Injectable } from '@angular/core';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import {
  HeaderKpiViewModel,
  ProductionOrderService,
  ProductionOrderSetupPhaseState,
  QuantityPool,
  WasteAssignment,
  WasteAssignmentService,
  WasteAssignmentType,
  WorkCenterService
} from 'chronos-core-client';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ActiveOrderDataStore } from './active-order-data.store';

@Injectable({ providedIn: 'root' })
export class ActiveOrderDataService {
  constructor(
    private activeWorkCenterService: ActiveWorkCenterService,
    private workCenterService: WorkCenterService,
    private activeOrderDataStore: ActiveOrderDataStore,
    private productionOrderService: ProductionOrderService,
    private wasteAssignmentService: WasteAssignmentService
  ) {}

  public renewWasteAssignment(productionOrderId: number): Observable<WasteAssignment[]> {
    this.activeOrderDataStore.setLoading(true);
    return this.wasteAssignmentService
      .getWasteAssignment({
        productionOrderId,
        wasteAssignmentType: WasteAssignmentType.SetupWaste
      })
      .pipe(
        tap((wasteAllocations: WasteAssignment[]) => {
          this.activeOrderDataStore.update({
            wasteAllocations
          });
          this.activeOrderDataStore.setLoading(false);
        })
      );
  }

  public getSetupPhaseStatus(productionOrderId: number): Observable<ProductionOrderSetupPhaseState> {
    this.activeOrderDataStore.setLoading(true);
    return this.productionOrderService
      .getSetupPhaseState({
        productionOrderId,
        runId: null
      })
      .pipe(
        tap((setupPhaseStatus) => {
          this.activeOrderDataStore.update({
            setupPhaseStatus
          });
          this.activeOrderDataStore.setLoading(false);
        })
      );
  }

  public updateSetupPhaseStatus(setupPhaseStatus: ProductionOrderSetupPhaseState): void {
    this.activeOrderDataStore.update({ setupPhaseStatus });
  }

  public deleteWasteAssignment(productionOrderId: number): Observable<WasteAssignment[]> {
    return this.renewWasteAssignment(productionOrderId);
  }

  public addWasteAssignment(productionOrderId: number): Observable<WasteAssignment[]> {
    return this.renewWasteAssignment(productionOrderId);
  }

  public getHeaderKpi(): void {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    this.productionOrderService.getHeaderKpi({ workCenterId }).subscribe((headerKpi) => {
      this.setHeaderKpi(headerKpi);
    });
  }

  public setHeaderKpi(headerKpi: HeaderKpiViewModel): void {
    this.activeOrderDataStore.update({ headerKpi });
  }

  public getQuantityPool(): Observable<QuantityPool> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.workCenterService.getQuantityPool({ workCenterId }).pipe(
      tap((response) => {
        this.updateQuantityPool(response);
      })
    );
  }

  public updateQuantityPool({ inputQuantity, outputQuantity }: QuantityPool): void {
    this.activeOrderDataStore.update({ inputQuantityPool: inputQuantity, outputQuantityPool: outputQuantity });
  }
}
