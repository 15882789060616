import { Injectable } from '@angular/core';
import { DowntimesService, EditDowntimeStatus } from 'chronos-core-client';
import { Quantity } from 'projects/chronos-core-client/src/public-api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DowntimeDsService {
  constructor(private downtimesService: DowntimesService) {}

  public splitDowntime(splitTime: string, downtimeId: number, workCenterId: number): Observable<void> {
    return this.downtimesService.splitDowntime({
      downtimeId,
      body: {
        splitTime,
        workCenterId
      }
    });
  }

  // Get edit downtime status
  public getEditDowntimeStatus(downtimeId: number): Observable<EditDowntimeStatus> {
    return this.downtimesService.getEditDowntimeStatus({ downtimeId });
  }

  // Update/Save downtime
  public editDowntime(
    downtimeId: number,
    workCenterId: number,
    startTime: string,
    startCounter: number,
    endTime: string,
    endCounter: number,
    wasteQuantity: Quantity
  ): Observable<void> {
    return this.downtimesService.editDowntime({
      downtimeId,
      body: {
        workCenterId,
        startTime,
        startCounter,
        endTime,
        endCounter,
        wasteQuantity
      }
    });
  }

  // Revert edit downtime
  public revertEditDowntime(downtimeId: number, workCenterId: number): Observable<void> {
    return this.downtimesService.revertEditDowntime({
      downtimeId,
      body: {
        workCenterId
      }
    });
  }
}
