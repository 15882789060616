import { Injectable } from '@angular/core';
import { TaskFilters } from './task.model';
import { TaskStore } from './task.store';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { TasksService, WorkCenterTask } from 'chronos-core-client';

@Injectable({ providedIn: 'root' })
export class TaskService {
  constructor(private activeWorkCenterService: ActiveWorkCenterService, private taskService: TasksService, private taskStore: TaskStore) {}

  public getAllTasks(): void {
    this.taskStore.setLoading(true);
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    this.taskService
      .getByWorkCenterId({
        workCenterId
      })
      .subscribe((tasks: WorkCenterTask[]) => {
        this.taskStore.set(tasks);
        this.taskStore.setLoading(false);
      });
  }

  public updateTasks(tasks: WorkCenterTask[]): void {
    this.taskStore.setLoading(true);
    this.taskStore.set(tasks);
    this.taskStore.setLoading(false);
  }

  public updateTaskRecord(task: WorkCenterTask): void {
    this.taskStore.update(task.id, task);
  }

  public updateFilters(filters: TaskFilters): void {
    this.taskStore.updateFilters(filters);
  }

  public resetFilters(): void {
    this.taskStore.resetFilters();
  }
}
