import { SetupFooterCommandBase, SetupFooterService } from '@app/shared/components';
import { notificationTopic, setupPhaseFooterRoutes } from '@app/shared/utils';
import { RunPhaseType, RunSubPhaseType } from 'chronos-core-client';
import { LoadingNotificationService } from 'chronos-shared';
import { finalize } from 'rxjs/operators';

export class NextSetupSubphaseCommandService implements SetupFooterCommandBase {
  public enabled = true;
  public buttonText: string;
  public required = false;
  public subPhase: RunSubPhaseType;
  public route: string;

  public loadingTopic = notificationTopic.footerNextSetupPhase;

  constructor(private setupFooterService: SetupFooterService) {}

  public execute(): void {
    LoadingNotificationService.publish(this.loadingTopic, true);
    this.setupFooterService
      .goToNextSubPhase(this.route, RunPhaseType.Setup, this.subPhase, setupPhaseFooterRoutes)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.loadingTopic, false);
        })
      )
      .subscribe();
  }

  public setNavigationParams(subPhase: RunSubPhaseType, route: string): void {
    this.subPhase = subPhase;
    this.route = route;
  }
}
