import { Injectable } from '@angular/core';
import { ConsumedMaterialDsService } from '@app/core/data-services';
import { MountingService } from '@app/modules/mounting/services';
import { PrimaryMountedMaterialQuery } from '@app/modules/mounting/state';
import { ArticleDescription, LabelPrintResult, PrimaryMaterialMounted } from 'chronos-core-client';
import { AppSettingsQuery, SortDirection } from 'chronos-shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PrimaryMaterialConsumptionModalService {
  constructor(
    private consumedMaterialDsService: ConsumedMaterialDsService,
    private primaryMountedMaterialQuery: PrimaryMountedMaterialQuery,
    private appSettingsQuery: AppSettingsQuery,
    private mountingService: MountingService
  ) {}

  public getConsumedMaterial(): Observable<PrimaryMaterialMounted[]> {
    return this.consumedMaterialDsService.getConsumedMaterial();
  }

  public renewConsumedMaterial(): Observable<PrimaryMaterialMounted[]> {
    return this.consumedMaterialDsService.renewConsumedMaterial();
  }

  public getPalletSequence(): Observable<SortDirection> {
    return this.consumedMaterialDsService.getPalletSequence();
  }

  public updateFilters(showOnlyDummy: boolean): void {
    this.consumedMaterialDsService.updateFilters(showOnlyDummy);
  }

  public updateSorting(palletSequence: SortDirection): void {
    this.consumedMaterialDsService.updateSorting(palletSequence);
  }

  public getActiveMaterialArticle(): Observable<ArticleDescription> {
    return this.primaryMountedMaterialQuery.getActiveMaterialBlock().pipe(map((block) => block.article));
  }

  public getExternalWorkCenterId(): Observable<string> {
    return this.appSettingsQuery.externalWorkCenterId$;
  }

  public dismountConsumedDummy(mounted: PrimaryMaterialMounted): Observable<void> {
    const isLabelPrinted = mounted.container.labelPrintResult === LabelPrintResult.Done;

    return this.mountingService.dismountContainer(
      mounted.container.containerId,
      mounted.mountedMaterialId,
      mounted.consumedQuantity.value,
      isLabelPrinted
    );
  }
}
