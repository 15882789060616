


<div class="signature" [ngClass]="getClassObject()" (click)="onClick($event)">
    <div class="signature__title">
      {{ 'SIGNATURE.TITLE' | translate }}
      <span class="icon-checked-solid icon-tick" *ngIf="!state.highlight"></span>
    </div>
    <div class="signature__content">
      {{ 'SIGNATURE.INFO' | translate }} {{ 'SIGNATURE.SIGN' | translate }} :
      <span class="icon-mounting icon-barcode"></span>
      <div class="input-wrapper" *ngIf="!state.hasScanSignatureResponse">
        <input
          #scanSignatureInput
          type="text"
          [(ngModel)]="signatureData.scanBadgeText"
          class="scan-signature-input scan-signature-placeholder-input"
          (keyup.enter)="onEnter($event.target.value)"
          (input)="onInput($event.target.value)"
          placeholder="{{ 'SIGNATURE.SCAN_BADGE_PLACEHOLDER' | translate }}"
          pInputText
          tabindex="0"
          style="position: absolute; left: -9999px;"
        />
      </div>
        <span *ngIf="state.hasScanSignatureResponse">
          {{ signatureData.name}}
          {{ 'SIGNATURE.SIGNED' | translate }} {{signatureData.timeStamp | date: 'dd.MM.yyyy HH:mm'}}
        </span>
    </div>
    
    <div class="signature__badge--checkbox">
        <p-checkbox
            binary="true"
            [(ngModel)]="signatureData.hasManualInput"
            (onChange)="onBadgeChecked($event)"
            label="{{ 'SIGNATURE.BADGE_CHECK' | translate }}"
            [disabled] = "state.hasManualInputDisabled"
        ></p-checkbox>
    </div>
 
    <div class="signature__badge--elements" *ngIf="signatureData.hasManualInput">
      <div class="autocomplete-wrapper">
        <i class="pi pi-search search-icon" (click)="openEmployeeDropdown()" aria-hidden="true"></i>
        <p-autoComplete
          #employeeAutoComplete
          [(ngModel)]="selectedEmployee$"
          [suggestions]="employees"
          [inputStyle]="{ paddingLeft: '30px', width: inputWidth, height: '35px', border: 'none', borderBottom: '1px solid darkgray' }"
          field="name"
          [autofocus]="true"
          [forceSelection]="true"
          (onSelect)="onAutoCompleteEmployeeSelect(selectedEmployee$)"
          (onClear)="clearTreeSelection()"
          [placeholder]="'SIGNATURE.SEARCH_EMP' | translate"
          (completeMethod)="filterAutoCompleteEmployees($event)"
          class="custom-auto-complete"
          appendTo="body"
        ></p-autoComplete>
      </div>
      <span class="spacer"></span>
      <div class="justification-input-wrapper">
          <label for="justification-input">{{ 'SIGNATURE.JUSTIFICATION' | translate }}</label>  
          <span class="spacer"></span>
          <input
              type="text"
              [(ngModel)]="signatureData.comment"
              class="justification-input"
              (input)="onJustification($event.target.value)"
              (blur)="onJustificationBlur()"
              minlength="4"
              [style.width]="inputWidth"
          />
      </div>
      <div *ngIf="commentError" id="justification-error" class="justification-error" [ngClass]="{'line-clearance-justification-error': isLineClearance()}">
        <small>{{ commentError }}</small>
      </div>
    </div>
</div>
