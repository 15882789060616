<section class="pallet-card-view">
  <div class="pallet-card-view__content">
    <div class="row">
      <div class="pallet-card-view__materials">
        <div class="pallet-card-view__material_block">
          <lib-material-status
            [showPlannedStartTime]="true"
            [styleClass]="MATERIAL_STATUS.NEXT"
            [title]="'MOUNTING.MATERIAL_CHANGE' | translate"
            [plannedStartTime]="plannedStartTime"
            [article]="nextMaterial"
          ></lib-material-status>
        </div>
        <div class="pallet-card-view__material_block--grow"></div>
        <div class="pallet-card-view__material_block">
          <lib-material-status [alignRight]="true" [title]="'MOUNTING.ACTIVE_MATERIAL' | translate" [article]="activeMaterial">
            <div *ngIf="activeMaterial" class="dummy_link dummy_link--card_view" (click)="onDummyPalletButtonClick(activeMaterial.id)">
              <i class="pi pi-plus-circle dummy_link__icon"></i>
              {{ 'MOUNTING.DUMMY' | translate }}
            </div>
          </lib-material-status>
        </div>
      </div>
    </div>
    <div
      cdkDropList
      cdkDropListOrientation="horizontal"
      (cdkDropListDropped)="changeCardViewContainerListSequence($event)"
      class="row draggable-mounting-containers-list"
    >
      <div
        class="col-3 pallet-card-view__col draggable-mounting-container"
        *ngFor="let mountedCard of this.getMountedContainersForCardView(); trackBy: trackByFunction"
      >
        <ng-container [ngSwitch]="mountedCard.containerType">
          <div *ngSwitchCase="'info'" class="pallet-scan">
            <div class="pallet-scan__wrapper">
              <div class="pallet-scan__title">
                {{ 'MOUNTING.SCAN_PALLET_TEXT' | translate }}
              </div>
            </div>
          </div>
          <div *ngSwitchCase="'placeholder'">
            <div class="pallet-placeholder"></div>
          </div>
          <ng-container *ngSwitchCase="'container'">
            <div
              *ngIf="mountedCard.mountedMaterialStatus === MOUNTED_MATERIAL_STATUS.Mounted"
              cdkDrag
              cdkDragLockAxis="x"
              class="draggable-mounting-container"
            >
              <app-mount-pallet-card-item [mountedMaterial]="mountedCard">
                <div
                  cdkDragHandle
                  class="mount-pallet__draggable"
                  [ngClass]="{ 'mount-pallet__draggable--dummy': mountedCard.container.isVirtualContainer }"
                >
                  <span class="hellip">&hellip; &hellip;</span>
                </div>
              </app-mount-pallet-card-item>
            </div>
            <div *ngIf="mountedCard.mountedMaterialStatus !== MOUNTED_MATERIAL_STATUS.Mounted">
              <app-mount-pallet-card-item
                [mountedMaterial]="mountedCard"
                [allowDismount]="machineState === MACHINE_STATE.Downtime"
              ></app-mount-pallet-card-item>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="pallet-card-view__status-panel">
    <div class="pallet-card-status">
      <div class="pallet-card-status__label">{{ 'MOUNTING.MACHINE' | translate }}</div>
      <div class="pallet-card-status__value">{{ externalWorkCenterId }}</div>
    </div>
  </div>
</section>
