import { NgModule } from '@angular/core';
import { ModalProductionPeriodsService } from '@app/modules/orders/containers/order-list/components/modal-production-periods/modal-production-periods.service';
import { ModalProductionLoyaltyService } from '@app/modules/orders/services/modal-production-loyalty/modal-production-loyalty.service';
import { OrderListService } from '@app/modules/orders/services/order-list/order-list.service';
import { ParametersModule } from '@app/modules/parameters/parameters.module';
import { SharedModule } from '@app/shared/shared.module';
import { LoyaltyReasonsComponent } from './containers/order-list/components/loyalty-reasons/loyalty-reasons.component';
import { ModalProductionLoyaltyComponent } from './containers/order-list/components/modal-production-loyalty/modal-production-loyalty.component';
import { ModalProductionPeriodsComponent } from './containers/order-list/components/modal-production-periods/modal-production-periods.component';
import { OrderItemComponent } from './containers/order-list/components/order-item/order-item.component';
import { OrderPrimaryMaterialComponent } from './containers/order-list/components/order-primary-material/order-primary-material.component';
import { PresetupModalComponent } from './containers/order-list/components/presetup-modal/presetup-modal.component';
import { ProductOrderComponent } from './containers/order-list/components/product-order/product-order.component';
import { OrderListComponent } from './containers/order-list/order-list.component';
import { PresetupService } from './services/presetup-modal/presetup-modal.service';

@NgModule({
  declarations: [
    OrderListComponent,
    OrderItemComponent,
    ProductOrderComponent,
    ModalProductionPeriodsComponent,
    PresetupModalComponent,
    ModalProductionLoyaltyComponent,
    LoyaltyReasonsComponent,
    OrderPrimaryMaterialComponent
  ],
  imports: [SharedModule, ParametersModule],
  providers: [OrderListService, ModalProductionPeriodsService, ModalProductionLoyaltyService, PresetupService]
})
export class OrdersModule {}
