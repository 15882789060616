import { ActiveEmployeeRegistration } from 'chronos-core-client';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderService } from '@app/core/services';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { EmployeeRegistrationDetailsService } from '@app/modules/employee-registration/services';
import { version } from '@version';
import { AppSettingsQuery, LanguageService, ListValue } from 'chronos-shared';
import { Observable, of, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { EmployeeRegistrationComponent } from '@app/modules/employee-registration/components/employee-registration/employee-registration.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  public disabledWorkCenter: boolean;

  constructor(
    private activeWorkCenterService: ActiveWorkCenterService,
    private headerService: HeaderService,
    public appSettingsQuery: AppSettingsQuery,
    private languageService: LanguageService,
    private employeeRegistrationDetailService: EmployeeRegistrationDetailsService,
    private dialogService: DialogService,
    private translateService: TranslateService
  ) {}

  public version = version;
  public workCenterOptions$: Observable<ListValue[]>;
  public currentWorkCenter: number;
  public supportedLanguages$: Observable<ListValue[]>;

  public currentLanguage$: Observable<string>;
  public hasWorkCenterSelection$: Observable<boolean>;
  public isProductionEnvironment$: Observable<boolean>;
  public activeEmployeeRegistration: ActiveEmployeeRegistration;
  public isEmployeeRegistrationEnabled: boolean;

  public ngOnInit(): void {
    this.currentLanguage$ = this.languageService.language$;
    this.currentWorkCenter = this.activeWorkCenterService.getWorkCenterId();
    this.workCenterOptions$ = this.headerService.getWorkCenters();

    // added temporarily, will be replaced with proper solution.
    setTimeout(() => {
      this.supportedLanguages$ = this.languageOptions();
    }, 100);

    this.hasWorkCenterSelection$ = this.appSettingsQuery.isWorkCenterSelectionEnabled$;
    this.isProductionEnvironment$ = this.appSettingsQuery.isProductionEnvironment$;
    this.isEmployeeRegistrationEnabled = this.appSettingsQuery.isEmployeeRegistrationEnabled();

    this.employeeRegistrationDetailService
      .getActiveLogin(this.currentWorkCenter)
      .pipe(
        tap((activeEmployee) => {
          this.checkLoginPopuponStartup(activeEmployee);
          this.activeEmployeeRegistration = activeEmployee;
          activeEmployee
            ? this.employeeRegistrationDetailService.enableCancelSubject.next(true)
            : this.employeeRegistrationDetailService.enableCancelSubject.next(false);
        })
      )
      .subscribe();

    this.subscriptions.add(this.updateActiveEmployeeonNotification().subscribe());

    this.subscriptions.add(
      this.headerService.enableWorkCenter$.subscribe((isEnabled) => {
        this.disabledWorkCenter = isEnabled;
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private updateActiveEmployeeonNotification(): Observable<ActiveEmployeeRegistration> {
    return this.headerService.getWorkCenterLoginChangedNotifications(this.currentWorkCenter).pipe(
      tap((activeEmployee) => {
        this.activeEmployeeRegistration = activeEmployee;
        activeEmployee
          ? this.employeeRegistrationDetailService.enableCancelSubject.next(true)
          : this.employeeRegistrationDetailService.enableCancelSubject.next(false);
      })
    );
  }

  public onWorkCenterChange(newWorkCenterId: number): void {
    this.activeWorkCenterService.setWorkCenterId(newWorkCenterId);
    localStorage.setItem('workCenterChange', 'true');
    location.reload();
  }

  public onLanguageSelected(lang: string): void {
    if (lang === null) {
      lang = this.currentLanguage$.toString();
    }
    this.languageService.setDefaultLanguage(lang);
  }

  public onUserClick(): void {
    console.info('UserClicked');
    this.showEmployeeRegistrationPopup();
  }

  private checkLoginPopuponStartup(loggedInEmployee: ActiveEmployeeRegistration): void {
    if (
      (loggedInEmployee === null || loggedInEmployee?.name === null) &&
      this.appSettingsQuery.isEmployeeRegistrationMandatory() &&
      this.appSettingsQuery.enforceEmployeeRegistrationLogin() &&
      this.isEmployeeRegistrationEnabled
    ) {
      this.showEmployeeRegistrationPopup();
    }
  }

  private showEmployeeRegistrationPopup(): void {
    this.dialogService.open(EmployeeRegistrationComponent, {
      header: this.translateService.instant('SHIFT_REPORT.OPEN_TASK_EMPLOYEEREGISTRATION'),
      closable: false,
      data: {
        isLoginDisabled: false,
        enableSumbit: false
      }
    });
  }

  private languageOptions(): Observable<ListValue[]> {
    return of([
      { label: 'Deutsch', value: 'de' },
      { label: 'English (UK)', value: 'en' },
      { label: 'Español', value: 'es' },
      { label: 'Français', value: 'fr' },
      { label: 'Polski', value: 'pl' },
      { label: 'Română', value: 'ro' },
      { label: 'Debug', value: 'dbg' }
    ]);
  }
}
