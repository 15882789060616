import { Component, OnDestroy, OnInit } from '@angular/core';
import { PeriodicQaCheck, CreateManualQaChecksEntry, QaCheckFrequencyType } from 'chronos-core-client';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { QaCheckService } from '@app/core/services';
import { Subscription } from 'rxjs';
import { QaCheckFrequencyTypes } from '@app/modules/run-phase/models/qa-check-frequencytype';
import { StorageKey, StorageService } from 'chronos-shared';
import { SignatureHandlerService } from '@app/core/services/signature/signature-handler.service';
import { QaCheckType, SignatureConfig } from '@app/shared/models/signature';

@Component({
  selector: 'app-automatic-periodic-qa-check',
  templateUrl: './automatic-periodic-qa-check.component.html',
  styleUrls: ['./automatic-periodic-qa-check.component.scss']
})
export class AutomaticPeriodicQaCheckComponent implements OnInit, OnDestroy {
  public automaticPeriodicQaChecks: PeriodicQaCheck = null;
  public isConfirmButtonEnabled = false;
  public manualQaChecksEntry: CreateManualQaChecksEntry;
  public frequencyInMinutes = false;
  public frequencyInProcessingUnit = false;
  public defaultLanguage: string;
  public isSignatureEnabled = false;
  public signatureConfig = {} as SignatureConfig;

  private subscriptions = new Subscription();
  private readonly STORAGE_KEY = StorageKey.defaultLanguage;
  private confirmButtonStatus = false;

  constructor(
    private ref: DynamicDialogRef,
    private conf: DynamicDialogConfig,
    private qaCheckService: QaCheckService,
    private storageService: StorageService,
    private signatureHandlerService: SignatureHandlerService
  ) {}

  public ngOnInit(): void {
    this.subscriptions.add(
      this.qaCheckService.getActivePeriodicCheck(this.conf.data.productionOrderId).subscribe((qaChecks) => {
        this.automaticPeriodicQaChecks = qaChecks;
        if (qaChecks.frequencyType === QaCheckFrequencyType.Minutes) {
          this.frequencyInMinutes = true;
        }
        if (qaChecks.frequencyType === QaCheckFrequencyType.ProcessingUnit) {
          this.frequencyInProcessingUnit = true;
        }
      })
    );

    this.defaultLanguage = this.storageService.getItem(this.STORAGE_KEY);
    this.isSignatureEnabled = this.signatureHandlerService.isQualityCheckSignatureEnabled();
    if (this.isSignatureEnabled) {
      this.checkSignatureIsPresent();
      this.signatureConfig = {
        isSignatureFeatureEnabled: this.isSignatureEnabled,
        qaCheckType: QaCheckType.Automatic
      };
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public submitModal(): void {
    this.ref.close(true);
  }

  public later(): void {
    this.ref.close(false);
  }

  public setConfirmButtonStatus(status: boolean): void {
    this.confirmButtonStatus = status;
    if (this.isSignatureEnabled) {
      this.isConfirmButtonEnabled = this.signatureConfig.isSignaturePresent && status;
    } else {
      this.isConfirmButtonEnabled = status;
    }
  }

  public formatFrequencyType(data: string): string {
    return `FREQUENCY_TYPE.${data.toUpperCase()}`;
  }

  // Check if the signature is present
  private checkSignatureIsPresent(): void {
    this.subscriptions.add(
      this.signatureHandlerService.signatureIsPresent$.subscribe((signatureIsPresent) => {
        this.signatureConfig.isSignaturePresent = signatureIsPresent;
        this.updateConfirmButtonStatus();
      })
    );
  }

  private updateConfirmButtonStatus() {
    this.isConfirmButtonEnabled = this.signatureConfig.isSignaturePresent && this.confirmButtonStatus;
  }
}
