import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProductionQaCheck, QaCheckService } from 'chronos-core-client';
import { NAButtonValidator } from '@app/shared/models';

@Injectable()
export class LineClearanceService {
  public setFocusSubject = new BehaviorSubject<boolean>(false);
  public setFocus$ = this.setFocusSubject.asObservable();

  public checkCapsLockSubject = new BehaviorSubject<KeyboardEvent | MouseEvent>(null);
  public checkCapsLock$ = this.checkCapsLockSubject.asObservable();

  public validationSubject = new BehaviorSubject<NAButtonValidator[]>([]);
  public validation$ = this.validationSubject.asObservable();

  constructor(private qaCheckSerice: QaCheckService) {}

  public getQACheckFields(productionOrderId: number): Observable<ProductionQaCheck[]> {
    return this.qaCheckSerice.getLineClearanceQaChecks({ productionOrderId });
  }
}
