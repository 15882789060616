import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { ProducedMaterial, ProducedMaterialStatus } from 'chronos-core-client';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { OutputPalletsState, OutputPalletsStore } from './output-pallets.store';

@Injectable({
  providedIn: 'root'
})
@QueryConfig({
  sortBy: 'sequence',
  sortByOrder: Order.DESC
})
export class OutputPalletsQuery extends QueryEntity<OutputPalletsState, ProducedMaterial> {
  public outputPallets$: Observable<ProducedMaterial[]> = this.select((state) => state.filter).pipe(
    switchMap((filter) =>
      this.selectAll({
        filterBy: (producedMaterial) => this.filterContainer(producedMaterial, filter)
      })
    )
  );
  public allPalletsFinished$: Observable<boolean> = this.selectAll().pipe(map((pallets) => pallets.every(this.isPalletFinished)));

  constructor(protected store: OutputPalletsStore) {
    super(store);
  }

  public areAllPalletsFinished(): boolean {
    return this.getAll().every(this.isPalletFinished);
  }

  private isPalletFinished(pallet: ProducedMaterial): boolean {
    return pallet.status === ProducedMaterialStatus.Finished;
  }

  public filterContainer(container: ProducedMaterial, filter: string): boolean {
    const containerExistsBySequenceNumber = container.sequence ? container.sequence.toString().includes(filter) : false;

    return containerExistsBySequenceNumber || !filter;
  }
}
