import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  DocumentItem,
  FinishedGoodArticleItem,
  IssueType,
  NonConformanceItem,
  NonConformanceModel,
  NonConformanceStatus,
  NonConformanceType
} from 'chronos-core-client';
import { QaHistoryService } from '@app/modules/qa-history/services/qa-history/qa-history.service';
import * as R from 'ramda';
import { switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { NcDefectiveItemsModalComponent } from './components/nc-defective-items-modal/nc-defective-items-modal.component';
import { DialogService } from 'primeng/dynamicdialog';
import { CancelCreateNCCommandService, SubmitCreateNCCommandService } from '@app/modules/qa-history/commands';
import { AppSettingsQuery } from 'chronos-shared';

@Component({
  selector: 'app-qa-history',
  templateUrl: './qa-history.component.html',
  styleUrls: ['./qa-history.component.scss']
})
export class QaHistoryComponent implements OnInit, OnDestroy {
  private readonly SEARCH_FILTER_CHAR_LENGTH = 3;
  @Input() public showOneOrderOnly = false;

  @Input() public enableCreateNC? = false;

  public articleItems: FinishedGoodArticleItem[];
  public selectedArticleItem: FinishedGoodArticleItem;
  public nonConformances: NonConformanceItem[] = [];
  public selectedNonConformance: NonConformanceItem;
  public nonConformanceModel: NonConformanceModel;
  public updatingNonConformanceModel = false;
  public filteredNonConformances: NonConformanceItem[] = [];
  public cancelCommand: CancelCreateNCCommandService;
  public submitCommand: SubmitCreateNCCommandService;
  public isCreateNCMode: boolean;
  public activeOrderId: number;
  public sortingWasteQuantity: string;
  public updatedInternalMemo: string;
  public articleItemListSubject = new BehaviorSubject<FinishedGoodArticleItem[]>(null);
  public NonConformanceImages: DocumentItem[];
  public NonConformanceDocuments: DocumentItem[];

  private subscriptions = new Subscription();
  private selectedNonConformanceSubject = new Subject<NonConformanceItem>();
  private selectedArticleItemSubject = new Subject<FinishedGoodArticleItem>();
  private searchListFilterSubject = new BehaviorSubject<string>('');
  private searchListFilter$: Observable<string> = this.searchListFilterSubject.asObservable();
  private currentFGArticle: FinishedGoodArticleItem;

  constructor(
    private qaHistoryService: QaHistoryService,
    private dialogService: DialogService,
    private appSettingsQuery: AppSettingsQuery
  ) {}

  public ngOnInit(): void {
    this.subscriptions.add(
      this.qaHistoryService.getFinishedGoodArticles(this.showOneOrderOnly).subscribe((articles) => {
        this.articleItems = articles;
        this.articleItemListSubject.next(this.articleItems);
        this.changeSelectedArticle(R.head(articles));
      })
    );

    this.subscriptions.add(
      this.selectedNonConformanceSubject
        .pipe(
          tap(() => (this.updatingNonConformanceModel = true)),
          switchMap((selectedNc) => this.qaHistoryService.getNonConformance(selectedNc.nonConformanceId))
        )
        .subscribe((selectedNcModel) => {
          this.nonConformanceModel = selectedNcModel;
          this.loadNonConformanceAttachments(this.nonConformanceModel.documents);
          this.updatingNonConformanceModel = false;
        })
    );

    this.subscriptions.add(
      this.selectedArticleItemSubject
        .pipe(switchMap((selectedArticle) => this.qaHistoryService.getNonConformanceList(selectedArticle.articleId)))
        .subscribe((nonConformances) => {
          this.nonConformances = nonConformances;
          this.changeSelectedNonConformance(R.head(nonConformances));
          this.filteredNonConformances = this.nonConformances;
        })
    );

    this.subscriptions.add(
      this.searchListFilter$.subscribe((filterValue: string) => {
        this.filteredNonConformances = this.getFilteredList(this.nonConformances, filterValue);
      })
    );

    this.subscriptions.add(
      this.qaHistoryService.createNCMode$.subscribe((nonConformanceMode) => {
        nonConformanceMode ? this.startNCCreateMode() : this.closeNCCreateMode();
      })
    );
    this.setCommands();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.closeNCCreateMode();
    this.qaHistoryService.disableNCCreateMode();
  }

  public changeSelectedNonConformance(selectedNc: NonConformanceItem): void {
    if (selectedNc) {
      if (!this.isCreateNCMode) {
        this.selectedNonConformance = selectedNc;
        this.resetDocumentsForCarouselToWork();
        this.selectedNonConformanceSubject.next(selectedNc);
      }
    } else {
      this.selectedNonConformance = selectedNc;
      this.resetDocumentsForCarouselToWork();
    }
  }

  public isItemActive(item: NonConformanceItem): boolean {
    return item.nonConformanceId === this.selectedNonConformance.nonConformanceId;
  }

  public isItemPreliminary(item: NonConformanceItem): boolean {
    return item.status === NonConformanceStatus.Preliminary;
  }

  public changeSelectedArticle(selectedArticle: FinishedGoodArticleItem): void {
    if (selectedArticle) {
      this.selectedArticleItem = selectedArticle;
      this.selectedArticleItemSubject.next(selectedArticle);
    }
  }

  public searchBarValueChanged(filterValue: string) {
    this.searchListFilterSubject.next(filterValue);
  }

  private resetDocumentsForCarouselToWork(): void {
    if (this.nonConformanceModel?.documents) {
      this.nonConformanceModel.documents = [];
    }
  }

  public openDefectiveItemsModal(): void {
    this.dialogService.open(NcDefectiveItemsModalComponent, {
      data: { defectiveItems: this.nonConformanceModel?.documents }
    });
  }

  private getFilteredList(items: NonConformanceItem[], filterValue: string): NonConformanceItem[] {
    if (filterValue.length >= this.SEARCH_FILTER_CHAR_LENGTH) {
      return items.filter((x) => x.externalNonConformanceId.toLowerCase().includes(filterValue.toLowerCase()));
    } else {
      return items;
    }
  }

  public quantityChanged(changedQuantity: number): void {
    this.qaHistoryService.updateSortingWasteQuantity(changedQuantity);
  }

  public infoUpdated(updatedInfo: string): void {
    this.qaHistoryService.updateInternalMemo(updatedInfo);
  }

  public createNCButtonClicked(): void {
    if (this.articleItems?.length === 0) {
      const ncModal: NonConformanceModel = {
        documents: [],
        externalNonConformanceId: '',
        isClosed: false,
        isHighWasteOrder: false,
        issueType: IssueType.Info,
        nonConformanceDate: '',
        nonConformanceId: 0,
        nonConformanceType: NonConformanceType.Debitor,
        unitId: '',
        approval: '',
        batchId: '',
        customerId: '',
        customerName: '',
        internalMemo: '',
        memo: '',
        salesId: '',
        salesPosition: '',
        sortingErrorCauseName: '',
        sortingWasteCategory: '',
        sortingWasteOrigin: '',
        sortingWasteQuantity: 0,
        ssccCode: '',
        externalWorkCenterId: ''
      };
      this.nonConformanceModel = ncModal;
    }
    this.qaHistoryService.enableNCCreateMode();
  }

  public startNCCreateMode(): void {
    this.qaHistoryService.createNonConformancePath().subscribe((newNonConformance) => {
      this.currentFGArticle = {
        articleExternalId: newNonConformance.finishedGoodArticle.externalArticleId,
        articleId: newNonConformance.finishedGoodArticle.id,
        articleName: newNonConformance.finishedGoodArticle.articleName,
        configurationId: newNonConformance.finishedGoodArticle.externalConfigurationId,
        customerName: newNonConformance.customerName,
        description: newNonConformance.finishedGoodArticle.configurationName
      } as FinishedGoodArticleItem;
      this.nonConformanceModel.sortingWasteOrigin = newNonConformance.sortingWasteOrigin;
      this.nonConformanceModel.externalWorkCenterId = newNonConformance.externalWorkCenterId;
      this.showOneOrderOnly = true;
      this.articleItems.push(this.currentFGArticle);
      this.articleItemListSubject.next(this.articleItems);
      this.changeSelectedArticle(this.currentFGArticle);
      this.changeSelectedNonConformance({
        batchId: '',
        externalNonConformanceId: '',
        issueType: IssueType.Default,
        nonConformanceDate: '',
        nonConformanceId: 0,
        nonConformanceType: NonConformanceType.Debitor,
        status: NonConformanceStatus.Preliminary
      } as NonConformanceItem);
    });
    this.isCreateNCMode = true;
  }

  public closeNCCreateMode(): void {
    if (this.articleItems?.length === 0) {
      this.nonConformanceModel = null;
    }
    this.isCreateNCMode = false;
    this.showOneOrderOnly = false;
    if (this.qaHistoryService.cancelNCModeSubject && this.articleItems?.includes(this.currentFGArticle)) {
      this.articleItems.pop();
      this.articleItemListSubject.next(this.articleItems);
      this.changeSelectedArticle(R.head(this.articleItems));
    } else if (this.articleItems !== undefined) {
      this.changeSelectedArticle(this.currentFGArticle);
    }
  }

  private setCommands(): void {
    this.cancelCommand = new CancelCreateNCCommandService(this.qaHistoryService);
    this.cancelCommand.enabled = true;
    this.cancelCommand.buttonText = 'QA_HISTORY.CANCEL';

    this.submitCommand = new SubmitCreateNCCommandService(this.qaHistoryService);
    this.submitCommand.enabled = true;
    this.submitCommand.buttonText = 'QA_HISTORY.CREATE';
  }

  public createNCSubmit(): void {
    this.qaHistoryService.createNonConformance().subscribe();
    this.qaHistoryService.disableNCCreateMode();
  }

  private loadNonConformanceAttachments(documents: DocumentItem[]): void {
    this.NonConformanceImages = documents.filter((x) => x.documentType === 'NonConformanceImage');
    this.NonConformanceDocuments = documents
      .filter((x) => x.documentType === 'NonConformanceDocument')
      .sort((a, b) => a.name.localeCompare(b.name));
  }
}
