<section class="sampling-estimation" *ngIf="this.isAqlEnabled || this.isTgsEnabled">
  <h2 class="sampling-estimation__title">{{ 'SAMPLING.SAMPLING_ESTIMATION_TITLE' | translate }}</h2>
  <div *ngIf="this.isAqlEnabled" class="sampling-estimation__details">
    <span class="sampling-estimation__details--text">{{ 'SAMPLING.AQL' | translate }}: &nbsp;</span>
    <span class="sampling-estimation__details--value">
      {{
        'SAMPLING.AQL_SAMPLING_DETAILS'
          | translate
            : {
                aqlPerPiecesSampleQuantity: aqlPerPiecesSampleQuantity,
                aqlSampleQuantity: aqlSampleQuantity.value,
                unitId: unitId
              }
      }}
    </span>
  </div>
  <div *ngIf="this.isTgsEnabled" class="sampling-estimation__details">
    <span class="sampling-estimation__details--text">{{ 'SAMPLING.TGS' | translate }}: &nbsp;</span>
    <span class="sampling-estimation__details--value">
      {{
        'SAMPLING.TGS_SAMPLING_DETAILS'
          | translate
            : {
                tgsPerPiecesSampleQuantity: tgsPerPiecesSampleQuantity,
                tgsSampleQuantity: tgsSampleQuantity.value,
                unitId: unitId
              }
      }}
    </span>
  </div>
</section>
