import { Injectable } from '@angular/core';
import { SettingDsService } from '@app/core/data-services';
import {
  Setting,
  SettingHierarchyViewModel,
  SettingValuesViewModel,
  SettingEntityInfoViewModel,
  SettingHistoryViewModel,
  UpdateSettingEntry,
  ExportSettingsResponse,
  SettingValueRaw
} from 'chronos-core-client';
import { Observable } from 'rxjs';

@Injectable()
export class ResourceConfigurationService {
  constructor(private settingDsService: SettingDsService) {}

  public getSettings(): Observable<Setting[]> {
    return this.settingDsService.getSettings();
  }

  public getSettingValues(hierarchyType: string, hierarchyLevel: string, entityId: string): Observable<SettingValuesViewModel[]> {
    return this.settingDsService.getSettingValues(hierarchyType, hierarchyLevel, entityId);
  }

  public getSettingHierarchy(hierarchyType: string): Observable<SettingHierarchyViewModel> {
    return this.settingDsService.getSettingHierarchy(hierarchyType);
  }

  public getSettingEntityInfo(hierarchyType: string, hierarchyLevel: string, entityId: string): Observable<SettingEntityInfoViewModel> {
    return this.settingDsService.getSettingEntityInfo(hierarchyType, hierarchyLevel, entityId);
  }

  public getSettingHistory(
    settingId: number,
    hierarchyType: string,
    hierachyLevel: string,
    entityId: string
  ): Observable<SettingHistoryViewModel[]> {
    return this.settingDsService.getSettingHistory(settingId, hierarchyType, hierachyLevel, entityId);
  }

  public updateSetting(settingId: number, hierarchyLevel: string, externalId: string, entry: UpdateSettingEntry): Observable<void> {
    return this.settingDsService.updateSetting(settingId, hierarchyLevel, externalId, entry);
  }

  public importSetting(csvFile: string, updatedBy: string): Observable<void> {
    return this.settingDsService.importSettings(csvFile, updatedBy);
  }

  public exportSettings(): Observable<ExportSettingsResponse> {
    return this.settingDsService.exportSettings();
  }

  public updateSettingsBatch(hierarchyLevel: string, externalId: string, settings: SettingValueRaw[], updatedBy: string): Observable<void> {
    return this.settingDsService.updateSettingsBatch(hierarchyLevel, externalId, settings, updatedBy);
  }
}
