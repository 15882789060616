<div class="overview page-padding">
  <header>
    <h1 class="page-header">
      {{ 'FINISH_PHASE.OVERVIEW_HEADER' | translate }}
    </h1>
  </header>
  <div class="overview-columns">
    <div class="p-grid">
      <div class="p-col">
        <app-kpi-column
          [kpis]="kpiGroups[KPI_CATEGORY.Quantity]"
          [title]="formatColumnTitle(KPI_CATEGORY.Quantity) | translate"
          [kpiColumn]="KPI_CATEGORY.Quantity"
        ></app-kpi-column>
      </div>
      <div class="p-col">
        <app-kpi-column
          [kpis]="kpiGroups[KPI_CATEGORY.Time]"
          [title]="formatColumnTitle(KPI_CATEGORY.Time) | translate"
          [kpiColumn]="KPI_CATEGORY.Time"
        ></app-kpi-column>
      </div>
      <div class="p-col">
        <app-kpi-column
          [kpis]="kpiGroups[KPI_CATEGORY.Speed]"
          [title]="formatColumnTitle(KPI_CATEGORY.Speed) | translate"
          [kpiColumn]="KPI_CATEGORY.Speed"
        ></app-kpi-column>
      </div>
      <div class="p-col">
        <app-kpi-column
          [kpis]="kpiGroups[KPI_CATEGORY.Project]"
          [title]="formatColumnTitle(KPI_CATEGORY.Project) | translate"
          [kpiColumn]="KPI_CATEGORY.Project"
        ></app-kpi-column>
      </div>
    </div>
  </div>
  <div *ngIf="isAutomatedMode$ | async">
    <lib-machine-chart
      [workCenterId]="workCenterId$ | async"
      [start]="'MACHINE_CHART.START' | translate"
      [rest]="'MACHINE_CHART.REST' | translate"
      [chartMode]="chartMode"
    ></lib-machine-chart>
  </div>
</div>
