<section *ngIf="shutdownReasons$ | async as shutdownReasons" class="shutdown section-padding">
  <header>
    <h1 class="page-header">{{ 'SHUTDOWN.TITLE' | translate }}</h1>
  </header>

  <div class="row">
    <div class="col-6">
      <div class="shutdown-table" [formGroup]="shutdownGroup">
        <div class="shutdown-table__row" [ngClass]="{ invalid: shutdownGroup.controls.shutDownReasonId.invalid }">
          <div class="row">
            <div class="col-4">
              <div class="shutdown-table__row-title">{{ 'SHUTDOWN.ROW_REASON_TITLE' | translate }}*</div>
            </div>
            <div class="col-8">
              <div class="form-group" *ngFor="let reason of shutdownReasons">
                <input class="radio" type="radio" formControlName="shutDownReasonId" [id]="reason.id" [value]="reason.value" />
                <label [htmlFor]="reason.id">
                  {{ reason.label }}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="shutdown-table__row" [ngClass]="{ invalid: shutdownGroup.controls.shutDownEventTime.invalid }">
          <div class="row">
            <div class="col-4">
              <div class="shutdown-table__row-title">{{ 'SHUTDOWN.ROW_TIME_TITLE' | translate }}*</div>
            </div>
            <div class="col-8">
              <div class="form-group">
                <input class="radio" type="radio" formControlName="shutDownEventTime" id="time-1" [value]="SHUTDOWN_TIME_TYPE.ByLastItem" />
                <label for="time-1">
                  {{ 'SHUTDOWN.FROM_LAST_SHEET' | translate }}
                </label>
              </div>

              <div class="form-group">
                <input class="radio" type="radio" formControlName="shutDownEventTime" id="time-2" [value]="SHUTDOWN_TIME_TYPE.ByNow" />
                <label for="time-2">
                  {{ 'SHUTDOWN.STARTING_NOW' | translate }}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="shutdown-table__row">
          <div class="row">
            <div class="col-4">
              <label for="infoText" class="shutdown-table__row-title">
                {{ 'SHUTDOWN.ROW_INFO_TITLE' | translate }}
              </label>
            </div>
            <div class="col-8">
              <textarea [rows]="6" class="shutdown-table__textarea" formControlName="infoText" id="infoText" pInputTextarea></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <footer class="shutdown-footer">
    <lib-button
      (clicked)="navigateToOrderList()"
      [label]="'SETUP_PHASE_FOOTER.CANCEL'"
      [buttonId]="'shutdown_back_button'"
      [sizeClass]="'button--small'"
      [typeClass]="'button--secondary'"
    ></lib-button>
    <lib-button
      (clicked)="submitShutdownForm()"
      [disabled]="shutdownGroup.invalid"
      [label]="'SHUTDOWN.SHUTDOWN'"
      [buttonId]="'shutdown_submit_button'"
      [loadingTopic]="LOADING_TOPIC"
      [sizeClass]="'button--small'"
    ></lib-button>
  </footer>
</section>
