import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { QaCheckField, QaCheckValueChange } from '@app/shared/models';
import { values } from 'ramda';
import { BehaviorSubject, forkJoin, merge, Observable } from 'rxjs';
import {
  ProductionQaCheck,
  QaCheckResult,
  SetQaCheckResultEntry,
  PeriodicQaCheck,
  CreateManualQaChecksEntry,
  CreateManualQaCheckEntry
} from 'chronos-core-client';
import { QaCheckDsService } from '@app/core/data-services';
import moment from 'moment-mini';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class QaCheckService {
  constructor(private activeWorkCenterService: ActiveWorkCenterService, private qaCheckDsService: QaCheckDsService) {}
  public manualQaCheckParamsSubject = new BehaviorSubject<CreateManualQaChecksEntry>(null);
  public manualQaCheckParamsSubject$: Observable<CreateManualQaChecksEntry> = this.manualQaCheckParamsSubject.asObservable();

  public manualQaCheckItem: CreateManualQaCheckEntry;
  public manualQaCheckentryCollection: CreateManualQaCheckEntry[] = [];

  public qaChecksFormControlsInit(qaCheckFields: ProductionQaCheck[]): UntypedFormGroup {
    const controls = {};
    qaCheckFields.forEach((qa) => {
      controls[qa.qaCheckId] = new UntypedFormGroup({
        check: new UntypedFormControl(qa.checkResult, qa.qaCheck?.isCheckMandatory ? Validators.required : []),
        comment: new UntypedFormControl(qa.comment, { updateOn: 'blur' }),
        measurementResult: new UntypedFormControl(qa.measurementResult, { updateOn: 'blur' }),
        id: new UntypedFormControl(qa.id)
      });
    });

    return new UntypedFormGroup(controls);
  }

  public lineClearanceFormControlsInit(qaCheckFields: ProductionQaCheck[]): UntypedFormGroup {
    const controls = {};
    qaCheckFields.forEach((qa) => {
      controls[qa.qaCheckId] = new UntypedFormGroup({
        check: new UntypedFormControl(qa.checkResult, Validators.required),
        comment: new UntypedFormControl(qa.comment, { updateOn: 'blur' }),
        id: new UntypedFormControl(qa.id)
      });
    });

    return new UntypedFormGroup(controls);
  }

  public setCheckResultMultipleRows(lineClearanceChecks: Observable<ProductionQaCheck[]>, barCode: string): Observable<boolean> {
    return lineClearanceChecks.pipe(
      map((checks) => {
        const filteredChecks = checks.filter((check) => check.qaCheck.lineClearanceBarcode === barCode);
        return filteredChecks;
      }),
      mergeMap((checks) => {
        const requests = checks.map((check) => this.setCheckResult(check.id, QaCheckResult.Ok, check.comment, check.measurementResult));
        return forkJoin(requests).pipe(map((req) => req.length > 0));
      })
    );
  }

  public controlsEmittersInit(qaForm: UntypedFormGroup): Observable<QaCheckValueChange> {
    return merge(...this.getQaCheckFormControls(qaForm).map((control) => control.valueChanges));
  }

  public getQaCheckFormControls(qaForm: UntypedFormGroup): UntypedFormGroup[] {
    return values(qaForm.controls) as UntypedFormGroup[];
  }

  public isAllChecksOk(qaCheckForm: UntypedFormGroup): boolean {
    return this.getQaCheckFormControls(qaCheckForm).every((control) => control.value.check === QaCheckResult.Ok);
  }

  public getQaCheckDescriptionData(id: number, qaCheckFields: ProductionQaCheck[]): QaCheckField {
    const currentField = qaCheckFields.find((field: ProductionQaCheck) => field.id === id);
    return {
      label: currentField.qaCheck.name,
      description: currentField.qaCheck.description,
      isCritical: currentField.qaCheck.isCritical,
      measurementResultUnitId: currentField.qaCheck.measurementResultUnitId,
      isLineClearanceCheck: currentField.qaCheck.isLineClearanceCheck
    };
  }

  public setFormFieldsToOk(formValue: any, qaForm: UntypedFormGroup): void {
    this.getQaCheckFormControls(qaForm).forEach((control) => {
      control.get('check').patchValue(formValue, { emitEvent: false });
    });

    qaForm.updateValueAndValidity();
  }

  public getCheckControl(id: number, qaForm: UntypedFormGroup, qaCheckFields: ProductionQaCheck[]): UntypedFormControl {
    const currentField = qaCheckFields.find((field) => field.id === id);
    const currentFormGroup = qaForm.get(currentField.qaCheckId.toString()) as UntypedFormGroup;
    return currentFormGroup.get('check') as UntypedFormControl;
  }

  public getQaCheckOkEntry(isOk: boolean): SetQaCheckResultEntry {
    const formValue = isOk ? QaCheckResult.Ok : null;
    return {
      checkResult: formValue,
      workCenterId: this.activeWorkCenterService.getWorkCenterId()
    };
  }

  public setManualQACheckeCollection(qaForm: UntypedFormGroup) {
    this.manualQaCheckentryCollection = [];
    this.getQaCheckFormControls(qaForm).forEach((control) => {
      this.manualQaCheckentryCollection.push({
        productionQaCheckId: control.get('id').value,
        checkResult: control.get('check').value,
        comment: control.get('comment').value
      });
    });

    this.manualQaCheckParamsSubject.next({
      creationTime: moment().format('YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]'),
      productionQaChecks: this.manualQaCheckentryCollection
    });
  }

  public setFormFieldsForProducedMaterialToOk(producedMaterialId: number, isAllOk: boolean, qaForm: UntypedFormGroup) {
    const qaCheckEntry = this.getQaCheckOkEntry(isAllOk);

    this.setAllQaChecksForProducedMaterial(producedMaterialId, qaCheckEntry).subscribe(() => {
      this.setFormFieldsToOk(qaCheckEntry?.checkResult, qaForm);
    });
  }

  public setCheckResult(
    productionQaCheckId: number,
    qaCheckResult: QaCheckResult,
    qaCheckComment: string,
    qaMeasurementResult: number
  ): Observable<any> {
    return this.qaCheckDsService.setCheckResult(productionQaCheckId, qaCheckResult, qaCheckComment, qaMeasurementResult);
  }

  public setAllQaChecksForProducedMaterial(producedMaterialId: number, qaCheckResult: SetQaCheckResultEntry): Observable<void> {
    return this.qaCheckDsService.setAllQaChecksForProducedMaterial(producedMaterialId, qaCheckResult);
  }

  public setAllQaChecksForSetup(qaCheckResult: SetQaCheckResultEntry): Observable<void> {
    return this.qaCheckDsService.setAllQaChecksForSetup(qaCheckResult);
  }

  public setAllQaChecksForPeriodic(periodicQaCheckId: number, qaCheckResult: SetQaCheckResultEntry): Observable<void> {
    return this.qaCheckDsService.setAllQaChecksForPeriodic(periodicQaCheckId, qaCheckResult);
  }

  public getManualProductionQaChecks(productionOrderId: number): Observable<PeriodicQaCheck> {
    return this.qaCheckDsService.getManualProductionQaChecks(productionOrderId);
  }

  public createManualQaChecks(manualQaChecksEntry: CreateManualQaChecksEntry): Observable<void> {
    return this.qaCheckDsService.createManualQaChecks(manualQaChecksEntry);
  }

  public getActivePeriodicCheck(productionOrderId: number): Observable<PeriodicQaCheck> {
    return this.qaCheckDsService.getActivePeriodiQaChecks(productionOrderId);
  }
}
