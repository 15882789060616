import { Injectable } from '@angular/core';
import { TaskCategoryService } from '@app/core/global-state';
import { HubUrlsService } from '@app/core/utils';
import { TasksService, WebSocketClientService, WorkCenterTasksByCategory } from 'chronos-core-client';
import { concat, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ActiveWorkCenterService } from '@app/core/workcenter';

@Injectable({ providedIn: 'root' })
export class TaskCategoryListDsService {
  constructor(
    private tasksService: TasksService,
    private activeWorkCenterService: ActiveWorkCenterService,
    private hubUrlsService: HubUrlsService,
    private webSocketClientService: WebSocketClientService,
    private taskCategoryService: TaskCategoryService
  ) {}

  private getTaskCategoryList(workCenterId: number): Observable<WorkCenterTasksByCategory[]> {
    return this.tasksService.getOpenWorkCenterTasksByCategory({ workCenterId });
  }

  private getTaskCategoryListNotifications(workCenterId: number): Observable<WorkCenterTasksByCategory[]> {
    return this.webSocketClientService.getNotificationsForTopic<WorkCenterTasksByCategory[]>(
      this.hubUrlsService.getTaskCountByCategory(workCenterId)
    );
  }

  public getTaskCategoryListWithNotifications(): Observable<WorkCenterTasksByCategory[]> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.getTaskCategoryList(workCenterId).pipe(
      switchMap((categories) => concat(of(categories), this.getTaskCategoryListNotifications(workCenterId))),
      tap((data) => {
        this.taskCategoryService.updateTaskCategories(data);
      })
    );
  }
}
