import { Injectable } from '@angular/core';
import { MaterialTableTreeNode } from '@app/modules/mounting/models/material-table.model';
import {
  CreateAndAssignTraceContainerEntry,
  MaterialAvailableAtLocation,
  MaterialService,
  MaterialType,
  Quantity,
  TraceMaterial,
  TraceMaterialService
} from 'chronos-core-client';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { MountingMaterialServiceDsService } from '../mounting-material-ds';
import { ActiveWorkCenterService } from '@app/core/workcenter';

@Injectable({
  providedIn: 'root'
})
export class TraceMaterialDsService {
  constructor(
    private traceMaterialService: TraceMaterialService,
    private activeWorkCenterService: ActiveWorkCenterService,
    private mountingMaterialServiceDsService: MountingMaterialServiceDsService,
    private matrialService: MaterialService
  ) {}

  public getTraceMaterial(): Observable<MaterialTableTreeNode[]> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.traceMaterialService
      .getTraceMaterialAssigned({ workCenterId })
      .pipe(map((traceMaterial: TraceMaterial) => this.mountingMaterialServiceDsService.mapTraceMaterial(traceMaterial.materialBlocks)));
  }

  public unassignTraceMaterial(mountedMaterialId: number): Observable<MaterialTableTreeNode[]> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.traceMaterialService
      .unassign({
        mountedMaterialId,
        body: {
          workCenterId
        }
      })
      .pipe(switchMap(() => this.getTraceMaterial()));
  }

  public createAndAssignTraceMaterial(body: CreateAndAssignTraceContainerEntry): Observable<MaterialTableTreeNode[]> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.traceMaterialService
      .createAndAssign({
        workCenterId,
        body
      })
      .pipe(switchMap(() => this.getTraceMaterial()));
  }

  public getAvailableTraceMaterial(articleId: number): Observable<MaterialAvailableAtLocation> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.traceMaterialService.getMaterialAvailableForArticle2({
      workCenterId,
      articleId
    });
  }

  public assignTraceMaterial(
    containerId: number,
    materialBlockId: number,
    whiteWasteQuantity?: Quantity
  ): Observable<MaterialTableTreeNode[]> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.matrialService
      .mountContainer({
        workCenterId,
        body: {
          containerId,
          materialBlockId,
          materialType: MaterialType.Trace,
          whiteWasteQuantity
        }
      })
      .pipe(switchMap(() => this.getTraceMaterial()));
  }
}
