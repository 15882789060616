<section class="sampling-log">
  <div *ngIf="productionOrders$ | async as productionOrders" class="sampling-log__sidebar-container">
    <app-scrollable-sidebar
      [itemList]="productionOrders"
      [scrollTo]="initialProductionOrderIndex"
      (searchBarValueChanged)="searchBarValueChanged($event)"
    >
      <app-order-sidebar-item
        *appListItem="let item"
        [item]="item"
        [isItemActive]="isItemActive(item)"
        (itemSelected)="onOrderItemSelect($event)"
      ></app-order-sidebar-item>
    </app-scrollable-sidebar>
  </div>
  <div class="sampling-log__details">
    <app-sampling-log-details
      *ngIf="samplingDataListViewModel$ | async as samplingDataListViewModel; else loading"
      [samplingLogDetails]="samplingDataListViewModel"
    ></app-sampling-log-details>
  </div>

  <ng-template #loading>
    <lib-loading-indicator></lib-loading-indicator>
  </ng-template>
</section>
