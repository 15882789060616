import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ParameterBase, ParametersFields } from '@app/shared/models';
import { ListValue } from 'chronos-shared';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { ParametersBaseService, ParametersFactoryService } from '@app/core/services/parameters-factory';
import { SystemParameterType } from 'chronos-core-client';

@Component({
  selector: 'app-parameters',
  templateUrl: './parameters.component.html',
  styleUrls: ['./parameters.component.scss']
})
export class ParametersComponent implements OnInit, OnDestroy {
  @Input() public form: UntypedFormGroup;
  @Input() public isPresetupParameter: boolean = false;

  @Output() public isParametersValid = new EventEmitter<boolean>();

  public setupParameterKindOptions: ListValue[];
  public setupParameterFields: ParametersFields;
  public isOptionalParamsFormVisible = false;
  public isSetupParamsFormVisible = false;
  public isAdditionalParamsVisible = false;
  public dropdownLoading = true;
  public manualTransportValue: boolean;
  public isManualTransportFormVisible = false;
  public ismanualTransportReadOnly: boolean;
  public manualTransport: ParameterBase;

  private isToolsParamsFormValid = true;
  private parametersService: ParametersBaseService;
  private subscriptions = new Subscription();

  public setupKind = new UntypedFormControl(null, Validators.required);

  constructor(private parametersFactoryService: ParametersFactoryService) {}

  public ngOnInit(): void {
    this.parametersService = this.parametersFactoryService.initParametersService();

    this.form.addControl('setupKind', this.setupKind);

    this.subscriptions.add(
      this.parametersService
        .getSetupParameterKinds()
        .pipe(
          tap((setupInfo) => {
            this.initSetupKind(setupInfo.selectedSetupKindId);
          }),
          tap((setupInfo) => (this.setupParameterKindOptions = [...setupInfo.setupParameterKinds])),
          switchMap(() => this.getSetupParameterFields()),
          finalize(() => (this.dropdownLoading = false))
        )
        .subscribe()
    );

    this.changeValidity();
  }

  private initSetupKind(initialValue: number): void {
    this.setupKind.setValue(initialValue);

    setTimeout(() => {
      this.subscriptions.add(this.setupKind.valueChanges.pipe(switchMap((value) => this.setSelectedParameterKind(value))).subscribe());
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public setSelectedParameterKind(selectedKindId?: number): Observable<ParametersFields> {
    this.isSetupParamsFormVisible = false;
    this.changeValidity();

    if (selectedKindId) {
      return this.parametersService.setSetupParameterKind(selectedKindId).pipe(
        switchMap(() => this.getSetupParameterFields()),
        catchError(() => EMPTY)
      );
    } else {
      return EMPTY;
    }
  }

  public renewSetupParameterFields(): void {
    if (this.setupKind.value) {
      this.getSetupParameterFields().subscribe();
    }
  }

  public setToolsParamsFormStatus(isValid: boolean): void {
    this.isToolsParamsFormValid = isValid;
    this.changeValidity();
  }

  public dropdownError(): boolean {
    return this.setupKind.invalid && !this.setupKind.errors.required;
  }

  public dropdownWarning(): boolean {
    return this.setupKind.invalid && this.setupKind.errors.required;
  }

  private getSetupParameterFields(): Observable<ParametersFields> {
    this.dropdownLoading = true;
    return this.parametersService.getSetupParameters().pipe(
      tap((options) => (this.setupParameterFields = options)),
      tap(() => (this.isSetupParamsFormVisible = true)),
      tap(() => {
        this.checkManualTranport();
      }),
      finalize(() => {
        this.changeValidity();
      }),
      finalize(() => (this.dropdownLoading = false))
    );
  }

  private checkManualTranport(): void {
    this.manualTransport = this.setupParameterFields.optional?.find((x) => x?.systemParameterType === SystemParameterType.ManualTransport);

    this.isManualTransportFormVisible = !!this.manualTransport;
    this.isAdditionalParamsVisible =
      this.isSetupParamsFormVisible && (this.setupParameterFields.toolSetup.length > 0 || this.isManualTransportFormVisible);
    this.manualTransportValue = !!this.manualTransport?.value;
    this.ismanualTransportReadOnly = !!this.manualTransport && this.manualTransport?.readonly;
  }

  private changeValidity(): void {
    const isValid = this.isToolsParamsFormValid && this.isSetupParamsFormVisible;
    this.isParametersValid.emit(isValid);
  }

  public requestSaveField(): void {
    this.parametersService
      .setSetupParameter(this.manualTransport.id, Number(this.manualTransportValue), SystemParameterType.ManualTransport)
      .subscribe();
  }
}
