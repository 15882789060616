import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { OutputContainerService } from '@app/modules/run-phase/services';
import { OuterInfo } from '@app/shared/models';
import { BobbinPrintLabel } from '@app/shared/models/bobbin-print-label';
import { LabelPrintResult, OuterLabelType, ProducedMaterialPrintLabelInfo } from 'chronos-core-client';

@Component({
  selector: 'app-material-label-table',
  templateUrl: './material-label-table.component.html',
  styleUrls: ['./material-label-table.component.scss']
})
export class MaterialLabelTableComponent implements OnInit {
  public readonly COLUMNS = [
    { label: 'LABELS.COLUMN_STATUS', width: '140px' },
    { label: 'LABELS.COLUMN_NUMBER', width: '170px' },
    { label: 'LABELS.COLUMN_QUANTITY', width: '120px' },
    { label: 'LABELS.COLUMN_PRINT', width: '250px' },
    { label: 'LABELS.COLUMN_SSCC', width: '290px' }
  ];

  @Input() public allItemAction: TemplateRef<any>;
  @Input() public perItemAction: TemplateRef<any>;
  @Input() public value: ProducedMaterialPrintLabelInfo[] = [];
  @Output() public labelPrintBobbinButtonClicked = new EventEmitter<BobbinPrintLabel>();
  @Output() public quantityPerOuterChanged = new EventEmitter<OuterInfo>();
  @Input() public slitCount = 0;
  public producedMaterialId?: number;

  public labelPrintResult = LabelPrintResult;
  public isBobbin = false;

  public isQuantityPerOuterValid = false;

  constructor(private outputContainerService: OutputContainerService) {}

  public corePrintBobbinLabel(printLabel: BobbinPrintLabel): void {
    this.labelPrintBobbinButtonClicked.emit(printLabel);
  }

  public quantityPerOuterOrNumberofCopiesChange(info: OuterInfo): void {
    this.outputContainerService
      .changeProducedMaterialPrintInfo(info.producedMaterialId, OuterLabelType.Core, info.quantityPerOuter, info.numberCopies)
      .subscribe();
  }

  public ngOnInit(): void {
    if (this.slitCount > 1) {
      const printInfo = this.value.filter((x) => x.printInfo != null);
      if (printInfo?.length) {
        this.isBobbin = true;
      }
    }
  }
}
