import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ReduceConsumptionModalService } from '@app/modules/mounting/services';
import { ArticleClassification, ArticleDescription, ManualMachineMode, MountedMaterialStatus, Quantity } from 'chronos-core-client';
import { AppSettingsQuery, DismountingPalletInformation, LoadingNotificationService } from 'chronos-shared';
import { notificationTopic } from '@app/shared/utils';
import { finalize, mergeMap } from 'rxjs/operators';
import { Subscription, Observable, of } from 'rxjs';
import { ActiveOrderQuery } from '@app/core/global-state';

@Component({
  selector: 'app-reduce-consumption-modal',
  templateUrl: './reduce-consumption-modal.component.html',
  styleUrls: ['./reduce-consumption-modal.component.scss']
})
export class ReduceConsumptionModalComponent implements OnInit, OnDestroy {
  public readonly LOADING_TOPIC = notificationTopic.modalReduceConsumption;

  public reduceConsumptionInfo: DismountingPalletInformation = this.config.data.reduceInformation;
  public selectedMaterial: ArticleDescription = this.reduceConsumptionInfo.article;
  public inventoryUnitId: string = this.config.data.inventoryUnitId;
  public bomUnitFactor: number = this.config.data.bomUnitFactor;

  private containerId: number = this.config.data.containerId;
  private mountedMaterialId: number = this.reduceConsumptionInfo.mountedMaterialId;
  private mountedMaterialStatus = this.reduceConsumptionInfo.mountedMaterialStatus;
  private shouldPrintLabel: boolean = this.reduceConsumptionInfo.printLabel;
  private workCenterId: number = this.reduceConsumptionInfo.workCenterId;

  public reduceConsumptionForm: UntypedFormGroup;
  public submitLabel: string;
  public containerStatusLabel: string;
  public quantity: Quantity;
  public isPaper = this.isTypePaper();
  public palletType: string;
  public allowQuantityChange$: Observable<boolean>;
  private isDismountMode = this.isDismount();
  private isConsumed = this.isMaterialConsumed();
  private isTool = this.isTypeTool();

  public readonly LOWEST_RETURN_QUANTITY_VALUE = 0;
  public readonly DEFAULT_RETURN_QUANTITY_VALUE = this.getDefaultQuantity();

  private subscriptions = new Subscription();

  constructor(
    private reduceConsumptionModalService: ReduceConsumptionModalService,
    private ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public fb: UntypedFormBuilder,
    private appSettingsQuery: AppSettingsQuery,
    private activeOrderQuery: ActiveOrderQuery
  ) {}

  public ngOnInit(): void {
    const maxQuantity = this.getMaxQuantity();

    this.reduceConsumptionForm = this.fb.group({
      returnQuantity: [
        { value: this.DEFAULT_RETURN_QUANTITY_VALUE, disabled: this.isTool },
        [Validators.min(this.LOWEST_RETURN_QUANTITY_VALUE), Validators.max(maxQuantity), Validators.required]
      ],
      printLabel: this.shouldPrintLabel
    });

    this.subscriptions.add(
      this.reduceConsumptionForm.controls.returnQuantity.valueChanges.subscribe((value) => {
        this.resetPrintLabel(value);
      })
    );

    this.initBasedOnDismount();
    this.resetQuantities();

    this.palletType = this.getPalletType();

    this.allowQuantityChange$ = this.activeOrderQuery.manualMachineMode$.pipe(
      mergeMap((val) => {
        return this.appSettingsQuery.allowQuantityChange$;
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public dismountSecondaryContainer(): void {
    const returnQuantity = this.reduceConsumptionForm.value.returnQuantity;
    const printLabel = this.reduceConsumptionForm.value.printLabel;

    LoadingNotificationService.publish(this.LOADING_TOPIC, true);
    this.reduceConsumptionModalService
      .dismountSecondaryContainer(
        this.mountedMaterialId,
        returnQuantity,
        printLabel,
        this.containerId,
        this.reduceConsumptionInfo.runId,
        this.workCenterId
      )
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
        })
      )
      .subscribe(() => {
        this.ref.close({ materialDataChanged: true });
      });
  }

  public resetQuantities(): void {
    this.reduceConsumptionForm.controls.returnQuantity.setValue(this.DEFAULT_RETURN_QUANTITY_VALUE);
  }

  private isDismount(): boolean {
    return this.isTypeTool() || this.isTypePaper();
  }

  private initBasedOnDismount(): void {
    if (this.isConsumed) {
      this.containerStatusLabel = 'MOUNTING.CONSUMED';
      this.quantity = this.reduceConsumptionInfo.consumed;
    } else {
      this.containerStatusLabel = 'MOUNTING.MOUNTED';
      this.quantity = this.reduceConsumptionInfo.mounted;
    }

    if (this.isDismountMode) {
      this.submitLabel = 'MOUNTING.DISMOUNT';
    } else {
      this.submitLabel = 'MOUNTING.REDUCE_CONSUMPTION_TITLE';
    }
  }

  private getDefaultQuantity(): number {
    return this.isConsumed ? this.reduceConsumptionInfo.consumed.value : this.reduceConsumptionInfo.mounted.value;
  }

  private getMaxQuantity(): number {
    return this.isConsumed ? this.reduceConsumptionInfo.consumed.value : this.reduceConsumptionInfo.initialQuantity.value;
  }

  private isTypeTool(): boolean {
    return this.selectedMaterial.classification === ArticleClassification.Tool;
  }

  private isTypePaper(): boolean {
    return this.selectedMaterial.classification === ArticleClassification.Reel;
  }

  private isMaterialConsumed(): boolean {
    return this.mountedMaterialStatus === MountedMaterialStatus.Consumed;
  }

  private resetPrintLabel(value: number): void {
    if (value && value > 0) {
      this.reduceConsumptionForm.patchValue({ printLabel: this.shouldPrintLabel });
    } else {
      this.reduceConsumptionForm.patchValue({ printLabel: false });
    }
  }

  private getPalletType() {
    const classification = this.selectedMaterial.classification;
    if (classification === 'Reel') {
      return 'roll';
    } else if (classification === 'Sheet' || classification === 'Product') {
      return 'pallet';
    } else {
      return 'container';
    }
  }
}
