import { AfterContentInit, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Quantity, ArticleDescription, ProductType } from 'chronos-core-client';
import { knownUnits } from '../../../models';

type onChanged = (value: number) => void;
type onTouched = () => void;

@Component({
  selector: 'lib-pallet-quantity-calculation',
  templateUrl: './pallet-quantity-calculation.component.html',
  styleUrls: ['./pallet-quantity-calculation.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PalletQuantityCalculationComponent),
      multi: true
    }
  ]
})
export class PalletQuantityCalculationComponent implements OnInit, AfterContentInit, ControlValueAccessor {
  @Input() public formUnitLabel = '';
  @Input() public article: ArticleDescription;
  @Input() public bomUnitId = 'SHT';
  @Input() public disabled = false;
  @Input() public bomUnitFactor? = null;
  @Input() public inventoryQuantity: Quantity;

  public readonly INPUT_STYLE = {
    width: '80px'
  };

  public formInventoryQuantity = 0;
  public height = 0;
  public bomQuantity = 0;
  public decimalDigitCount = 0;
  public inventoryUnitId: string;
  public thickness = 0;
  public isHeightDisplay = false;
  public isInventoryUnitDisplay = false;
  private initialValue = 0;
  private propagateChanged: onChanged = () => {};
  private propagateTouched: onTouched = () => {};

  public ngOnInit() {
    this.decimalDigitCount = this.setDecimalDigitCount(this.bomUnitId);
    this.isInventoryUnitDisplay = this.checkInventoryUnitDisplay();
    this.isHeightDisplay = this.checkHeightDisplay();
    this.inventoryUnitId = this.article?.inventoryUnitId;
    this.thickness = this.article?.thickness;
    this.bomUnitFactor = this.bomUnitFactor ?? this.article?.bomUnitFactor;
  }

  public ngAfterContentInit(): void {
    this.initialValue = this.bomQuantity;
  }

  public writeValue(value: number): void {
    this.countSheets(value);
  }

  public registerOnChange(method: onChanged): void {
    this.propagateChanged = method;
  }

  public registerOnTouched(method: onTouched): void {
    this.propagateTouched = method;
  }

  public countSheets(sheets = 0): void {
    const inventoryUnit = this.isWeightEnabled() ? this.calculateKgFromSheet(sheets) : 0;
    const height = this.checkHeightDisplay() ? this.calculateMmFromSheet(sheets) : 0;

    this.setQuantities(sheets, inventoryUnit, height);
  }

  public countKilograms(inventoryUnit = 0): void {
    const sheets = this.bomUnitFactor > 0 ? inventoryUnit * this.bomUnitFactor : 0;
    const height = this.calculateMmFromSheet(sheets);

    this.setQuantities(sheets, inventoryUnit, height);
  }

  public countMillimeters(height = 0): void {
    const sheets = (height / this.thickness) * 1000;
    const inventoryUnit = this.calculateKgFromSheet(sheets);

    this.setQuantities(sheets, inventoryUnit, height);
  }

  private calculateKgFromSheet(sheets: number): number {
    return this.bomUnitFactor > 0 ? sheets / this.bomUnitFactor : 0;
  }

  private calculateMmFromSheet(sheets: number): number {
    return (sheets * this.thickness) / 1000;
  }

  private roundTo2DecimalPlaces(numberToRound: number): number {
    return Math.round(numberToRound * 100) / 100;
  }

  private ceil(value: number, decimalPlaces: number): number {
    if (!Number.isInteger(decimalPlaces)) {
      throw new Error('Parameter decimalPlaces must be an integer!');
    }
    const scale = Math.pow(10, decimalPlaces);
    return Math.ceil(value * scale) / scale;
  }

  private setQuantities(sheets: number, kilograms: number, millimeters: number): void {
    this.bomQuantity = this.ceil(sheets, this.decimalDigitCount);
    this.formInventoryQuantity = this.roundTo2DecimalPlaces(kilograms);
    this.height = this.roundTo2DecimalPlaces(millimeters);
    this.propagateChanged(this.bomQuantity);
  }

  public resetQuantities(): void {
    this.countSheets(this.initialValue);
  }

  // for sheet and paper roll we have BOMUnitFactor so we can calculate weight
  private isWeightEnabled(): boolean {
    return (
      (this.bomUnitFactor > 0 && this.inventoryQuantity?.unitId === 'KG') || (this.inventoryQuantity?.unitId !== 'KG' && this.thickness > 0)
    );
  }

  private checkInventoryUnitDisplay(): boolean {
    return (
      this.article &&
      this.article.inventoryUnitId &&
      this.article.bomUnitFactor &&
      this.article.inventoryUnitId !== this.article.bomUnitId &&
      this.article.productType === ProductType.RawMaterial
    );
  }

  private checkHeightDisplay(): boolean {
    return (
      this.article &&
      this.article.classification === 'Sheet' &&
      this.article.thickness &&
      this.article.productType === ProductType.RawMaterial
    );
  }

  private setDecimalDigitCount(unitId: string): number {
    if (!knownUnits.WholeUnits.includes(unitId)) return 2;
    return 0;
  }
}
