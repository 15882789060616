import { Injectable } from '@angular/core';
import { SetToolCheckoutResultEntry, ToolCheckoutInfo, ToolService } from 'chronos-core-client';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class ToolCheckoutDsService {
  constructor(private toolService: ToolService) {}

  public getProductionOrderToolCheckoutInfo(productionOrderId: number): Observable<ToolCheckoutInfo[]> {
    return this.toolService.getToolCheckoutInfo({ productionOrderId });
  }

  public setToolCheckoutResult(productionOrderId: number, body: SetToolCheckoutResultEntry): Observable<void> {
    return this.toolService.setToolCheckoutResult({
      productionOrderId,
      body
    });
  }
}
