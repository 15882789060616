import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ActiveProductionOrder,
  ProductionOrder,
  SetSetupParameterEntry,
  SetupParameterKind,
  SystemParameterType
} from 'chronos-core-client';
import { ParametersFields } from '@app/shared/models';
import { map } from 'rxjs/operators';
import { ParametersBaseService } from './parameters-base.service';
import { ApproveDsService } from '@app/core/data-services';

@Injectable({
  providedIn: 'root'
})
export class ApproveParametersService extends ParametersBaseService {
  public workCenterId: number;
  public approvalReportLineId: number;
  public approvalReportDate: string;
  public productionOrder: ProductionOrder | ActiveProductionOrder;

  constructor(private approveDsService: ApproveDsService) {
    super();
  }

  public getSetupParameterKinds(): Observable<SetupParameterKind> {
    return this.approveDsService.getApprovalSetupParameterKinds(this.workCenterId, this.approvalReportLineId, this.approvalReportDate);
  }

  public setSetupParameterKind(parameterKindId: number): Observable<void> {
    return this.approveDsService.setApprovalReportSetupParameterKind(
      this.workCenterId,
      this.approvalReportLineId,
      this.approvalReportDate,
      parameterKindId
    );
  }

  public getSetupParameters(): Observable<ParametersFields> {
    return this.approveDsService
      .getApprovalSetupParameters(this.workCenterId, this.approvalReportLineId, this.approvalReportDate)
      .pipe(map((parametersFields) => ParametersBaseService.mapParameters(parametersFields)));
  }

  public setSetupParameter(parameterId: number, paramValue: string, systemParameterType: SystemParameterType): Observable<void> {
    const numericParamValue = Number(paramValue);
    return this.approveDsService.setApprovalReportParameter(
      this.workCenterId,
      parameterId,
      this.approvalReportLineId,
      this.approvalReportDate,
      <SetSetupParameterEntry>{
        numericValue: numericParamValue,
        systemParameterType,
        textValue: paramValue
      }
    );
  }
}
