/* tslint:disable */
/* eslint-disable */
export enum ApprovalReportItemType {
  None = 'None',
  Downtime = 'Downtime',
  ProductionOrder = 'ProductionOrder',
  ProductionOrderRunPhase = 'ProductionOrderRunPhase',
  DowntimeDetail = 'DowntimeDetail',
  ConsumptionArticle = 'ConsumptionArticle',
  ConsumptionMaterial = 'ConsumptionMaterial',
  RunDetail = 'RunDetail',
  SetupDetail = 'SetupDetail'
}
