<ng-container *ngIf="outputUnit$ | async as outputUnit">
  <app-output-quantity-pool
    [grossQuantity]="grossQuantity"
    [isManualCheckAvailable]="isManualCheckAvailable"
    [isGluing]="true"
    [sampleDataEnabled]="sampleDataEnabled"
    (openPeriodicQaCheckClicked)="openPeriodicQACheckDialog()"
    (openSamplingDialogClicked)="openSamplingDialog()"
  ></app-output-quantity-pool>
  <app-gluing
    [outputUnit]="outputUnit"
    [wasteReasons]="reasonOptions"
    [grossQuantity]="grossQuantity"
    (finishClicked)="onFinishClicked($event)"
    (changeOutputUnitQuantityClicked)="changeOutputUnitQuantity($event)"
  ></app-gluing>
</ng-container>
