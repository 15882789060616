<div *ngIf="isSamplingEnabled" class="sampling-estimation">
  <div *ngIf="aqlQuantity?.value > 0" class="sampling-estimation__wrapper">
    <span class="sampling-estimation__icon">
      <i class="icon-qa-log-outline"></i>
    </span>
    <div class="sampling-estimation__col">
      <div class="sampling-estimation__label">{{ 'SAMPLING.AQL' | translate }}</div>
      <div class="sampling-estimation__value">
        {{ totalAqlSampleQuantity | formatQuantity : false : 0 : true }}/{{ aqlQuantity | formatQuantity : false }}
      </div>
    </div>
  </div>
  <div *ngIf="tgsQuantity?.value > 0" class="sampling-estimation__wrapper">
    <span *ngIf="!(aqlQuantity?.value > 0)" class="sampling-estimation__icon">
      <i class="icon-qa-log-outline"></i>
    </span>
    <span *ngIf="aqlQuantity?.value > 0" class="empty--icon-padding"></span>
    <div class="sampling-estimation__col">
      <div class="sampling-estimation__label">{{ 'SAMPLING.TGS' | translate }}</div>
      <div class="sampling-estimation__value">
        {{ totalTgsSampleQuantity | formatQuantity : false : 0 : true }}/{{ tgsQuantity | formatQuantity : false }}
      </div>
    </div>
  </div>
</div>
