import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProductionOrder } from 'chronos-core-client';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UntypedFormGroup } from '@angular/forms';
import { ParametersFactoryService } from '@app/core/services/parameters-factory';
import { getCombinationIcon } from '@app/modules/orders/utils';
import { notificationTopic } from '@app/shared/utils';
import {
  MaterialTableDataRow,
  MaterialTableDataRowDisplayMode,
  MaterialTableTreeNode
} from '@app/modules/mounting/models/material-table.model';
import { TabTraceService } from '@app/modules/mounting/services/tab-trace/tab-trace.service';
import { TranslateService } from '@ngx-translate/core';
import { TraceMaterialAssignationModalComponent } from '@app/shared/components/trace-material-assignation-modal/trace-material-assignation-modal.component';
import { LoadingNotificationService } from 'chronos-shared';
import { finalize } from 'rxjs/operators';
import { ButtonItem } from '@app/shared/components';

@Component({
  selector: 'app-presetup-modal',
  templateUrl: './presetup-modal.component.html',
  styleUrls: ['./presetup-modal.component.scss']
})
export class PresetupModalComponent implements OnInit, OnDestroy {
  public productionOrder: ProductionOrder;
  public form = new UntypedFormGroup({});

  public itemCollapsed = true;
  public combinationIcon: string;
  public readonly LOADING_TOPIC = notificationTopic.unassignTraceAction;
  public tableData: MaterialTableTreeNode[] = [];

  MaterialTableDataRowDisplayMode = MaterialTableDataRowDisplayMode;

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private parametersFactoryService: ParametersFactoryService,
    private tabTraceService: TabTraceService,
    private dialogService: DialogService,
    private translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    this.productionOrder = this.config.data.orderItem;
    this.parametersFactoryService.setValuesForSetup(this.productionOrder);
    this.combinationIcon = getCombinationIcon(this.productionOrder);
    this.initTraceMaterials();
  }

  private initTraceMaterials(): void {
    this.tabTraceService.getTraceMaterial().subscribe((data) => (this.tableData = data));
  }

  public constructButtons(rowData: MaterialTableDataRow): ButtonItem[] {
    return [
      {
        label: 'MOUNTING.ASSIGN',
        command: () => {
          this.clickAssignButton(rowData);
        }
      }
    ];
  }

  private clickAssignButton(rowData: MaterialTableDataRow) {
    this.openNewTraceMaterialAssignationModal(rowData);
  }

  public unassignTraceMaterial(mountedMaterialId: number): void {
    const loadingTopic = this.LOADING_TOPIC + mountedMaterialId;

    LoadingNotificationService.publish(loadingTopic, true);

    this.tabTraceService
      .unassignTraceMaterial(mountedMaterialId)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(loadingTopic, false);
          this.initTraceMaterials();
        })
      )
      .subscribe();
  }

  private openNewTraceMaterialAssignationModal(rowData: MaterialTableDataRow): void {
    this.dialogService
      .open(TraceMaterialAssignationModalComponent, {
        header: this.translateService.instant('MOUNTING.ASSIGN'),
        data: {
          materialBlockId: rowData.materialBlockId,
          article: rowData.article
        }
      })
      .onClose.subscribe((isSubmit: boolean) => {
        this.onCloseModal(isSubmit);
      });
  }

  private onCloseModal(isSubmit: boolean): void {
    if (isSubmit) {
      this.initTraceMaterials();
    }
  }

  public toggleContent(): void {
    this.itemCollapsed = !this.itemCollapsed;
  }

  public ngOnDestroy(): void {
    this.parametersFactoryService.cleanParametersFactoryValues();
  }

  public onSubmitClick(): void {
    this.ref.close();
  }
}
