import { Injectable } from '@angular/core';
import { PatternDetail, PatternInfoService } from 'chronos-core-client';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class PatternInfoDsService {
  constructor(private patternInfoService: PatternInfoService) {}

  public getProductionOrderPatternInfo(productionOrderId: number): Observable<PatternDetail> {
    return this.patternInfoService.getProductionOrderPatternInfo({ productionOrderId });
  }

  public isPatternAvailableForProductionOrder(productionOrderId: number): Observable<boolean> {
    return this.patternInfoService.isPatternAvailableForProductionOrder({ productionOrderId });
  }
}
