import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MaterialAvailableAtLocation, MaterialAvailableAtLocationItem } from 'chronos-core-client';
import { Observable } from 'rxjs/internal/Observable';
import { PrimaryMaterialDsService, SecondaryMaterialDsService } from '@app/core/data-services';
import { finalize, map } from 'rxjs/operators';
import { LoadingNotificationService } from 'chronos-shared';
import { notificationTopic } from '@app/shared/utils';

@Component({
  selector: 'app-modal-material-stock',
  templateUrl: './modal-material-stock.component.html',
  styleUrls: ['./modal-material-stock.component.scss']
})
export class ModalMaterialStockComponent implements OnInit {
  public readonly LOADING_TOPIC = notificationTopic.approveMaterialModalAction;
  public materialStock: MaterialAvailableAtLocation;
  public isArticleWip = false;
  public tabName = '';
  private isMaterialPrimary: boolean;
  private selectedWorkCenterId: number;
  private billOfMaterialId: number;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private primaryMaterialDsService: PrimaryMaterialDsService,
    private secondaryMaterialDsService: SecondaryMaterialDsService
  ) {}

  public ngOnInit(): void {
    this.isMaterialPrimary = this.config.data?.isPrimary;
    this.selectedWorkCenterId = this.config.data?.workCenterId;
    const productionOrderId = this.config.data?.productionOrderId;
    const articleId = this.config.data?.articleId;
    this.billOfMaterialId = this.config.data?.billOfMaterialId;
    this.tabName = this.config.data?.tabName;

    if (this.config.data) {
      this.config.data.articleForConsumption = { id: articleId };
    }

    if (this.isMaterialPrimary) {
      this.primaryMaterialDsService
        .getMaterialListForProductionOrder(this.selectedWorkCenterId, productionOrderId)
        .pipe(
          map((availableMaterial) => {
            this.materialStock = availableMaterial;
            //this.isArticleWip = availableMaterial.articles.every((article) => article.productType === ProductType.WIP);
          })
        )
        .subscribe();
    } else {
      this.secondaryMaterialDsService
        .getMaterialListForProductionOrderAndArticle(this.selectedWorkCenterId, productionOrderId, articleId)
        .pipe(map((availableMaterial) => (this.materialStock = availableMaterial)))
        .subscribe();
    }
  }

  public onSubmitClick([material, quantity, consumeAll]: [MaterialAvailableAtLocationItem, number, number, boolean]): void {
    LoadingNotificationService.publish(this.LOADING_TOPIC, true);
    let action: Observable<void>;
    const runId = this.config.data?.runId;
    if (this.isMaterialPrimary) {
      if (consumeAll) {
        const containerIds = this.materialStock.materials.map((stockMaterial) => stockMaterial.containerId);
        action = this.primaryMaterialDsService.consumeContainers(this.selectedWorkCenterId, containerIds, runId, this.billOfMaterialId);
      } else {
        action = this.primaryMaterialDsService.consumeContainer(
          this.selectedWorkCenterId,
          material.containerId,
          quantity,
          runId,
          this.billOfMaterialId
        );
      }
    } else {
      action = this.secondaryMaterialDsService.consumeMaterial(
        material.containerId,
        quantity,
        this.selectedWorkCenterId,
        runId,
        this.billOfMaterialId
      );
    }
    action
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
        })
      )
      .subscribe(() => {
        this.ref.close(true);
      });
  }
}
