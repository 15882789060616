import { Injectable } from '@angular/core';
import { QaHistoryDsService } from '@app/core/data-services/qa-history-ds/qa-history-ds.service';
import { ActiveOrderQuery, ActiveOrderService } from '@app/core/global-state';
import {
  ActiveProductionOrder,
  CancelProductionOrderEntry,
  EndProductionOrderProductionEntry,
  FinishProductionOrderForManualMachineEntry,
  ManualMachineCheckoutPageMode,
  ProductionOrder,
  ProductionOrderManualModeFinishingDataViewModel,
  ProductionOrderManualModeFinishingValuesEntry,
  ProductionOrderService,
  Quantity,
  RunEmployeeWorkTimeData,
  RunPhase,
  RunPhaseType,
  RunSubPhaseType,
  SetCurrentRunPhaseEntry,
  StartManualProductionOrderEntry,
  StartProductionOrderEntry
} from 'chronos-core-client';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ActiveOrderDsService } from '@app/core/data-services/active-order-ds/active-order-ds.service';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { AppSettingsService } from 'chronos-shared';

@Injectable({
  providedIn: 'root'
})
export class ActiveOrderPhaseDsService {
  constructor(
    private activeOrderService: ActiveOrderService,
    private appSettingsService: AppSettingsService,
    private activeOrderQuery: ActiveOrderQuery,
    private productionOrderService: ProductionOrderService,
    private qaHistoryDsService: QaHistoryDsService,
    private activeOrderDsService: ActiveOrderDsService,
    private activeWorkCenterService: ActiveWorkCenterService
  ) {}

  public enterRunPhase(): Observable<void> {
    return this.setNewActiveOrderPhase(RunPhaseType.Run, RunSubPhaseType.Run);
  }

  public enterFinishingPhase(): Observable<void> {
    return this.setNewActiveOrderPhase(RunPhaseType.Finishing, RunSubPhaseType.FinishingOverview);
  }

  public enterSetupPhase(productionOrder: ProductionOrder, startTime: string, manualTransport: boolean): Observable<ActiveProductionOrder> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.productionOrderService
      .startProductionOrder({
        productionOrderId: productionOrder.productionOrderId,
        body: {
          productionOrderStartTime: startTime,
          workCenterId,
          manualTransport
        }
      })
      .pipe(switchMap(() => this.activeOrderDsService.getActiveProductionOrder()));
  }

  public enterSetupPhaseWithAutomaticPeriodSelection(
    productionOrder: ProductionOrder,
    manualTransport: boolean
  ): Observable<ActiveProductionOrder> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.productionOrderService
      .startProductionOrderWithAutomaticPeriodSelection({
        productionOrderId: productionOrder.productionOrderId,
        body: {
          workCenterId,
          manualTransport
        }
      })
      .pipe(switchMap(() => this.activeOrderDsService.getActiveProductionOrder()));
  }

  public cancelActiveOrder(): Observable<void> {
    const activeOrderId = this.activeOrderQuery.getActiveOrderId();
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.productionOrderService
      .cancelProductionOrder({
        productionOrderId: activeOrderId,
        body: {
          workCenterId
        }
      })
      .pipe(
        tap(() => {
          this.removeActiveOrder();
        })
      );
  }

  public removeActiveOrder(): void {
    this.activeOrderService.reset();
  }

  public selectActiveOrderPhase(): Observable<RunPhaseType> {
    return this.activeOrderQuery.activeOrderPhase$;
  }

  public selectActiveOrderSubPhase(): Observable<RunSubPhaseType> {
    return this.activeOrderQuery.activeOrderSubPhase$;
  }

  public getActiveOrderSubPhase(): RunSubPhaseType {
    return this.activeOrderQuery.getActiveOrderSubPhase();
  }

  public getKpisConfirmed(): Observable<boolean> {
    return this.activeOrderQuery.areKpisConfirmed$;
  }

  public setNewActiveOrderPhase(newPhase: RunPhaseType, newSubPhase: RunSubPhaseType): Observable<void> {
    const activeOrderId = this.activeOrderQuery.getActiveOrderId();
    return this.setOrderPhaseByOrderId(activeOrderId, newPhase, newSubPhase);
  }

  private setOrderPhaseByOrderId(orderId: number, newPhase: RunPhaseType, newSubPhase: RunSubPhaseType): Observable<void> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.productionOrderService
      .setCurrentRunSubPhase({
        productionOrderId: orderId,
        body: {
          runSubPhaseType: newSubPhase,
          workCenterId
        }
      })
      .pipe(
        tap(() => {
          this.activeOrderService.setActiveOrderPhase(newPhase, newSubPhase);
        })
      );
  }

  public getFirstSetupPhase(productionOrderId: number): Observable<RunPhase> {
    return this.productionOrderService.getRunPhases({ productionOrderId }).pipe(
      tap((phases) => {
        this.setSubPhaseStatus(phases);
      }),
      map((phases) => phases[0])
    );
  }

  public setSubPhaseStatus(phases: RunPhase[]): void {
    this.appSettingsService.setPatternInfoSetup(phases.some((phase) => this.isPatternInfoSubPhase(phase)));
    this.appSettingsService.setLineClearanceSetup(phases.some((phase) => this.isLineClearanceSubPhase(phase)));
    this.appSettingsService.setEnableToolCheckout(phases.some((phase) => this.isToolCheckoutSubPhase(phase)));
    this.qaHistoryDsService.setQaHistoryStatusForSetup(phases.some((phase) => this.isQaHistorySubPhase(phase)));
    this.qaHistoryDsService.setQaChecksStatusForSetup(phases.some((phase) => this.isQaChecksSubPhase(phase)));
  }

  public endOrderInAutomaticMode(isInterrupted: boolean): Observable<ActiveProductionOrder> {
    const activeOrderId = this.activeOrderQuery.getActiveOrderId();
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.productionOrderService
      .endProductionOrderProduction({
        productionOrderId: activeOrderId,
        body: {
          isInterrupted,
          workCenterId
        }
      })
      .pipe(switchMap(() => this.activeOrderDsService.getActiveProductionOrder()));
  }

  public startOrderInManualMode(
    productionOrderId: number,
    orderStartTime: Date,
    quantityPerPallet?: Quantity
  ): Observable<ActiveProductionOrder> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.productionOrderService
      .startProductionOrderManualMachine({
        productionOrderId,
        body: {
          productionOrderStartTime: orderStartTime.toISOString(),
          quantityPerPallet,
          workCenterId
        }
      })
      .pipe(
        switchMap(() => this.activeOrderDsService.getActiveProductionOrder()),
        tap(() => {
          this.activeOrderService.setActiveOrderPhase(RunPhaseType.Run, RunSubPhaseType.Run);
        })
      );
  }

  public endOrderInManualMode(
    productionOrderId: number,
    orderStartTime: string,
    orderEndTime: string,
    isInterrupted: boolean
  ): Observable<void> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.productionOrderService
      .endProductionForManualMachine({
        productionOrderId,
        body: {
          startTime: orderStartTime,
          endTime: orderEndTime,
          isInterrupted,
          workCenterId
        }
      })
      .pipe(
        switchMap(() => this.activeOrderDsService.getActiveProductionOrder()),
        switchMap(() => this.enterFinishingPhase())
      );
  }

  public getManualModeFinishingData(productionOrderId: number): Observable<ProductionOrderManualModeFinishingDataViewModel> {
    return this.productionOrderService.getManualModeFinishingData({ productionOrderId });
  }

  public saveManualModeFinishingValues(
    manualMachineCheckoutPageMode: ManualMachineCheckoutPageMode,
    productionOrderId: number,
    runEmployeeWorkTimes?: RunEmployeeWorkTimeData[],
    numberOfWorkers?: number,
    duration?: string
  ): Observable<void> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.productionOrderService.saveManualModeFinishingValues({
      productionOrderId,
      body: {
        manualMachineCheckoutPageMode,
        duration,
        numberOfWorkers,
        runEmployeeWorkTimes,
        workCenterId
      }
    });
  }

  private isPatternInfoSubPhase(phase: RunPhase): boolean {
    return phase.runSubPhaseType === RunSubPhaseType.SetupPatternInfo;
  }
  private isQaHistorySubPhase(phase: RunPhase): boolean {
    return phase.runSubPhaseType === RunSubPhaseType.SetupQaHistory;
  }
  private isLineClearanceSubPhase(phase: RunPhase): boolean {
    return phase.runSubPhaseType === RunSubPhaseType.SetupLineClearance;
  }
  private isQaChecksSubPhase(phase: RunPhase): boolean {
    return phase.runSubPhaseType === RunSubPhaseType.SetupInProgressFinishingCheck;
  }
  private isToolCheckoutSubPhase(phase: RunPhase): boolean {
    return phase.runSubPhaseType === RunSubPhaseType.FinishingToolCheckout;
  }
}
