<section class="sampling-details-table">
  <p-table [value]="samplingLogDetails">
    <ng-template pTemplate="header">
      <tr>
        <th class="sampling-details-table__text-cell">{{ 'SAMPLING.TYPE' | translate }}</th>
        <th class="sampling-details-table__number-cell">{{ 'SAMPLING.SAMPLE_QTY' | translate }}</th>
        <th class="sampling-details-table__text-cell">{{ 'SAMPLING.USER' | translate }}</th>
        <th class="sampling-details-table__text-cell">{{ 'SAMPLING.DATETIME' | translate }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td class="sampling-details-table__text-cell">
          {{ (rowData.wasteReasonDescription ? rowData.wasteReasonDescription : rowData.externalWasteReasonCode) | emptyTextPlaceholder }}
        </td>
        <td class="sampling-details-table__number-cell">
          {{ rowData.samplingDataQuantity | formatQuantity }}
        </td>
        <td class="sampling-details-table__text-cell">
          {{ rowData.employeeSignature?.employee?.name | emptyTextPlaceholder }}
        </td>
        <td class="sampling-details-table__text-cell">
          {{ rowData.bookingTime | utcToLocal | date : 'dd.MM.YYYY HH:mm' }}
        </td>
      </tr>
    </ng-template>
    <ng-template *ngIf="activeOrderQuery.isSampleDataEnabled()" pTemplate="emptymessage">
      <tr>
        <td colspan="9">{{ 'APP.NO_DATA_FOUND' | translate }}</td>
      </tr>
    </ng-template>

    <ng-template *ngIf="!activeOrderQuery.isSampleDataEnabled()" pTemplate="emptymessage">
      <tr>
        <td colspan="9">{{ 'SAMPLING.SAMPLING_IS_NOT_ENABLED' | translate }}</td>
      </tr>
    </ng-template>
  </p-table>
</section>
