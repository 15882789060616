import { Component, OnDestroy, OnInit } from '@angular/core';
import { ButtonItem } from '@app/shared/components';
import { ConfirmInformationCommand, SendCompleteReportCommand, SendReportCommand } from '@app/modules/shift-report/commands';
import { ShiftReportModalService, ShiftReportService } from '@app/modules/shift-report/services';
import { Observable, Subscription } from 'rxjs';
import { GroupedDropdownItem } from '@app/modules/shift-report/models';
import { ShiftReportItem, ShiftReportOpenTask, UiStateQuery, UiStateService } from '@app/core/global-state';
import { TaskAssignationService } from '@app/core/services';
import {
  AssignReasonToDowntimeBehaviourType,
  Downtime,
  KpiIdentifier,
  KpiValue,
  Shift,
  ShiftReportItemType,
  ShiftReportOpenTaskItemType,
  ShiftReportStatus
} from 'chronos-core-client';
import { DowntimeReasonViewData } from '@app/shared/modals/task-assign-modal/models';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { EmployeeRegistrationComponent } from '@app/modules/employee-registration/components/employee-registration/employee-registration.component';
import { AppSettingsQuery, LoadingNotificationService, LogService } from 'chronos-shared';
import { notificationTopic } from '@app/shared/utils';
import { ShiftReportDsService } from '@app/core/data-services';

@Component({
  selector: 'app-shift-report',
  templateUrl: './shift-report.component.html',
  styleUrls: ['./shift-report.component.scss']
})
export class ShiftReportComponent implements OnInit, OnDestroy {
  public readonly KPI_IDENTIFIER = KpiIdentifier;
  public readonly LOADING_TOPIC = notificationTopic.cancelShiftReport;

  public shiftReportOpenItems$: Observable<ShiftReportItem[]>;
  public shiftReportNotOpenItems$: Observable<ShiftReportItem[]>;
  public shiftReportHeaderKpis$: Observable<KpiValue[]>;
  public shiftReportOpenTasks$: Observable<ShiftReportOpenTask[]>;
  public dropdownOptions$: Observable<GroupedDropdownItem[]>;
  public hasUncompletedTask$: Observable<boolean>;
  public shiftReportAvailable$: Observable<boolean>;
  public isEnableShiftReportCancellation$: Observable<boolean>;
  public confirmCheckboxCommand: ConfirmInformationCommand;
  public sendReportCommand: SendReportCommand;
  public sendCompleteReportCommand: SendCompleteReportCommand;
  public selectedShiftId$: Observable<number>;
  public isDowntimeFlag = true;
  public enableShiftCancellation: boolean;
  private downtimeViewData: DowntimeReasonViewData;
  private subscriptions = new Subscription();
  private shiftId: number;

  constructor(
    private shiftReportService: ShiftReportService,
    private shiftReportModalService: ShiftReportModalService,
    private taskAssignationService: TaskAssignationService,
    private uiStateService: UiStateService,
    private uiStateQuery: UiStateQuery,
    private activeWorkCenterService: ActiveWorkCenterService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private appSettingsQuery: AppSettingsQuery,
    private shiftReportDsService: ShiftReportDsService
  ) {}

  public ngOnInit(): void {
    this.dropdownOptions$ = this.shiftReportService.getDropDownOptions();

    this.subscriptions.add(
      this.shiftReportService.getCurrentShift().subscribe((shift) => {
        this.selectActiveShift(shift);
      })
    );

    this.subscriptions.add(this.shiftReportService.downtimeViewData$.subscribe((data) => (this.downtimeViewData = data)));
    this.subscriptions.add(this.shiftReportService.getActiveOrderNotifications().subscribe());
    this.subscriptions.add(this.shiftReportService.getHeaderShiftKpisNotifications().subscribe());
    this.subscriptions.add(this.shiftReportService.getShiftOpenTasksNotifications().subscribe());

    this.shiftReportOpenItems$ = this.shiftReportService.filterShiftReportOpenItems();
    this.shiftReportNotOpenItems$ = this.shiftReportService.filterShiftReportNotOpenItems();
    this.shiftReportAvailable$ = this.shiftReportService.isShiftReportAvailable();
    this.shiftReportHeaderKpis$ = this.shiftReportService.selectedShiftReportHeaderKpis$;
    this.shiftReportOpenTasks$ = this.shiftReportService.selectedShiftOpenTasks$;
    this.hasUncompletedTask$ = this.shiftReportService.hasUncompletedTask$;
    this.selectedShiftId$ = this.shiftReportService.selectedShiftId$;
    this.selectedShiftId$.subscribe((list) => (this.shiftId = list));
    this.isEnableShiftReportCancellation$ = this.appSettingsQuery.isEnableShiftReportCancellation$;

    this.enableDisableShiftCancellation();

    this.setCommands();

    this.subscriptions.add(
      this.shiftReportService.refreshShiftObservable.subscribe(() => {
        this.updateShiftCancellationStatus();
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private selectActiveShift(shift: Shift): void {
    const uiStateShiftId = this.uiStateQuery.getShiftReportStateSelectedShift();
    const activeShiftId = uiStateShiftId || shift.id;
    this.shiftReportService.changeShift(activeShiftId);
  }

  public createButtonValues(shiftReportItem: ShiftReportItem): ButtonItem[] {
    if (shiftReportItem.type === ShiftReportItemType.Downtime) {
      return [
        {
          label: 'SHIFT_REPORT.ASSIGN',
          command: () => {
            this.openAssignTaskModal(shiftReportItem);
          },
          disabled: !shiftReportItem.downtime.isDowntimeCodeAssigned || shiftReportItem.shiftReportStatus === ShiftReportStatus.Closed
        }
      ];
    } else {
      return [
        {
          label: 'SHIFT_REPORT.OVERVIEW',
          command: () => {
            this.openOverviewModal(shiftReportItem);
          }
        }
      ];
    }
  }

  public changeShiftReport(shiftId: number): void {
    this.uiStateService.setShiftReportStateSelectedShift(shiftId);
    this.shiftReportService.changeShift(shiftId);

    this.enableDisableShiftCancellation();
  }

  public viewShiftReport(): void {
    this.shiftReportModalService.viewSendReportModal();
  }

  public filterByProductionOrder(filterValue: string): void {
    this.shiftReportService.filterShiftReportItemsByProductionOrder(filterValue);
  }

  public onOpenTaskKPIClick(taskType: string): void {
    if (taskType === ShiftReportOpenTaskItemType.EmployeeRegistration) {
      this.showEmployeeRegistrationPopup();
    }
  }

  public cancelSendShiftReport(): void {
    LoadingNotificationService.publish(this.LOADING_TOPIC, true);
    this.shiftReportService.cancelSendShiftReport(this.shiftId).subscribe(() => {
      this.dropdownOptions$ = this.shiftReportService.getDropDownOptions();

      this.subscriptions.add(
        this.shiftReportService.getCurrentShift().subscribe((shift) => {
          this.selectActiveShift(shift);
          this.updateShiftCancellationStatus();
        })
      );

      LoadingNotificationService.publish(this.LOADING_TOPIC, false);
      LogService.success('HEADER_MESSAGE.DEFAULT_SUCCESS');
    });
  }

  private enableDisableShiftCancellation(): void {
   
    this.isEnableShiftReportCancellation$.subscribe((isEnable) => {
      if (!isEnable) {
        return;
      }

      this.updateShiftCancellationStatus();
    });
  }

  private openOverviewModal(shiftReportItem: ShiftReportItem): void {
    this.shiftReportModalService.openOverviewModal(shiftReportItem);
  }

  private openAssignTaskModal(shiftReportItem: ShiftReportItem): void {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const endTime = shiftReportItem.endTime?.toString();
    const downtime: Downtime = { startTime: shiftReportItem.startTime, endTime, ...shiftReportItem.downtime };
    const modal = this.taskAssignationService.openAssignTaskModal(
      downtime,
      this.downtimeViewData,
      !downtime.hasRunAssigned,
      AssignReasonToDowntimeBehaviourType.ShiftReport,
      workCenterId
    );
    this.subscriptions.add(
      modal.onClose.subscribe(() => {
        this.shiftReportService.refreshShifts();
      })
    );
  }

  private setCommands(): void {
    this.confirmCheckboxCommand = new ConfirmInformationCommand(this.shiftReportService);
    this.confirmCheckboxCommand.buttonText = 'SHIFT_REPORT.CONFIRM_CHECK';

    this.sendReportCommand = new SendReportCommand(this.shiftReportModalService);
    this.sendReportCommand.buttonText = 'SHIFT_REPORT.SEND_REPORT';

    this.sendCompleteReportCommand = new SendCompleteReportCommand(this.shiftReportModalService);
    this.sendCompleteReportCommand.buttonText = 'SHIFT_REPORT.SEND_REPORT';

    this.shiftReportService.setCommands(this.sendReportCommand, this.sendCompleteReportCommand, this.confirmCheckboxCommand);
  }

  private showEmployeeRegistrationPopup(): void {
    this.dialogService.open(EmployeeRegistrationComponent, {
      header: this.translateService.instant('SHIFT_REPORT.OPEN_TASK_EMPLOYEEREGISTRATION'),
      closable: false,
      data: {
        isLoginDisabled: true,
        selectedShiftId: this.shiftId,
        enableSumbit: true
      }
    });
  }

  private updateShiftCancellationStatus() {
    this.shiftReportDsService.getShifts().subscribe((shifts) => {
      const shift = shifts.find((s) => s.id === this.shiftId);
      this.enableShiftCancellation = shift.shiftReportStatus === ShiftReportStatus.Closed ? true : false;
    });
  }
}
