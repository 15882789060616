import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { FormStatus, SettingFieldType, SettingModel } from '@app/modules/resource-configuration/models';
import { Setting, SettingValueInfo, SettingValueType } from 'chronos-core-client';
import { ListValue } from 'chronos-shared';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-resource-setting',
  templateUrl: './resource-setting.component.html',
  styleUrls: ['./resource-setting.component.scss']
})
export class ResourceSettingComponent implements OnInit {
  public settingModel: SettingModel;
  public isCollapsed = true;
  public readonly SETTING_FIELD_TYPES = SettingFieldType;
  public readonly INPUT_STYLE = { width: '200px' };
  public readonly INPUT_STYLE_DROPDOWN = { width: '200px' };
  public readonly panelStyle = { height: '30px' };
  private readonly VALUE_ORIGIN_DEFAULT = 'Default';

  @Input() public setting: SettingValueInfo;
  @Input() public defaultSettingList?: Setting[];
  @Input() public isAdmin?: boolean;
  @Input() public enableEditSetting$: Observable<boolean>;
  @Input() public parentForm: UntypedFormGroup;
  @Output() public viewSettingHistory = new EventEmitter<SettingModel>();
  @Output() public updateSetting = new EventEmitter<SettingModel>();
  @Output() public formValidityChange = new EventEmitter<FormStatus>();

  public form: UntypedFormGroup;
  constructor() {}

  public ngOnInit(): void {
    this.prepareSettingModel();

    this.form.valueChanges.subscribe(() => {
      this.formValidityChange.emit({ status: this.form.valid, settingId: this.setting.settingId });
    });
  }

  public prepareSettingModel() {
    const array = this.defaultSettingList;
    const matchedSetting = array.find((item) => item.name.split('.').pop() === this.setting.settingName);

    if (matchedSetting) {
      const isFromOrigin = this.setting.definedAtLevel === this.VALUE_ORIGIN_DEFAULT;
      this.settingModel = {
        settingId: this.setting.settingId,
        settingName: this.setting.settingName,
        description: matchedSetting.description,
        defaultValue: this.getDefaultValue(matchedSetting.settingValueType, matchedSetting.defaultValue),
        enumerationValues: this.createListFromEnumArray(matchedSetting.enumerationValues),
        settingValueType: matchedSetting.settingValueType,
        definedAtLevel: this.setting.definedAtLevel
      };

      this.setValueByType(matchedSetting, isFromOrigin);
      this.settingValidation(matchedSetting.isMandatory, matchedSetting);
    }
  }

  public onChange(newValue?: any): void {
    if (!this.isSettingValueInvalid() && this.isValueUpdated(newValue)) {
      this.updateSettingValue(newValue);
      this.updateSetting.emit(this.settingModel);
    }
  }

  private updateSettingValue(value: any) {
    switch (this.settingModel.settingValueType) {
      case 'Boolean':
        this.settingModel.boolValue = value;
        break;
      case 'Integer':
        this.settingModel.intValue = value;
        break;
      case 'String':
        this.settingModel.stringValue = value;
        break;
      case 'DateTime':
        this.settingModel.dateTimeValue = value;
        break;
      case 'TimeSpan':
        this.settingModel.timeSpanValue = value;
        break;
      case 'Enum':
        this.settingModel.enumValue = value;
        break;
      case 'EnumMultiSelect':
        this.settingModel.enumValueMultiselect = value;
        break;
    }
  }

  private isValueUpdated(value: any): boolean {
    switch (this.settingModel.settingValueType) {
      case 'Boolean':
        return this.settingModel.boolValue !== value ? true : false;
      case 'Integer':
        return this.settingModel.intValue !== value ? true : false;
      case 'String':
        return this.settingModel.stringValue !== value ? true : false;
      case 'DateTime':
        return this.settingModel.dateTimeValue !== value ? true : false;
      case 'TimeSpan':
        return this.settingModel.timeSpanValue !== value ? true : false;
      case 'Enum':
        return this.settingModel.enumValue !== value ? true : false;
      case 'EnumMultiSelect':
        return this.settingModel.enumValueMultiselect !== value ? true : false;
    }
  }

  public showHistory(setting: SettingModel): void {
    this.viewSettingHistory.emit(setting);
  }

  public isSettingValueInvalid(validationType?: string): boolean {
    const settingValueControl = this.form.get('settingControl');

    if (!settingValueControl) {
      return false;
    }

    if (validationType === 'required') {
      const isRequiredError = settingValueControl.hasError('required');

      if (settingValueControl.invalid) {
        this.formValidityChange.emit({ status: false, settingId: this.setting.settingId });
      }

      return isRequiredError;
    }

    return settingValueControl.invalid;
  }

  public isEnableEditSetting(): boolean {
    let isEnableEdit = false;

    this.enableEditSetting$.subscribe((value) => {
      isEnableEdit = value;
    });

    return isEnableEdit;
  }

  public getDefaultValue(valueType: SettingValueType, defaultValue: string): string | string[] {
    if (valueType === SettingValueType.EnumMultiSelect) {
      return [defaultValue];
    } else return defaultValue;
  }

  public toggleContent(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  private setValueByType(matchedSetting: Setting, isFromOrigin: boolean): void {
    switch (this.settingModel.settingValueType) {
      case 'Boolean':
        this.settingModel.boolValue = isFromOrigin ? matchedSetting.defaultValue.toLowerCase() === 'true' : this.setting.boolValue;
        break;
      case 'Integer':
        this.settingModel.intValue = isFromOrigin ? parseInt(matchedSetting.defaultValue, 10) : this.setting.intValue;
        break;
      case 'String':
        this.settingModel.stringValue = isFromOrigin ? matchedSetting.defaultValue : this.setting.stringValue;
        break;
      case 'DateTime':
        this.settingModel.dateTimeValue = isFromOrigin ? matchedSetting.defaultValue : this.setting.dateTimeValue;
        break;
      case 'TimeSpan':
        this.settingModel.timeSpanValue = isFromOrigin ? matchedSetting.defaultValue : this.setting.timeSpanValue;
        break;
      case 'Enum':
        this.settingModel.enumValue = isFromOrigin ? matchedSetting.defaultValue : this.setting.enumValue;
        break;
      case 'EnumMultiSelect':
        let selectedValues = isFromOrigin ? matchedSetting.defaultValue : this.setting.enumValueMultiselect;
        if (!selectedValues && this.setting.stringValue) {
          selectedValues = this.setting.stringValue;
        }
        this.settingModel.enumValueMultiselect = selectedValues;
        break;
    }
  }

  private settingValidation(isRequired: boolean, setting: Setting): void {
    switch (setting.settingValueType) {
      case 'Boolean':
        this.form = new UntypedFormGroup({
          settingControl: new UntypedFormControl(this.settingModel.boolValue, isRequired ? Validators.required : [])
        });
        break;
      case 'Integer':
        this.form = new UntypedFormGroup({
          settingControl: new UntypedFormControl(this.settingModel.intValue, [
            isRequired ? Validators.required : null,
            Validators.pattern(/^-?\d+$/)
          ])
        });
        break;
      case 'String':
        this.form = new UntypedFormGroup({
          settingControl: new UntypedFormControl(this.settingModel.stringValue, isRequired ? Validators.required : [])
        });
        break;
      case 'DateTime':
        this.form = new UntypedFormGroup({
          settingControl: new UntypedFormControl(this.settingModel.dateTimeValue, isRequired ? Validators.required : [])
        });
        break;
      case 'TimeSpan':
        this.form = new UntypedFormGroup({
          settingControl: new UntypedFormControl(this.settingModel.timeSpanValue, [
            isRequired ? Validators.required : null,
            this.timespanValidator()
          ])
        });
        break;
      case 'Enum':
        this.form = new UntypedFormGroup({
          settingControl: new UntypedFormControl(this.settingModel.enumValue, isRequired ? Validators.required : [])
        });
        break;
      case 'EnumMultiSelect':
        this.form = new UntypedFormGroup({
          settingControl: new UntypedFormControl(this.settingModel.enumValueMultiselect, isRequired ? Validators.required : [])
        });
        break;

      default:
        console.warn('Unsupported setting value type:', setting.settingValueType);
        break;
    }
  }

  private timespanValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const timespanPattern = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;

      if (!control.value) {
        return null;
      }

      if (!timespanPattern.test(control.value)) {
        return { invalidTimespan: true };
      }

      return null;
    };
  }

  private createListFromEnumArray(array: string[]): ListValue[] {
    if (array) {
      return array.map((item) => ({
        value: item,
        label: item
      }));
    }
    return [];
  }
}
