import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import {
  MaterialAvailableAtLocation,
  MountPrimaryContainerResponse,
  PrimaryMaterial,
  PrimaryMaterialBlockConsumed,
  PrimaryMaterialMounted,
  PrimaryMaterialService,
  CreateAndMountVirtualPrimaryContainerEntry,
  MaterialService,
  MountContainerEntry,
  MountContainerResponse,
  MaterialType,
  MaterialTypeFilterCriteria
} from 'chronos-core-client';
import { MaterialLocationsMode, MaterialLocationsSelectedValueMode } from 'projects/chronos-shared/src/lib/models';
import { Quantity } from 'projects/chronos-core-client/src/public-api';

@Injectable({
  providedIn: 'root'
})
export class PrimaryMaterialDsService {
  constructor(
    private primaryMaterialService: PrimaryMaterialService,
    private activeWorkCenterService: ActiveWorkCenterService,
    private materialService: MaterialService
  ) {}

  public getPrimaryMaterialBlocks(): Observable<PrimaryMaterial> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.primaryMaterialService.getCardViewGroupByMaterial({ workCenterId });
  }

  public getMaterialListAtMachine(productionOrderId?: number, isOverviewTab?: boolean): Observable<MaterialAvailableAtLocation> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();

    if (productionOrderId && isOverviewTab) {
      return this.materialService.getAvailableMaterials({
        workCenterId,
        ProductionOrderId: productionOrderId,
        MaterialLocationsMode: MaterialLocationsMode.MachineLocation,
        MaterialFilterCriteria: MaterialTypeFilterCriteria.PrimaryAndSecondary,
        MaterialLocationsSelectedValueMode: MaterialLocationsSelectedValueMode.CountAndSumAndContainers
      });
    }

    if (productionOrderId && !isOverviewTab) {
      return this.primaryMaterialService.getMaterialAvailableAtMachineForProductionOrder({
        workCenterId,
        productionOrderId
      });
    }

    return this.primaryMaterialService.getMaterialAvailableAtMachine({ workCenterId });
  }

  public consumeContainer(
    workCenterId: number,
    containerId: number,
    quantity: number,
    runId: number,
    billOfMaterialId: number
  ): Observable<void> {
    return this.primaryMaterialService.consumePrimaryContainer({
      workCenterId,
      body: {
        containerId,
        quantity,
        runId,
        billOfMaterialId
      }
    });
  }

  public getMaterialListAtWarehouse(productionOrderId?: number): Observable<MaterialAvailableAtLocation> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();

    if (productionOrderId) {
      return this.primaryMaterialService.getMaterialAvailableAtWarehouseForProductionOrder({
        workCenterId,
        productionOrderId
      });
    } else {
      return this.primaryMaterialService.getMaterialAvailableAtWarehouse({ workCenterId });
    }
  }

  public getMaterialListForProductionOrder(workCenterId: number, productionOrderId: number): Observable<MaterialAvailableAtLocation> {
    return this.primaryMaterialService.getMaterialAvailableForProductionOrder({
      workCenterId,
      productionOrderId
    });
  }

  public getNextContainerForDismount(): Observable<PrimaryMaterialMounted> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.primaryMaterialService.getNextPrimaryContainerForDismount({ workCenterId });
  }

  public dismountContainer(
    containerId: number,
    mountedMaterialId: number,
    remainingQuantity: number,
    shouldPrintLabel: boolean,
    runId: number = null,
    workCenterId: number = null
  ): Observable<void> {
    workCenterId = workCenterId ?? this.activeWorkCenterService.getWorkCenterId();
    return this.primaryMaterialService.dismountContainer({
      containerId,
      body: {
        mountedMaterialId,
        remainingQuantity,
        shouldPrintLabel,
        workCenterId,
        runId
      }
    });
  }

  public changeMountedMaterialSequence(
    materialBlockId: number,
    mountedMaterialId: number,
    insertBeforeMountedMaterialId?: number
  ): Observable<void> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.primaryMaterialService.changeMountedMaterialSequence({
      materialBlockId,
      body: {
        insertBeforeMountedMaterialId,
        mountedMaterialId,
        workCenterId
      }
    });
  }

  public getConsumption(): Observable<PrimaryMaterialBlockConsumed> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.primaryMaterialService.getConsumption({ workCenterId });
  }

  public mountContainerBySSCC(
    identificationCode: string,
    productionOrderId: number,
    materialBlockId: number,
    whiteWasteQuantity?: Quantity
  ): Observable<MountPrimaryContainerResponse> {
    return this.mountPrimaryContainer({ identificationCode, productionOrderId, materialBlockId, whiteWasteQuantity });
  }

  public mountPrimaryContainerByIdAndProductionOrder(
    containerId: number,
    productionOrderId: number,
    whiteWasteQuantity?: Quantity
  ): Observable<MountPrimaryContainerResponse> {
    return this.mountPrimaryContainer({ containerId, productionOrderId, whiteWasteQuantity });
  }

  public mountPrimaryContainerByContainerAndProductionOrder(
    identificationCode: string,
    productionOrderId: number,
    whiteWasteQuantity?: Quantity
  ): Observable<MountPrimaryContainerResponse> {
    return this.mountPrimaryContainer({ identificationCode, productionOrderId, whiteWasteQuantity });
  }

  private mountPrimaryContainer(containerEntry: MountContainerEntry): Observable<MountContainerResponse> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    containerEntry.materialType = MaterialType.Primary;
    return this.materialService.mountContainer({
      workCenterId,
      body: containerEntry
    });
  }

  public replaceContainer(mountedMaterialId: number, virtualContainerId: number, newContainerId: number): Observable<void> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.primaryMaterialService.replaceVirtualPrimaryContainer({
      mountedMaterialId,
      body: {
        newContainerId,
        virtualContainerId,
        workCenterId
      }
    });
  }

  public addWhiteWasteToMountedMaterial(mountedMaterialId: number, whiteWaste: Quantity): Observable<void> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.primaryMaterialService.addWhiteWasteToMountedMaterial({
      mountedMaterialId,
      body: {
        workCenterId,
        waste: whiteWaste
      }
    });
  }

  public removeWhiteWasteFromMountedMaterial(mountedMaterialId: number): Observable<void> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.primaryMaterialService.deleteWhiteWasteAssignment({
      mountedMaterialId,
      body: {
        workCenterId
      }
    });
  }

  public consumeContainers(workCenterId: number, containerIds: number[], runId: any, billOfMaterialId: number): Observable<void> {
    return this.primaryMaterialService.consumePrimaryContainer2({
      workCenterId,
      body: {
        containerIds,
        runId,
        billOfMaterialId
      }
    });
  }

  public addVirtualContainer(virtualContainerEntry: CreateAndMountVirtualPrimaryContainerEntry): Observable<void> {
    return this.primaryMaterialService.createAndMountVirtualPrimaryContainer({
      workCenterId: this.activeWorkCenterService.getWorkCenterId(),
      body: virtualContainerEntry
    });
  }

  public getMaterialCount(productionOrderId?: number): Observable<MaterialAvailableAtLocation> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.materialService.getAvailableMaterials({
      workCenterId,
      ProductionOrderId: productionOrderId,
      MaterialLocationsMode: MaterialLocationsMode.MachineLocation,
      MaterialFilterCriteria: MaterialTypeFilterCriteria.PrimaryAndSecondary,
      MaterialLocationsSelectedValueMode: MaterialLocationsSelectedValueMode.CountOnly
    });
  }
}
