import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActiveOrderPanelData, ActiveOrderPanelService, NavigationRouteService } from '@app/core/services';
import { nav } from '@app/shared/utils';
import { RunPhaseType, RunSubPhaseType, ProductionOrderStatus, Quantity, WorkCenterProductionMode } from 'chronos-core-client';
import { Observable, Subscription } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { DatePickerModalComponent } from '@app/shell/components/modals';
import { productionOrderStatusFlags, OrderPhaseItems, SubPhaseItem } from '@app/shared/models';
import { AppSettingsQuery } from 'chronos-shared';

@Component({
  selector: 'app-active-order',
  templateUrl: './active-order-panel.component.html',
  styleUrls: ['./active-order-panel.component.scss']
})
export class ActiveOrderPanelComponent implements OnInit, OnDestroy {
  public isMachineSpeedDisabled = false;
  public isCombination = false;
  public activeOrderPanelData$: Observable<ActiveOrderPanelData>;
  public machineSpeedInput = 0;
  public isClockInEnabled = false;
  public isMachineSpeedSimulationEnabled = false;
  public workCenterProductionMode = null;

  public readonly navigation = nav;
  public readonly orderPhase = RunPhaseType;
  public readonly orderSubPhase = RunSubPhaseType;
  public phaseItems: OrderPhaseItems;

  private subscriptions = new Subscription();

  public readonly STATUS_FLAGS = productionOrderStatusFlags;

  constructor(
    public router: Router,
    private activeOrderPanelService: ActiveOrderPanelService,
    private navigationRouteService: NavigationRouteService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private appSettingsQuery: AppSettingsQuery
  ) {}

  public ngOnInit(): void {
    this.activeOrderPanelData$ = this.activeOrderPanelService.selectActiveOrderPanelData().pipe(
      tap((data) => {
        this.setIsCombination(data);
      })
    );
    this.subscriptions.add(this.activeOrderPanelService.renewMachineSpeed().subscribe((speed) => (this.machineSpeedInput = speed)));
    this.subscriptions.add(this.activeOrderPanelService.getCounterNotifications().subscribe());

    this.appSettingsQuery.isClockInEnabled$.subscribe((value) => (this.isClockInEnabled = value));
    this.appSettingsQuery.isMachineSpeedSimulationEnabled$.subscribe((value) => (this.isMachineSpeedSimulationEnabled = value));
    this.appSettingsQuery.workCenterProductionMode$.subscribe((value) => (this.workCenterProductionMode = value));
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public isPhaseActive(phaseRoute: string): boolean {
    return this.router.isActive(phaseRoute, { paths: 'subset', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored' });
  }

  public isPhaseOfType(runPhaseType: string, expectedType: RunPhaseType): boolean {
    return runPhaseType === expectedType;
  }

  public isSubPhaseOfType(runSubPhaseType: string, expectedType: RunSubPhaseType): boolean {
    return runSubPhaseType === expectedType;
  }

  public showMachineSpeed() {
    return this.isMachineSpeedSimulationEnabled && this.workCenterProductionMode === WorkCenterProductionMode.Automation;
  }

  public onMachineSpeedBlur(): void {
    const machineSpeed = this.machineSpeedInput ?? 0;
    this.isMachineSpeedDisabled = true;
    this.activeOrderPanelService
      .changeSpeed(machineSpeed)
      .pipe(finalize(() => (this.isMachineSpeedDisabled = false)))
      .subscribe();
  }

  public onNavigateToActivePhaseClick(): void {
    this.router.navigate([this.navigationRouteService.getNavigationRoute()]);
  }

  public blurOnEnter(event: KeyboardEvent): void {
    (event.target as HTMLInputElement).blur();
  }

  public openTimePickerModal(): void {
    this.dialogService.open(DatePickerModalComponent, {
      header: this.translateService.instant('ACTIVE_ORDER.CLOCKIN'),
      width: '600px'
    });
  }

  private setIsCombination(activeOrderPanelData: ActiveOrderPanelData): void {
    this.isCombination =
      activeOrderPanelData.activeProductionOrder?.combinationOrderIdentifier &&
      !activeOrderPanelData.activeProductionOrder?.isCombinationOrder;

    if (this.router.url.indexOf('/setup/') > -1) {
      this.activeOrderPanelService.isQaHistoryConfirmedAutomaticallySubject.next(
        activeOrderPanelData.activeProductionOrder?.isQaHistoryConfirmedAutomatically
      );
    } else {
      this.activeOrderPanelService.isQaHistoryConfirmedAutomaticallySubject.next(false);
    }

    this.CreateOrderPhases(activeOrderPanelData);
  }

  public isPredecesorStatusNone(predecessorStatus: ProductionOrderStatus): boolean {
    return predecessorStatus === ProductionOrderStatus.None;
  }

  public isPredecessortQuantityAvailable(predecessorQuantity: Quantity): boolean {
    return predecessorQuantity?.value === 0;
  }

  private CreateOrderPhases(activeOrderPanelData: ActiveOrderPanelData) {
    this.phaseItems = [
      {
        link: this.navigation.routes.setup,
        title: 'ACTIVE_ORDER.SETUP',
        icon: 'icon-active-order-setup',
        phaseType: this.orderPhase.Setup,
        isReadOnly: activeOrderPanelData.isManualMode || !activeOrderPanelData.activeProductionOrder?.isEditOfSetupPhaseEnabled,
        subItems: this.createSetupSubItems(activeOrderPanelData)
      },
      {
        link: this.navigation.routes.run,
        title: 'ACTIVE_ORDER.RUN',
        icon: 'icon-active-order-run',
        phaseType: this.orderPhase.Run
      },
      {
        link: this.navigation.routes.finish,
        title: 'ACTIVE_ORDER.FINISH',
        icon: 'icon-active-order-finish',
        phaseType: this.orderPhase.Finishing,
        subItems: this.createFinishingSubItems(activeOrderPanelData)
      }
    ];
  }

  private createFinishingSubItems(activeOrderPanelData: ActiveOrderPanelData): SubPhaseItem[] {
    return [
      {
        link: this.navigation.routes.finishOverview,
        title: 'ACTIVE_ORDER.OVERVIEW',
        subPhaseType: this.orderSubPhase.FinishingOverview,
        isActive: true
      },
      {
        link: this.navigation.routes.finishToolcheckout,
        title: 'ACTIVE_ORDER.TOOL_CHECKOUT',
        subPhaseType: this.orderSubPhase.FinishingToolCheckout,
        isActive: activeOrderPanelData.isToolCheckoutAvailable
      },
      {
        link: this.navigation.routes.finishEnd,
        title: 'ACTIVE_ORDER.END',
        subPhaseType: this.orderSubPhase.FinishingEnd,
        isActive: true
      }
    ];
  }

  private createSetupSubItems(activeOrderPanelData: ActiveOrderPanelData): SubPhaseItem[] {
    return [
      {
        link: this.navigation.routes.setupLineClearance,
        title: 'ACTIVE_ORDER.LINE_CLEARANCE',
        subPhaseType: this.orderSubPhase.SetupLineClearance,
        isActive: activeOrderPanelData.showLineClearance
      },
      {
        link: this.navigation.routes.setupQaHistory,
        title: 'ACTIVE_ORDER.NONCONFORMANCE',
        subPhaseType: this.orderSubPhase.SetupQaHistory,
        isActive: activeOrderPanelData.showQaHistory
      },
      {
        link: this.navigation.routes.setupPatternInfo,
        title: this.translateService.instant('ACTIVE_ORDER.PATTERNINFO'),
        subPhaseType: this.orderSubPhase.SetupPatternInfo,
        isActive: activeOrderPanelData.isPatternInfoAvailableForOrder
      },
      {
        link: this.navigation.routes.setupParameters,
        title: 'ACTIVE_ORDER.PARAMETERS',
        subPhaseType: this.orderSubPhase.SetupParameter,
        isActive: true
      },
      {
        link: this.navigation.routes.qualityAssurance,
        title: 'ACTIVE_ORDER.QA_CHECKS',
        subPhaseType: this.orderSubPhase.SetupInProgressFinishingCheck,
        isActive: activeOrderPanelData.showQaChecks
      },
      {
        link: this.navigation.routes.wasteAssignment,
        title: 'ACTIVE_ORDER.ADAPT_SETUPTIME',
        subPhaseType: this.orderSubPhase.SetupMaterialWaste,
        isActive: true
      }
    ];
  }
}
