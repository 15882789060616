import { Injectable } from '@angular/core';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { Observable } from 'rxjs';
import {
  FinishedGoodArticleItem,
  NonConformanceItem,
  NonConformanceModel,
  QualityHistoryService,
  CreateNonConformance
} from 'chronos-core-client';
import { AppSettingsQuery, AppSettingsService } from 'chronos-shared';

@Injectable({
  providedIn: 'root'
})
export class QaHistoryDsService {
  constructor(
    private appSettingsQuery: AppSettingsQuery,
    private appSettingsService: AppSettingsService,
    private qualityHistoryService: QualityHistoryService,
    private activeWorkCenterService: ActiveWorkCenterService
  ) {}

  public isQaHistoryAvailableForSetup(): Observable<boolean> {
    return this.appSettingsQuery.isQaHistoryEnabledForSetup$;
  }

  public isQaChecksAvailableForSetup(): Observable<boolean> {
    return this.appSettingsQuery.isQaChecksEnabledForSetup$;
  }

  public isLineClearanceEnableForSetup(): Observable<boolean> {
    return this.appSettingsQuery.isLineClearanceEnabledForSetup$;
  }

  public setQaHistoryStatusForSetup(isAvailable: boolean): void {
    this.appSettingsService.setQaHistorySetup(isAvailable);
  }

  public setQaChecksStatusForSetup(isAvailable: boolean): void {
    this.appSettingsService.setQaChecksSetup(isAvailable);
  }

  public getFinishedGoodArticles(loadOnlyForActiveOrder: boolean): Observable<FinishedGoodArticleItem[]> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.qualityHistoryService.getFinishedGoodArticles({
      workCenterId,
      loadOnlyForActiveOrder
    });
  }

  public getNonConformanceList(articleId: number): Observable<NonConformanceItem[]> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.qualityHistoryService.getNonConformanceOverview({
      workCenterId,
      articleId
    });
  }

  public getNonConformance(nonConformanceId: number): Observable<NonConformanceModel> {
    return this.qualityHistoryService.getNonConformance({ nonConformanceId });
  }

  public setQaHistoryCheck(productionOrderId: number, isChecked: boolean): Observable<void> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.qualityHistoryService.checkQaHistory({
      productionOrderId,
      body: {
        isChecked,
        workCenterId
      }
    });
  }

  public createNonConformancePath(productionOrderId: number): Observable<CreateNonConformance> {
    return this.qualityHistoryService.getNonConformance2({ productionOrderId });
  }

  public createNonConformance(productionOrderId: number, sortingWasteQuantity: number, internalMemo: string): Observable<void> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.qualityHistoryService.createNonConformance({
      productionOrderId,
      body: {
        workCenterId,
        sortingWasteQuantity,
        internalMemo
      }
    });
  }

  public setQaToolCheckoutConfirm(productionOrderId: number, isChecked: boolean): Observable<void> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.qualityHistoryService.setQaToolCheckoutConfirm({
      productionOrderId,
      body: {
        isChecked,
        workCenterId
      }
    });
  }
}
