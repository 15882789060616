/* tslint:disable */
/* eslint-disable */
export enum ArticleClassification {
  Other = 'Other',
  Product = 'Product',
  Reel = 'Reel',
  Sheet = 'Sheet',
  Liquid = 'Liquid',
  Tool = 'Tool'
}
