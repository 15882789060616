import { Injectable } from '@angular/core';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import {
  DocumentManagementService,
  ProductionOrderDocumentInfo,
  ProductionOrderDocuments,
  ProductionOrderPrintedLabelInfo
} from 'chronos-core-client';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class DocumentsDsService {
  constructor(private documentManagementService: DocumentManagementService, private activeWorkCenterService: ActiveWorkCenterService) {}

  public getDocumentList(productionOrderId: number): Observable<ProductionOrderDocuments> {
    return this.documentManagementService.getDocuments({ productionOrderId });
  }

  public getProductionOrderInfo(productionOrderId: number): Observable<ProductionOrderDocumentInfo> {
    return this.documentManagementService.getProductionOrderInformation({ productionOrderId });
  }

  public getProductionOrderLabelInfo(productionOrderId: number): Observable<ProductionOrderPrintedLabelInfo> {
    return this.documentManagementService.getPrintedLabelInfo({ productionOrderId });
  }

  public saveRemark(productionOrderId: number, documentInfo: string): Observable<void> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.documentManagementService.editInformation({
      productionOrderId,
      body: {
        documentInfo,
        workCenterId
      }
    });
  }

  public markAsDisplayed(productionOrderId: number, documentId: number): Observable<void> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.documentManagementService.markAsDisplayed({
      productionOrderId,
      body: {
        documentId,
        workCenterId,
        isAuthorized: true /* will be overwritten by the backend */
      }
    });
  }
}
