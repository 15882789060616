import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ActiveProductionOrder,
  ArticleDescription,
  ManualMachineCheckoutPageMode,
  ProductionOrderManualModeFinishingDataViewModel
} from 'chronos-core-client';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ManualModeService } from '@app/modules/manual-mode/services/manual-mode/manual-mode.service';
import moment from 'moment-mini';
import { nav, notificationTopic } from '@app/shared/utils';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { LoadingNotificationService, LogService } from 'chronos-shared';
import { Subscription } from 'rxjs';
import { OutputFinish, OutputFinishState } from '@app/modules/run-phase/models';

@Component({
  selector: 'app-order-finish-simple-modal',
  templateUrl: './order-finish-simple-modal.component.html',
  styleUrls: ['./order-finish-simple-modal.component.scss']
})
export class OrderFinishSimpleModalComponent implements OnInit, OnDestroy {
  private readonly HOURS = 24;

  public article: ArticleDescription;
  public form: UntypedFormGroup;
  public hours = 0;
  public minutes = 0;
  public minValue = 0;
  public maxHours = this.HOURS;
  public maxMinutes = 59;

  public isInvalidTime = false;
  public isInvalidForm = false;
  public isInvalidEmployees = false;
  public outputFinish?: OutputFinish;
  public submitLabel = '';
  public violatedEmployeeLimit = false;
  public violatedShiftDuration = false;
  private shiftDuration: string;
  private isFromPallet: false;

  private activeProductionOrder: ActiveProductionOrder;
  private sortingValues: ProductionOrderManualModeFinishingDataViewModel;
  private durationFormat = 'HH:mm';
  private durationFormatWithSeconds = 'HH:mm:ss';

  private subscriptions = new Subscription();

  public readonly LOADING_TOPIC = notificationTopic.modalForManualMode;
  public readonly MAXIMUM_NUMBEROF_EMPLOYEES = 10;
  public readonly TEXT_MINUTES = 'minutes';
  public readonly TEXT_HOURS = 'hours';

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private manualModeService: ManualModeService
  ) {}

  public ngOnInit(): void {
    this.sortingValues = this.config.data?.sortingValues;
    this.activeProductionOrder = this.config.data?.activeProductionOrder;
    this.article = this.config.data?.article;
    this.submitLabel = this.config.data?.submitLabel;
    this.outputFinish = this.config.data?.outputFinish;
    this.shiftDuration = this.config.data?.shiftDuration;
    this.isFromPallet = this.config.data?.isFromPallet;

    this.initModalForm(this.sortingValues);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public submitButton(): void {
    LoadingNotificationService.publish(this.LOADING_TOPIC, true);

    const { hours, minutes, employees } = this.form.value;
    const endTime = moment().toDate();
    const duration = this.formatDate(minutes, hours);
    const workerFlag = employees || this.sortingValues?.numberOfWorkers;
    const durationFlag = duration || this.sortingValues?.duration;
    if (!!durationFlag && !!workerFlag) {
      this.manualModeService
        .saveManualModeFinishingValues(
          ManualMachineCheckoutPageMode.Simple,
          this.activeProductionOrder.productionOrderId,
          null,
          employees,
          duration
        )
        .subscribe(() => {
          if (!this.isFromPallet) {
            LoadingNotificationService.publish(this.LOADING_TOPIC, false);
            this.ref.close(true);
            return;
          }

          if (this.outputFinish?.finishState === OutputFinishState.Switch) {
            this.manualModeService
              .switchManualOrder(
                ManualMachineCheckoutPageMode.Simple,
                this.activeProductionOrder.productionOrderId,
                this.outputFinish?.producedMaterialId,
                endTime,
                null,
                null,
                employees,
                duration
              )
              .pipe(
                finalize(() => {
                  LoadingNotificationService.publish(this.LOADING_TOPIC, false);
                })
              )
              .subscribe(() => {
                this.finishOrder();
              });
          } else {
            this.manualModeService
              .endManualSortingOrder(
                this.activeProductionOrder.productionOrderId,
                endTime,
                this.outputFinish?.finishState === OutputFinishState.Interrupt
              )
              .pipe(
                finalize(() => {
                  LoadingNotificationService.publish(this.LOADING_TOPIC, false);
                })
              )
              .subscribe(() => {
                this.finishOrder();
              });
          }
        });
    }
  }

  public cancelButton(): void {
    this.ref.close(false);
  }

  public onEmployeeChange(value: number): void {
    this.violatedEmployeeLimit = value > this.MAXIMUM_NUMBEROF_EMPLOYEES ? true : false;
  }

  public onHoursOrMinutesChange(): void {
    const minutes = this.form.value.minutes;
    const hours = this.form.value.hours;
    this.violatedShiftDuration = this.checkDurationViolation(hours, minutes);
  }

  private formatDate(minutes: number, hours: number): string {
    return moment().set(this.TEXT_MINUTES, minutes).set(this.TEXT_HOURS, hours).format(this.durationFormat);
  }

  private finishOrder(): void {
    LogService.success('SUCCESS_MESSAGE.JOB_FINISHED');
    this.navigateToFinishPhase();
  }

  private validateForm(): void {
    this.isInvalidTime = this.form.controls.hours.invalid || this.form.controls.minutes.invalid || this.form?.errors?.invalidTime;
    this.isInvalidEmployees = this.form.controls.employees.invalid;
    this.isInvalidForm = this.form.invalid;
  }

  private calculateSum(): void {
    let { hours, minutes } = this.form.value;
    const { employees } = this.form.value;

    this.maxHours = minutes === 0 ? this.HOURS : this.HOURS - 1;

    if (hours > this.maxHours) {
      this.form.controls.hours.setValue(this.maxHours, { emitEvent: false });
      hours = this.maxHours;
    }

    if ((minutes > 0 || hours > 0) && employees > 0) {
      minutes *= employees;
      hours *= employees;

      this.minutes = minutes % 60;
      this.hours = hours + Math.floor(minutes / 60);
    } else {
      this.minutes = 0;
      this.hours = 0;
    }
  }

  private initModalForm(sortingValues: ProductionOrderManualModeFinishingDataViewModel): void {
    let hours = 0;
    let minutes = 0;
    let employees = 0;

    if (sortingValues?.duration) {
      const time = moment(sortingValues.duration, this.durationFormat);

      hours = time.get('hours');
      minutes = time.get('minutes');
    }

    if (sortingValues?.numberOfWorkers) {
      employees = sortingValues.numberOfWorkers;
    }

    this.violatedEmployeeLimit = employees > this.MAXIMUM_NUMBEROF_EMPLOYEES ? true : false;
    this.violatedShiftDuration = this.checkDurationViolation(hours, minutes);

    this.form = this.formBuilder.group(
      {
        employees: [employees, [Validators.required, Validators.min(1)]],
        hours: [hours, [Validators.required, Validators.min(0)]],
        minutes: [minutes, [Validators.required, Validators.min(0)]]
      },
      { validators: validateTime }
    );

    this.subscriptions.add(
      this.form.valueChanges.subscribe(() => {
        this.calculateSum();
      })
    );
    this.subscriptions.add(
      this.form.statusChanges.subscribe(() => {
        this.validateForm();
      })
    );
    this.form.updateValueAndValidity();
  }

  private navigateToFinishPhase(): void {
    this.router.navigate([nav.finish]).then(() => {
      this.ref.close(true);
    });
  }

  private checkDurationViolation(hours: number, minutes: number) {
    let duration;
    if (!minutes) {
      minutes = 0;
    }

    if (hours === 24 && minutes === 0) {
      duration = '24:00';
    } else {
      duration = this.formatDate(minutes, hours);
    }

    const formattedShiftDuration = moment(this.shiftDuration, this.durationFormatWithSeconds).format(this.durationFormat);
    return moment(duration, this.durationFormat).isAfter(moment(formattedShiftDuration, this.durationFormat));
  }
}

function validateTime(formGroup: UntypedFormGroup): ValidationErrors | null {
  return formGroup.value.minutes === formGroup.value.hours && formGroup.value.hours === 0 ? { invalidTime: true } : null;
}
