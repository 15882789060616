import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { LabelPrintMode, ProducedMaterialPrintLabelInfo } from 'chronos-core-client';
import { DocumentsService } from '@app/modules/documents/services';
import { combineLatest, EMPTY } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { LoadingNotificationService, LogService } from 'chronos-shared';
import { notificationTopic } from '@app/shared/utils';

@Component({
  selector: 'app-modal-print-label',
  templateUrl: './modal-print-label.component.html',
  styleUrls: ['./modal-print-label.component.scss']
})
export class ModalPrintLabelComponent implements OnInit {
  public readonly INPUT_STYLE = { width: '60px' };
  public readonly LOADING_TOPIC = notificationTopic.modalPrintLabel;

  public availableCount: number = this.config.data?.availableCount;
  public modalForm: UntypedFormGroup;

  private labels: ProducedMaterialPrintLabelInfo[] = this.config.data?.labels;

  constructor(private ref: DynamicDialogRef, private config: DynamicDialogConfig, private documentsService: DocumentsService) {}

  public ngOnInit(): void {
    this.initModalForm();
  }

  public closeModal(): void {
    this.ref.close(false);
  }

  public submitModal(): void {
    if (this.modalForm.valid) {
      const fromRangeIndex = this.modalForm.value.fromRange - 1;
      const toRangeIndex = this.modalForm.value.toRange - 1;

      const action = [];
      const sequence = [];

      this.labels.forEach((label, index: number) => {
        if (index >= fromRangeIndex && index <= toRangeIndex && label.printMode !== LabelPrintMode.None) {
          action.push(
            this.documentsService.sendPrintLabel(label.containerId, label.printMode === LabelPrintMode.RePrint).pipe(
              tap(() => sequence.push(label.containerSequence)),
              catchError(() => EMPTY)
            )
          );
        }
      });

      LoadingNotificationService.publish(this.LOADING_TOPIC, true);
      combineLatest(action)
        .pipe(
          finalize(() => {
            this.onFinalizeAction(sequence);
          })
        )
        .subscribe(() => {
          this.ref.close(true);
        });
    }
  }

  public initModalForm(): void {
    const toRange = this.labels?.length;

    this.modalForm = new UntypedFormGroup(
      {
        fromRange: new UntypedFormControl(1, [Validators.required, Validators.min(1)]),
        toRange: new UntypedFormControl(toRange, [Validators.required, Validators.min(1), Validators.max(toRange)])
      },
      [validateRange]
    );
  }

  private onFinalizeAction(sequence: number[]): void {
    LoadingNotificationService.publish(this.LOADING_TOPIC, false);

    if (sequence.length) {
      sequence.sort((a, b) => a - b);

      let message = '';
      let nextSequence: number;
      let firstSequence = sequence[0];
      let lastSequence = sequence[0];

      for (let i = 1; i < sequence.length; i++) {
        nextSequence = sequence[i];
        if (sequence[i - 1] + 1 !== nextSequence) {
          message += this.mapSequenceString(firstSequence, lastSequence, ', ');
          firstSequence = nextSequence;
        }
        lastSequence = nextSequence;
      }

      message += this.mapSequenceString(firstSequence, lastSequence);
      LogService.success('SUCCESS_MESSAGE.GLOBAL_PRINT', { sequence: message });
    }
  }

  private mapSequenceString(first: number, last: number, separator: string = ''): string {
    return first !== last ? `${first} - ${last}${separator}` : `${last}${separator}`;
  }
}

function validateRange(formGroup: UntypedFormGroup): ValidationErrors | null {
  return formGroup.value.fromRange > formGroup.value.toRange ? { invalidRange: true } : null;
}
