<div *ngIf="currentKpi && currentKpi.name && valuePropertyBag.mode === 'Large'" class="kpi">
  <div class="lower-kpi-label">
    {{ currentKpi.name | translate }}
  </div>
  <div class="lower-kpi-value">
    <label *ngIf="currentKpi">
      <span *ngIf="currentKpi.value">{{ currentKpi.value | formatQuantity : false }}</span>
      <span *ngIf="currentKpi.valueTime">{{ currentKpi.valueTime }}</span>
    </label>
  </div>
</div>
