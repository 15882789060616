import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Remark, RemarkService } from 'chronos-core-client';

@Injectable({
  providedIn: 'root'
})
export class RemarkDsService {
  constructor(private remarkService: RemarkService) {}

  public getRemark(remarkId: number): Observable<Remark> {
    return this.remarkService.getRemark({ remarkId });
  }

  public setRemark(workCenterId: number, remarkId: number, text: string): Observable<void> {
    return this.remarkService.editRemark({
      remarkId,
      body: {
        workCenterId,
        remarkText: text
      }
    });
  }
}
