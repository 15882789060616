import { Injectable } from '@angular/core';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { Observable } from 'rxjs';
import { MaintenanceService, SimulationService } from 'chronos-core-client';

@Injectable({
  providedIn: 'root'
})
export class SimulationDsService {
  constructor(
    private simulationService: SimulationService,
    private maintenanceService: MaintenanceService,
    private activeWorkCenterService: ActiveWorkCenterService
  ) {}

  public changeMachineSpeed(speed: number): Observable<void> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.simulationService.setSpeed({
      workCenterId,
      body: {
        speed
      }
    });
  }

  public recreatePeriods(startTime: Date): Observable<void> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.simulationService.recreatePeriods({
      workCenterId,
      body: {
        startTime: startTime.toISOString()
      }
    });
  }

  public clockInFilterDate(startTime: Date, setInAllWorkCenters: boolean): Observable<void> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.maintenanceService.clockInFilterDate({
      workCenterId,
      body: {
        startTime: startTime.toISOString(),
        setInAllWorkCenters
      }
    });
  }
}
