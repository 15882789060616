import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import * as R from 'ramda';
import {
  ArticleDescription,
  MaterialAvailableAtLocationItem,
  Quantity,
  ScanContainerResponse,
  ScannedIdentificationCodeType
} from 'chronos-core-client';
import { TranslateService } from '@ngx-translate/core';
// To be deleted.
@Component({
  selector: 'app-mounting-material',
  templateUrl: './mounting-material.component.html',
  styleUrls: ['./mounting-material.component.scss']
})
export class MountingMaterialComponent implements OnInit {
  @Input() public availableMountingMaterial: MaterialAvailableAtLocationItem[];
  @Input() public billOfMaterialArticleList: ArticleDescription[];
  @Input() public submitButtonLabel = 'MOUNTING.CONSUME';
  @Input() public suggestedQuantity: Quantity;
  @Input() public whenLoadingTopic = '';
  @Input() public isConsumeAllEnabled = false;

  @Output() public mountingMaterialSubmit = new EventEmitter<[MaterialAvailableAtLocationItem, number, number, boolean]>();
  @Output() public emitSelectedRow = new EventEmitter<MaterialAvailableAtLocationItem>();

  public readonly MINIMUM_MATERIAL_QUANTITY = 0;

  public warehouseData: MaterialAvailableAtLocationItem[] = [];
  public warehouseFilteredData: MaterialAvailableAtLocationItem[] = [];
  public selectedMaterial: MaterialAvailableAtLocationItem;
  public selectedMaterialQuantity = this.MINIMUM_MATERIAL_QUANTITY;
  public selectedMaterialQuantityUnitId: string;
  public columns: any[];
  public dropDownOptions: any[];
  public selectedOption: number;
  public preselectedArticle: ArticleDescription;
  public preselectedMaterialFilter: ScanContainerResponse;
  public isMaterialQuantityRequired = true;
  public materialBlockId = 0;
  public consumeAllRows = false;

  private maxQuantity?: Quantity;

  constructor(private ref: DynamicDialogRef, private conf: DynamicDialogConfig, private translateService: TranslateService) {}

  public ngOnInit(): void {
    if (!R.isNil(this.conf.data.articleForConsumption)) {
      this.selectedOption = this.conf.data.articleForConsumption.id;

      if (this.billOfMaterialArticleList) {
        this.preselectedArticle = this.billOfMaterialArticleList.find((article) => article.id === this.selectedOption);
      } else {
        this.preselectedArticle = this.conf.data.articleForConsumption;
      }
    }

    if (!R.isNil(this.conf.data.isMaterialQuantityRequired)) {
      this.isMaterialQuantityRequired = this.conf.data.isMaterialQuantityRequired;
    }

    this.preselectedMaterialFilter = this.conf.data.filterFromScan;
    this.materialBlockId = this.conf.data.materialBlockId;

    this.warehouseData = this.availableMountingMaterial;
    this.filterWarehouseData();

    if (this.billOfMaterialArticleList) {
      this.setDropDownOptions();
    }

    if (this.suggestedQuantity) {
      this.updateMaterialQuantity();
    }

    this.columns = [
      { field: 'isBlocked', type: 'status', header: 'MOUNTING.STATUS', width: 100 },
      { field: 'externalArticleId', header: 'MOUNTING.ARTICLE_ID', width: 120 },
      { field: 'identificationCode', type: 'sscc', header: 'MOUNTING.IDENTIFICATION', width: 210 },
      { field: 'internalBatchId', header: 'MOUNTING.BATCH_INTERNAL', width: 150 },
      { field: 'externalBatchId', header: 'MOUNTING.BATCH_EXTERNAL', width: 155 },
      { field: 'quantity', type: 'quantity', header: 'MOUNTING.ON_HAND', style: 'text-right', width: 120 },
      { field: 'warehouseName', header: 'MOUNTING.WAREHOUSE', width: 140 },
      { field: 'warehouseLocationName', header: 'MOUNTING.LOCATION', width: 120 },
      { field: 'dateTimeAvailable', type: 'datetime', header: 'MOUNTING.MADE_AVAILABLE', width: 120 }
    ];
  }

  public onSubmitClick(): void {
    if (this.isSubmitEnabled()) {
      this.mountingMaterialSubmit.emit([this.selectedMaterial, this.selectedMaterialQuantity, this.materialBlockId, this.consumeAllRows]);
    }
  }

  public onDeclineClick(): void {
    this.ref.close();
  }

  public selectedMaterialChange(material: MaterialAvailableAtLocationItem): void {
    this.consumeAllRows = false;
    this.selectedMaterial = material;
    this.updateMaterialMaxQuantity();
    this.updateMaterialQuantity();
    this.emitSelectedRow.emit(material);
  }

  public consumeAllCheckChange(): void {
    if (this.consumeAllRows) {
      this.selectedMaterial = null;
      this.selectedMaterialQuantity = this.warehouseFilteredData.map((material) => material.bomQuantity.value).reduce((a, b) => a + b);
    }
  }

  public isSubmitEnabled(): boolean {
    return this.consumeAllRows || (!R.isNil(this.selectedMaterial) && this.isQuantityValid() && this.selectedMaterial.isMountable);
  }

  private updateMaterialMaxQuantity(): void {
    if (this.selectedMaterial) {
      this.maxQuantity = this.selectedMaterial.bomQuantity;
    }
  }

  public filterWarehouseData(): void {
    this.resetSelectedMaterial();

    let filteredData = this.warehouseData;
    if (this.selectedOption) {
      filteredData = this.filterByArticleId(this.selectedOption);
    } else if (this.preselectedMaterialFilter) {
      filteredData = this.filterByMaterialScanResponse(this.preselectedMaterialFilter);
    }

    this.warehouseFilteredData = filteredData.map((item) => ({
      ...item,
      externalArticleId: item.article.externalArticleId
    }));
  }

  private filterByArticleId(articleId: number): MaterialAvailableAtLocationItem[] {
    return this.warehouseData.filter((data) => data.article.id === articleId);
  }

  private filterByMaterialScanResponse(materialScanResponse: ScanContainerResponse): MaterialAvailableAtLocationItem[] {
    let identificationTypeLookup;

    switch (materialScanResponse.scannedIdentificationCodeType) {
      case ScannedIdentificationCodeType.ExternalSerialId:
        identificationTypeLookup = 'externalSerialId';
        break;
      case ScannedIdentificationCodeType.SsccCode:
        identificationTypeLookup = 'ssccCode';
        break;
    }

    return this.warehouseData.filter((data) => R.prop(identificationTypeLookup, data) === materialScanResponse.scannedValue);
  }

  private resetSelectedMaterial(): void {
    this.maxQuantity = null;
    this.selectedMaterial = null;
    this.updateMaterialQuantity();
  }

  private isQuantityValid(): boolean {
    const isAboveMinimum = this.selectedMaterialQuantity >= this.MINIMUM_MATERIAL_QUANTITY;
    const isBelowMaximum = this.selectedMaterialQuantity <= this.maxQuantity?.value;

    return isAboveMinimum && isBelowMaximum;
  }

  private setDropDownOptions(): void {
    this.dropDownOptions = R.pipe(
      R.map((article: ArticleDescription) => ({
        label: `${article.externalArticleId} ${article.articleName}`,
        value: article.id
      })),
      R.uniq
    )(this.billOfMaterialArticleList).concat({
      label: this.translateService.instant('DROPDOWN_ALL_LABEL'),
      value: null
    });
  }

  private updateMaterialQuantity(): void {
    if (this.suggestedQuantity) {
      if (this.selectedMaterial && this.maxQuantity?.value < this.suggestedQuantity.value) {
        this.selectedMaterialQuantity = this.maxQuantity.value;
      } else {
        this.selectedMaterialQuantity = this.suggestedQuantity.value;
      }
      this.selectedMaterialQuantityUnitId = this.suggestedQuantity.unitId;
    } else {
      this.selectedMaterialQuantity = this.selectedMaterial ? this.maxQuantity.value : this.MINIMUM_MATERIAL_QUANTITY;
      this.selectedMaterialQuantityUnitId = this.selectedMaterial ? this.maxQuantity?.unitId : '';
    }
  }

  public onBlurInputQuantity(): void {
    if (!this.selectedMaterialQuantity || this.selectedMaterialQuantity < 0) {
      this.updateMaterialQuantity();
    }
  }
}
