import { Injectable } from '@angular/core';
import { KpiIdentifier } from 'chronos-core-client';
import {
  KpiActionBase,
  KpiActionConsumptionPrimaryService,
  KpiActionConsumptionSecondaryService,
  KpiActionDowntimeService,
  KpiActionEmptyService,
  KpiActionGoodQuantityService,
  KpiActionSetupTimeService,
  KpiActionDurationService,
  KpiActionConsumptionSecondaryLaminationService
} from './actions';

@Injectable({
  providedIn: 'root'
})
export class KpiActionFactoryService {
  constructor(
    private kpiActionDowntimeService: KpiActionDowntimeService,
    private kpiActionSetupTimeService: KpiActionSetupTimeService,
    private kpiActionGoodQuantityService: KpiActionGoodQuantityService,
    private kpiActionEmptyService: KpiActionEmptyService,
    private kpiActionConsumptionPrimaryService: KpiActionConsumptionPrimaryService,
    private kpiActionConsumptionSecondaryService: KpiActionConsumptionSecondaryService,
    private kpiActionDurationService: KpiActionDurationService,
    private kpiActionConsumptionSecondaryLaminationService: KpiActionConsumptionSecondaryLaminationService
  ) {}

  public createKpiAction(kpiType: KpiIdentifier): KpiActionBase {
    switch (kpiType) {
      case KpiIdentifier.Downtime: {
        return this.kpiActionDowntimeService;
      }
      case KpiIdentifier.SetupTime: {
        return this.kpiActionSetupTimeService;
      }
      case KpiIdentifier.GoodQuantity: {
        return this.kpiActionGoodQuantityService;
      }
      case KpiIdentifier.ConsumptionPrimary: {
        return this.kpiActionConsumptionPrimaryService;
      }
      case KpiIdentifier.ConsumptionSecondary: {
        return this.kpiActionConsumptionSecondaryService;
      }
      case KpiIdentifier.ConsumptionSecondaryLamination: {
        return this.kpiActionConsumptionSecondaryLaminationService;
      }
      case KpiIdentifier.NumberOfEmployees: {
        return this.kpiActionDurationService;
      }
      case KpiIdentifier.Duration: {
        return this.kpiActionDurationService;
      }

      default: {
        return this.kpiActionEmptyService;
      }
    }
  }
}
