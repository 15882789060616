import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActiveOrderPhaseDsService } from '@app/core/data-services';
import { SetupFooterCommandBase } from '@app/shared/components';
import { nav } from '@app/shared/utils';
import { Observable } from 'rxjs/internal/Observable';
import { finalize, map, tap } from 'rxjs/operators';
import { RunPhaseType } from 'chronos-core-client';
import { elastic } from '@app/shared/utils/elastic';
@Injectable()
export class SetupPhaseService {
  public backButtonCommand: SetupFooterCommandBase;
  public nextButtonCommand: SetupFooterCommandBase;
  public firstCheckboxCommand: SetupFooterCommandBase;

  constructor(private activeOrderPhaseDsService: ActiveOrderPhaseDsService, private router: Router) {}

  public setSetupCommands(
    backCommand: SetupFooterCommandBase,
    nextCommand: SetupFooterCommandBase,
    firstCommand?: SetupFooterCommandBase
  ): void {
    this.backButtonCommand = backCommand;
    this.nextButtonCommand = nextCommand;
    this.firstCheckboxCommand = firstCommand;
  }

  public enterRunPhase(route: string): Observable<void> {
    const trace = elastic.traceUiActivity('SetupPhaseService.enterRunPhase');

    return this.activeOrderPhaseDsService.enterRunPhase().pipe(
      tap(() => this.router.navigate([route])),
      finalize(() => {
        trace.end();
      })
    );
  }

  public cancelSetupPhase(): Observable<void> {
    const trace = elastic.traceUiActivity('SetupPhaseService.cancelSetupPhase');
    return this.activeOrderPhaseDsService.cancelActiveOrder().pipe(
      tap(() => this.router.navigate([nav.routes.ordersList])),
      finalize(() => {
        trace.end();
      })
    );
  }

  public isSetupPhase(): Observable<boolean> {
    return this.activeOrderPhaseDsService.selectActiveOrderPhase().pipe(map((currentPhase) => currentPhase === RunPhaseType.Setup));
  }
}
