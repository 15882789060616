/* tslint:disable */
/* eslint-disable */
export enum MaterialTypeFilterCriteria {
  None = 'None',
  Primary = 'Primary',
  Secondary = 'Secondary',
  Trace = 'Trace',
  PrimaryAndSecondary = 'PrimaryAndSecondary',
  All = 'All'
}
