import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { OuterLabelType, ProducedMaterial, ProducedMaterialService, SetForwardedWasteEntry } from 'chronos-core-client';
import { BobbinPrintLabel } from '@app/shared/models/bobbin-print-label';
import { ActiveWorkCenterService } from '@app/core/workcenter';

@Injectable({
  providedIn: 'root'
})
export class ProducedMaterialDsService {
  constructor(private producedMaterialService: ProducedMaterialService, private activeWorkCenterService: ActiveWorkCenterService) {}

  public setProducedForwardedWaste(producedMaterial: ProducedMaterial): Observable<void> {
    // Forwarded waste must be either true or false, so if it's null, we don't need to make a request. This happens when the produced material is finished.
    if (producedMaterial.containsForwardedWaste == null) {
      return of(null);
    }
    return this.producedMaterialService.setForwardedWaste({
      producedMaterialId: producedMaterial.producedMaterialId,
      body: {
        containsForwardedWaste: producedMaterial.containsForwardedWaste,
        workCenterId: producedMaterial.producedWorkCenterId
      }
    });
  }

  public printBobbinCoreLabel(printLabel: BobbinPrintLabel): Observable<void> {
    return this.producedMaterialService.printBobbinCoreLabel({
      producedMaterialId: printLabel.producedMaterialId,
      body: printLabel.entry
    });
  }

  public changeProducedMaterialPrintInfo(
    producedMaterialId: number,
    outerLabelType: OuterLabelType,
    quantityPerOuter: number,
    numberOfCopies: number
  ): Observable<void> {
    return this.producedMaterialService.changeProducedMaterialPrintInfo({
      producedMaterialId,
      body: {
        numberCopies: numberOfCopies,
        outerLabelType,
        quantityPerOuter,
        workCenterId: this.activeWorkCenterService.getWorkCenterId()
      }
    });
  }
}
