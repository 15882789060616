import { Component, Input, OnInit } from '@angular/core';
import { ActiveOrderQuery } from '@app/core/global-state';
import { Quantity } from 'chronos-core-client';

@Component({
  selector: 'app-sampling-kpi',
  templateUrl: './sampling-kpi.component.html',
  styleUrls: ['./sampling-kpi.component.scss']
})
export class SamplingKpiComponent implements OnInit {
  @Input() public totalAqlSampleQuantity?: Quantity;
  @Input() public totalTgsSampleQuantity?: Quantity;

  public aqlQuantity?: Quantity;
  public tgsQuantity?: Quantity;
  public isAqlOrTgsActive = false;
  public isAqlAndTgsActive = false;
  public isSamplingEnabled = false;

  constructor(private activeOrderQuery: ActiveOrderQuery) {}

  public ngOnInit(): void {
    this.aqlQuantity = this.activeOrderQuery.getAqlQuantity();
    this.tgsQuantity = this.activeOrderQuery.getTgsQuantity();
    this.isSamplingEnabled = this.activeOrderQuery.isSampleDataEnabled();

    this.isAqlOrTgsActive = this.aqlQuantity?.value > 0 || this.tgsQuantity?.value > 0;
    this.isAqlAndTgsActive = this.aqlQuantity?.value > 0 && this.tgsQuantity?.value > 0;
  }
}
