/* tslint:disable */
/* eslint-disable */
export enum KpiCategory {
  Quantity = 'Quantity',
  Time = 'Time',
  Speed = 'Speed',
  Project = 'Project',
  ShiftHeader = 'ShiftHeader',
  ApprovalReport = 'ApprovalReport'
}
