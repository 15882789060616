import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActiveOrderPhaseDsService } from '@app/core/data-services';
import { RunPhaseType, RunSubPhaseType } from 'chronos-core-client';
import { SubPhaseRoute } from '@app/shared/utils';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

@Injectable()
export class SetupFooterService {
  constructor(private activeOrderPhaseDsService: ActiveOrderPhaseDsService, private router: Router) {}

  public goToNextSubPhase(route: string, phase: RunPhaseType, subPhase: RunSubPhaseType, phaseRoutes: SubPhaseRoute[]): Observable<void> {
    const activeSubPhase = this.activeOrderPhaseDsService.getActiveOrderSubPhase();

    if (this.isNextSubPhaseAlreadyActive(activeSubPhase, phaseRoutes)) {
      this.router.navigate([route]);
      return EMPTY;
    }

    return this.activeOrderPhaseDsService.setNewActiveOrderPhase(phase, subPhase).pipe(tap(() => this.router.navigate([route])));
  }

  public goToPreviousSubPhase(route: string): void {
    this.router.navigate([route]);
  }

  private isNextSubPhaseAlreadyActive(activeSubPhase: RunSubPhaseType, phaseRoutes: SubPhaseRoute[]): boolean {
    const activeSubPhaseIndex = phaseRoutes.findIndex((setupRoute) => setupRoute.subPhase === activeSubPhase);

    const currentSubPhaseIndex = phaseRoutes.map((setupRoute) => setupRoute.route).indexOf(this.router.url);

    return activeSubPhaseIndex > currentSubPhaseIndex;
  }
}
