import { Component, Input, OnInit } from '@angular/core';
import { MachineSchedule, ProductionOrderStatus } from 'chronos-core-client';
import { productionOrderStatusFlags } from '@app/shared/models';

@Component({
  selector: 'app-order-primary-material',
  templateUrl: './order-primary-material.component.html',
  styleUrls: ['./order-primary-material.component.scss']
})
export class OrderPrimaryMaterialComponent implements OnInit {
  @Input() public machineSchedule: MachineSchedule;

  public readonly STATUS_FLAGS = productionOrderStatusFlags;
  public isPredecesorStatusNone: boolean;

  public ngOnInit(): void {
    this.isPredecesorStatusNone = this.machineSchedule.predecessorStatus === ProductionOrderStatus.None;
  }
}
