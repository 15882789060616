import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { QaCheckDsService } from '@app/core/data-services';
import { SignatureHandlerService } from '@app/core/services/signature/signature-handler.service';
import { SignatureDetailsEntry } from '@app/shared/models/signature';
import { notificationTopic } from '@app/shared/utils';
import { Quantity } from 'chronos-core-client';
import { AppSettingsQuery, LoadingNotificationService, LogService } from 'chronos-shared';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-sample-input-dialog',
  templateUrl: './sample-input-dialog.component.html',
  styleUrls: ['./sample-input-dialog.component.scss']
})
export class SampleInputDialogComponent implements OnInit, OnDestroy {
  public readonly INPUT_STYLE = { textAlign: 'right', width: '175px' };
  public aqlQuantity: number;
  public tgsQuantity: number;
  public modalForm: UntypedFormGroup;
  public unit: string;
  public isSignatureEnabled: boolean;
  public isSigntureScanned = false;
  private isOuterRequest = false;
  private producedMaterialId: number;
  private runId: number;
  private signatureDetails: SignatureDetailsEntry;

  private readonly INPUT_MAX_QUANTITY = 99999;
  private readonly INPUT_MIN_QUANTITY = 1;
  public readonly LOADING_TOPIC = notificationTopic.samplingInputDialog;

  constructor(
    private signatureService: SignatureHandlerService,
    private appSettingsQuery: AppSettingsQuery,
    private qaCheckDsService: QaCheckDsService,
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {}

  public ngOnInit(): void {
    this.initModalData();
    this.initModalForm();
  }

  public submitModal(): void {
    LoadingNotificationService.publish(this.LOADING_TOPIC, true);

    const values = this.modalForm.value;

    const aqlSampleQuantity = {
      value: values.aqlQuantity,
      unitId: values.aqlQuantity > 0 ? this.unit : null
    } as Quantity;

    const tgsSampleQuantity = {
      value: values.tgsQuantity,
      unitId: values.tgsQuantity > 0 ? this.unit : null
    } as Quantity;

    if (this.isOuterRequest) {
      this.qaCheckDsService.CreateSamplingDataForRun(this.runId, this.signatureDetails, aqlSampleQuantity, tgsSampleQuantity).subscribe(
        () => {
          LogService.success('SAMPLING.SAMPLE_ADDED_SUCCESS');
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
          this.closeModal();
        },
        () => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
        }
      );

      return;
    }

    this.qaCheckDsService
      .createSamplingDataForProducedMaterial(this.producedMaterialId, this.signatureDetails, aqlSampleQuantity, tgsSampleQuantity)
      .subscribe(
        () => {
          LogService.success('SAMPLING.SAMPLE_ADDED_SUCCESS');
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
          this.closeModal();
        },
        () => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
        }
      );
  }

  public onBadgeScanned(signDetails: SignatureDetailsEntry): void {
    this.isSigntureScanned = true;
    this.signatureDetails = signDetails;
  }

  public onEmployeeUnSelected(): void {
    this.isSigntureScanned = false;
    this.signatureDetails = null;
  }

  public ngOnDestroy(): void {
    console.info('SampleInputDialogComponent destroyed');
  }

  public closeModal(): void {
    this.ref.close();
  }

  private initModalForm(): void {
    const formControls = {};

    if (this.aqlQuantity > 0) {
      formControls['aqlQuantity'] = new UntypedFormControl(0, [
        Validators.required,
        Validators.min(this.INPUT_MIN_QUANTITY),
        Validators.max(this.INPUT_MAX_QUANTITY)
      ]);
    }

    if (this.tgsQuantity > 0) {
      formControls['tgsQuantity'] = new UntypedFormControl(0, [
        Validators.required,
        Validators.min(this.INPUT_MIN_QUANTITY),
        Validators.max(this.INPUT_MAX_QUANTITY)
      ]);
    }

    this.modalForm = new UntypedFormGroup(formControls);
  }

  private initModalData(): void {
    this.aqlQuantity = this.config.data?.aqlSampleQuantity;
    this.tgsQuantity = this.config.data?.tgsSampleQuantity;
    this.producedMaterialId = this.config.data?.producedMaterialId;
    this.runId = this.config.data?.runId;
    this.isOuterRequest = !!this.config.data?.isOuter;

    this.unit = this.appSettingsQuery.getWorkCenter().counterUnitId;
    this.isSignatureEnabled = this.signatureService.isQualityCheckSignatureEnabled();
    this.isSigntureScanned = !this.isSignatureEnabled; // if signature is not enabled, we assume it is scanned.
  }
}
