import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import { ActiveOrderDsService, MachineDsService, PrimaryMaterialDsService, VirtualContainerDsService } from '@app/core/data-services';
import { DraggableContainerIndices } from '@app/modules/mounting/models';
import {
  MachineData,
  MachineState,
  CreateAndMountVirtualPrimaryContainerEntry,
  WebSocketClientService,
  CreatePrimaryVirtualContainerData
} from 'chronos-core-client';
import * as R from 'ramda';
import { clone } from 'ramda';
import { Observable } from 'rxjs/internal/Observable';
import { MaterialTableTreeNode } from '@app/modules/mounting/models/material-table.model';
import { HubUrlsService } from '@app/core/utils';
import { concat } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListValue, MountedMaterial } from 'chronos-shared';
import { TreeNode } from 'primeng/api';
import { Quantity } from 'projects/chronos-core-client/src/public-api';

@Injectable()
export class MountingService {
  constructor(
    private machineDsService: MachineDsService,
    private activeOrderDsService: ActiveOrderDsService,
    private hubUrlsService: HubUrlsService,
    private webSocketClientService: WebSocketClientService,
    private primaryMaterialDsService: PrimaryMaterialDsService,
    private virtualContainerDsService: VirtualContainerDsService
  ) {}

  public getVirtualContainerReasons(): Observable<ListValue[]> {
    return this.virtualContainerDsService.getVirtualContainerReasonsList();
  }

  public getPrimaryVirtualContainerCreationData(productionOrderId: number): Observable<CreatePrimaryVirtualContainerData> {
    return this.virtualContainerDsService.getPrimaryVirtualContainerCreationData(productionOrderId);
  }

  public addNewVirtualInputContainer(primaryVirtualContainer: CreateAndMountVirtualPrimaryContainerEntry): Observable<void> {
    return this.primaryMaterialDsService.addVirtualContainer(primaryVirtualContainer);
  }

  public changeInfoForVirtualContainer(containerId: number, info: string): Observable<void> {
    return this.virtualContainerDsService.changeVirtualContainerInfo(containerId, info);
  }

  public getMachineStateNotifications(): Observable<MachineState> {
    return concat(this.getMachineData(), this.getMachineDataNotifications()).pipe(map((machineData) => machineData.state));
  }

  public getMachineData(): Observable<MachineData> {
    return this.machineDsService.getStatus();
  }

  public getMachineDataNotifications(): Observable<MachineData> {
    return this.webSocketClientService.getNotificationsForTopic<MachineData>(this.hubUrlsService.getState());
  }

  public dismountContainer(
    containerId: number,
    mountedMaterialId: number,
    remainingQuantity: number,
    isLabelPrinted: boolean
  ): Observable<void> {
    return this.primaryMaterialDsService.dismountContainer(containerId, mountedMaterialId, remainingQuantity, isLabelPrinted);
  }

  public addContainerWhiteWaste(mountedMaterialId: number, whiteWaste: Quantity): Observable<void> {
    return this.primaryMaterialDsService.addWhiteWasteToMountedMaterial(mountedMaterialId, whiteWaste);
  }

  public removeContainerWhiteWaste(mountedMaterialId: number): Observable<void> {
    return this.primaryMaterialDsService.removeWhiteWasteFromMountedMaterial(mountedMaterialId);
  }

  public changeContainerListSequence(
    draggableContainerIndices: DraggableContainerIndices,
    containers: MountedMaterial[]
  ): MountedMaterial[] {
    const clonedContainers = clone(containers);
    const materialBlockId = clonedContainers.find((container) => container.materialBlockId).materialBlockId;

    moveItemInArray(clonedContainers, draggableContainerIndices.previousIndex, draggableContainerIndices.currentIndex);

    const mountedMaterialId = clonedContainers[draggableContainerIndices.currentIndex].mountedMaterialId;
    const insertBeforeMountedMaterialId = clonedContainers[draggableContainerIndices.currentIndex + 1].mountedMaterialId;

    this.primaryMaterialDsService
      .changeMountedMaterialSequence(materialBlockId, mountedMaterialId, insertBeforeMountedMaterialId)
      .subscribe();

    return clonedContainers;
  }

  public getMountedContainers(cardsVisibleInView: number, mountedMaterials: MountedMaterial[]): MountedMaterial[] {
    return R.take(cardsVisibleInView, mountedMaterials);
  }

  public getActiveOrderId(): Observable<number> {
    return this.activeOrderDsService.selectactiveOrderId();
  }

  public setTableRowsExpandingStatus(
    currentTableData: MaterialTableTreeNode[],
    previousTableData: MaterialTableTreeNode[]
  ): MaterialTableTreeNode[] {
    if (!currentTableData) {
      return [];
    }

    if (!previousTableData?.length) {
      return currentTableData;
    }

    const clonedCurrentTableData = clone(currentTableData);

    return clonedCurrentTableData.map((item: MaterialTableTreeNode) => {
      item.expanded = previousTableData.find(
        (oldItem: MaterialTableTreeNode) => oldItem.data.materialBlockId === item.data.materialBlockId
      )?.expanded;

      return item;
    });
  }

  public expandCollapseTable(
    tableData: MaterialTableTreeNode[],
    tableExpandingStatus: boolean
  ): { tableData: MaterialTableTreeNode[]; tableExpandingStatus: boolean } {
    const clonedTableData = clone(tableData);
    let clonedTableExpandingStatus = clone(tableExpandingStatus);

    clonedTableExpandingStatus = !clonedTableExpandingStatus;

    clonedTableData.map((dataRow: TreeNode) => (dataRow.expanded = clonedTableExpandingStatus));
    return {
      tableData: [...clonedTableData],
      tableExpandingStatus: clonedTableExpandingStatus
    };
  }
}
