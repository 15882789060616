import { Component, Input, OnInit } from '@angular/core';
import { StatusPanelType } from './status-panel-type';
import { StatusPanelTextStyle } from './status-panel-text-style';
import { StatusPanelHeight } from './status-panel-height';

@Component({
  selector: 'app-status-panel',
  templateUrl: './status-panel.component.html',
  styleUrls: ['./status-panel.component.scss']
})
export class StatusPanelComponent implements OnInit {
  @Input() public label: string;
  @Input() public value: string;
  @Input() public unitId: string;
  @Input() public containers: string;
  @Input() public status: StatusPanelType;
  @Input() public textStyle: StatusPanelTextStyle = 'large';
  @Input() public height: StatusPanelHeight = 'normal';
  public valueTitle: string;

  private readonly MAX_VALUE_LENGTH: number = 27;

  public ngOnInit(): void {
    if (this.value && this.value.length > this.MAX_VALUE_LENGTH) {
      this.valueTitle = this.value;
      this.value = `${this.value.substring(0, this.MAX_VALUE_LENGTH)}...`;
    }
  }
}
