<app-time-edit-template class="order-finish chronos-dialog--size-md">
  <app-time-edit-header>
    <div class="order-finish__order-code">{{ activeProductionOrder?.externalProductionOrderId }}</div>
    <div class="order-finish__open-time">{{ modalOpenTime | date : 'HH:mm' }}</div>
  </app-time-edit-header>
  <app-time-edit-header>{{ 'MANUAL_MODE.JOB_FINISH_TITLE_LONG' | translate }}</app-time-edit-header>
  <app-time-edit-content class="content" [ngClass]="{ 'content--warning': !selectedStartDate }">
    <div>{{ 'MANUAL_MODE.START' | translate }}</div>
    <lib-calendar
      [(ngModel)]="selectedStartDate"
      [minDate]="minSelectableStartDate"
      [maxDate]="getMaxStartDate()"
      [inputStyle]="{ width: '270px' }"
    ></lib-calendar>
  </app-time-edit-content>
  <app-time-edit-content class="content" [ngClass]="{ 'content--warning': !selectedFinishDate }">
    <div>{{ 'MANUAL_MODE.END' | translate }}</div>
    <lib-calendar
      [(ngModel)]="selectedFinishDate"
      [minDate]="getMinFinishDate()"
      [maxDate]="modalOpenTime"
      [inputStyle]="{ width: '270px' }"
    ></lib-calendar>
  </app-time-edit-content>

  <app-multi-user-worktime-table
    *ngIf="manualModeFinishData.checkoutPageMode === USER_PAGE_MODE.MultiUser"
    [manualModeFinishData]="manualModeFinishData"
    [form]="form"
    (quantityPanelChanged)="panelChange($event)"
    (formStatusChanged)="formStatusChange($event)"
  ></app-multi-user-worktime-table>

  <lib-modal-footer
    [submitDisabled]="!isModalValid()"
    [whenLoadingTopic]="LOADING_TOPIC"
    (submitClicked)="submitButton()"
    (declineClicked)="cancelButton()"
    [submitLabel]="'MANUAL_MODE.CONFIRM_TIMES'"
  ></lib-modal-footer>
</app-time-edit-template>
