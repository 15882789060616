import { Component, Input } from '@angular/core';
import { ActiveOrderQuery } from '@app/core/global-state';
import { SamplingDataListViewModel } from 'chronos-core-client';

@Component({
  selector: 'app-sampling-log-details',
  templateUrl: './sampling-log-details.component.html',
  styleUrls: ['./sampling-log-details.component.scss']
})
export class SamplingLogDetailsComponent {
  @Input() public samplingLogDetails: SamplingDataListViewModel;

  constructor(public activeOrderQuery: ActiveOrderQuery) {}
}
