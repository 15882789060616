import { Injectable } from '@angular/core';
import { DummyToolModalComponent } from '@app/modules/tools/components/dummy-tool-modal/dummy-tool-modal.component';
import { ToolStockModalComponent } from '@app/modules/tools/components/tool-stock-modal/tool-stock-modal.component';
import { ToolSetup } from '@app/shared/models';
import { TranslateService } from '@ngx-translate/core';
import { ArticleClassification } from 'chronos-core-client';
import { Dummy } from 'chronos-shared';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { ToolDsService } from '@app/core/data-services';

@Injectable()
export class ToolsService {
  constructor(private dialogService: DialogService, private translateService: TranslateService, private toolDsService: ToolDsService) {}

  public openToolStockModal(
    materialBlockId?: number,
    tabName?: string,
    articleId?: number,
    isDropDownOptionDisabled?: boolean
  ): DynamicDialogRef {
    return this.dialogService.open(ToolStockModalComponent, {
      header: this.translateService.instant('MOUNTING.MOUNTING'),
      data: {
        articleId,
        isMaterialQuantityRequired: false,
        materialBlockId,
        tabName,
        isDropDownOptionDisabled
      }
    });
  }

  public replaceDummyTool(
    mountedMaterialId: number,
    containerId: number,
    tabName?: string,
    isDropDownOptionDisabled?: boolean,
    articleId?: number
  ): DynamicDialogRef {
    return this.dialogService.open(ToolStockModalComponent, {
      header: this.translateService.instant('MOUNTING.REPLACE_DUMMY'),
      data: {
        articleId,
        isMaterialQuantityRequired: false,
        replaceTool: true,
        mountedMaterialId,
        containerId,
        tabName,
        isDropDownOptionDisabled
      }
    });
  }

  public createDummyTool(toolSetup: Partial<ToolSetup>, articleClassification: ArticleClassification, articles: any): DynamicDialogRef {
    const showQuantity = articleClassification !== ArticleClassification.Tool;
    return this.openDummyToolModal(
      {
        mounted: showQuantity ? null : 1,
        articleId: toolSetup.articleId,
        materialBlockId: toolSetup.materialBlockId,
        bomUnitId: toolSetup.bomUnitId
      } as Dummy,
      'SETUP_PARAMS.MOUNT_DUMMY',
      showQuantity,
      articles
    );
  }

  public updateDummyTool(toolSetup: ToolSetup, articleClassification: ArticleClassification, articles: any): DynamicDialogRef {
    return this.openDummyToolModal(
      {
        code: toolSetup.identificationCode,
        reasonInfo: toolSetup.virtualContainerInfo,
        reasonId: toolSetup.toolCreationReasonId,
        containerId: toolSetup.containerId,
        materialBlockId: toolSetup.materialBlockId
      } as Dummy,
      'SETUP_PARAMS.CHANGE_DUMMY',
      articleClassification !== ArticleClassification.Tool,
      articles
    );
  }

  public mountToolForActiveOrder(identificationCode: string, containerId: number, materialBlockId?: number): Observable<void> {
    return this.toolDsService.mountToolForActiveOrder(identificationCode, containerId, materialBlockId);
  }

  private openDummyToolModal(
    dummyTool: Dummy,
    modalName: string,
    showQuantity: boolean,
    secondaryAlternativeArticles: any
  ): DynamicDialogRef {
    return this.dialogService.open(DummyToolModalComponent, {
      header: this.translateService.instant(modalName),
      data: { dummyTool, showQuantity, secondaryAlternativeArticles }
    });
  }
}
