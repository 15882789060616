import { Injectable } from '@angular/core';
import { ActiveOrderDsService, ContainersDsService, ParametersDsService, ProducedMaterialDsService } from '@app/core/data-services';
import { HubUrlsService } from '@app/core/utils';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { OutputPalletsQuery, OutputPalletsService } from '@app/modules/run-phase/state';
import { WasteAssignmentFormEvent } from '@app/shared/models';
import {
  BobbinQuantityViewModel,
  ContainersService,
  OuterLabelType,
  ProducedMaterialStatus,
  ProducedMaterialsViewModel,
  ProductionOrderService,
  Quantity,
  QuantityPerPalletViewModel,
  SetupParameterService,
  WebSocketClientService
} from 'chronos-core-client';
import { ProducedMaterialLoadingMode } from 'projects/chronos-core-client/src/public-api';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

@Injectable()
export class OutputContainerService {
  constructor(
    private activeOrderDsService: ActiveOrderDsService,
    private webSocketClientService: WebSocketClientService,
    private hubUrlsService: HubUrlsService,
    private outputPalletsService: OutputPalletsService,
    private activeWorkCenterService: ActiveWorkCenterService,
    private containerService: ContainersService,
    private containersDsService: ContainersDsService,
    private setupParameterService: SetupParameterService,
    private productionOrderService: ProductionOrderService,
    private parametersDsService: ParametersDsService,
    private producedMaterialDsService: ProducedMaterialDsService,
    private outputPalletsQuery: OutputPalletsQuery
  ) {}

  public createOutputPallet(productionOrderId: number): Observable<void> {
    return this.containersDsService.createOutputPalletFromPool(productionOrderId);
  }

  public addProducedMaterialWasteAssignment(producedMaterialId: number, wasteAssignment: WasteAssignmentFormEvent): Observable<void> {
    return this.containersDsService.addProducedMaterialWasteAssignment(
      producedMaterialId,
      wasteAssignment.maculature,
      wasteAssignment.waste,
      wasteAssignment?.reason
    );
  }

  public removePalletWasteAssignment(producedMaterialId: number, wasteAssignmentId: number): Observable<any> {
    return this.containersDsService.deletePalletWasteAssignment(producedMaterialId, wasteAssignmentId);
  }

  public printLabel(containerId: number, isReprint: boolean): Observable<void> {
    return this.containersDsService.printLabel(containerId, isReprint);
  }

  public setPalletAsFinished(producedMaterialId: number, workCenterId: number): Observable<any> {
    return this.containerService.finishProducedMaterial({
      producedMaterialId,
      body: {
        workCenterId
      }
    });
  }

  public setPalletAsOpened(producedMaterialId: number, workCenterId: number): Observable<any> {
    return this.containerService.openProducedMaterial({
      producedMaterialId,
      body: {
        workCenterId
      }
    });
  }

  public markPalletAsNotLast(producedMaterialId: number, workCenterId: number): Observable<any> {
    return this.containerService
      .removeLastMark({
        producedMaterialId,
        body: {
          workCenterId
        }
      })
      .pipe(take(1));
  }

  public markPalletAsLast(producedMaterialId: number, workCenterId: number): Observable<any> {
    return this.containerService
      .setLastMark({
        producedMaterialId,
        body: {
          workCenterId
        }
      })
      .pipe(take(1));
  }

  public getQuantityPerPallet(productionOrderId: number): Observable<QuantityPerPalletViewModel> {
    return this.setupParameterService.getQuantityPerPallet({ productionOrderId }).pipe(take(1));
  }

  public getOutputPallets(productionOrderId: number, loadingMode: ProducedMaterialLoadingMode): Observable<ProducedMaterialsViewModel> {
    return this.containerService.getProducedMaterialList({ productionOrderId, loadingMode }).pipe(
      tap((producedMaterialsViewModel) => {
        this.outputPalletsService.setOutputPallets(producedMaterialsViewModel);
      })
    );
  }

  public setOutputPoolContainerQuantity(productionOrderId: number, palletQuantity: number): Observable<void> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.productionOrderService.changeContainerTargetQuantity({
      productionOrderId,
      body: {
        newAmount: palletQuantity,
        workCenterId
      }
    });
  }

  public changeContainerQuantity(producedMaterialId: number, newQuantity: Quantity, changeFollowingPallets: boolean): Observable<void> {
    return this.containersDsService.changeContainerQuantity(producedMaterialId, newQuantity, changeFollowingPallets);
  }

  public setPrintLabelInfo(containerId: number, printLabelInfo: string): Observable<any> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();

    return this.containerService
      .setPrintLabelInfo({
        containerId,
        body: {
          workCenterId,
          printLabelInfo
        }
      })
      .pipe(take(1));
  }

  public getProducedMaterialNotifications(): Observable<any> {
    const hubUrl = this.hubUrlsService.getProducedMaterialList(this.activeOrderDsService.getActiveOrderId());
    return this.webSocketClientService.getNotificationsForTopic(hubUrl).pipe(
      tap((data) => {
        if (data.loadingMode === ProducedMaterialLoadingMode.All) {
          this.outputPalletsService.setOutputPallets(data);
        } else if (data.loadingMode === ProducedMaterialLoadingMode.Update) {
          this.outputPalletsService.updateOutputPallets(data);
        } else {
          let producedMaterialIds: number[];
          // Removing and replacing the open/active pallets from the store.
          this.outputPalletsQuery.outputPallets$
            .pipe(
              map((items) =>
                items.filter((item) => item.status === ProducedMaterialStatus.Open || item.status === ProducedMaterialStatus.Active)
              ),
              tap((materials) => {
                producedMaterialIds = materials.map((material) => material.producedMaterialId);
              })
            )
            .subscribe();

          this.outputPalletsService.removeItems(producedMaterialIds);
          this.outputPalletsService.updateOutputPallets(data);
        }
      })
    );
  }

  public getBobbinQuantity(productionOrderId: number): Observable<BobbinQuantityViewModel> {
    return this.parametersDsService.getBobbinQuantity(productionOrderId);
  }

  // this is from container change quantity dialog
  public changeContainerBobbinQuantity(
    producedMaterialId: number,
    bobbinQuantity: number,
    bobbinQuantityUnit: string,
    changeFollowingPallets: boolean
  ): Observable<void> {
    return this.containersDsService.changeContainerBobbinQuantity(
      producedMaterialId,
      bobbinQuantity,
      bobbinQuantityUnit,
      changeFollowingPallets
    );
  }

  // changes the pool quantity
  public setOutputPoolBobbinQuantity(productionOrderId: number, bobbinQuantity: number): Observable<void> {
    return this.containersDsService.setOutputPoolBobbinQuantity(productionOrderId, bobbinQuantity);
  }

  // manage pallet bobbin quatity and number of copies.
  public changeProducedMaterialPrintInfo(
    producedMaterialId: number,
    outerLabelType: OuterLabelType,
    quantityPerOuter: number,
    numberOfCopies: number
  ): Observable<void> {
    return this.producedMaterialDsService.changeProducedMaterialPrintInfo(
      producedMaterialId,
      outerLabelType,
      quantityPerOuter,
      numberOfCopies
    );
  }
}
