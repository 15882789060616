import { SetupFooterCommandBase, SetupFooterService } from '@app/shared/components';
import { FinishPhaseService } from '../services';
import { LoadingNotificationService, LogService } from 'chronos-shared';
import { RunPhaseType, RunSubPhaseType } from 'chronos-core-client';
import { ActiveOrderService } from '@app/core/global-state';
import { finalize } from 'rxjs/operators';
import { notificationTopic } from '@app/shared/utils';

export class PreviousFinishingSubphaseCommandService implements SetupFooterCommandBase {
  public enabled = true;
  public buttonText: string;
  public required = false;
  public route: string;
  public cancelEndProduction = false;
  public subPhase: RunSubPhaseType;
  public loadingTopic = notificationTopic.cancelFinishPhase;

  constructor(
    private setupFooterService: SetupFooterService,
    private finishPhaseService?: FinishPhaseService,
    private activeOrderService?: ActiveOrderService
  ) {}

  public execute(): void {
    if (this.cancelEndProduction) {
      LoadingNotificationService.publish(this.loadingTopic, true);

      this.finishPhaseService
        .cancelEndProduction()
        .pipe(
          finalize(() => {
            LoadingNotificationService.publish(this.loadingTopic, false);
          })
        )
        .subscribe(() => {
          LogService.success('SUCCESS_MESSAGE.PRODUCTION_END_CANCELLED');
          this.activeOrderService.setActiveOrderPhase(RunPhaseType.Run, RunSubPhaseType.Run);
          this.setupFooterService.goToPreviousSubPhase(this.route);
        });
    }

    if (!this.cancelEndProduction) {
      this.setupFooterService.goToPreviousSubPhase(this.route);
    }
  }

  public setNavigationParams(route: string): void {
    this.route = route;
  }
}
