import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import moment from 'moment-mini';
import * as R from 'ramda';
import {
  ActiveProductionOrder,
  ManualMachineCheckoutPageMode,
  ProductionOrderManualModeFinishingDataViewModel,
  Quantity,
  RunEmployeeWorkTimeData
} from 'chronos-core-client';
import { ManualModeService } from '@app/modules/manual-mode/services/manual-mode/manual-mode.service';
import { Router } from '@angular/router';
import { nav, notificationTopic } from '@app/shared/utils';
import { LoadingNotificationService, LogService, UtcToLocalPipe } from 'chronos-shared';
import { finalize } from 'rxjs/operators';
import { OutputFinish, OutputFinishState } from '@app/modules/run-phase/models';
import { Observable } from 'rxjs';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { StatusPanel } from '../../models';

@Component({
  selector: 'app-order-finish-modal',
  templateUrl: './order-finish-modal.component.html',
  styleUrls: ['./order-finish-modal.component.scss']
})
export class OrderFinishModalComponent implements OnInit {
  public readonly LOADING_TOPIC = notificationTopic.modalForManualMode;
  public form: UntypedFormGroup;

  public modalOpenTime: Date;
  public minSelectableStartDate: Date;
  public selectedStartDate: Date;
  public selectedFinishDate: Date;
  public activeProductionOrder: ActiveProductionOrder;
  public outputFinish: OutputFinish;
  public manualModeFinishData: ProductionOrderManualModeFinishingDataViewModel;
  public orderGrossQuantity: Quantity;
  public qtyStatusPanel: StatusPanel;
  public isFormValid: boolean;

  private action: Observable<any>;
  private runEmployeeWorkTimeData: RunEmployeeWorkTimeData[];

  private isFromPallet: false;

  private readonly DAYS_AVAILABLE = 5;
  public readonly USER_PAGE_MODE = ManualMachineCheckoutPageMode;
  public readonly HOURS_MAX_LIMIT = 838;

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private manualModeService: ManualModeService,
    private router: Router,
    private utcToLocal: UtcToLocalPipe,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      rows: this.fb.array([])
    });
  }

  public ngOnInit(): void {
    this.activeProductionOrder = this.config.data?.activeProductionOrder;
    this.manualModeFinishData = this.config.data?.outputFinishData;
    this.orderGrossQuantity = this.manualModeFinishData?.orderGrossQuantity;
    this.outputFinish = this.config.data?.outputFinish;
    this.isFromPallet = this.config.data?.isFromPallet;
    this.modalOpenTime = new Date();
    this.selectedStartDate = this.utcToLocal.transform(this.activeProductionOrder?.productionStartTime);
    this.selectedFinishDate = this.modalOpenTime;
    this.minSelectableStartDate = moment(this.selectedStartDate).subtract(this.DAYS_AVAILABLE, 'days').toDate();
  }

  public cancelButton(): void {
    this.ref.close();
  }

  public submitButton(): void {
    if (this.manualModeFinishData.checkoutPageMode === ManualMachineCheckoutPageMode.MultiUser) {
      this.setActionDetails(ManualMachineCheckoutPageMode.MultiUser);
      this.multiUserSubmitCall();
      return;
    }

    if (this.manualModeFinishData.checkoutPageMode === ManualMachineCheckoutPageMode.Simple) {
      this.setActionDetails(ManualMachineCheckoutPageMode.Simple);
      this.simpleUserSubmitCall();
      return;
    }
  }

  // sets action to be executed.
  private setActionDetails(checkoutPageMode: ManualMachineCheckoutPageMode) {
    if (this.outputFinish.finishState === OutputFinishState.Switch) {
      this.action = this.manualModeService.switchManualOrder(
        checkoutPageMode,
        this.activeProductionOrder.productionOrderId,
        this.outputFinish.producedMaterialId,
        this.selectedFinishDate,
        this.selectedStartDate
      );
    } else {
      this.action = this.manualModeService.endManualCuttingOrder(
        this.activeProductionOrder.productionOrderId,
        this.selectedStartDate,
        this.selectedFinishDate,
        this.outputFinish.finishState === OutputFinishState.Interrupt
      );
    }
  }

  private multiUserSubmitCall(): void {
    if (!this.form.valid) return;

    if (this.qtyStatusPanel.totalHours > this.HOURS_MAX_LIMIT) {
      LogService.warn('FINISH_ORDER_MODAL.MAX_HOURS_VALIDATION');
      return;
    }
    this.runEmployeeWorkTimeData = this.createFormValues(this.form.value);
    LoadingNotificationService.publish(this.LOADING_TOPIC, true);

    this.manualModeService
      .saveManualModeFinishingValues(
        ManualMachineCheckoutPageMode.MultiUser,
        this.activeProductionOrder.productionOrderId,
        this.runEmployeeWorkTimeData
      )
      .subscribe(
        () => {
          this.checkIsRequestFromPallet();
          this.executeAction();
        },
        () => {
          // on error
          this.ref.close(true);
        }
      );
  }

  private simpleUserSubmitCall(): void {
    this.executeAction();
  }

  public formStatusChange(formStatus: boolean) {
    this.isFormValid = formStatus;
  }

  public panelChange(statusPanel: StatusPanel) {
    console.info(statusPanel);
    this.qtyStatusPanel = statusPanel;
  }

  private finishOrder(): void {
    LogService.success('SUCCESS_MESSAGE.JOB_FINISHED');
    this.navigateToFinishPhase();
  }

  private checkIsRequestFromPallet() {
    if (!this.isFromPallet) {
      LoadingNotificationService.publish(this.LOADING_TOPIC, false);
      this.ref.close(true);
      return;
    }
  }

  private executeAction() {
    this.action
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
        })
      )
      .subscribe(() => {
        this.finishOrder();
      });
  }

  private employeeWorkTimeFormStatus(): boolean {
    if (this.manualModeFinishData.checkoutPageMode === ManualMachineCheckoutPageMode.MultiUser) return this.isFormValid;

    return true;
  }

  public getMaxStartDate(): Date {
    return this.selectedFinishDate ? this.selectedFinishDate : this.modalOpenTime;
  }

  public getMinFinishDate(): Date {
    return this.selectedStartDate ? this.selectedStartDate : this.minSelectableStartDate;
  }

  public isModalValid(): boolean {
    return (
      !R.isNil(this.selectedStartDate) &&
      !R.isNil(this.selectedFinishDate) &&
      this.selectedStartDate <= this.selectedFinishDate &&
      this.employeeWorkTimeFormStatus()
    );
  }

  private navigateToFinishPhase(): void {
    this.router.navigate([nav.finish]).then(() => {
      this.ref.close();
    });
  }

  private createFormValues(formData) {
    return formData.rows.map((element) => ({
      employeeId: element.employee.id,
      duration: this.formatDate(element.minutes, element.hours),
      quantity: { value: element.quantity, unitId: this.orderGrossQuantity.unitId }
    }));
  }

  private formatDate(minutes: number, hours: number): any {
    const totalDays = Math.floor(hours / 24);
    const remainingHours = hours % 24;

    const timeSpan = `${totalDays > 0 ? `${totalDays}.` : ''}${remainingHours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:00`;

    console.info(timeSpan);

    return timeSpan;
  }
}
