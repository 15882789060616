<section class="overview-modal page-padding chronos-dialog--size-lg">
  <app-order-item content="mainInfoOnly" [shiftReportItem]="shiftReportItem"></app-order-item>
  <div class="overview-modal__kpis p-grid">
    <div class="p-col">
      <app-kpi-column
        [kpis]="kpis[KPI_CATEGORY.Quantity]"
        [title]="formatColumnTitle(KPI_CATEGORY.Quantity) | translate"
        [kpiColumn]="KPI_CATEGORY.Quantity"
      ></app-kpi-column>
    </div>
    <div class="p-col">
      <app-kpi-column
        [kpis]="kpis[KPI_CATEGORY.Time]"
        [title]="formatColumnTitle(KPI_CATEGORY.Time) | translate"
        [kpiColumn]="KPI_CATEGORY.Time"
      ></app-kpi-column>
    </div>
    <div class="p-col">
      <app-kpi-column
        [kpis]="kpis[KPI_CATEGORY.Speed]"
        [title]="formatColumnTitle(KPI_CATEGORY.Speed) | translate"
        [kpiColumn]="KPI_CATEGORY.Speed"
      ></app-kpi-column>
    </div>
    <div class="p-col">
      <app-kpi-column
        [kpis]="kpis[KPI_CATEGORY.Project]"
        [title]="formatColumnTitle(KPI_CATEGORY.Project) | translate"
        [kpiColumn]="KPI_CATEGORY.Project"
      ></app-kpi-column>
    </div>
  </div>

  <lib-modal-footer [submitLabel]="'SHIFT_REPORT.CLOSE'" [showDeclineButton]="false" (submitClicked)="closeModal()"></lib-modal-footer>
</section>
