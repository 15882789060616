import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ProductionOrderDsService } from '@app/core/data-services';
import { MachineSchedule, ProductionOrderDeviationEntry, ProductionOrderProductionLoyalty, WorkCenter } from 'chronos-core-client';
import { ActiveWorkCenterService, WorkCenterDsService } from '@app/core/workcenter';

@Injectable({
  providedIn: 'root'
})
export class OrderItemService {
  constructor(
    private productionOrderDsService: ProductionOrderDsService,
    private activeWorkCenterService: ActiveWorkCenterService,
    private workCenterDsService: WorkCenterDsService
  ) {}

  public setChecked(id: number, isChecked: boolean): Observable<void> {
    return this.productionOrderDsService.setCheckedProductionOrder(id, isChecked);
  }

  public presetupProductionOrder(productionOrder: number): Observable<void> {
    return this.productionOrderDsService.presetupProductionOrder(productionOrder);
  }

  public getProductionLoyaltyReasons(productionOrderId: number): Observable<ProductionOrderProductionLoyalty> {
    return this.productionOrderDsService.getProductionLoyaltyReasons(productionOrderId);
  }

  public setNextProductionOrder(productionOrderId: number, showAll: boolean): Observable<MachineSchedule[]> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const entry: ProductionOrderDeviationEntry = {
      workCenterId
    };
    return this.productionOrderDsService
      .setAsNextOrder(productionOrderId, entry)
      .pipe(switchMap(() => this.productionOrderDsService.getProductionOrderListByShift(showAll)));
  }

  public reopenProductionOrder(productionOrderId: number): Observable<void> {
    return this.productionOrderDsService.reopenProductionOrder(productionOrderId);
  }

  public getWorkCenter(): Observable<WorkCenter> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.workCenterDsService.getWorkCenter(workCenterId);
  }
}
