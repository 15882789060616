import { Component } from '@angular/core';
import { DocumentEditModalComponent } from '@app/modules/documents/containers/documents/components';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { DocumentsService } from '@app/modules/documents/services';
import { ProductionOrderView, RemarkType, RemarkView } from 'chronos-core-client';
import { ActiveWorkCenterService } from '@app/core/workcenter';

@Component({
  selector: 'app-tab-information',
  templateUrl: './tab-information.component.html',
  styleUrls: ['./tab-information.component.scss']
})
export class TabInformationComponent {
  public informationData$ = this.documentsService.getProductionOrderInformation();

  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService,
    private documentsService: DocumentsService,
    private activeWorkCenterService: ActiveWorkCenterService
  ) {}

  public trackByFunction(index: number): number {
    return index;
  }

  public editRemarkInfo(remark: RemarkView, model: ProductionOrderView): void {
    const stepName = model?.operationName;
    const productionOrderId = model?.productionOrderId;

    this.dialogService
      .open(DocumentEditModalComponent, {
        header: this.getProductionOrderstepHeaderName(stepName),
        width: '500px',
        data: {
          remarkInfo: remark?.remarkText,
          productionOrderId
        }
      })
      .onClose.subscribe((isSuccess) => {
        isSuccess && this.documentsService.refreshSelectedOrder();
      });
  }

  public isRemarkEditable(remark: RemarkView, model: ProductionOrderView): boolean {
    const currentWorkCenterId = this.activeWorkCenterService.getWorkCenterId();

    const isProductionOrderCreatedInCurrentWorkCenter = model?.workCenterId === currentWorkCenterId;
    const remarkSourceIsEditable = !remark || remark?.remarkSource === RemarkType.ProductionOrderInfoShopFloor;

    return isProductionOrderCreatedInCurrentWorkCenter && remarkSourceIsEditable;
  }

  private getProductionOrderstepHeaderName(productionOrderStep: string): string {
    return this.translateService.instant('DOCUMENTS.REMARK_EDIT_MODAL_TITLE', { productionOrderStep });
  }
}
