import { Component, Input, OnInit } from '@angular/core';
import { ActiveOrderDsService } from '@app/core/data-services';
import { Quantity } from 'chronos-core-client';
import { AppSettingsQuery, knownUnits } from 'chronos-shared';

@Component({
  selector: 'app-sampling-estimation',
  templateUrl: './sampling-estimation.component.html',
  styleUrls: ['./sampling-estimation.component.scss']
})
export class SamplingEstimationComponent implements OnInit {
  @Input() public aqlSampleQuantity: Quantity;
  @Input() public tgsSampleQuantity: Quantity;
  public unitId: string;
  public productionQuantity: number;
  public aqlPerPiecesSampleQuantity: number | string;
  public tgsPerPiecesSampleQuantity: number | string;
  public isAqlEnabled = false;
  public isTgsEnabled = false;

  constructor(private appSettingsQuery: AppSettingsQuery, private activeOrderDsService: ActiveOrderDsService) {}

  public ngOnInit(): void {
    this.unitId = this.appSettingsQuery.getWorkCenter().counterUnitId;
    const roundUpto = knownUnits.WholeUnits.includes(this.unitId) ? null : 2;

    const activeOrder = this.activeOrderDsService.getActiveOrder();
    this.productionQuantity = activeOrder.estimatedQuantity.value;

    this.aqlPerPiecesSampleQuantity = this.calculateSampleQuantity(this.productionQuantity, this.aqlSampleQuantity.value, roundUpto);
    this.tgsPerPiecesSampleQuantity = this.calculateSampleQuantity(this.productionQuantity, this.tgsSampleQuantity.value, roundUpto);

    this.isAqlEnabled = this.aqlSampleQuantity?.value > 0;
    this.isTgsEnabled = this.tgsSampleQuantity?.value > 0;
  }

  private calculateSampleQuantity(productionQuantity: number, sampleQuantity: number, roundUpto: number | null): number | string {
    const quantity = productionQuantity / (sampleQuantity || 1);
    return roundUpto === null ? Math.floor(quantity) : quantity.toFixed(roundUpto);
  }
}
