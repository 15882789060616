<div class="row">
  <form libEnterKeyHandler [formGroup]="form" class="col-7 parameters-form">
    <app-parameters [form]="form" (isParametersValid)="onParameterValidation($event)"></app-parameters>
  </form>
  <div class="col">
    <ng-container *ngIf="showInactive">
      <div class="row">
        <app-setup-info></app-setup-info>
      </div>
    </ng-container>
    <ng-container *ngIf="printInfo$ | async as label">
      <div class="row">
        <div class="row no-gutters">
          <div class="print-label col-12">
            {{ 'OUTPUT_UNIT.PRINT_LABEL' | translate }}
          </div>
        </div>
        <app-print-label
          [printerName]="label.printerName"
          [labelsTotal]="label.totalOutputUnits"
          [printedLabels]="label.printedOutputUnits"
          [quantityPerOuter]="label.quantityPerOuter"
          [defaultQuantityPerOuter]="label.defaultQuantityPerOuter"
          (labelPrintButtonClicked)="printLabel($event)"
          (quantityPerOuterChanged)="quantityPerOuterChange($event)"
        ></app-print-label>
      </div>
    </ng-container>
    <ng-container *ngIf="aqlSampleQuantity?.value > 0 || tgsSampleQuantity?.value > 0">
      <div class="row">
        <app-sampling-estimation [aqlSampleQuantity]="aqlSampleQuantity" [tgsSampleQuantity]="tgsSampleQuantity"></app-sampling-estimation>
      </div>
    </ng-container>
  </div>
</div>
