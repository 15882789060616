import { Injectable } from '@angular/core';
import { ActiveOrderDsService, OutputUnitDsService } from '@app/core/data-services';
import { OuterPrintInfo, PrintLabelForOuterEntry, ProducedMaterialType, OuterLabelType } from 'chronos-core-client';
import { BehaviorSubject } from 'rxjs';
import { AppSettingsQuery, LoadingNotificationService, LogService } from 'chronos-shared';
import { notificationTopic } from '@app/shared/utils';
import { finalize, tap } from 'rxjs/operators';

@Injectable()
export class SetupParametersService {
  private readonly OUTER_PRINT_LOADING_TOPIC = notificationTopic.outerPrintLabelAction;
  private readonly OUTER_PREPRINT_LOADING_TOPIC = notificationTopic.outerPreprintLabelAction;

  private printInfoBehaviorSubject = new BehaviorSubject<OuterPrintInfo>(null);
  public printInfo$ = this.printInfoBehaviorSubject.asObservable();

  constructor(
    private activeOrderDsService: ActiveOrderDsService,
    private outputUnitDsService: OutputUnitDsService,
    private appSettingsQuery: AppSettingsQuery
  ) {}

  public showInactiveElement(): boolean {
    return this.appSettingsQuery.showInactiveElement();
  }

  public getOutputUnitPrintLabelInfo(): void {
    if (this.isOutputUnit() || this.isOuterPrintVisibleInSetup()) {
      const productionOrderId: number = this.activeOrderDsService.getActiveOrderId();
      this.outputUnitDsService.getOutputUnitPrintLabelInfo(productionOrderId, OuterLabelType.Outer).subscribe((printInfo) => {
        this.printInfoBehaviorSubject.next(printInfo);
      });
    }
  }

  public printOutputUnitLabel(printLabel: PrintLabelForOuterEntry): void {
    const loadingTopic = printLabel.reprint ? this.OUTER_PRINT_LOADING_TOPIC : this.OUTER_PREPRINT_LOADING_TOPIC;
    const productionOrderId: number = this.activeOrderDsService.getActiveOrderId();

    LoadingNotificationService.publish(loadingTopic, true);
    this.outputUnitDsService
      .printOutputUnitLabel(productionOrderId, printLabel)
      .pipe(
        tap(() => {
          LogService.success('SUCCESS_MESSAGE.PRINTED_LABELS');
        }),
        finalize(() => {
          LoadingNotificationService.publish(loadingTopic, false);
        })
      )
      .subscribe(() => {
        this.getOutputUnitPrintLabelInfo();
      });
  }

  public saveQuantityPerOuter(quantity: number): void {
    const productionOrderId: number = this.activeOrderDsService.getActiveOrderId();
    this.outputUnitDsService.saveQuantityPerOuterAndCopies(productionOrderId, OuterLabelType.Outer, quantity, 1).subscribe();
  }

  private isOutputUnit(): boolean {
    const activeOrderType = this.activeOrderDsService.getActiveOrderType();
    return activeOrderType === ProducedMaterialType.OutputUnit;
  }

  private isOuterPrintVisibleInSetup(): boolean {
    const activeOrder = this.activeOrderDsService.getActiveOrder();
    return activeOrder?.isOuterPrintVisible ?? false;
  }
}
