<div cdkDropList (cdkDropListDropped)="changeListViewContainerListSequence($event)" class="items draggable-mounting-containers-list">
  <div class="item" *ngFor="let mountedCard of getMountedContainersForListView(); trackBy: trackByFunction">
    <ng-container [ngSwitch]="mountedCard.containerType">
      <ng-container *ngSwitchDefault>
        <div
          *ngIf="mountedCard.mountedMaterialStatus === MOUNTED_MATERIAL_STATUS.Mounted"
          cdkDrag
          cdkDragLockAxis="y"
          class="draggable-mounting-container"
        >
          <app-mount-pallet-list-item [mountedMaterial]="mountedCard">
            <div
              cdkDragHandle
              class="mount-pallet-list__draggable"
              [ngClass]="{ 'mount-pallet-list__draggable--dummy': mountedCard.container.isVirtualContainer }"
            >
              <span>.. .. ..</span>
            </div>
          </app-mount-pallet-list-item>
        </div>
        <div *ngIf="mountedCard.mountedMaterialStatus !== MOUNTED_MATERIAL_STATUS.Mounted">
          <app-mount-pallet-list-item [mountedMaterial]="mountedCard" [allowDismount]="machineState === MACHINE_STATE.Downtime">
            <div class="mount-pallet-list__draggable" style="visibility: hidden"></div>
          </app-mount-pallet-list-item>
        </div>
      </ng-container>
      <div *ngSwitchCase="'info'" class="pallet-list-dummy">
        {{ 'MOUNTING.SCAN_PALLET_TEXT' | translate }}
      </div>
      <div *ngSwitchCase="'placeholder'" class="pallet-list-scan"></div>
    </ng-container>
  </div>
</div>
