import { Injectable } from '@angular/core';
import { DraggableContainerIndices } from '@app/modules/mounting/models';
import { MountingService } from '@app/modules/mounting/services/mounting/mounting.service';
import { MountedMaterialStatus } from 'chronos-core-client';
import { MountedMaterial } from 'chronos-shared';

@Injectable()
export class MountPalletListService {
  constructor(private mountingService: MountingService) {}

  public changeListViewContainerListSequence(
    draggableContainerIndices: DraggableContainerIndices,
    cardsVisibleInView: number,
    mountedMaterials: MountedMaterial[]
  ): MountedMaterial[] {
    const immovableComponentsCount = this.immovableComponentsCount(mountedMaterials);
    const [previousIndex, currentIndex] = [
      draggableContainerIndices.previousIndex + immovableComponentsCount,
      draggableContainerIndices.currentIndex + immovableComponentsCount
    ];

    return this.mountingService.changeContainerListSequence({ previousIndex, currentIndex }, mountedMaterials);
  }

  private immovableComponentsCount(mountedMaterials: MountedMaterial[]): number {
    const statuses = [MountedMaterialStatus.Consumed, MountedMaterialStatus.Active];
    return mountedMaterials.filter((mountedMaterial) => statuses.includes(mountedMaterial.mountedMaterialStatus)).length;
  }
}
