import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActiveOrderDsService, QaHistoryDsService } from '@app/core/data-services';
import { NextSetupSubphaseCommandService } from '@app/modules/setup-phase/commands';
import { SetupFooterService } from '@app/shared/components';
import { nav } from '@app/shared/utils';
import { Observable, Subject, Subscription } from 'rxjs';
import { SetupPhaseService } from '../../services/setup-phase/setup-phase.service';
import { ProductionQaCheck, RunSubPhaseType } from 'chronos-core-client';
import { LineClearanceService } from '../../services/line-clearance/line-clearance.service';
import { debounceTime, shareReplay, take } from 'rxjs/operators';
import { QaCheckService } from '@app/core/services';
import { SignatureHandlerService } from '@app/core/services/signature/signature-handler.service';
import { QaCheckType, SignatureConfig } from '@app/shared/models/signature';

@Component({
  selector: 'app-line-clearance',
  templateUrl: './line-clearance.component.html',
  styleUrls: ['./line-clearance.component.scss']
})
export class LineClearanceComponent implements OnInit, AfterViewInit {
  @ViewChild('scanInput', { static: true }) public scanInput: ElementRef;
  @ViewChild('clearance') public clearance: ElementRef;

  public fields$: Observable<ProductionQaCheck[]>;
  public nextCommandService: NextSetupSubphaseCommandService;
  public isCapsLockOn = false;
  public scanInputText;
  public isSignatureFeatureEnabled = false;
  public signatureConfig = {} as SignatureConfig;
  public activeSignature: string | null = null;
  public leftSignatureQaChecks: ProductionQaCheck[] = [];
  public rightSignatureQaChecks: ProductionQaCheck[] = [];
  public isReadOnly = false;

  private input$ = new Subject<string>();
  private productionOrderId: number;
  private subscriptions = new Subscription();
  private qaChecksBySequence: { [key: number]: any[] } = {};

  constructor(
    private lineClearanceService: LineClearanceService,
    private activeOrderDsService: ActiveOrderDsService,
    private setupPhaseService: SetupPhaseService,
    private setupFooterService: SetupFooterService,
    private changeDetector: ChangeDetectorRef,
    private qaCheckService: QaCheckService,
    private qaHistoryDsService: QaHistoryDsService,
    private signatureHandlerService: SignatureHandlerService
  ) {
    this.input$.pipe(debounceTime(30000)).subscribe(() => {
      this.scanInputText = '';
    });
  }

  public ngOnInit(): void {
    this.productionOrderId = this.activeOrderDsService.getActiveOrderId();
    this.fields$ = this.lineClearanceService.getQACheckFields(this.productionOrderId).pipe(shareReplay(1));
    this.setCommands();

    this.subscriptions.add(
      this.qaHistoryDsService.isQaHistoryAvailableForSetup().subscribe((isAvailable) => {
        this.updateNextCommand(isAvailable);
      })
    );

    this.lineClearanceService.setFocus$.subscribe(() => {
      this.setFocusScanInput();
    });

    this.lineClearanceService.checkCapsLock$.subscribe((event) => {
      this.manageCapsLock(event);
    });

    this.lineClearanceService.validation$.subscribe((invalidItems) => {
      this.nextCommandService.enabled = invalidItems.length === 0;
    });

    this.isSignatureFeatureEnabled = this.signatureHandlerService.isQualityCheckSignatureEnabled();
    if (this.isSignatureFeatureEnabled) {
      this.signatureConfig = {
        isSignatureFeatureEnabled: this.isSignatureFeatureEnabled,
        qaCheckType: QaCheckType.LineClearance
      };

      this.filterProductionQaCheck();
    }

    this.isReadOnly = this.signatureHandlerService.setReadOnlyState(true);
    this.SetfocusScanField();
  }

  public ngAfterViewInit(): void {
    this.setFocusScanInput();

    const eventHandler = (event: KeyboardEvent | MouseEvent) => {
      if (event?.type === 'keyup' && !(event instanceof KeyboardEvent) && !(event instanceof MouseEvent)) {
        return;
      }
      this.manageCapsLock(event);
      this.changeDetector.markForCheck();
      this.setFocusScanInput();
    };

    this.scanInput?.nativeElement?.addEventListener('keyup', eventHandler);
    this.scanInput?.nativeElement?.addEventListener('mousedown', eventHandler);
  }

  public onFormValidityChange(isQaCheckValid: boolean): void {
    const customeValidation = this.lineClearanceService.validationSubject.value?.length;
    this.nextCommandService.enabled = isQaCheckValid && customeValidation === 0;
  }

  @HostListener('window:keydown.enter', ['$event'])
  public SetfocusScanField() {
    if (this.isFocusAvailable() && !this.isReadOnly) {
      this.setFocusScanInput();
    }

    this.manageCapsLock();
  }

  public onClick(event: KeyboardEvent | MouseEvent) {
    if (event?.type === 'keyup' && !(event instanceof KeyboardEvent) && !(event instanceof MouseEvent)) {
      return;
    }

    this.SetfocusScanField();
    this.manageCapsLock(event);
  }

  public onBlur(event: KeyboardEvent | MouseEvent) {
    if (event?.type === 'keyup' && !(event instanceof KeyboardEvent) && !(event instanceof MouseEvent)) {
      return;
    }

    this.SetfocusScanField();
    this.manageCapsLock(event);
  }

  public onInput(value: string) {
    this.input$.next(value);
  }

  public onEnter(value: string) {
    this.qaCheckService.setCheckResultMultipleRows(this.fields$, value).subscribe((res) => {
      if (res) {
        this.fields$ = this.lineClearanceService.getQACheckFields(this.productionOrderId);
      }
    });

    this.scanInputText = '';
  }

  //#region Signature handling

  public setActiveSignature(position: string): void {
    this.activeSignature = position;
  }

  public isActiveSignature(position: string): boolean {
    return this.activeSignature === position;
  }

  public getSignatureClasses(position: string): Record<string, boolean> {
    return {
      'active-signature': this.isActiveSignature(position),
      [position]: true
    };
  }

  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (!target.closest('.signature-container')) {
      this.activeSignature = null;
    }
  }

  public shouldShowSignatureContainer(): boolean {
    return this.isSignatureFeatureEnabled && this.leftSignatureQaChecks.length > 0 && this.rightSignatureQaChecks.length > 0;
  }

  private filterProductionQaCheck(): void {
    let productionQaCheck: ProductionQaCheck[] = [];

    this.fields$.pipe(take(1)).subscribe((data: ProductionQaCheck[]) => {
      productionQaCheck = data;

      if (productionQaCheck.length) {
        const uniqueSequenceNumbers = [...new Set(productionQaCheck.map((item) => item.checkSequenceNumber))];

        console.info('uniqueSequenceNumbers', uniqueSequenceNumbers);

        uniqueSequenceNumbers.forEach((sequenceNumber) => {
          this.qaChecksBySequence[sequenceNumber] = productionQaCheck.filter((item) => item.checkSequenceNumber === sequenceNumber);
        });

        if (uniqueSequenceNumbers.length > 0) {
          this.leftSignatureQaChecks = this.qaChecksBySequence[uniqueSequenceNumbers[0]];
        }
        if (uniqueSequenceNumbers.length > 1) {
          this.rightSignatureQaChecks = this.qaChecksBySequence[uniqueSequenceNumbers[1]];
        }

        console.info('Operator One QA Checks:', this.leftSignatureQaChecks);
        console.info('Operator Two QA Checks:', this.rightSignatureQaChecks);
      }
    });
  }

  //#endregion

  private isFocusAvailable(): boolean {
    const modal = document.querySelector('p-dynamicdialog');

    if (modal) {
      return false;
    }

    return document.activeElement?.tagName !== 'INPUT' && document.activeElement?.tagName !== 'SELECT';
  }

  private manageCapsLock(event?: KeyboardEvent | MouseEvent) {
    this.isCapsLockOn = event?.getModifierState('CapsLock') ? true : false;
  }

  private setFocusScanInput(): void {
    this.scanInput.nativeElement.focus();
  }

  private setCommands(): void {
    this.nextCommandService = new NextSetupSubphaseCommandService(this.setupFooterService);
    this.setupPhaseService.setSetupCommands(null, this.nextCommandService);
  }

  private updateNextCommand(isQaAvailable: boolean): void {
    if (isQaAvailable) {
      this.nextCommandService.setNavigationParams(RunSubPhaseType.SetupQaHistory, nav.routes.setupQaHistory);
    } else {
      this.nextCommandService.setNavigationParams(RunSubPhaseType.SetupParameter, nav.routes.setupParameters);
    }
  }
}
