import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActiveProductionOrder, ProductionOrder, SetupParameterKind, SetupParameters, SystemParameterType } from 'chronos-core-client';
import { ParametersFields } from '@app/shared/models';
import { mapSetupFields, sortFields } from '@app/shared/utils';

@Injectable()
export abstract class ParametersBaseService {
  public abstract productionOrder: ProductionOrder | ActiveProductionOrder;

  public static mapParameters(parametersFields: SetupParameters): ParametersFields {
    const setup = parametersFields.setup.map(mapSetupFields).sort(sortFields);

    let optional = null;
    if (parametersFields.optional) {
      optional = parametersFields.optional.map(mapSetupFields).sort(sortFields);
    }

    let toolSetup = null;
    if (parametersFields.toolSetup) {
      toolSetup = parametersFields.toolSetup;
    }

    return { setup, optional, toolSetup };
  }

  public abstract getSetupParameterKinds(): Observable<SetupParameterKind>;
  public abstract setSetupParameterKind(parameterKindId: number): Observable<void>;
  public abstract getSetupParameters(): Observable<ParametersFields>;
  public abstract setSetupParameter(
    parameterId: number,
    paramValue: string | number,
    systemParameterType: SystemParameterType
  ): Observable<void>;
}
