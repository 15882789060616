import { Injectable } from '@angular/core';
import { QueryConfig, QueryEntity } from '@datorama/akita';
import { MountedMaterialStatus, PrimaryMaterialMounted } from 'chronos-core-client';
import { Observable } from 'rxjs';
import { InputPalletsState, InputPalletsStore } from './input-pallets.store';

@Injectable()
@QueryConfig({
  sortBy: 'sequence'
})
export class InputPalletsQuery extends QueryEntity<InputPalletsState, PrimaryMaterialMounted> {
  public activePallets$: Observable<PrimaryMaterialMounted[]> = this.selectAll({
    filterBy: (pallet) => pallet.mountedMaterialStatus === MountedMaterialStatus.Active
  });
  public consumedPallets$: Observable<PrimaryMaterialMounted[]> = this.selectAll({
    filterBy: (pallet) => pallet.mountedMaterialStatus === MountedMaterialStatus.Consumed
  });
  public mountedPallets$: Observable<PrimaryMaterialMounted[]> = this.selectAll({
    filterBy: (pallet) => pallet.mountedMaterialStatus === MountedMaterialStatus.Mounted
  });
  public consumedPalletsCount$: Observable<number> = this.selectCount(
    (pallet) => pallet.mountedMaterialStatus === MountedMaterialStatus.Consumed
  );
  public mountedPalletsCount$: Observable<number> = this.selectCount(
    (pallet) => pallet.mountedMaterialStatus === MountedMaterialStatus.Mounted
  );

  constructor(protected store: InputPalletsStore) {
    super(store);
  }
}
