import { Injectable } from '@angular/core';
import { InputPalletsQuery, InputPalletsService } from '@app/modules/run-phase/state';
import { ContainersService, PrimaryMaterialMounted, WebSocketClientService } from 'chronos-core-client';
import { concat, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { HubUrlsService } from '@app/core/utils';

@Injectable({
  providedIn: 'root'
})
export class InputContainersDsService {
  constructor(
    private containersService: ContainersService,
    private activeWorkCenterService: ActiveWorkCenterService,
    private inputPalletsService: InputPalletsService,
    private inputPalletsQuery: InputPalletsQuery,
    private webSocketClientService: WebSocketClientService,
    private hubUrlsService: HubUrlsService
  ) {}

  public getInputListWithNotifications(): Observable<PrimaryMaterialMounted[]> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.containersService.getInputContainerList({ workCenterId }).pipe(
      switchMap((categories) => concat(of(categories), this.getInputListNotifications())),
      tap((materialList) => {
        this.inputPalletsService.setInputPallets(materialList);
      })
    );
  }

  private getInputListNotifications(): Observable<PrimaryMaterialMounted[]> {
    return this.webSocketClientService.getNotificationsForTopic<PrimaryMaterialMounted[]>(this.hubUrlsService.getInputPallets());
  }

  public getActiveInputPallets(): Observable<PrimaryMaterialMounted[]> {
    return this.inputPalletsQuery.activePallets$;
  }

  public getConsumedInputPallets(): Observable<PrimaryMaterialMounted[]> {
    return this.inputPalletsQuery.consumedPallets$;
  }

  public getMountedInputPallets(): Observable<PrimaryMaterialMounted[]> {
    return this.inputPalletsQuery.mountedPallets$;
  }

  public getConsumedInputPalletsCount(): Observable<number> {
    return this.inputPalletsQuery.consumedPalletsCount$;
  }

  public getMountedInputPalletsCount(): Observable<number> {
    return this.inputPalletsQuery.mountedPalletsCount$;
  }
}
