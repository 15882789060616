import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { EmployeeSignatureDsService } from '@app/core/data-services/employee-signature-ds/employee-signature-ds.service';
import { SignatureHandlerService } from '@app/core/services/signature/signature-handler.service';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { Employee, SignatureDetailsEntry, QaCheckType, SignatureConfig, SignatureData, SignatureState } from '@app/shared/models/signature';
import { TranslateService } from '@ngx-translate/core';
import { LogService } from 'chronos-shared';
import { ProductionQaCheck, ScanSignatureResponse } from 'projects/chronos-core-client/src/public-api';
import { of, Subject, Subscription } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('scanSignatureInput') public scanSignatureInput: ElementRef;
  @ViewChild('employeeAutoComplete') public employeeAutoComplete: any;

  @Input() public productionQaCheck: ProductionQaCheck[];
  @Input() public keepBorder? = false;
  @Input() public signatureConfig?: SignatureConfig;
  @Input() public isFromSampling?: boolean;
  @Output() public badgeScanned? = new EventEmitter<SignatureDetailsEntry>();
  @Output() public employeeUnSelect? = new EventEmitter<void>();

  public signatureData: SignatureData = {
    scanBadgeText: '',
    hasSignature: false,
    employeeId: null,
    hasManualInput: false,
    comment: '',
    timeStamp: null,
    name: ''
  };

  public state: SignatureState = {
    highlight: true,
    hasManualInputDisabled: false,
    hasScanSignatureResponse: false
  };

  public commentError = '';
  public selectedEmployee$: Employee = null;
  public employeesSuggestionList: Employee[] = [];
  public employees = [];
  public isCapsLockOn = false;
  public inputWidth = '20em';

  private input$ = new Subject<string>();
  private subscriptions = new Subscription();
  private isScanSuccess = false;
  private productionQaCheckIds: number[] = [];

  constructor(
    private workCenterService: ActiveWorkCenterService,
    private employeeSignatureService: EmployeeSignatureDsService,
    private cdr: ChangeDetectorRef,
    private translationService: TranslateService,
    private signatureHandlerService: SignatureHandlerService
  ) {}

  ngOnInit(): void {
    this.loadEmployees();

    this.monitorQaCheckChanges();

    // Subscribe to the reset signature observable
    this.subscriptions.add(
      this.signatureHandlerService.resetSignature$.subscribe(() => {
        this.resetSignature();
      })
    );

    this.SetfocusScanField();
  }

  ngAfterViewInit(): void {
    this.focusScanInput();
    this.inputEvents();
    this.adjustLineClearanceInputWidth();
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Handles the input event on the scan input.
   * @param value The value of the input field.
   */
  public onInput(value: string) {
    this.input$.next(value);
  }

  /**
   * Handles the ENTER key press event on the scan input.
   * @param value The value of the input field.
   */
  public onEnter(value: string): void {
    this.scanEmployeeSignature(value);
    this.signatureData.scanBadgeText = '';
  }

  /**
   * Handles the key press event on the scan input.
   * @param event The keyboard event.
   */
  @HostListener('window:keydown.enter', ['$event'])
  public SetfocusScanField() {
    if (this.isFocusAvailable()) {
      this.focusScanInput();
    }

    this.manageCapsLock();
  }

  /**
   * Handles the key press event on the scan input.
   * @param event The keyboard
   */
  public onClick(event: KeyboardEvent | MouseEvent) {
    if (event?.type === 'keyup' && !(event instanceof KeyboardEvent) && !(event instanceof MouseEvent)) {
      return;
    }

    this.SetfocusScanField();
    this.manageCapsLock(event);
  }

  /**
   * Toggles the manual input checkbox.
   * @param event The checkbox event.
   */
  public onBadgeChecked(event: any): void {
    this.signatureData.hasManualInput = event.checked;
  }

  /**
   * Filters the employee list based on the autocomplete query.
   * @param event The autocomplete event.
   */
  public filterAutoCompleteEmployees(event: any): void {
    const query = event.query.toLowerCase();
    this.employees = this.employeesSuggestionList.filter((employee) => employee.name.toLowerCase().includes(query));
  }

  /**
   * Opens the employee dropdown.
   */
  public openEmployeeDropdown(): void {
    if (this.employeeAutoComplete) {
      const value = this.employeeAutoComplete.value;
      this.employees = value
        ? this.employeesSuggestionList.filter((employee) => employee.name.toLowerCase().includes(value.name.toLowerCase()))
        : this.employeesSuggestionList;
      this.employeeAutoComplete.show();
    }
  }

  /**
   * Handles the selection of an employee from the autocomplete.
   * @param selected The selected employee.
   */
  public onAutoCompleteEmployeeSelect(selected: any): void {
    this.selectedEmployee$ = selected;
  }

  /**
   * Clears the selected employee.
   */
  public clearTreeSelection(): void {
    this.selectedEmployee$ = null;
    this.employeeUnSelect.emit();
  }

  /**
   * Handles the justification input.
   * @param value The justification text.
   */
  public onJustification(value: string): void {
    this.signatureData.comment = value;
    this.validateJustification();
  }

  /**
   * Handles the blur event on the justification input.
   */
  public onJustificationBlur(): void {
    if (this.isSignatureForQaChecksValid()) {
      if (this.isFromSampling) {
        this.handleSampling(); // Handle the sampling case
        return;
      }
      this.setSignatureForQaChecks();
    }
  }

  /**
   * Resets the employee signature.
   */
  public resetSignature(): void {
    this.resetSignatureValues();
    this.focusScanInput();
  }

  /**
   * Gets the class object for the signature component.
   * @returns
   */
  public getClassObject() {
    return {
      'highlight-background': this.state.highlight,
      'pallet-signature-margin': this.keepBorder
    };
  }

  /**
   * Check is line clearance
   * @returns
   */
  public isLineClearance = (): boolean => this.signatureConfig?.qaCheckType === QaCheckType.LineClearance;

  /**
   * Loads the list of employees for the autocomplete.
   */
  private loadEmployees(): void {
    const workCenterId = this.getWorkCenterId();
    const requireEmployeeRegistration = this.getRequireEmployeeRegistration();

    this.subscriptions.add(
      this.employeeSignatureService.getEmployeesForSignature(workCenterId, requireEmployeeRegistration).subscribe((res: any) => {
        this.employeesSuggestionList = res;
      })
    );
  }

  /**
   * Subscribes to the production QA check observable.
   */
  private async monitorQaCheckChanges(): Promise<void> {
    if (this.productionQaCheck?.length) {
      this.productionQaCheckIds = this.productionQaCheck.map((field) => field.id);
      this.updateSignatureData(this.productionQaCheck[0]?.employeeSignature);
    }
  }

  /**
   * Updates the signature data.
   * @param employeeSignature The employee signature data.
   */
  private updateSignatureData(employeeSignature: any): void {
    if (employeeSignature) {
      const employee = employeeSignature.employee;
      this.signatureData.name = employee.name;
      this.signatureData.employeeId = employee.employeeId;
      this.signatureData.comment = employeeSignature.comment;
      this.signatureData.hasManualInput = false;
      this.signatureData.hasSignature = employeeSignature.hasSignature;
      this.signatureData.timeStamp = new Date(employeeSignature.timestamp);
      this.toggleHighlight();
      this.state.hasManualInputDisabled = employeeSignature !== null;
      this.state.hasScanSignatureResponse = true;
    }
  }

  /**
   * Validates the justification input.
   * @returns True if the justification is valid, false otherwise.
   */
  private validateJustification(): boolean {
    const comment = this.signatureData.comment.trim();
    if (!comment) {
      this.commentError = this.translationService.instant('SIGNATURE.ERROR_MESSAGE.COMMENT_WHITE_SPACE');
      return false;
    } else if (comment.length < 4) {
      this.commentError = this.translationService.instant('SIGNATURE.ERROR_MESSAGE.COMMENT_LENGTH');
      return false;
    } else {
      this.commentError = '';
      return true;
    }
  }

  /**
   * Checks if the signature for QA checks is valid.
   * @returns True if the signature for QA checks is valid, false otherwise.
   */
  private isSignatureForQaChecksValid(): boolean {
    return !!this.signatureData.comment && this.validateJustification() && !!this.selectedEmployee$;
  }

  /**
   * Gets the active work center ID.
   * @returns The active work center ID.
   */
  private getWorkCenterId(): number {
    return this.workCenterService.getWorkCenterId();
  }

  /**
   * Scans the employee signature using the badge ID.
   * @param value The badge ID to scan.
   */
  private scanEmployeeSignature(value: string): void {
    const activeWorkCenterId = this.getWorkCenterId();
    const badgeId = value;
    const requireEmployeeRegistration = this.getRequireEmployeeRegistration();
    this.employeeSignatureService
      .scanSignature(badgeId, requireEmployeeRegistration, activeWorkCenterId)
      .pipe(
        tap((res: ScanSignatureResponse) => {
          this.isScanSuccess = !!res;
          this.setScanServiceResponse(res);
          LogService.success('SIGNATURE.SCAN_SUCCESS');

          if (this.isFromSampling) {
            this.handleSampling(); // Handle the sampling case
            return;
          }

          this.setSignatureForQaChecks();
        }),
        catchError(() => {
          return of(null);
        }),
        finalize(() => {
          console.info('Scan signature completed');
        })
      )
      .subscribe();
  }

  /**
   * Handles the sampling case.
   */
  private handleSampling() {
    this.badgeScanned.emit(this.createSignatureDetailsEntry());
    this.resetScanStatus();
  }

  /**
   * Creates a signature details entry.
   */
  private createSignatureDetailsEntry(res?: ScanSignatureResponse): SignatureDetailsEntry {
    const employeeId = res?.employeeId ?? this.selectedEmployee$?.employeeId ?? this.signatureData?.employeeId;

    const signatureDetailsEntry = {
      employeeId,
      hasManualInput: this.signatureData.hasManualInput,
      timestamp: new Date().toISOString(),
      comment: this.signatureData.comment
    };

    return signatureDetailsEntry;
  }

  /**
   * Sets the signature for QA checks.
   */
  private setSignatureForQaChecks(): void {
    const productionQaCheckIds = this.productionQaCheckIds;
    const workCenterId = this.workCenterService.getWorkCenterId();
    const requireEmployeeRegistration = this.getRequireEmployeeRegistration();

    // Store the shared data for periodic QA checks
    if (this.signatureConfig?.qaCheckType === QaCheckType.Periodic) {
      const dataToShare = this.createSignatureDetailsEntry();

      this.signatureHandlerService.storeSharedData(dataToShare);
      this.resetScanStatus();
      return;
    } else {
      this.employeeSignatureService
        .setSignatureForQaChecks(
          this.signatureData.comment,
          this.signatureData?.employeeId ?? this.selectedEmployee$?.employeeId,
          this.signatureData.hasManualInput,
          productionQaCheckIds,
          requireEmployeeRegistration,
          new Date().toISOString(),
          workCenterId
        )
        .pipe(
          tap(() => {
            this.resetScanStatus();
          }),
          catchError(() => {
            return of(null);
          }),
          finalize(() => {
            console.info('Set signature for QA checks completed');
          })
        )
        .subscribe();
    }
  }

  /**
   * Checks if the employee registration is required.
   * @returns True if the employee registration is required, false otherwise.
   * @private
   */
  private getRequireEmployeeRegistration(): boolean {
    return this.isLineClearance() ? true : false;
  }

  /**
   * Resets the scan status.
   */
  private resetScanStatus() {
    if (!this.isScanSuccess) {
      this.setScanServiceResponse(null);
    }
    this.signatureData.hasManualInput = false;
    LogService.success('SIGNATURE.SET_SIGN_QA_SUCCESS');
  }

  /**
   * Resets the signature values.
   */
  private resetSignatureValues(): void {
    this.signatureData = {
      scanBadgeText: '',
      hasSignature: false,
      employeeId: null,
      hasManualInput: false,
      comment: '',
      timeStamp: null,
      name: ''
    };

    this.state = {
      highlight: true,
      hasManualInputDisabled: false,
      hasScanSignatureResponse: false
    };

    this.selectedEmployee$ = null;
    this.isScanSuccess = false;
    this.signatureIsPresent(false);
  }

  /**
   * Sets the scan service response.
   * @param response The scan service response.
   */
  private setScanServiceResponse(response: ScanSignatureResponse): void {
    this.signatureData.name = response?.name ?? this.selectedEmployee$.name;
    this.signatureData.employeeId = response?.employeeId ?? this.selectedEmployee$.employeeId;
    this.signatureData.timeStamp = new Date();
    this.toggleHighlight();
    this.state.hasManualInputDisabled = response !== null || response !== undefined;
    this.state.hasScanSignatureResponse = true;
  }

  /**
   * Toggles the highlight state.
   */
  private toggleHighlight(): void {
    const hasSignature = this.state.highlight;
    this.signatureHandlerService.signatureIsPresent(hasSignature);
    this.state.highlight = !this.state.highlight;
  }

  /**
   * Focuses the scan input.
   */
  private focusScanInput(): void {
    if (this.scanSignatureInput && this.scanSignatureInput.nativeElement) {
      this.scanSignatureInput.nativeElement.focus();
    }
  }

  /**
   * Checks if the focus is available.
   * @returns True if the focus is available, false otherwise.
   */
  private isFocusAvailable(): boolean {
    return document.activeElement?.tagName !== 'INPUT' && document.activeElement?.tagName !== 'SELECT';
  }

  /**
   * Input events
   */
  private inputEvents(): void {
    const eventHandler = (event: KeyboardEvent | MouseEvent) => {
      if (event?.type === 'keyup' && !(event instanceof KeyboardEvent) && !(event instanceof MouseEvent)) {
        return;
      }
      this.manageCapsLock(event);
      this.cdr.markForCheck();
      this.focusScanInput();
    };

    this.scanSignatureInput?.nativeElement?.addEventListener('keyup', eventHandler);
    this.scanSignatureInput?.nativeElement?.addEventListener('mousedown', eventHandler);
  }

  /**
   * Manage Caps Lock
   * @param event The keyboard event.
   */
  private manageCapsLock(event?: KeyboardEvent | MouseEvent) {
    this.isCapsLockOn = event?.getModifierState('CapsLock') ? true : false;
  }

  /**
   * Adjusts the input width for line clearance.
   * @returns
   */
  private adjustLineClearanceInputWidth(): void {
    if (this.isLineClearance()) {
      this.inputWidth = '12em';
    }
  }

  /**
   * Sets the signature is present.
   * @param value The value to set.
   */
  private signatureIsPresent(value: boolean): void {
    this.signatureHandlerService.signatureIsPresent(value);
  }
}
