import { Component } from '@angular/core';
import { DocumentsService } from '@app/modules/documents/services';
import { LabelPrintMode, PrintLabelForOuterEntry, ProducedMaterialPrintLabelInfo, OuterLabelType } from 'chronos-core-client';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { ButtonItem } from '@app/shared/components';
import { ModalPrintLabelComponent } from '@app/modules/documents/components';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { LoadingNotificationService, LogService, ModalConfirmComponent } from 'chronos-shared';
import { notificationTopic } from '@app/shared/utils';
import { OuterInfo } from '@app/shared/models';
import { BobbinPrintLabel } from '@app/shared/models/bobbin-print-label';
import { OutputContainerService } from '@app/modules/run-phase/services';

@Component({
  selector: 'app-tab-label',
  templateUrl: './tab-label.component.html',
  styleUrls: ['./tab-label.component.scss']
})
export class TabLabelComponent {
  public readonly PRINT_MODE = LabelPrintMode;
  public readonly LOADING_TOPIC = notificationTopic.printLabelAction;
  public readonly MODAL_LOADING_TOPIC = notificationTopic.modalPrintLabel;
  private readonly SEARCH_FILTER_CHAR_LENGTH = 3;
  private readonly OUTER_PRINT_LOADING_TOPIC = notificationTopic.outerPrintLabelAction;
  private readonly OUTER_PREPRINT_LOADING_TOPIC = notificationTopic.outerPreprintLabelAction;
  public readonly OUTER_LABEL_TYPE = OuterLabelType;
  private searchFilterSubject = new BehaviorSubject<string>('');
  private searchFilter$: Observable<string> = this.searchFilterSubject;
  public slitCount = 1;
  public isBobbin = false;

  public printLabelData$ = combineLatest([this.documentService.getPrintedLabelListData(), this.searchFilter$]).pipe(
    map(([response, filter]) => ({
      labels: response.labels,
      slitCount: response.slitCount,
      outerLabels: response.outerLabels,
      coreLabels: response.coreLabels,
      filteredLabels: this.searchFilterLabels(response.labels, filter)
    }))
  );

  constructor(
    private outputContainerService: OutputContainerService,
    private documentService: DocumentsService,
    private dialogService: DialogService,
    private translateService: TranslateService
  ) {}

  public rowButtonItems(row: ProducedMaterialPrintLabelInfo): ButtonItem[] {
    const isPrint = this.isPrint(row.printMode);
    const isRePrint = this.isReprint(row.printMode);
    return [
      {
        label: 'LABELS.PRINT',
        command: () => {
          this.onRowClick(row, 'LABELS.PRINT');
        },
        primary: isPrint,
        visible: isPrint
      },
      {
        label: 'LABELS.REPRINT',
        command: () => {
          this.onRowClick(row, 'LABELS.REPRINT');
        },
        primary: isRePrint,
        visible: isRePrint
      }
    ];
  }

  public rowListButtonItems(rows: ProducedMaterialPrintLabelInfo[]) {
    return [
      {
        label: 'LABELS.PRINT',
        id: `print${rows.length}`,
        command: () => {
          this.onRowListClick(rows);
        }
      }
    ];
  }

  private onRowListClick(rows: ProducedMaterialPrintLabelInfo[]): void {
    const availableCount = this.filterLabels(rows)?.length || 0;

    if (availableCount) {
      this.openPrintLabelModal(rows, availableCount);
    } else {
      const question = this.translateService.instant('LABELS.PALLETS_NOT_AVAILABLE');

      this.openConfirmModal(question, false);
    }
  }

  private onRowClick(row: ProducedMaterialPrintLabelInfo, translationKey: string): void {
    const printMode = this.translateService.instant(translationKey)?.toLowerCase();
    const question = this.translateService.instant('LABELS.PRINT_QUESTION', {
      printMode,
      sequence: row.containerSequence
    });

    this.openConfirmModal(question, true).onClose.subscribe((hasAccepted: boolean) => {
      this.onCloseConfirmModal(hasAccepted, row);
    });
  }

  private openConfirmModal(question: string, acceptable: boolean): DynamicDialogRef {
    return this.dialogService.open(ModalConfirmComponent, {
      header: this.translateService.instant('LABELS.PRINT_LABEL'),
      data: {
        question,
        acceptable
      }
    });
  }

  private onCloseConfirmModal(hasAccepted: boolean, row: ProducedMaterialPrintLabelInfo): void {
    if (hasAccepted) {
      const loadingTopic = this.LOADING_TOPIC + row.containerSequence;
      LoadingNotificationService.publish(loadingTopic, true);
      this.documentService
        .sendPrintLabel(row.containerId, this.isReprint(row.printMode))
        .pipe(
          finalize(() => {
            LoadingNotificationService.publish(loadingTopic, false);
          })
        )
        .subscribe(() => {
          this.documentService.refreshSelectedOrder();
        });
    }
  }

  private openPrintLabelModal(labels: ProducedMaterialPrintLabelInfo[], availableCount: number): void {
    this.dialogService
      .open(ModalPrintLabelComponent, {
        header: this.translateService.instant('LABELS.PRINT_LABEL'),
        data: {
          labels,
          availableCount
        }
      })
      .onClose.subscribe((successfullyClosed: boolean) => {
        this.onClosePrintLabelModal(successfullyClosed);
      });
  }

  private onClosePrintLabelModal(successfullyClosed: boolean): void {
    if (successfullyClosed) {
      this.documentService.refreshSelectedOrder();
    }
  }

  private filterLabels(rows: ProducedMaterialPrintLabelInfo[]): ProducedMaterialPrintLabelInfo[] {
    return rows.filter((row) => row.printMode !== LabelPrintMode.None);
  }

  public outerPrintLabel(printLabel: PrintLabelForOuterEntry) {
    const loadingTopic = printLabel.reprint ? this.OUTER_PRINT_LOADING_TOPIC : this.OUTER_PREPRINT_LOADING_TOPIC;
    LoadingNotificationService.publish(loadingTopic, true);
    this.documentService
      .printOuterLabel(printLabel)
      .pipe(
        tap(() => {
          LogService.success('SUCCESS_MESSAGE.PRINTED_LABELS');
        }),
        finalize(() => {
          LoadingNotificationService.publish(loadingTopic, false);
        })
      )
      .subscribe();
  }

  public coreLabelOuterPrintLabel(printLabel: PrintLabelForOuterEntry) {
    const loadingTopic = printLabel.reprint ? this.OUTER_PRINT_LOADING_TOPIC : this.OUTER_PREPRINT_LOADING_TOPIC;
    LoadingNotificationService.publish(loadingTopic, true);
    this.documentService
      .printOuterLabel(printLabel)
      .pipe(
        tap(() => {
          LogService.success('SUCCESS_MESSAGE.PRINTED_LABELS');
        }),
        finalize(() => {
          LoadingNotificationService.publish(loadingTopic, false);
        })
      )
      .subscribe();
  }

  public corePrintBobbinLabel(printLabel: BobbinPrintLabel) {
    const loadingTopic = printLabel.entry.reprint ? this.OUTER_PRINT_LOADING_TOPIC : this.OUTER_PREPRINT_LOADING_TOPIC;
    LoadingNotificationService.publish(loadingTopic, true);
    this.documentService
      .printBobbinCoreLabel(printLabel)
      .pipe(
        tap(() => LogService.success('SUCCESS_MESSAGE.PRINTED_LABELS')),
        finalize(() => LoadingNotificationService.publish(loadingTopic, false))
      )
      .subscribe();
  }

  public quantityPerOuterAndCopiesChange(info: OuterInfo): void {
    this.outputContainerService
      .changeProducedMaterialPrintInfo(info.producedMaterialId, OuterLabelType.Core, info.quantityPerOuter, info.numberCopies)
      .subscribe();
  }

  private isReprint(printMode: LabelPrintMode): boolean {
    return printMode === LabelPrintMode.RePrint;
  }

  private isPrint(printMode: LabelPrintMode): boolean {
    return printMode === LabelPrintMode.Print;
  }

  public searchLabels(filter: string): void {
    this.searchFilterSubject.next(filter);
  }

  private searchFilterLabels(rows: ProducedMaterialPrintLabelInfo[], filter: string): ProducedMaterialPrintLabelInfo[] {
    if (filter.length >= this.SEARCH_FILTER_CHAR_LENGTH) {
      return rows.filter((row) => row.ssccCode.includes(filter));
    } else {
      return rows;
    }
  }

  public outerQuantityPerOuterChange(info: OuterInfo): void {
    this.documentService.saveQuantityPerOuterAndCopies(OuterLabelType.Outer, info.quantityPerOuter, info.numberCopies).subscribe();
  }

  public coreLabelQuantityPerOuterChange(info: OuterInfo): void {
    this.documentService.saveQuantityPerOuterAndCopies(OuterLabelType.Core, info.quantityPerOuter, info.numberCopies).subscribe();
  }

  public coreLabelNumberOfCopiesChanged(info: OuterInfo): void {
    this.documentService.saveQuantityPerOuterAndCopies(OuterLabelType.Core, info.quantityPerOuter, info.numberCopies).subscribe();
  }
}
