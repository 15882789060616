<ng-container *ngIf="printLabelData$ | async as data">
  <section class="label-section">
    <div class="label-section__flex">
      <div class="label-section__title label-section__title--grow">{{ 'LABELS.OUTPUT_WIP' | translate }}</div>
      <app-search-bar
        class="label-section__search"
        (searchBarValueChanged)="searchLabels($event)"
        [searchBarPlaceholder]="'LABELS.SEARCH_BAR_PLACEHOLDER' | translate"
      ></app-search-bar>
    </div>

    <div class="label-section__table">
      <app-material-label-table
        [value]="data.filteredLabels"
        [allItemAction]="outputAllItemAction"
        [perItemAction]="outputPerItemAction"
        (labelPrintButtonClicked)="outerPrintLabel($event)"
        (labelPrintBobbinButtonClicked)="corePrintBobbinLabel($event)"
        [slitCount]="data.slitCount"
      ></app-material-label-table>
    </div>

    <ng-container *ngIf="data.outerLabels">
      <div class="label-section__title">
        {{ 'LABELS.OUTER_OU' | translate }}
      </div>
      <div class="label-section__table">
        <app-print-label
          [printerName]="data.outerLabels.printerName"
          [labelsTotal]="data.outerLabels.totalOutputUnits"
          [printedLabels]="data.outerLabels.printedOutputUnits"
          [quantityPerOuter]="data.outerLabels.quantityPerOuter"
          [labelType]="data.outerLabels.outerLabelType"
          [isQuantityPerOuterValidationRequired]="false"
          [defaultQuantityPerOuter]="data.outerLabels.defaultQuantityPerOuter"
          (labelPrintButtonClicked)="outerPrintLabel($event)"
          (quantityPerOuterChanged)="outerQuantityPerOuterChange($event)"
        ></app-print-label>
      </div>
    </ng-container>

    <ng-container *ngIf="data.coreLabels">
      <div class="label-section__title">
        {{ 'LABELS.CORE_LABEL' | translate }}
      </div>
      <div class="label-section__table">
        <app-print-label
          [printerName]="data.coreLabels.printerName"
          [labelsTotal]="data.coreLabels.totalOutputUnits"
          [printedLabels]="data.coreLabels.printedOutputUnits"
          [quantityPerOuter]="data.coreLabels.quantityPerOuter"
          [defaultQuantityPerOuter]="data.coreLabels.defaultQuantityPerOuter"
          [numberOfCopies]="data.coreLabels.numberCopies"
          [labelType]="data.coreLabels.outerLabelType"
          [isQuantityPerOuterValidationRequired]="true"
          (labelPrintButtonClicked)="coreLabelOuterPrintLabel($event)"
          (quantityPerOuterChanged)="coreLabelQuantityPerOuterChange($event)"
          (numberOfCopiesChanged)="coreLabelNumberOfCopiesChanged($event)"
        ></app-print-label>
      </div>
    </ng-container>
  </section>

  <ng-template #outputAllItemAction>
    <app-split-button
      class="table-action"
      appendTo="body"
      [items]="rowListButtonItems(data.labels)"
      [loadingTopic]="MODAL_LOADING_TOPIC"
    ></app-split-button>
  </ng-template>

  <ng-template #outputPerItemAction let-row>
    <ng-container *ngIf="row.printMode !== PRINT_MODE.None">
      <app-split-button
        class="table-action"
        appendTo="body"
        [items]="rowButtonItems(row)"
        [loadingTopic]="LOADING_TOPIC + row.containerSequence"
      ></app-split-button>
    </ng-container>
  </ng-template>
</ng-container>
