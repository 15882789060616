/* tslint:disable */
/* eslint-disable */
export enum InfoEntryType {
  ProductionOrder = 'ProductionOrder',
  ProductionOrderKpi = 'ProductionOrderKpi',
  Shift = 'Shift',
  Downtime = 'Downtime',
  ContainerLabelInfo = 'ContainerLabelInfo',
  CheckResult = 'CheckResult'
}
