import { Injectable } from '@angular/core';
import {
  QaCheckResult,
  QaCheckService,
  SetQaCheckResultEntry,
  PeriodicQaCheck,
  CreateManualQaChecksEntry,
  CreateSamplingDataForProducedMaterialEntry,
  Quantity,
  CreateSamplingDataForRunEntry,
  SamplingDataListViewModel,
  QaCheck
} from 'chronos-core-client';
import { Observable } from 'rxjs';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { ActiveOrderQuery } from '@app/core/global-state';
import { SignatureDetailsEntry } from '@app/shared/models/signature';

@Injectable({
  providedIn: 'root'
})
export class QaCheckDsService {
  constructor(
    private qaCheckService: QaCheckService,
    private activeWorkCenterService: ActiveWorkCenterService,
    private activeOrderQuery: ActiveOrderQuery
  ) {}

  public setAllQaChecksForSetup(body: SetQaCheckResultEntry): Observable<void> {
    return this.qaCheckService.setAllQaChecksForSetup({
      productionOrderId: this.activeOrderQuery.getActiveOrderId(),
      workCenterId: this.activeWorkCenterService.getWorkCenterId(),
      body
    });
  }

  public setAllQaChecksForPeriodic(periodicQaCheckId: number, qaCheckResult: SetQaCheckResultEntry): Observable<void> {
    return this.qaCheckService.setAllQaChecksForPeriodic({
      workCenterId: this.activeWorkCenterService.getWorkCenterId(),
      periodicQaCheckId,
      body: qaCheckResult
    });
  }

  public setAllQaChecksForProducedMaterial(producedMaterialId: number, qaCheckResult: SetQaCheckResultEntry): Observable<void> {
    return this.qaCheckService.setAllQaChecksForProducedMaterial({
      workCenterId: this.activeWorkCenterService.getWorkCenterId(),
      producedMaterialId,
      body: qaCheckResult
    });
  }

  public setCheckResult(
    productionQaCheckId: number,
    qaCheckResult: QaCheckResult,
    qaCheckComment: string,
    qaMeasurementResult: number
  ): Observable<void> {
    const workCenterId: number = this.activeWorkCenterService.getWorkCenterId();
    return this.qaCheckService.setCheckResult({
      workCenterId,
      productionQaCheckId,
      body: {
        checkResult: qaCheckResult,
        comment: qaCheckComment,
        workCenterId,
        measurementValue: qaMeasurementResult
      }
    });
  }

  public getManualProductionQaChecks(productionOrderId: number): Observable<PeriodicQaCheck> {
    return this.qaCheckService.getManualProductionQaChecks({ productionOrderId });
  }

  public getActivePeriodiQaChecks(productionOrderId: number): Observable<PeriodicQaCheck> {
    return this.qaCheckService.getActivePeriodicCheck({ productionOrderId });
  }

  public createManualQaChecks(manualQACheckItems: CreateManualQaChecksEntry): Observable<void> {
    return this.qaCheckService.createManualQaChecks({
      productionOrderId: this.activeOrderQuery.getActiveOrderId(),
      workCenterId: this.activeWorkCenterService.getWorkCenterId(),
      body: manualQACheckItems
    });
  }

  public createSamplingDataForProducedMaterial(
    producedMaterialId: number,
    signatureDetailsEntry: SignatureDetailsEntry,
    aqlSampleQuantity?: Quantity,
    tgsSampleQuantity?: Quantity
  ): Observable<void> {
    const palletSamplingData: CreateSamplingDataForProducedMaterialEntry = {
      aqlSampleQuantity,
      comment: signatureDetailsEntry?.comment,
      employeeId: signatureDetailsEntry?.employeeId,
      isManualInput: signatureDetailsEntry?.hasManualInput,
      tgsSampleQuantity,
      timestamp: signatureDetailsEntry?.timestamp
    };

    return this.qaCheckService.createSamplingDataForProducedMaterial({
      workCenterId: this.activeWorkCenterService.getWorkCenterId(),
      producedMaterialId,
      body: palletSamplingData
    });
  }

  public CreateSamplingDataForRun(
    runId: number,
    signatureDetailsEntry?: SignatureDetailsEntry,
    aqlSampleQuantity?: Quantity,
    tgsSampleQuantity?: Quantity
  ): Observable<void> {
    const runSamplingData: CreateSamplingDataForRunEntry = {
      aqlSampleQuantity,
      comment: signatureDetailsEntry?.comment,
      employeeId: signatureDetailsEntry?.employeeId,
      isManualInput: signatureDetailsEntry?.hasManualInput,
      tgsSampleQuantity,
      timestamp: signatureDetailsEntry?.timestamp
    };

    return this.qaCheckService.createSamplingDataForRun({
      workCenterId: this.activeWorkCenterService.getWorkCenterId(),
      runId,
      body: runSamplingData
    });
  }

  public getSamplingDataForProducedMaterial(productionOrderId: number): Observable<SamplingDataListViewModel[]> {
    return this.qaCheckService.getSamplingData({ productionOrderId });
  }

  public getQaChecksForContainer(productionOrderId: number): Observable<QaCheck[]> {
    return this.qaCheckService.getQaChecksForContainer({ productionOrderId });
  }
}
