/* tslint:disable */
/* eslint-disable */
export enum ProductionOrderStatus {
  None = 'None',
  Open = 'Open',
  Checked = 'Checked',
  Active = 'Active',
  Finished = 'Finished',
  Reopened = 'Reopened',
  Interrupted = 'Interrupted'
}
