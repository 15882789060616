import { Observable } from 'rxjs';
import {
  ProductionOrderData,
  ProductionOrderService,
  ProductionPeriodDetailData,
  WasteAssignmentInTime,
  WorkCenterService
} from 'chronos-core-client';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MachineChartService {
  constructor(private workCenterService: WorkCenterService, private productionOrderService: ProductionOrderService) {}

  public getWasteAssignments(workCenterId: number, periodStart: string, periodEnd: string): Observable<WasteAssignmentInTime[]> {
    return this.workCenterService.getWasteAssignments({
      workCenterId,
      periodStart,
      periodEnd
    });
  }

  public getProductionOrderDetails(workCenterId: number, startTime: string, endTime: string): Observable<ProductionOrderData[]> {
    const request = {
      startTime,
      endTime
    };

    return this.productionOrderService.getProductionOrderDetails({
      workCenterId,
      body: request
    });
  }

  public getPeriods(workCenterId: number, periodStart: string, periodEnd: string): Observable<ProductionPeriodDetailData[]> {
    return this.workCenterService.getPeriods({
      workCenterId,
      periodStart,
      periodEnd
    });
  }
}
