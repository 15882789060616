import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { MountedMaterialStatus, PrimaryMaterialMounted } from 'chronos-core-client';
import { RecentlyConsumedMaterialStore } from './recently-consumed-material.store';

@Injectable()
export class RecentlyConsumedMaterialService {
  constructor(private consumedMaterialStore: RecentlyConsumedMaterialStore) {}

  @transaction()
  public updateConsumedMaterial(consumedMaterial: PrimaryMaterialMounted): void {
    if (this.isMaterialConsumed(consumedMaterial.mountedMaterialStatus) && !consumedMaterial.container.isVirtualContainer) {
      this.consumedMaterialStore.setLoading(true);
      this.consumedMaterialStore.upsert(consumedMaterial.mountedMaterialId, consumedMaterial);
      this.consumedMaterialStore.setLoading(false);
    }
  }

  public removePallet(consumedMaterialIds: number[] | number): void {
    this.consumedMaterialStore.remove(consumedMaterialIds);
  }

  public resetStore(): void {
    this.consumedMaterialStore.reset();
  }

  private isMaterialConsumed(materialStatus: MountedMaterialStatus): boolean {
    return materialStatus === MountedMaterialStatus.Consumed;
  }
}
