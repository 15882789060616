import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { OrderStartModalComponent } from '@app/modules/manual-mode/components/order-start-modal/order-start-modal.component';
import { ManualModeService } from '@app/modules/manual-mode/services/manual-mode/manual-mode.service';
import { ModalProductionLoyaltyComponent } from '@app/modules/orders/containers/order-list/components/modal-production-loyalty/modal-production-loyalty.component';
import { OrderItemService, OrderListService } from '@app/modules/orders/services';
import { ButtonItem } from '@app/shared/components';
import { productionOrderStatusFlags } from '@app/shared/models';
import { nav, notificationTopic } from '@app/shared/utils';
import { TranslateService } from '@ngx-translate/core';
import {
  DefaultTargetContainerQuantityInfo,
  MachineSchedule,
  ManualMachineMode,
  ProductionOrderProductionLoyalty,
  ProductionOrderStatus
} from 'chronos-core-client';
import { DialogService } from 'primeng/dynamicdialog';
import { finalize, switchMap } from 'rxjs/operators';
import { ModalProductionPeriodsComponent } from '../modal-production-periods/modal-production-periods.component';
import { PresetupModalComponent } from '../presetup-modal/presetup-modal.component';
import { UiStateQuery, UiStateService } from '@app/core/global-state';
import { LoadingNotificationService } from 'chronos-shared';
import { OrderItemButtonType } from './order-item.model';
import { elastic } from '@app/shared/utils/elastic';
import { getCombinationIcon } from '@app/modules/orders/utils';
import { ModalProductionPeriodsService } from '../modal-production-periods/modal-production-periods.service';
import { Subscription } from 'rxjs';
import { ProductionOrderDsService } from '@app/core/data-services';

@Component({
  selector: 'app-order-item',
  templateUrl: './order-item.component.html',
  styleUrls: ['./order-item.component.scss']
})
export class OrderItemComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public activeOrderExists: boolean;
  @Input() public isShowingAllOrderItems: boolean;
  @Input() public manualMachineMode: ManualMachineMode;
  @Input() public orderItem: MachineSchedule;
  @Input() public isExpandCollapseAll = false;
  @Input() public automaticPeriodSelectionOnProductionOrderStart = false;

  public combinationIcon: string;
  public loadingTopic: string;
  public isChecked = false;
  public isItemCollapsed = true;
  public orderButtons: ButtonItem[];
  public defaultPalletQuantityInfo: DefaultTargetContainerQuantityInfo;
  private subscriptions = new Subscription();

  public readonly STATUS_FLAGS = productionOrderStatusFlags;
  public readonly ORDER_STATUS = ProductionOrderStatus;
  public showInactive: boolean;

  constructor(
    private orderListService: OrderListService,
    private orderItemService: OrderItemService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private manualModeService: ManualModeService,
    private modalProductionPeriodsService: ModalProductionPeriodsService,
    private router: Router,
    private uiStateService: UiStateService,
    private uiStateQuery: UiStateQuery,
    private productionOrderService: ProductionOrderDsService
  ) {}

  public ngOnInit(): void {
    this.showInactive = this.orderListService.showInactiveElement();

    this.isItemCollapsed =
      !(this.orderItem.status === this.ORDER_STATUS.Active || this.isExpandCollapseAll) &&
      this.uiStateQuery.getOrderListExpandedOrder(this.orderItem.productionOrderId);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.orderItem) {
      this.combinationIcon = getCombinationIcon(this.orderItem);
      this.loadingTopic = notificationTopic.orderItemPrimaryButton + this.orderItem.productionOrderId;
      this.isChecked = this.orderItem.status === this.ORDER_STATUS.Active || this.orderItem.status === this.ORDER_STATUS.Checked;
    }

    if (changes.isExpandCollapseAll) {
      this.isItemCollapsed = !changes.isExpandCollapseAll.currentValue;
    }

    if (changes.orderItem || changes.manualModeSorting || changes.manualModeCutting) {
      this.orderButtons = this.buildOrderButtons();
    }
  }

  public ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  private buildOrderButtons(): ButtonItem[] {
    const buttonType = this.getButtonType();
    switch (buttonType) {
      case OrderItemButtonType.SETUP:
        return this.constructSetupButtons();
      case OrderItemButtonType.NEXT:
        return this.constructNextButtons();
      case OrderItemButtonType.REOPEN:
        return this.constructReopenButtons();
      case OrderItemButtonType.MANUAL_SORTING:
        return this.constructManualModeSortingButtons();
      case OrderItemButtonType.MANUAL_CUTTING:
        return this.constructManualModeCuttingButtons();
    }
  }

  public isCheckedDisabled(): boolean {
    return (
      this.orderItem.status === this.ORDER_STATUS.Active ||
      this.orderItem.status === this.ORDER_STATUS.Finished ||
      this.orderItem.status === this.ORDER_STATUS.Interrupted
    );
  }

  public toggleContent(): void {
    this.isItemCollapsed = !this.isItemCollapsed;
    if (this.isItemCollapsed) {
      this.uiStateService.orderListCollapseOrder(this.orderItem.productionOrderId);
    } else {
      this.uiStateService.orderListExpandedOrder(this.orderItem.productionOrderId);
    }
  }

  public isSetupButtonDisabled(): boolean {
    return !this.orderItem.isNextProductionOrder || this.orderItem.status !== this.ORDER_STATUS.Checked || this.activeOrderExists;
  }

  private reopenProductionOrder(): void {
    LoadingNotificationService.publish(this.loadingTopic, true);
    this.orderItemService
      .reopenProductionOrder(this.orderItem.productionOrderId)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.loadingTopic, false);
        })
      )
      .subscribe();
  }

  public handleCheckChange(): void {
    const trace = elastic.traceUiActivity('OrderItemComponent.handleCheckChange');
    LoadingNotificationService.publish(this.loadingTopic, true);

    const previousCheck = !this.isChecked;
    this.orderItemService
      .setChecked(this.orderItem.productionOrderId, this.isChecked)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.loadingTopic, false);
          trace.end();
        })
      )
      .subscribe({ error: () => (this.isChecked = previousCheck) });
  }

  private prepareSetup(): void {
    if (this.automaticPeriodSelectionOnProductionOrderStart) {
      this.startProductionOrder();
    } else {
      this.openProductionPeriodsModal();
    }
  }

  private startProductionOrder() {
    const trace = elastic.traceUiActivity('OrderItemComponent.startProductionOrder');

    LoadingNotificationService.publish(this.loadingTopic, true);
    this.modalProductionPeriodsService
      .startProductionOrderWithAutomaticPeriodSelection(this.orderItem, false)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.loadingTopic, false);
          trace.end();
        })
      )
      .subscribe((route) => {
        this.navigateToRoute(route);
      });
  }

  private navigateToRoute(route: string): void {
    this.router.navigate([route]).then();
  }

  private openProductionPeriodsModal(): void {
    this.productionOrderService.getManualTransportProcessInfo(this.orderItem.productionOrderId).subscribe((manualTransportProcessInfo) => {
      this.dialogService.open(ModalProductionPeriodsComponent, {
        header: this.translateService.instant('OPEN_PERIODS_MODAL.TITLE'),
        data: {
          productionOrder: this.orderItem,
          allowTransportProcessAfterGluingManualOverride: manualTransportProcessInfo.isManualTransportProcessAllowed
        }
      });
    });
  }

  private setAsNextOrder(): void {
    LoadingNotificationService.publish(this.loadingTopic, true);
    this.orderItemService
      .setNextProductionOrder(this.orderItem.productionOrderId, this.isShowingAllOrderItems)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.loadingTopic, false);
        })
      )
      .subscribe();
  }

  private openProductionLoyaltyModal(loyaltyReasons: ProductionOrderProductionLoyalty): void {
    this.dialogService.open(ModalProductionLoyaltyComponent, {
      header: this.translateService.instant('PRODUCTION_LOYALTY_MODAL.TITLE'),
      data: {
        productionOrder: this.orderItem,
        productionLoyalty: loyaltyReasons,
        isShowingAllOrderItems: this.isShowingAllOrderItems
      }
    });
  }

  public setNextProductionOrder(): void {
    const trace = elastic.traceUiActivity('OrderItemComponent.setNextProductionOrder');
    LoadingNotificationService.publish(this.loadingTopic, true);
    this.orderItemService
      .getProductionLoyaltyReasons(this.orderItem.productionOrderId)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.loadingTopic, false);
          trace.end();
        })
      )
      .subscribe((loyaltyReasons) => {
        loyaltyReasons.hasProductionLoyalty ? this.openProductionLoyaltyModal(loyaltyReasons) : this.setAsNextOrder();
      });
  }

  private getButtonType(): OrderItemButtonType {
    let buttonType: OrderItemButtonType;
    if (
      !this.orderItem.isNextProductionOrder &&
      this.orderItem.status !== this.ORDER_STATUS.Finished &&
      this.orderItem.status !== this.ORDER_STATUS.Interrupted
    ) {
      buttonType = OrderItemButtonType.NEXT;
    } else if (this.orderItem.isNextProductionOrder) {
      switch (this.manualMachineMode) {
        case ManualMachineMode.Cutting:
          buttonType = OrderItemButtonType.MANUAL_CUTTING;
          break;
        case ManualMachineMode.ManualWorkingPlace:
          buttonType = OrderItemButtonType.MANUAL_SORTING;
          break;
        default:
          buttonType = OrderItemButtonType.SETUP;
      }
    } else if (this.orderItem.status === this.ORDER_STATUS.Finished || this.orderItem.status === this.ORDER_STATUS.Interrupted) {
      buttonType = OrderItemButtonType.REOPEN;
    }
    return buttonType;
  }

  private constructReopenButtons(): ButtonItem[] {
    return [
      {
        command: () => {
          this.reopenProductionOrder();
        },
        label: 'ORDER_ITEM.RE_OPEN'
      }
    ];
  }

  private constructSetupButtons(): ButtonItem[] {
    const buttons: ButtonItem[] = [
      {
        command: () => {
          this.prepareSetup();
        },
        label: 'ORDER_ITEM.SETUP',
        disabled: this.isSetupButtonDisabled(),
        primary: !this.isSetupButtonDisabled()
      }
    ];
    if (this.orderItem.status === this.ORDER_STATUS.Checked) {
      buttons.push(this.getPresetupButton(this.isSetupButtonDisabled()));
    }
    return buttons;
  }

  private constructNextButtons(): ButtonItem[] {
    const isStatusChecked = this.orderItem.status === this.ORDER_STATUS.Checked;
    const buttons: ButtonItem[] = [
      {
        command: () => {
          this.setNextProductionOrder();
        },
        label: 'ORDER_ITEM.NEXT',
        disabled: !isStatusChecked,
        primary: isStatusChecked
      }
    ];
    if (isStatusChecked) {
      buttons.push(this.getPresetupButton(!isStatusChecked));
    }
    return buttons;
  }

  private getPresetupButton(isPrimary: boolean): ButtonItem {
    return {
      command: () => {
        this.presetupProductionOrder();
      },
      label: 'ORDER_ITEM.PRE_SETUP',
      disabled: false,
      primary: isPrimary
    };
  }

  private constructManualModeCuttingButtons(): ButtonItem[] {
    return [
      {
        command: () => {
          this.openStartOrderInManualModeModal();
        },
        label: 'ORDER_ITEM.START',
        disabled: this.isSetupButtonDisabled(),
        primary: true
      }
    ];
  }

  private constructManualModeSortingButtons(): ButtonItem[] {
    return [
      {
        command: () => {
          this.startManualModeSorting();
        },
        label: 'ORDER_ITEM.START',
        disabled: this.isSetupButtonDisabled(),
        primary: true
      }
    ];
  }

  private openPresetupModal(): void {
    const modal = this.dialogService.open(PresetupModalComponent, {
      header: this.translateService.instant('ORDER_ITEM.PRE_SETUP'),
      data: { orderItem: this.orderItem }
    });
    modal.onClose.pipe(switchMap(() => this.orderListService.getProductionOrderListByShift(this.isShowingAllOrderItems))).subscribe();
  }

  private presetupProductionOrder(): void {
    this.orderItemService.presetupProductionOrder(this.orderItem.productionOrderId).subscribe(() => {
      this.openPresetupModal();
    });
  }

  private openStartOrderInManualModeModal(): void {
    let defaultTargetContainerQuantityInfo = null;
    this.orderListService
      .getDefaultTargetContainerQuantityInfo(this.orderItem.productionOrderId)
      .pipe(
        finalize(() => {
          this.dialogService.open(OrderStartModalComponent, {
            header: this.translateService.instant('MANUAL_MODE.JOB_START_TITLE'),
            data: {
              productionOrder: this.orderItem,
              defaultPalletQuantityInfo: defaultTargetContainerQuantityInfo
            }
          });
        })
      )
      .subscribe((defaultInfo) => (defaultTargetContainerQuantityInfo = defaultInfo));
  }

  private startManualModeSorting(): void {
    const trace = elastic.traceUiActivity('OrderItemComponent.startManualModeSorting');

    LoadingNotificationService.publish(this.loadingTopic, true);
    this.manualModeService
      .startOrderInManualMode(this.orderItem.productionOrderId, new Date())
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.loadingTopic, false);
          trace.end();
        })
      )
      .subscribe(() => this.router.navigate([nav.routes.run]));
  }

  public navigateToDocuments(): void {
    this.uiStateService.setDocumentStateSelectedOrderId(this.orderItem.productionOrderId);
    this.router.navigate([nav.routes.files]).then();
  }
}
