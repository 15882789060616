import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewEncapsulation,
  AfterViewInit,
  ChangeDetectorRef,
  HostListener,
  ViewChild,
  ElementRef
} from '@angular/core';
import moment from 'moment-mini';
import {
  WorkCenterService,
  MachineScheduleService,
  ProductionPeriodDetailData,
  WorkCenterTask,
  ShiftReportService,
  WorkCenterData,
  WasteAssignmentInTime,
  ProductionOrderSetupPhaseState
} from 'chronos-core-client';
import { WorkCenterCachedService } from 'chronos-core-client';
import { TelemetryService, TelemetryQueryDto, MeasurementService, TelemetryResultDto, WnsReportDto } from 'chronos-panda-client';
import { Subscription, Observable, combineLatest, Subject, forkJoin } from 'rxjs';
import * as Highcharts from 'highcharts/highstock';
import HighchartsMore from 'highcharts/highcharts-more';
// import hc_mouseWheelZoom from 'highcharts/modules/mouse-wheel-zoom';
import hc_patternFill from 'highcharts/modules/pattern-fill';
import hc_export from 'highcharts/modules/exporting';
import hc_offline_export from 'highcharts/modules/offline-exporting';
import hc_accessibility from 'highcharts/modules/accessibility';
import draggable from 'highcharts/modules/draggable-points';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MachineChartConfigurationService } from './machine-chart-configuration.service';
import { SelectItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { WorkCenterMachineData, ProductionOrderService } from 'chronos-core-client';
import { MatTableDataSource } from '@angular/material/table';
import { MachineData, MetadataProperties, SignalType } from './machine-data';
import { ChartType } from '../models/chart-type.model';
import { MetaModelService, OrganizationsService } from 'chronos-live-client';
import { Router } from '@angular/router';
import { MachineChartService } from './machine-chart.service';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';

const DeclaredPerformanceDefault = 20000;

@Component({
  selector: 'lib-machine-chart',
  templateUrl: './machine-chart.component.html',
  styleUrls: ['./machine-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MachineChartComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  private resetZoom: string;
  private declaredPerformance: number;
  constructor(
    private translate: TranslateService,
    private productionOrderService: ProductionOrderService,
    private workCenterService: WorkCenterService,
    private workCenterCachedService: WorkCenterCachedService,
    private formBuilder: UntypedFormBuilder,
    private machineScheduleService: MachineScheduleService,
    private telemetryService: TelemetryService,
    private machineChartConfigurationService: MachineChartConfigurationService,
    private measurementService: MeasurementService,
    private organizationService: OrganizationsService,
    private metamodelService: MetaModelService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private shiftReportService: ShiftReportService,
    private machineChartService: MachineChartService
  ) {
    this.translate.stream(['MACHINE_CHART.RESET_ZOOM']).subscribe((values) => {
      Object.keys(values).map((key) => {
        switch (key) {
          case 'MACHINE_CHART.RESET_ZOOM':
            this.resetZoom = values[key];
            break;
        }
      });
    });
    HighchartsMore(Highcharts);
    hc_patternFill(Highcharts);
    //hc_mouseWheelZoom(Highcharts);
    hc_export(Highcharts);
    hc_offline_export(Highcharts);
    hc_accessibility(Highcharts);
    draggable(Highcharts);
    // exportData(Highcharts);

    this.formGroup = this.formBuilder.group({
      startDate: [null],
      endDate: [null],
      machineId: [null],
      period: [null]
    });

    this.chartCallback = (chart) => {
      this.chart = chart;
      const values = this.chart.yAxis[0].getExtremes();
      this.targetSpeedForLines = values.max;
      this.updateSeriesLines();
      this.chart.redraw();
    };
    this.dataSource = new MatTableDataSource(this.machineData);

    this.listenToStartDateChanges();
    this.listenToEndDateChanges();
  }
  public isEnterKey = false;

  @ViewChild('chartContainer') public chartContainer: ElementRef;

  @Input() public workCenterId: number;
  @Input() public showMachineList = false;
  @Input() public isAutoUpdate = false;
  @Input() public isFullViewMode = false;
  @Input() public showDatetimeFilter = false;
  @Input() public start: string;
  @Input() public rest: string;
  @Input() public isMachineChartInSetupPhase = false;
  @Input() public setupStartTime: Date; // current start of the setup phase
  @Input() public setupEndTime: Date; // current end of the setup phase
  @Input() public splitTime: Date; // SplitDowntime: the time when the downtime is split
  @Input() public earlistDateLimit: Date; // start of editable area
  @Input() public latestDateLimit: Date; // end of editable area

  @Output() public taskChange = new EventEmitter<WorkCenterTask>();
  @Output()
  public setupStartTimeChanged: EventEmitter<Date> = new EventEmitter();
  @Output()
  public setupEndTimeChanged: EventEmitter<Date> = new EventEmitter();

  @Input() public taskList: WorkCenterTask[];
  @Input() public task: WorkCenterTask;

  @Output()
  public splitTimeChanged: EventEmitter<Date> = new EventEmitter();

  @Input() public isChartIsInSplitDowntime = false;

  @Input() public chartMode: ChartType;
  @Input() public orderSetupPhaseState?: ProductionOrderSetupPhaseState;

  public isDateValid = true;
  public isDurationValid = true;

  public maxSpeedAxis1: number;
  public maxSpeedAxis2: number;
  public externalWorkCenterId: string;
  public workCenterName = '';
  public highcharts = Highcharts;
  public chartDetails: Highcharts.Options;
  public periods: ProductionPeriodDetailData[];
  public selectedPeriodHours = '8Hrs';
  public periodStartTime: Date;
  public periodEndTime: Date;
  public periodStart: Date;
  public periodEnd: Date;
  public downtimeComment: string;
  public startTime: string;
  public timeout: any;
  // TODO WTF
  public chart: Highcharts.Chart;
  public startDate: string;
  public orderScheduleId: string;
  public formGroup: UntypedFormGroup;
  public machineList: WorkCenterData[] = [];
  private activeSubscriptions: Subscription[] = [];
  public updateFromInput: boolean;
  public chartCallback;
  public chartConstructor = 'chart';
  public isMobileView = false;
  public downtime: string;
  public timeRemaining: string;
  public timeFilters: SelectItem[];
  public isAddScrollBar = false;
  public maxDate: Date;
  public plotBandHeight: number;
  public excludedSignals: string[] = [];

  public isDetailedView = false;
  public allMachinesData: WorkCenterMachineData[] = [];
  public machineData: MachineData[] = [];
  public selectedMachine: MachineData;
  public selectedMachineData: any;
  public dataSource: MatTableDataSource<MachineData>;
  public displayedColumns: string[] = [
    'activeProductionOrderCode',
    'salesOrderAndPosition',
    'externalFinishedGoodArticleId',
    'finishedGoodArticleConfigurationId',
    'customerName',
    'finishedGoodArticleName',
    'plannedQuantity',
    'quantityPredecessor',
    'goodQuantity',
    'waste',
    'maculature',
    'numberOfStoppers'
  ];
  public displayedColumnsNames: string[] = [];
  public months: string[] = [];
  public days: string[] = [];
  public customPeriod: string;
  public eightHrs: string;
  public twelveHrs: string;
  public oneDay: string;
  public twoDays: string;
  public threeDays: string;
  public oneWeek: string;
  public loadingText: string;
  public contextMenuText: string;
  public viewFullscreenText: string;
  public printChartText: string;
  public downloadPNGText: string;
  public downloadJPEGText: string;
  public downloadPDFText: string;
  public downloadSVGText: string;
  public targetSpeedForLines: number;
  public isCollapsed = false;
  public sign = 'up';
  public chartHeight = 500;
  public counterUnitId: string;
  public isForwardDisabled = true;
  public activeWorkcenters: WorkCenterData[] = [];
  public activeWorkcenterMachineData: WorkCenterMachineData[] = [];
  public netQuantity = 0;
  public totalWaste = 0;
  public totalMaculature = 0;
  public grossQuantity = 0;

  public isLiveviewEnabled = true;
  public showPeriod = false;
  public showEndDatetime = true;
  public isChartZoomed = false;
  public liveState = false;
  public isMarkerEnabled = false;
  public minX;
  public maxX;
  public isPeriodSelected = false;
  public workCenterChartYAxisSpace: number;
  public noWorkCenterSelectedFlag = false;
  public noWorkCenterAccessFlag = false;
  public firstWorkCenterFromList = false;
  public isDefaultWorkCenterNotAvailable = false;
  public isFromDashboardMachineList = false;
  public wasteAssignmentsDetails: WasteAssignmentInTime[];
  public showWasteAssignments: boolean;
  private startDateSelected$ = new Subject<Date>();
  private endDateSelected$ = new Subject<Date>();

  @HostListener('window:keydown', ['$event'])
  public keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.isEnterKey = true;
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.setupStartTime) {
      this.updateStartTime(this.setupStartTime);
    }

    if (changes.splitTime) {
      this.updateSplitTime(this.splitTime);
    }

    if (changes.setupEndTime) {
      this.updateEndTime(this.setupEndTime);
    }

    if (changes.task && this.task !== undefined) {
      this.updateChart(this.task);
    }

    if (changes.taskList && this.taskList !== undefined) {
      this.updateDowntime(this.taskList);
    }

    this.chart?.redraw();
  }

  public ngAfterViewInit() {
    this.setIsMobileView(window.screen.width);
    this.cdr.detectChanges();
  }

  public onResize(event) {
    this.setIsMobileView(event.target.innerWidth);
  }

  private setIsMobileView(width: number): void {
    this.isMobileView = width <= 700;
  }

  public updateSeriesLines() {
    if (this.chartMode === ChartType.editSetup || this.chartMode === ChartType.editDowntime) {
      this.chart.series[5].data[0].y = this.targetSpeedForLines;
      this.chart.series[5].update(null, false);

      this.chart.series[6].data[0].y = this.targetSpeedForLines;
      this.chart.series[6].update(null, false);
    } else if (this.chartMode === ChartType.splitDowntime) {
      this.chart.series[4].data[0].y = this.targetSpeedForLines;
      this.chart.series[4].update(null, false);
    }
  }

  public updateDowntime(taskItemDowntime: WorkCenterTask[]) {
    if (this.chart) {
      taskItemDowntime.forEach((taskItem) => {
        this.chart.series[3].data.forEach((downElement) => {
          if (
            moment.utc(taskItem.downtime.startTime).format('YYYY-MM-DDTHH:mm:ss') ===
              moment.utc(downElement.x).format('YYYY-MM-DDTHH:mm:ss') &&
            downElement.y > 0
          ) {
            const regExp = /\(([^)]+)\)/;
            const matches = regExp.exec(taskItem.downtime.downtimeReasonLocalDescription);
            downElement['externalDowntimeLocalCode'] = matches[1];
          }
        });
      });
      this.chart.series[3].update(null, false);
    }
  }

  public ngOnInit(): void {
    if (!this.workCenterId) {
      this.isDefaultWorkCenterNotAvailable = true;
    }

    this.maxDate = new Date();
    if (history.state.flag) {
      this.workCenterId = history.state.workCenterId;
      this.isFullViewMode = history.state.isFullViewMode;
      this.showMachineList = true;
      this.isAutoUpdate = true;
      this.externalWorkCenterId = history.state.externalWorkCenterId;
      this.isFromDashboardMachineList = history.state.fromDashboardMachineList;
      this.showWasteAssignments = history.state.showWasteAssignmentsSeries;

      if (history.state.isDetailedView) {
        this.isDetailedView = history.state.isDetailedView;
        this.chartMode = history.state.chartMode;
      }
    }

    const id = this.router.url.substring(this.router.url.lastIndexOf('/') + 1, this.router.url.length);
    if (!isNaN(Number(id))) {
      this.externalWorkCenterId = id;
      this.setIsMobileView(window.screen.width);
      this.chartMode = ChartType.fullView;
      this.isFullViewMode = true;
      this.showMachineList = true;
      this.isDetailedView = true;
    }

    if (ChartType.fullView === this.chartMode) {
      this.machineList = [];
      this.activeWorkcenters = [];
      this.organizationService.getActiveOrganizationsForUser({ orid: 0 }).subscribe((workCenters) => {
        if (workCenters && workCenters.length > 0) {
          const activeSubscription = this.workCenterCachedService.workCenterGetWorkCenterList().subscribe((workCenterList) => {
            if (workCenterList && workCenterList.length > 0) {
              workCenters.forEach((activeWorkcenter) => {
                workCenterList.forEach((workcenterelement) => {
                  if (activeWorkcenter.orname === workcenterelement.externalWorkCenterId) {
                    this.activeWorkcenters.push(workcenterelement);

                    if (this.isDefaultWorkCenterNotAvailable) {
                      if (!this.firstWorkCenterFromList && !this.isFromDashboardMachineList) {
                        this.workCenterId = workcenterelement.id;
                        this.firstWorkCenterFromList = true;
                      }
                    }
                  }

                  if (workcenterelement.externalWorkCenterId === this.workCenterId?.toString() && !this.isDefaultWorkCenterNotAvailable) {
                    this.workCenterId = workcenterelement.id;
                    this.externalWorkCenterId = workcenterelement.externalWorkCenterId;
                  }
                });
              });
              this.setMachineAdditionalInfo(this.workCenterId);
              this.loadWorkCenterDetails();
              if (this.showMachineList) {
                this.machineList = this.activeWorkcenters.sort((a, b) => a.externalWorkCenterId.localeCompare(b.externalWorkCenterId));
                this.formGroup.patchValue({
                  machineId: Number(this.workCenterId)
                });
              }
            }
          });

          this.activeSubscriptions.push(activeSubscription);
        } else {
          this.workCenterId = null;
          this.noWorkCenterAccessFlag = true;
        }
      });
    } else {
      this.loadWorkCenterDetails();
    }

    if (this.isAutoUpdate && this.isLiveviewEnabled) {
      this.setAutoUpdate();
    }

    this.configureGlobalChartSettings();
    this.getAllMachineDetails();
  }

  public setTimePeriods() {
    this.timeFilters = [
      { label: this.eightHrs, value: '8Hrs' },
      { label: this.twelveHrs, value: '12Hrs' },
      { label: this.oneDay, value: '24Hrs' },
      { label: this.twoDays, value: '2Days' },
      { label: this.threeDays, value: '3Days' },
      { label: this.oneWeek, value: '1Week' }
    ];
  }

  public toggleFilters() {
    if (!this.isEnterKey) {
      this.isCollapsed = !this.isCollapsed;
      if (this.isCollapsed) {
        this.chartHeight = this.chartHeight + 125;
        if (this.sign === 'up') {
          this.sign = 'down';
        }
      } else {
        this.chartHeight = this.chartHeight - 125;
        if (this.sign === 'down') {
          this.sign = 'up';
        }
      }
    }
    this.isEnterKey = false;
  }

  public setMachineAdditionalInfo(workCenterId: number) {
    const selectedStartDate = this.getSelectedStartDate();
    const selectedEndDate = this.getSelectedEndDate();
    const activeSubscription = this.machineChartService
      .getProductionOrderDetails(workCenterId, selectedStartDate, selectedEndDate)
      .subscribe((result) => {
        if (result) {
          this.setWorkCenterDetails(result);
        }
        this.activeSubscriptions.push(activeSubscription);
      });
  }

  public getAllMachineDetails() {
    this.organizationService.getActiveOrganizations({ orid: 0 }).subscribe((workCenters) => {
      if (workCenters) {
        this.workCenterCachedService.getWorkCenterMachineDataList().subscribe((result) => {
          workCenters.forEach((activeWorkcenter) => {
            result.forEach((workcenterelement) => {
              if (activeWorkcenter.orname === workcenterelement.externalWorkCenterId) {
                this.activeWorkcenterMachineData.push(workcenterelement);
              }
            });
          });
        });
        this.allMachinesData = this.activeWorkcenterMachineData;
      }
    });
  }

  public configureGlobalChartSettings(): void {
    const filterSubscription = combineLatest([
      this.configureGlobalTableHeaders(),
      this.configureGlobalChartMonths(),
      this.configureGlobalChartDays(),
      this.configureGlobalChartFilterDurations(),
      this.configureGlobalChartOptions()
    ]).subscribe(([tableHeadersResult, chartMonthResult, chartDaysResult, chartFilterDurationsResult, chartOptionsResult]) => {
      this.displayedColumnsNames = Object.keys(tableHeadersResult).map((key) => tableHeadersResult[key]);
      this.months = Object.keys(chartMonthResult).map((key) => chartMonthResult[key]);
      this.days = Object.keys(chartDaysResult).map((key) => chartDaysResult[key]);

      Object.keys(chartFilterDurationsResult).map((key) => {
        switch (key) {
          case 'MACHINE_CHART.8_HRS':
            this.eightHrs = chartFilterDurationsResult[key];
            break;
          case 'MACHINE_CHART.12_HRS':
            this.twelveHrs = chartFilterDurationsResult[key];
            break;
          case 'MACHINE_CHART.1_DAY':
            this.oneDay = chartFilterDurationsResult[key];
            break;
          case 'MACHINE_CHART.2_DAYS':
            this.twoDays = chartFilterDurationsResult[key];
            break;
          case 'MACHINE_CHART.3_DAYS':
            this.threeDays = chartFilterDurationsResult[key];
            break;
          case 'MACHINE_CHART.1_WEEK':
            this.oneWeek = chartFilterDurationsResult[key];
            break;
          case 'MACHINE_CHART.CUSTOM':
            this.customPeriod = chartFilterDurationsResult[key];
            break;
          default:
            this.eightHrs = chartFilterDurationsResult[key];
            break;
        }
      });
      Object.keys(chartOptionsResult).map((key) => {
        switch (key) {
          case 'MACHINE_CHART.LOADING':
            this.loadingText = chartOptionsResult[key];
            break;
          case 'MACHINE_CHART.CONTEXT_MENU':
            this.contextMenuText = chartOptionsResult[key];
            break;
          case 'MACHINE_CHART.VIEW_FULLSCREEN':
            this.viewFullscreenText = chartOptionsResult[key];
            break;
          case 'MACHINE_CHART.PRINT_CHART':
            this.printChartText = chartOptionsResult[key];
            break;
          case 'MACHINE_CHART.DOWNLOAD_PNG':
            this.downloadPNGText = chartOptionsResult[key];
            break;
          case 'MACHINE_CHART.DOWNLOAD_JPEG':
            this.downloadJPEGText = chartOptionsResult[key];
            break;
          case 'MACHINE_CHART.DOWNLOAD_PDF':
            this.downloadPDFText = chartOptionsResult[key];
            break;
          case 'MACHINE_CHART.DOWNLOAD_SVG':
            this.downloadSVGText = chartOptionsResult[key];
            break;
        }
      });
      this.setTimePeriods();
      this.setHighchartOptions();
    });
    this.activeSubscriptions.push(filterSubscription);
  }

  public setHighchartOptions(): void {
    Highcharts.setOptions({
      lang: {
        numericSymbols: null,
        shortMonths: this.months,
        weekdays: this.days,
        loading: this.loadingText,
        contextButtonTitle: this.contextMenuText,
        viewFullscreen: this.viewFullscreenText,
        printChart: this.printChartText,
        downloadPNG: this.downloadPNGText,
        downloadJPEG: this.downloadJPEGText,
        downloadPDF: this.downloadPDFText,
        downloadSVG: this.downloadSVGText,
        resetZoom: this.resetZoom
      }
    });
  }

  public configureGlobalTableHeaders = (): Observable<any> =>
    this.translate.stream([
      'MACHINE_LIST.ACTIVE_JOB',
      'MACHINE_LIST.SALES_ORDER_AND_POSITION',
      'MACHINE_LIST.CUSTOMER_NAME',
      'MACHINE_LIST.ARTICLE_ID',
      'MACHINE_LIST.PLANNED_QUANTITY',
      'MACHINE_LIST.QUANTITY_PREDECESSOR',
      'MACHINE_LIST.NET_QUANTITY',
      'MACHINE_LIST.WASTE',
      'MACHINE_LIST.MACULATURE',
      'MACHINE_LIST.NUMBEROFSTOPPERS'
    ]);

  public configureGlobalChartMonths = (): Observable<any> =>
    this.translate.stream([
      'MACHINE_CHART.JAN',
      'MACHINE_CHART.FEB',
      'MACHINE_CHART.MAR',
      'MACHINE_CHART.APR',
      'MACHINE_CHART.MAY',
      'MACHINE_CHART.JUN',
      'MACHINE_CHART.JUL',
      'MACHINE_CHART.AUG',
      'MACHINE_CHART.SEP',
      'MACHINE_CHART.OCT',
      'MACHINE_CHART.NOV',
      'MACHINE_CHART.DEC'
    ]);

  public showPeriodInfo(): void {
    this.chart.showLoading();
    this.showPeriod = true;
    this.showEndDatetime = false;
    const start = new Date(this.periodStart);
    if (this.formGroup.controls.period.value === null) {
      this.formGroup.patchValue({
        period: '8Hrs'
      });
    }
    if (this.showPeriod) {
      switch (this.formGroup.controls.period.value) {
        case '8Hrs':
          this.periodEnd = moment.utc(start).add(8, 'hours').toDate();
          break;
        case '12Hrs':
          this.periodEnd = moment.utc(start).add(12, 'hours').toDate();
          break;
        case '24Hrs':
          this.periodEnd = moment.utc(start).add(24, 'hours').toDate();
          break;
        case '2Days':
          this.periodEnd = moment.utc(start).add(2, 'days').toDate();
          break;
        case '3Days':
          this.periodEnd = moment.utc(start).add(3, 'days').toDate();
          break;
        case '1Week':
          this.periodEnd = moment.utc(start).add(7, 'days').toDate();
          break;
      }
    }
    this.getPeriods(this.workCenterId, start, this.periodEnd);
  }

  public showEndDate(): void {
    this.showPeriod = false;
    this.showEndDatetime = true;
  }

  public configureGlobalChartDays = (): Observable<any> =>
    this.translate.stream([
      'MACHINE_CHART.MONDAY',
      'MACHINE_CHART.TUESDAY',
      'MACHINE_CHART.WEDNESDAY',
      'MACHINE_CHART.THURSDAY',
      'MACHINE_CHART.FRIDAY',
      'MACHINE_CHART.SATURDAY',
      'MACHINE_CHART.SUNDAY'
    ]);

  public configureGlobalChartFilterDurations = (): Observable<any> =>
    this.translate.stream([
      'MACHINE_CHART.8_HRS',
      'MACHINE_CHART.12_HRS',
      'MACHINE_CHART.1_DAY',
      'MACHINE_CHART.2_DAYS',
      'MACHINE_CHART.3_DAYS',
      'MACHINE_CHART.1_WEEK',
      'MACHINE_CHART.CUSTOM'
    ]);

  public configureGlobalChartOptions = (): Observable<any> =>
    this.translate.stream([
      'MACHINE_CHART.LOADING',
      'MACHINE_CHART.CONTEXT_MENU',
      'MACHINE_CHART.VIEW_FULLSCREEN',
      'MACHINE_CHART.PRINT_CHART',
      'MACHINE_CHART.DOWNLOAD_PNG',
      'MACHINE_CHART.DOWNLOAD_JPEG',
      'MACHINE_CHART.DOWNLOAD_PDF',
      'MACHINE_CHART.DOWNLOAD_SVG'
    ]);

  public loadWorkCenterDetails() {
    this.workCenterService.getWorkCenterDetail({ workCenterId: this.workCenterId }).subscribe((workCenterDetails) => {
      if (workCenterDetails) {
        this.workCenterName = workCenterDetails.name;
        this.externalWorkCenterId = workCenterDetails.externalWorkCenterId;
        this.counterUnitId = workCenterDetails.counterUnitId;
        this.metamodelService.getMetaModel(this.setProperty(MetadataProperties.workcenterHideSignal)).subscribe((hideSignalResult) => {
          if (hideSignalResult.length > 0 && hideSignalResult[0].value !== 'NA') {
            const excludedSignalsText = hideSignalResult[0].value ?? '';

            // we need to exclude counter_qty and speed - those are required signals and they cannot be manually excluded
            this.excludedSignals = excludedSignalsText
              .split(',')
              .map((data) => data.trim())
              .filter((signal) => signal !== SignalType.counter_qty && signal !== SignalType.speed);
          } else {
            this.excludedSignals = [];
          }

          this.metamodelService.getMetaModel(this.setProperty(MetadataProperties.workCenterChartYAxisSpace)).subscribe((res) => {
            if (res && res.length > 0 && res[0].value !== 'NA') {
              // if the text is not parsable, Number() returns NaN
              this.workCenterChartYAxisSpace = Number(res[0].value);
            }

            if (isNaN(this.workCenterChartYAxisSpace)) {
              this.workCenterChartYAxisSpace = 0;
            }

            this.declaredPerformance = Math.max(0, workCenterDetails.declaredPerformance.value ?? DeclaredPerformanceDefault);

            switch (this.chartMode) {
              case ChartType.fullView:
                this.periodStart = this.formGroup.value.startDate ?? moment.utc().subtract(8, 'hours').toDate();
                this.periodEnd = this.formGroup.value.endDate ?? moment.utc().toDate();
                this.getPeriods(this.workCenterId, this.periodStart, this.periodEnd);
                break;
              case ChartType.downtimeTask:
                this.machineScheduleService.getActiveOrder({ workCenterId: this.workCenterId }).subscribe((activeProductionOrder) => {
                  if (activeProductionOrder) {
                    this.orderScheduleId = activeProductionOrder.externalProductionOrderId;
                    this.startTime = moment.utc(activeProductionOrder.productionStartTime).local().format('HH:mm');
                  } else {
                    this.orderScheduleId = null;
                    this.startTime = null;
                  }
                  this.shiftReportService.getShifts({ workCenterId: this.workCenterId }).subscribe((shifts) => {
                    this.periodStart = shifts.length > 0 ? new Date(shifts[0]?.startTime) : moment.utc().subtract(8, 'hours').toDate();
                    this.periodEnd = moment.utc().toDate();
                    this.getPeriods(this.workCenterId, this.periodStart, this.periodEnd);
                  });
                });
                break;
              case ChartType.finishPhase:
                this.activeSubscriptions.push(
                  this.machineScheduleService.getActiveOrder({ workCenterId: this.workCenterId }).subscribe((activeProductionOrder) => {
                    if (activeProductionOrder) {
                      this.orderScheduleId = activeProductionOrder.externalProductionOrderId;
                      this.startTime = moment.utc(activeProductionOrder.productionStartTime).local().format('HH:mm');
                      this.productionOrderService
                        .getProjectedRunEnd({ productionOrderId: activeProductionOrder.productionOrderId })
                        .subscribe((orderWithLastDateTime) => {
                          if (orderWithLastDateTime) {
                            this.timeRemaining = moment
                              .duration(activeProductionOrder.timeRemaining)
                              .add(30, 'second')
                              .minutes()
                              .toLocaleString();
                            this.periodStart = new Date(activeProductionOrder.productionStartTime);
                            this.periodEnd = moment.utc(orderWithLastDateTime.lastProductionDateTime).toDate();
                            this.getPeriods(
                              this.workCenterId,
                              new Date(activeProductionOrder.productionStartTime),
                              activeProductionOrder.productionEndTime
                                ? new Date(activeProductionOrder.productionEndTime)
                                : moment.utc(orderWithLastDateTime.lastProductionDateTime).toDate()
                            );
                          }
                        });
                    }
                  })
                );
                break;
              case ChartType.editSetup:
                const diffInTime = this.setupEndTime.getTime() - this.setupStartTime.getTime();
                const diffInDays = diffInTime / (1000 * 3600 * 24);

                this.periodStart = moment.utc(this.setupStartTime).subtract(1, 'hours').toDate();

                this.periodEnd =
                  diffInDays > 2
                    ? moment.utc(this.setupEndTime).add(8, 'hours').toDate()
                    : moment.utc(this.setupEndTime).add(1, 'hours').toDate();

                this.setupEndTime = this.setupEndTime == null ? this.periodEnd : this.setupEndTime;

                this.getPeriods(this.workCenterId, this.periodStart, this.periodEnd);
                break;
              case ChartType.editDowntime:
                this.setEditDowntimePeriodRange();
                this.getPeriods(this.workCenterId, this.periodStart, this.periodEnd);
                break;
              case ChartType.splitDowntime:
                this.periodStart = moment.utc(this.setupStartTime).subtract(1, 'hours').toDate();
                this.periodEnd = moment.utc(this.setupEndTime).add(1, 'hours').toDate();
                this.getPeriods(this.workCenterId, this.periodStart, this.periodEnd);
                break;
            }
          });
        });
      }
    });
  }

  private setEditDowntimePeriodRange() {
    const downtimeDuration = this.setupEndTime.getTime() - this.setupStartTime.getTime();
    const minimumDateVal =
      moment.utc(this.setupEndTime).add(3, 'hours').toDate() > this.latestDateLimit
        ? this.latestDateLimit
        : moment.utc(this.setupEndTime).add(3, 'hours').toDate();
    const tenPerc = 0.1 * (this.setupStartTime.getTime() - (minimumDateVal.getTime() + downtimeDuration));

    this.periodStart = moment.utc(this.setupStartTime).add(tenPerc).toDate();
    this.periodEnd = moment.utc(this.setupEndTime).add(3, 'hours').toDate();
  }

  public setAutoUpdate() {
    this.timeout = setInterval(() => {
      if (this.isLiveviewEnabled) {
        this.isForwardDisabled = true;
        this.setLiveViewValues();
      } else {
        clearInterval(this.timeout);
      }
    }, 30 * 1000);
  }

  public openSpeedMonitor() {
    this.router.navigateByUrl(`/speedmonitor/${this.externalWorkCenterId}`, {
      state: {
        workCenterId: this.workCenterId,
        flag: true,
        externalWorkCenterId: this.externalWorkCenterId
      }
    });
  }

  public generateSignalQuery(speedQueryFactor: number, aggfun: string, wnsReport: WnsReportDto): TelemetryQueryDto {
    this.isMarkerEnabled = this.isChartZoomed === true ? true : false;
    if (speedQueryFactor < 10) {
      return {
        wellKnownSignal: wnsReport.wellKnownSignal,
        resource: this.externalWorkCenterId,
        from: moment.utc(this.periodStart).format('YYYY-MM-DDTHH:mm:ss.SSSSSSS'),
        to:
          this.periodEnd === null
            ? moment.utc().format('YYYY-MM-DDTHH:mm:ss.SSSSSSS')
            : moment.utc(this.periodEnd).format('YYYY-MM-DDTHH:mm:ss.SSSSSSS'),
        resultPoints: ''
      };
    } else {
      return {
        wellKnownSignal: wnsReport.wellKnownSignal,
        resource: this.externalWorkCenterId,
        from: moment.utc(this.periodStart).format('YYYY-MM-DDTHH:mm:ss.SSSSSSS'),
        to:
          this.periodEnd === null
            ? moment.utc().format('YYYY-MM-DDTHH:mm:ss.SSSSSSS')
            : moment.utc(this.periodEnd).format('YYYY-MM-DDTHH:mm:ss.SSSSSSS'),
        aggFunction: aggfun,
        groupBy: `time(${speedQueryFactor}s) fill(none)`,
        resultPoints: ''
      };
    }
  }

  public getPeriods(workCenterId: number, start: Date, end: Date): void {
    if (!this.isChartZoomed) {
      this.formGroup.controls.startDate.setValue(start);
      this.formGroup.controls.endDate.setValue(end);
    }

    forkJoin([
      this.machineChartService.getPeriods(workCenterId, start.toISOString(), end.toISOString()),
      this.wasteAssignments()
    ]).subscribe(([productionPeriods, wasteAssignments]) => {
      this.setProductionPeriodDetails(productionPeriods, wasteAssignments);
    });
  }

  private updateStartTime(setupStartTime: Date): void {
    const x = moment.utc(setupStartTime).valueOf();
    if (this.chart) {
      this.chart.series[4].data[0].x = x;
      this.chart.series[4].data[0].y = this.maxSpeedAxis1 - this.plotBandHeight;
      this.chart.series[4].data[3].x = x;
      this.chart.series[4].data[3].y = this.maxSpeedAxis1;
      this.chart.series[4].update(null, false);

      this.chart.series[5].data[0].x = x;
      this.chart.series[5].data[1].x = x;
      this.chart.series[5].update(null, false);
    }
  }

  private updateSplitTime(splitTime: Date): void {
    if (this.chart) {
      const x = moment.utc(splitTime).valueOf();
      this.chart.series[4].data[0].x = x;
      this.chart.series[4].data[1].x = x;
      this.chart.series[4].update(null, false);
    }
  }

  private updateEndTime(setupEndTime: Date): void {
    const x = moment.utc(setupEndTime).valueOf();
    if (this.chart) {
      this.chart.series[4].data[1].x = x;
      this.chart.series[4].data[1].y = this.maxSpeedAxis1 - this.plotBandHeight;
      this.chart.series[4].data[2].x = x;
      this.chart.series[4].data[2].y = this.maxSpeedAxis1;
      this.chart.series[4].update(null, false);

      this.chart.series[6].data[0].x = x;
      this.chart.series[6].data[1].x = x;
      this.chart.series[6].update(null, false);
    }
  }

  private calculateMaximumAxisValue(maxSpeed: number): number {
    // we take the declared performance or max speed as the minimum value for the axis
    maxSpeed = this.declaredPerformance || maxSpeed;

    // apply the y-axis spacing factor
    const spacingFactor = Math.max(0, this.workCenterChartYAxisSpace ?? 0);
    maxSpeed *= 1 + spacingFactor / 100;

    // lets calculate the scale to which we want to round up the maximum value, we want to keep two significant digits
    let power = Math.ceil(Math.log10(maxSpeed));
    power = Math.max(-28, Math.min(28, power));
    const magnitude = Math.pow(10, power - 2); // keep two significant digits

    // calculate the final range
    const result = (Math.ceil(maxSpeed / magnitude) + 1) * magnitude;

    return result;
  }

  private getMaximumValue(signalData: TelemetryResultDto): number {
    if (!signalData || !signalData.telemetryData) {
      return undefined;
    }

    return Math.max(...signalData.telemetryData.map((o) => o.value));
  }

  private validateDateRange(from: Date, to: Date): boolean {
    this.isDateValid = this.isDateRangeValid(from, to);

    if (!this.isDateValid) {
      console.error('The "From" date cannot be greater than the "To" date.');
      return false;
    }

    this.isDurationValid = this.isDurationRangeValid(from, to);

    if (!this.isDurationValid) {
      console.error('The selected time range is too large. It may be at most 7 days.');
      return false;
    }

    return true;
  }

  private isDurationRangeValid(from: Date, to: Date): boolean {
    return to.getTime() - from.getTime() <= 7 * 24 * 60 * 60 * 1000;
  }

  private isDateRangeValid(from: Date, to: Date): boolean {
    return from < to;
  }

  private shouldSignalBeExcluded(wnsReport: WnsReportDto) {
    return this.excludedSignals.includes(wnsReport.wellKnownSignal);
  }

  private needsCounterSignal(): boolean {
    return (
      this.chartMode === ChartType.editSetup || this.chartMode === ChartType.splitDowntime || this.chartMode === ChartType.editDowntime
    );
  }

  public backToDashboard() {
    console.info('Back to dashboard clicked');
    this.router.navigateByUrl('/dashboard');
  }

  public onBackClick() {
    console.info('Back button clicked');
    this.chart.showLoading();
    this.checkChartZoomMode();
    this.periodStartTime = this.periodStart;
    this.periodEndTime = this.periodEnd;
    if ((this.periodEndTime.getTime() - this.periodStartTime.getTime()) / (60 * 1000) > 8) {
      this.isLiveviewEnabled = false;
    }
    switch (this.selectedPeriodHours) {
      case '8Hrs':
        this.periodEndTime.setHours(this.periodEndTime.getHours() - 8);
        this.periodStartTime.setHours(this.periodStartTime.getHours() - 8);
        break;
      case '12Hrs':
        this.periodEndTime.setHours(this.periodEndTime.getHours() - 12);
        this.periodStartTime.setHours(this.periodStartTime.getHours() - 12);
        break;
      case '24Hrs':
        this.periodEndTime.setHours(this.periodEndTime.getHours() - 24);
        this.periodStartTime.setHours(this.periodStartTime.getHours() - 24);
        break;
      case '2Days':
        this.periodEndTime.setDate(this.periodEndTime.getDate() - 2);
        this.periodStartTime.setDate(this.periodStartTime.getDate() - 2);
        break;
      case '3Days':
        this.periodEndTime.setDate(this.periodEndTime.getDate() - 3);
        this.periodStartTime.setDate(this.periodStartTime.getDate() - 3);
        break;
      case '1Week':
        this.periodEndTime.setDate(this.periodEndTime.getDate() - 7);
        this.periodStartTime.setDate(this.periodStartTime.getDate() - 7);
        break;
    }
    this.periodStart = new Date(this.periodStartTime);
    this.periodEnd = new Date(this.periodEndTime);
    this.formGroup.controls.startDate.setValue(this.periodStart);
    this.formGroup.controls.endDate.setValue(this.periodEnd);
    this.getPeriods(this.workCenterId, this.periodStart, this.periodEnd);
    this.isPeriodEndEqualsNow();
    this.setMachineAdditionalInfo(this.workCenterId);
  }

  public isPeriodEndEqualsNow() {
    const currentTime = new Date();
    this.periodEnd.setSeconds(this.periodEnd.getMinutes() + 2);
    if (this.periodEnd >= currentTime) {
      this.isForwardDisabled = true;
    } else {
      this.isForwardDisabled = false;
    }
  }

  public isNextPeriodEndAvailable() {
    const currentTime = new Date();
    const futureTime: Date = new Date(this.periodEnd.getTime());
    switch (this.selectedPeriodHours) {
      case '8Hrs':
        futureTime.setHours(this.periodEnd.getHours() + 8);
        break;
      case '12Hrs':
        futureTime.setHours(this.periodEnd.getHours() + 12);
        break;
      case '24Hrs':
        futureTime.setHours(this.periodEnd.getHours() + 24);
        break;
      case '2Days':
        futureTime.setDate(this.periodEnd.getDate() + 2);
        break;
      case '3Days':
        futureTime.setDate(this.periodEnd.getDate() + 3);
        break;
      case '1Week':
        futureTime.setDate(this.periodEnd.getDate() + 7);
        break;
    }
    if (futureTime >= currentTime) {
      this.isForwardDisabled = true;
    } else {
      this.isForwardDisabled = false;
    }
  }

  private setProperty(metaPropetry: string) {
    const signalAttribute: string[] = [metaPropetry];
    return {
      orid: Number(this.externalWorkCenterId),
      attributeNames: signalAttribute
    };
  }

  public onForwardClick() {
    console.info('Forward button clicked');
    this.chart.showLoading();
    this.checkChartZoomMode();
    this.isPeriodEndEqualsNow();
    if (!this.isForwardDisabled) {
      this.periodStartTime = this.periodStart;
      this.periodEndTime = this.periodEnd;
      this.periodStart = this.periodEnd;
      switch (this.selectedPeriodHours) {
        case '8Hrs':
          this.periodStartTime.setHours(this.periodStartTime.getHours() + 8);
          this.periodEndTime.setHours(this.periodEndTime.getHours() + 8);
          break;
        case '12Hrs':
          this.periodStartTime.setHours(this.periodStartTime.getHours() + 12);
          this.periodEndTime.setHours(this.periodEndTime.getHours() + 12);
          break;
        case '24Hrs':
          this.periodStartTime.setHours(this.periodStartTime.getHours() + 24);
          this.periodEndTime.setHours(this.periodEndTime.getHours() + 24);
          break;
        case '2Days':
          this.periodStartTime.setDate(this.periodStartTime.getDate() + 2);
          this.periodEndTime.setDate(this.periodEndTime.getDate() + 2);
          break;
        case '3Days':
          this.periodStartTime.setDate(this.periodStartTime.getDate() + 3);
          this.periodEndTime.setDate(this.periodEndTime.getDate() + 3);
          break;
        case '1Week':
          this.periodStartTime.setDate(this.periodStartTime.getDate() + 7);
          this.periodEndTime.setDate(this.periodEndTime.getDate() + 7);
          break;
      }
      this.periodStart = new Date(this.periodStartTime);
      this.periodEnd = new Date(this.periodEndTime);
      this.formGroup.controls.startDate.setValue(this.periodStart);
      this.formGroup.controls.endDate.setValue(this.periodEnd);
      this.getPeriods(this.workCenterId, this.periodStart, this.periodEnd);
      this.isPeriodEndEqualsNow();
      this.isNextPeriodEndAvailable();
      this.setMachineAdditionalInfo(this.workCenterId);
    }
  }

  public prepareChartObject(workcenterCounterDetail: WorkCenterMachineData) {
    this.maxSpeedAxis1 = this.maxSpeedAxis1 === 0 ? 20000 : this.maxSpeedAxis1;
    switch (this.chartMode) {
      case ChartType.fullView:
        this.chartDetails = this.machineChartConfigurationService.getFullViewConfiguration(
          this.workCenterName,
          this.externalWorkCenterId,
          this.maxSpeedAxis1,
          this.maxSpeedAxis2,
          workcenterCounterDetail.counterUnitId,
          this.chartHeight
        );
        break;
      case ChartType.downtimeTask:
      case ChartType.finishPhase:
        this.chartDetails = this.machineChartConfigurationService.getFinishPhaseConfiguration(
          this.orderScheduleId,
          this.startTime,
          this.timeRemaining,
          workcenterCounterDetail.counterUnitId,
          this.chartMode
        ) as Highcharts.Options;
        break;
      case ChartType.splitDowntime:
        this.chartDetails = this.machineChartConfigurationService.getSplitOrEditDowntimeConfiguration(
          this.latestDateLimit,
          this.maxSpeedAxis1,
          workcenterCounterDetail.counterUnitId,
          true
        ) as Highcharts.Options;
        break;
      case ChartType.editDowntime:
        if (this.latestDateLimit > this.periodEnd) {
          this.latestDateLimit = this.periodEnd;
        }

        this.chartDetails = this.machineChartConfigurationService.getSplitOrEditDowntimeConfiguration(
          this.latestDateLimit,
          this.maxSpeedAxis1,
          workcenterCounterDetail.counterUnitId,
          false
        ) as Highcharts.Options;
        break;
      case ChartType.editSetup:
        if (this.earlistDateLimit < this.periodStart) {
          this.earlistDateLimit = this.periodStart;
        }

        if (this.latestDateLimit > this.periodEnd) {
          this.latestDateLimit = this.periodEnd;
        }

        if (this.orderSetupPhaseState.latestSetupEnd && this.latestDateLimit > new Date(this.orderSetupPhaseState.latestSetupEnd)) {
          this.latestDateLimit = new Date(this.orderSetupPhaseState.latestSetupEnd);
        }

        // Ensure periodEnd does not exceed the current date and time
        this.periodEnd = this.ensureDateDoesNotExceedCurrent(this.periodEnd);

        if (this.evaluateScrollBarRequirement()) {
          this.chartDetails = this.machineChartConfigurationService.getEditSetupConfiguration(
            this.periodEnd,
            this.maxSpeedAxis1,
            workcenterCounterDetail.counterUnitId
          ) as Highcharts.Options;
        } else {
          this.chartDetails = this.machineChartConfigurationService.getEditSetupConfigurationWithoutScroll(
            this.periodEnd,
            this.maxSpeedAxis1,
            workcenterCounterDetail.counterUnitId
          ) as Highcharts.Options;
        }
    }
  }

  // Method to ensure a date does not exceed the current date and time
  private ensureDateDoesNotExceedCurrent(date: Date): Date {
    const currentDate = new Date();
    return date > currentDate ? currentDate : date;
  }

  private evaluateScrollBarRequirement() {
    const diffInTime = this.setupEndTime.getTime() - this.setupStartTime.getTime();
    const diffInDays = diffInTime / (1000 * 3600 * 24);
    this.maxDate =
      diffInDays > 2 ? moment.utc(this.periodStart).add(8, 'hour').toDate() : moment.utc(this.periodStart).add(1, 'hours').toDate();
    return diffInDays > 1 ? true : false;
  }

  private wasteAssignments(): Observable<WasteAssignmentInTime[]> {
    const periodStart: string = moment.utc(this.periodStart).toISOString();
    const periodEnd: string = moment.utc(this.periodEnd).toISOString();
    const wasteAssignmentResult = this.machineChartService.getWasteAssignments(this.workCenterId, periodStart, periodEnd);

    return wasteAssignmentResult;
  }

  public setChartConfiguration(result: ProductionPeriodDetailData[], allSignalData: TelemetryResultDto[]): void {
    if (this.chartDetails) {
      if (this.chartMode === ChartType.splitDowntime) {
        result.forEach((periods) => {
          if (periods.isShiftApproved === true) {
            this.setupStartTime = new Date(periods.shiftEndTime);
          }
        });
      }

      const chartConfiguration = this.machineChartConfigurationService.getSeriesConfiguration(
        result,
        allSignalData,
        this.wasteAssignmentsDetails,
        this.maxSpeedAxis1,
        this.setupStartTime,
        this.setupEndTime,
        this.chartMode,
        this.plotBandHeight,
        this.isMarkerEnabled,
        this.task
      );

      chartConfiguration.forEach((chartConfigurationData) => {
        this.chartDetails.series.push(chartConfigurationData);
      });

      if (this.chart) {
        this.chart.redraw();
        this.chart.hideLoading();
      } else {
        console.info('Chart is empty');
      }

      // setup the grayed out zone where we load data, but editing into this zone is not possible
      const zones: Highcharts.SeriesZonesOptionsObject[] = [];

      if (this.earlistDateLimit) {
        zones.push({
          value: moment.utc(this.earlistDateLimit).subtract(10, 'seconds').valueOf(),
          className: 'zone-0'
        });
      }

      if (this.latestDateLimit) {
        zones.push({
          value: moment.utc(this.latestDateLimit).add(10, 'seconds').valueOf(),
          className: ''
        });
        zones.push({
          className: 'zone-0'
        });
      }

      this.chartDetails.plotOptions.series.zoneAxis = 'x';
      this.chartDetails.plotOptions.series.zones = zones;

      if (this.chartMode === ChartType.editSetup) {
        this.chartDetails.plotOptions.series.point = {
          events: {
            // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
            drop: (function (self) {
              return function () {
                if (this.series.name === 'setupStartSeries') {
                  self.setupStartTimeChanged.emit(new Date(this.x));
                } else if (this.series.name === 'setupEndSeries') {
                  self.setupEndTimeChanged.emit(new Date(this.x));
                }
              };
            })(this)
          }
        };
      }
      if (this.chartMode === ChartType.splitDowntime) {
        this.chartDetails.plotOptions.series.point = {
          events: {
            // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
            drop: (function (self) {
              return function () {
                self.splitTimeChanged.emit(new Date(this.x));
              };
            })(this)
          }
        };
      }
      if (this.chartMode === ChartType.editDowntime) {
        this.chartDetails.plotOptions.series.point = {
          events: {
            // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
            drop: (function (self) {
              return function () {
                if (this.series.name === 'enditDowntimeStartSeries') {
                  self.setupStartTimeChanged.emit(new Date(this.x));
                } else if (this.series.name === 'enditDowntimeEndSeries') {
                  self.setupEndTimeChanged.emit(new Date(this.x));
                }
              };
            })(this)
          }
        };
      }
      if (this.chartMode === ChartType.fullView) {
        this.chartDetails.chart.events = {
          selection: (e) => {
            if (e.xAxis) {
              console.info('The chart is zoomed in');
              this.liveState = this.isLiveviewEnabled;
              this.isLiveviewEnabled = false;
              this.getSelectedSection(e);
            } else {
              this.isChartZoomed = false;
              console.info('The chart is zoomed out');
              this.isLiveviewEnabled = this.liveState;
              if (this.isLiveviewEnabled) {
                this.periodStart = moment.utc().subtract(8, 'hours').toDate();
                this.periodEnd = moment.utc().toDate();
              }
              if (this.formGroup.controls.startDate.value !== undefined && this.formGroup.controls.endDate.value !== undefined) {
                this.periodStart = this.formGroup.controls.startDate.value;
                this.periodEnd = this.formGroup.controls.endDate.value;
                this.getPeriods(this.workCenterId, this.periodStart, this.periodEnd);
              }

              this.chart.series.forEach((series) => {
                if (series.name === this.machineChartConfigurationService.getSeriesTitleText(this.counterUnitId)) {
                  series.update(
                    {
                      type: series.type === 'area' ? 'area' : 'line',
                      marker: {
                        radius: 4
                      }
                    },
                    false
                  );
                }
              });
            }
            this.chart.redraw();
            return true;
          }
        };
      }
      if (this.chartMode !== ChartType.fullView) {
        this.chartDetails.chart.events = {
          selection: (e) => {
            if (e.xAxis) {
              this.chart.series.forEach((allseries) => {
                if (allseries.name !== 'plotBandSeries') {
                  allseries.update(
                    {
                      type: allseries.type === 'area' ? 'area' : 'line',
                      marker: {
                        enabled: true
                      }
                    },
                    false
                  );
                }
              });
            } else {
              this.chart.series.forEach((allseries) => {
                allseries.update(
                  {
                    type: allseries.type === 'area' ? 'area' : 'line',
                    marker: {
                      enabled: false
                    }
                  },
                  false
                );
              });
            }

            this.chart.redraw();
            return true;
          }
        };
      }
    }
  }

  public getSelectedSection(e) {
    this.isChartZoomed = true;
    if (e.xAxis !== undefined) {
      this.minX = moment.utc(e.xAxis[0].min).toDate();
      this.maxX = moment.utc(e.xAxis[0].max).toDate();
      if (this.minX !== undefined && this.maxX !== undefined) {
        this.getPeriods(this.workCenterId, this.minX, this.maxX);
      }
    }
    this.chart.series.forEach((series) => {
      if (series.name === 'speedSeries') {
        series.update(
          {
            type: series.type === 'area' ? 'area' : 'line',
            marker: {
              radius: 0
            }
          },
          false
        );
      }
    });

    this.chart.redraw();
  }

  public updateChart(downtimeResult: WorkCenterTask) {
    this.shiftReportService.getShifts({ workCenterId: this.workCenterId }).subscribe((shifts) => {
      shifts.forEach((downtimeShift) => {
        if (downtimeResult.downtime.startTime >= downtimeShift.startTime && downtimeResult.downtime.endTime <= downtimeShift.endTime) {
          this.getPeriods(this.workCenterId, new Date(downtimeShift.startTime), new Date(downtimeShift.endTime));
        }
      });
    });
  }

  public checkChartZoomMode() {
    if (this.chart && this.isChartZoomed) {
      this.chart.zoomOut();
      this.isChartZoomed = false;
    }
  }

  public onReset() {
    console.info('Reset button clicked');
    this.chart.showLoading();
    this.checkChartZoomMode();
    this.formGroup.reset();
    this.formGroup.patchValue({
      machineId: Number(this.workCenterId),
      period: '8Hrs'
    });
    this.isLiveviewEnabled = true;
    this.setAutoUpdate();
    this.periodStart = moment.utc().subtract(8, 'hours').toDate();
    this.periodEnd = moment.utc().toDate();
    this.getPeriods(this.workCenterId, this.periodStart, this.periodEnd);
    this.isForwardDisabled = true;
    this.setMachineAdditionalInfo(this.workCenterId);
  }

  public ngOnDestroy() {
    clearTimeout(this.timeout);
    this.activeSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  public onMachineSelect(event: any) {
    console.info('Machine selection changed');
    this.checkChartZoomMode();
    this.workCenterId = event.value;
    localStorage.setItem('selectedWorkcenterId', JSON.stringify(this.workCenterId));

    this.chart.showLoading();
    this.chart.legend.allItems.forEach((exludeLegend) => {
      exludeLegend.remove(false);
    });
    this.chart.redraw();

    this.loadWorkCenterDetails();
    this.setMachineAdditionalInfo(this.workCenterId);

    if (this.isDetailedView) {
      this.workCenterCachedService.workCenterGetWorkCenter(this.workCenterId).subscribe((workCenterDetails) => {
        if (workCenterDetails) {
          this.externalWorkCenterId = workCenterDetails.externalWorkCenterId;
          this.changeMachineChartLoadUrl();
        }
      });
    }
  }

  public changeMachineChartLoadUrl() {
    this.chartMode = ChartType.fullView;
    this.router.navigateByUrl(`/machineChart/${this.externalWorkCenterId}`, {
      state: {
        workCenterId: this.workCenterId,
        flag: true,
        isFullViewMode: true,
        isDetailedView: true,
        chartMode: this.chartMode,
        externalWorkCenterId: this.externalWorkCenterId
      }
    });
  }

  public onPointSelect(event) {
    console.info('chart selected');
    if (this.chartMode === ChartType.downtimeTask) {
      this.downtime = moment.utc(event.point.options.x).format('YYYY-MM-DDTHH:mm:ss');
      const task = this.taskList.filter(
        (taskelement) =>
          moment.utc(taskelement.downtime.startTime).format('YYYY-MM-DDTHH:mm:ss') === this.downtime ||
          moment.utc(taskelement.downtime.endTime).format('YYYY-MM-DDTHH:mm:ss') === this.downtime
      );
      this.task = task[0];
      this.taskChange.emit(this.task);
    }
  }

  public onStartDateSelect(newDate: Date) {
    this.formGroup.controls.startDate.setValue(newDate);
    this.startDateSelected$.next(newDate);
    console.info('Start date selected: ', this.formGroup.controls.startDate.value);
    console.info('End date selected: ', this.formGroup.controls.endDate.value);
  }

  public onEndDateSelect(newDate: Date) {
    this.formGroup.controls.endDate.setValue(newDate);
    this.endDateSelected$.next(newDate);
    console.info('Start date selected: ', this.formGroup.controls.startDate.value);
    console.info('End date selected: ', this.formGroup.controls.endDate.value);
  }

  public fromDateChange(newDate) {
    if (newDate) {
      const newDateTime = newDate.getTime();
      if (!isFinite(newDateTime)) {
        return;
      }

      if (newDateTime === this.periodStart.getTime()) {
        return;
      }

      this.checkChartZoomMode();
      this.isLiveviewEnabled = false;
      this.periodStart = newDate;
      const start = new Date(this.periodStart);
      if (this.formGroup.controls.period.value === null) {
        this.formGroup.patchValue({
          period: '8Hrs'
        });
      }
      if (this.showPeriod) {
        switch (this.formGroup.controls.period.value) {
          case '8Hrs':
            this.periodEnd = moment.utc(start).add(8, 'hours').toDate();
            break;
          case '12Hrs':
            this.periodEnd = moment.utc(start).add(12, 'hours').toDate();
            break;
          case '24Hrs':
            this.periodEnd = moment.utc(start).add(24, 'hours').toDate();
            break;
          case '2Days':
            this.periodEnd = moment.utc(start).add(2, 'days').toDate();
            break;
          case '3Days':
            this.periodEnd = moment.utc(start).add(3, 'days').toDate();
            break;
          case '1Week':
            this.periodEnd = moment.utc(start).add(7, 'days').toDate();
            break;
        }
      }
    }
  }

  public toDateChange(newDate) {
    if (newDate) {
      const newDateTime = newDate.getTime();
      if (!isFinite(newDateTime)) {
        return;
      }

      if (newDateTime === this.periodEnd.getTime()) {
        return;
      }

      this.checkChartZoomMode();
      this.isLiveviewEnabled = false;
      this.isPeriodSelected = false;
      this.periodEnd = newDate;
    }
  }

  public getInputChangeResult(): Observable<any> {
    this.isPeriodEndEqualsNow();
    this.chart.showLoading();

    const selectedStartDate = this.getSelectedStartDate();
    const selectedEndDate = this.getSelectedEndDate();

    const periods = this.machineChartService.getPeriods(this.workCenterId, this.periodStart.toISOString(), this.periodEnd.toISOString());
    const productionOrdersData = this.machineChartService.getProductionOrderDetails(this.workCenterId, selectedStartDate, selectedEndDate);
    const wasteAssignment = this.wasteAssignments();

    return forkJoin([productionOrdersData, periods, wasteAssignment]);
  }

  public onLiveChange(): void {
    this.chart.showLoading();
    console.info('Live view button clicked');
    this.checkChartZoomMode();
    this.formGroup.patchValue({
      machineId: Number(this.workCenterId),
      period: '8Hrs'
    });

    this.setLiveViewValues();
    this.isPeriodEndEqualsNow();
    this.setAutoUpdate();
    this.setMachineAdditionalInfo(this.workCenterId);
  }

  public setLiveViewValues() {
    if (!this.isPeriodSelected) {
      this.periodStart = moment.utc().subtract(8, 'hours').toDate();
      this.periodEnd = moment.utc().toDate();
    }
    this.formGroup.controls.startDate.setValue(this.periodStart);
    this.formGroup.controls.endDate.setValue(this.periodEnd);

    console.info('start date', this.formGroup.controls.startDate.value);
    console.info('end date', this.formGroup.controls.endDate.value);

    if (!this.isChartZoomed) {
      this.getPeriods(this.workCenterId, this.periodStart, this.periodEnd);
    } else {
      this.getPeriods(this.workCenterId, this.minX, this.maxX);
    }
  }

  public onPeriodSelect(event) {
    this.chart.showLoading();
    this.isPeriodSelected = true;
    this.checkChartZoomMode();
    this.periodEnd = new Date(this.periodEnd) ?? moment.utc().toDate();
    switch (event.value) {
      case '8Hrs':
        this.periodEnd = moment.utc(this.periodStart).add(8, 'hours').toDate();
        this.selectedPeriodHours = '8Hrs';
        break;
      case '12Hrs':
        this.periodEnd = moment.utc(this.periodStart).add(12, 'hours').toDate();
        this.selectedPeriodHours = '12Hrs';
        break;
      case '24Hrs':
        this.periodEnd = moment.utc(this.periodStart).add(24, 'hours').toDate();
        this.selectedPeriodHours = '24Hrs';
        break;
      case '2Days':
        this.periodEnd = moment.utc(this.periodStart).add(2, 'days').toDate();
        this.selectedPeriodHours = '2Days';
        break;
      case '3Days':
        this.periodEnd = moment.utc(this.periodStart).add(3, 'days').toDate();
        this.selectedPeriodHours = '3Days';
        break;
      case '1Week':
        this.periodEnd = moment.utc(this.periodStart).add(7, 'days').toDate();
        this.selectedPeriodHours = '1Week';
        break;
    }
    if (event.value === 'CustomPeriod') {
      if (this.periodStart) {
        this.formGroup.controls.startDate.setValue(this.periodStart);
      }
      if (this.periodEnd) {
        this.formGroup.controls.endDate.setValue(this.periodEnd);
      }
    } else {
      this.formGroup.controls.startDate.setValue(this.periodStart);
      this.formGroup.controls.endDate.setValue(this.periodEnd);
    }
    if (this.isLiveviewEnabled) {
      this.setAutoUpdate();
    } else {
      this.getPeriods(this.workCenterId, this.periodStart, this.periodEnd);
    }
    this.setMachineAdditionalInfo(this.workCenterId);
    this.isForwardDisabled = true;
  }

  public getPeriodOnDateRange(hours: number): string {
    let period = '8Hrs';
    switch (true) {
      case hours > 8 && hours <= 12:
        period = '12Hrs';
        break;
      case hours > 12 && hours <= 24:
        period = '24Hrs';
        break;
      case hours > 24 && hours <= 48:
        period = '2Days';
        break;
      case hours > 48 && hours <= 72:
        period = '3Days';
        break;
      case hours > 72:
        period = '1Week';
        break;
    }
    return period;
  }

  private getSelectedStartDate(): string {
    if (this.periodStart !== undefined) {
      return moment.utc(this.periodStart).set('second', 0).set('millisecond', 0).toISOString();
    }
    return moment.utc().subtract(8, 'hours').set('second', 0).set('millisecond', 0).toISOString();
  }

  private getSelectedEndDate(): string {
    if (this.periodEnd !== undefined) {
      return moment.utc(this.periodEnd).set('second', 0).set('millisecond', 0).toISOString();
    }
    return moment.utc().set('second', 0).set('millisecond', 0).toISOString();
  }

  private listenToEndDateChanges(): void {
    this.endDateSelected$
      .pipe(
        debounceTime(300),
        distinctUntilChanged(), // Avoid duplicate consecutive values
        filter((date) => this.validateDate(date, false)), // Validate the date
        switchMap(() => this.getInputChangeResult()) // Cancel previous and process the latest
      )
      .subscribe(([productionOrdersData, productionPeriod, wasteResult]) => {
        this.setWorkCenterDetails(productionOrdersData);
        this.setProductionPeriodDetails(productionPeriod, wasteResult);
      });
  }

  private listenToStartDateChanges(): void {
    this.startDateSelected$
      .pipe(
        debounceTime(300),
        distinctUntilChanged(), // Avoid duplicate consecutive values
        filter((date) => this.validateDate(date, true)), // Validate the date
        switchMap(() => this.getInputChangeResult()) // Cancel previous and process the latest
      )
      .subscribe(([productionOrdersData, productionPeriod, wasteResult]) => {
        this.setWorkCenterDetails(productionOrdersData);
        this.setProductionPeriodDetails(productionPeriod, wasteResult);
      });
  }

  private setProductionPeriodDetails(productionPeriods, wasteAssignments) {
    const start = this.periodStart;
    const end = this.periodEnd;
    let secondsPerTenPixels;

    if (productionPeriods) {
      this.periods = productionPeriods;
      this.wasteAssignmentsDetails = wasteAssignments;
      if (start !== undefined && end !== undefined) {
        // Note: the best approach is to use a predefined amount of pixels per data point
        const timeInSeconds = Math.round(Math.abs(end.getTime() - start.getTime())) / 1000;
        const scaleFactor = timeInSeconds / 3600 <= 12 ? 2000 : 1000;
        secondsPerTenPixels = Math.round(timeInSeconds / scaleFactor);
      }
      const allSignalDatas: TelemetryResultDto[] = [];
      let index = 0;
      this.measurementService
        .apiMeasurementListOfWnsByResourcePost({
          body: this.externalWorkCenterId
        })
        .subscribe((signalData) => {
          if (signalData && signalData.length > 0) {
            signalData.forEach((wnsReport) => {
              // don't load the counter qty if we don't need it => we need it only when we are in edit setup or split downtime mode
              if (wnsReport.wellKnownSignal === SignalType.counter_qty && !this.needsCounterSignal()) {
                return;
              }

              // ignore excluded signals
              if (this.shouldSignalBeExcluded(wnsReport)) {
                console.info('Excluded signal: ', wnsReport.wellKnownSignal);
                return;
              }

              index++;
              const aggfun =
                wnsReport.wellKnownSignal === SignalType.machine_run || wnsReport.wellKnownSignal === SignalType.wash ? 'MAX' : 'MEAN';
              const signalQuery: TelemetryQueryDto = this.generateSignalQuery(secondsPerTenPixels, aggfun, wnsReport);

              console.info('Loading telemetry data for ', signalQuery.wellKnownSignal);

              this.telemetryService.apiTelemetryPost({ body: signalQuery }).subscribe((signalsData) => {
                allSignalDatas.push(signalsData);

                if (allSignalDatas.length === index) {
                  const speedValues = allSignalDatas.find((speedSignal) => speedSignal.telemetryQuery.wellKnownSignal === SignalType.speed);
                  const speed2Values = allSignalDatas.find(
                    (speedSignal) => speedSignal.telemetryQuery.wellKnownSignal === SignalType.speed2
                  );

                  const maxSpeed = this.getMaximumValue(speedValues);
                  const maxSpeed2 = this.getMaximumValue(speed2Values);

                  // Set machine max speed for gluing machine that is gl.
                  this.maxSpeedAxis1 = this.calculateMaximumAxisValue(maxSpeed);
                  this.plotBandHeight = (10 / 100) * this.maxSpeedAxis1;

                  if (maxSpeed2 !== undefined) {
                    this.maxSpeedAxis2 = this.calculateMaximumAxisValue(maxSpeed2);
                    // this.targetSpeed2 = (15 / 100) * maxSpeed2 + maxSpeed2;
                  }
                  this.workCenterCachedService.getWorkCenterMachineDataList().subscribe((result) => {
                    if (result) {
                      const workcenterCounterDetail = result.filter((element) => element.id === this.workCenterId);
                      this.prepareChartObject(workcenterCounterDetail[0]);
                      this.setChartConfiguration(this.periods, allSignalDatas);
                    }
                  });
                }
              });
            });
          } else {
            // clear, no data found
            this.workCenterCachedService.getWorkCenterMachineDataList().subscribe((result) => {
              if (result) {
                const workcenterCounterDetail = result.filter((element) => element.id === this.workCenterId);
                this.prepareChartObject(workcenterCounterDetail[0]);
                this.setChartConfiguration(this.periods, allSignalDatas);
              }
            });
          }
        });
    }
  }

  private setWorkCenterDetails(result) {
    this.machineData = [];
    if (result) {
      result.forEach((resultelement) => {
        const selectedMachine: MachineData = {};
        selectedMachine.numberOfStoppers = {
          unitId: resultelement.numberOfStoppers?.value?.unitId,
          value: resultelement.numberOfStoppers?.value?.value
        };
        selectedMachine.activeProductionOrderCode = resultelement.externalProductionOrderId;
        selectedMachine.externalFinishedGoodArticleId = resultelement.externalFinishedGoodArticleId;
        selectedMachine.finishedGoodArticleConfigurationId = resultelement.finishedGoodArticleConfigurationId;
        selectedMachine.customerName = resultelement.customerName;
        selectedMachine.finishedGoodArticleName = resultelement.finishedGoodArticleName;
        selectedMachine.goodQuantity = {
          unitId: resultelement.goodQuantity.unitId,
          value: resultelement.goodQuantity.value
        };
        selectedMachine.waste = {
          unitId: resultelement.waste.unitId,
          value: resultelement.waste.value
        };
        selectedMachine.maculature = {
          unitId: resultelement.maculature.unitId,
          value: resultelement.maculature.value
        };
        selectedMachine.salesOrderAndPosition = resultelement.salesOrderAndPosition;
        selectedMachine.plannedQuantity = {
          unitId: resultelement.plannedQuantityUnitId,
          value: resultelement.plannedQuantity
        };
        selectedMachine.quantityPredecessor = {
          unitId: resultelement.quantityPredecessor.unitId,
          value: resultelement.quantityPredecessor.value
        };
        this.machineData.push(selectedMachine);
      });
      this.dataSource.data = this.machineData;
    }
    this.netQuantity = 0;
    this.totalWaste = 0;
    this.totalMaculature = 0;
    this.dataSource.data.forEach((element) => {
      this.netQuantity = this.netQuantity + element.goodQuantity?.value;
      this.totalWaste = this.totalWaste + element.waste?.value;
      this.totalMaculature = this.totalMaculature + element.maculature?.value;
    });
    const totalGross = this.netQuantity + this.totalWaste + this.totalMaculature;
    this.grossQuantity = totalGross;
  }

  private validateDate(date: Date, isFromDate: boolean): boolean {
    if (isFromDate) {
      this.fromDateChange(date);
    }

    if (!isFromDate) {
      this.toDateChange(date);
    }

    if (!this.validateDateRange(this.periodStart, this.periodEnd)) {
      return false;
    }

    return true;
  }
}
