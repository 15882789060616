import { Injectable } from '@angular/core';
import {
  ApprovalReport,
  ApprovalReportConsumption,
  ApprovalReportConsumptionItem,
  ApprovalReportConsumptionItemDetail,
  ApprovalReportItem,
  ApprovalReportItemType,
  ApprovalReportProductionDowntimeItem,
  ApprovalReportProductionOrderPhaseType,
  ApprovalReportRunPhaseItem,
  ApprovalReportStatus,
  ApprovalWorkCenter,
  MaterialType,
  WorkCenterGroup
} from 'chronos-core-client';
import { ListValue } from 'chronos-shared';
import { SelectItem, TreeNode } from 'primeng/api';
import { ApprovalReportData, ApproveReportTableRowStyleClass } from '@app/modules/approve/models';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ApproveMappingService {
  private readonly ROW_STYLE_CLASS = ApproveReportTableRowStyleClass;

  constructor(private translateService: TranslateService) {}

  public constructListValue(workCenterGroups: WorkCenterGroup[]): ListValue[] {
    return workCenterGroups.map<ListValue>((group) => ({ value: group.workCenterGroupId, label: group.name }));
  }

  public constructSelectItemList(approvalWorkCenters: ApprovalWorkCenter[]): SelectItem[] {
    return approvalWorkCenters.map<SelectItem>((approvalWorkCenter) => this.constructSelectItem(approvalWorkCenter));
  }

  private constructSelectItem(approvalWorkCenter: ApprovalWorkCenter): SelectItem {
    const selectItem: SelectItem = {
      value: approvalWorkCenter.workCenterId,
      label: approvalWorkCenter.externalWorkCenterId
    };
    if (approvalWorkCenter.approvalReportStatus === ApprovalReportStatus.Approved) {
      selectItem.title = 'icon-checked-solid';
    }
    return selectItem;
  }

  public mapApprovalReportToData(approvalReport: ApprovalReport): ApprovalReportData {
    return {
      isConfirmedByUser: approvalReport.isConfirmedByUser,
      items: this.mapItems(approvalReport.items),
      kpiItems: approvalReport.kpiItems.sort((a, b) => a.orderSequence - b.orderSequence),
      hasInfo: approvalReport.hasInfo,
      enableSendingApprovalReport: approvalReport.enableSendingApprovalReport,
      confirmSendingApprovalReport: approvalReport.confirmSendingApprovalReport
    };
  }

  public mapConsumptionToApprovalData(root: TreeNode[], consumption: ApprovalReportConsumption): TreeNode[] {
    const row = root.find((order) => order.data.approvalReportLineId === consumption.approvalReportLineId);
    const consumptionRow = row?.children?.find(
      (runPhases) => runPhases.data.runPhaseType === ApprovalReportProductionOrderPhaseType.Consumption
    );

    if (consumptionRow) {
      const children = this.remapConsumptionToTreeTable(consumption, consumptionRow.data.isEditable);
      children.forEach((rowChild) => {
        const consumptionChild = consumptionRow.children?.find((oldChild) => oldChild.data.articleId === rowChild.data.articleId);
        rowChild.expanded = consumptionChild?.expanded;
      });
      consumptionRow.children = children;
    }

    return [...root];
  }

  private mapItems(items: ApprovalReportItem[]): TreeNode[] {
    return items.map<TreeNode>((item) => ({
      data: { ...item, rowStyleClass: this.ROW_STYLE_CLASS.FIRST_LEVEL },
      children: this.mapChildItems(item.runPhaseChildItems, item.isEditable)
    }));
  }

  private mapChildItems(childItem: ApprovalReportRunPhaseItem[], isEditable: boolean): TreeNode[] {
    return childItem.map<TreeNode>((item) => ({
      data: {
        ...item,
        itemType: item.productionOrderRunPhase,
        rowStyleClass: this.ROW_STYLE_CLASS.SECOND_LEVEL,
        isEditable
      },
      leaf: item.runPhaseType !== ApprovalReportProductionOrderPhaseType.Consumption,
      children: this.mapGrandChildrenAccordingToType(item, isEditable)
    }));
  }

  private mapGrandChildrenAccordingToType(item: ApprovalReportRunPhaseItem, isEditable: boolean): TreeNode[] {
    if (
      item.runPhaseType === ApprovalReportProductionOrderPhaseType.Downtime ||
      item.runPhaseType === ApprovalReportProductionOrderPhaseType.Run ||
      item.runPhaseType === ApprovalReportProductionOrderPhaseType.Setup
    ) {
      return this.mapGrandChildItems(item.downtimes, isEditable);
    } else if (item.runPhaseType === ApprovalReportProductionOrderPhaseType.Consumption) {
      const loadingLine: ApprovalReportConsumptionItem = {
        articleId: null,
        billOfMaterialId: null,
        details: null,
        externalArticleId: this.translateService.instant('APPROVE.LOADING'),
        totalConsumption: null,
        type: null,
        runId: null,
        articleDescription: null
      };
      const returnItem: TreeNode = {
        data: {
          ...loadingLine,
          isEditable,
          rowStyleClass: this.ROW_STYLE_CLASS.THIRD_LEVEL,
          itemType: ApprovalReportItemType.ConsumptionArticle
        }
      };
      return [returnItem];
    }
  }

  private mapGrandChildItems(grandChildItems: ApprovalReportProductionDowntimeItem[], isEditable: boolean): TreeNode[] {
    return grandChildItems?.map<TreeNode>((item) => ({
      data: {
        ...item,
        isEditable,
        rowStyleClass: this.ROW_STYLE_CLASS.THIRD_LEVEL
      }
    }));
  }

  private remapConsumptionToTreeTable(data: ApprovalReportConsumption, isEditable: boolean): TreeNode[] {
    let articles: TreeNode[] = [];
    articles = articles.concat(this.remapConsumptionArticles(data.primaryMaterials, MaterialType.Primary, isEditable));
    articles = articles.concat(this.remapConsumptionArticles(data.secondaryMaterials, MaterialType.Secondary, isEditable));
    return articles;
  }

  private remapConsumptionArticles(
    materials: ApprovalReportConsumptionItem[],
    materialType: MaterialType,
    isEditable: boolean
  ): TreeNode[] {
    return materials.map<TreeNode>((element) => ({
      children: this.remapConsumptionArticleMaterial(element.details, isEditable),
      data: {
        ...element,
        isEditable,
        itemType: ApprovalReportItemType.ConsumptionArticle,
        materialType,
        rowStyleClass: ApproveReportTableRowStyleClass.THIRD_LEVEL
      }
    }));
  }

  private remapConsumptionArticleMaterial(articleDetails: ApprovalReportConsumptionItemDetail[], isEditable: boolean): TreeNode[] {
    return articleDetails.map<TreeNode>((element) => ({
      data: {
        ...element,
        isEditable,
        itemType: ApprovalReportItemType.ConsumptionMaterial,
        rowStyleClass:
          element.isVirtualContainer === true ? ApproveReportTableRowStyleClass.DUMMY_ARTICLE : ApproveReportTableRowStyleClass.FOURTH_LEVEL
      }
    }));
  }
}
