import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ProducedMaterialCheckStatus } from '@app/shared/models';
import { ProducedMaterialCheckResult, ProducedMaterialCheckState } from 'chronos-core-client';
import { StatusBarIcon } from '@app/modules/run-phase/models';

@Component({
  selector: 'app-status-bar-item',
  templateUrl: './status-bar-item.component.html',
  styleUrls: ['./status-bar-item.component.scss']
})
export class StatusBarItemComponent implements OnChanges {
  @Input() public itemStatus: ProducedMaterialCheckStatus;
  @Input() public itemIcon: StatusBarIcon;

  public isMandatory = false;
  public isDisabled = false;
  public isDone = false;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.itemStatus) {
      this.isMandatory = this.isStatusMandatory() && !this.isStatusDone();
      this.isDisabled = this.isStatusDisabled();
      this.isDone = this.isStatusDone();
    }
  }

  private isStatusMandatory(): boolean {
    return this.itemStatus.state === ProducedMaterialCheckState.Mandatory;
  }

  private isStatusDisabled(): boolean {
    return this.itemStatus.state === ProducedMaterialCheckState.Disabled;
  }

  private isStatusDone(): boolean {
    return this.itemStatus.result === ProducedMaterialCheckResult.Done;
  }
}
