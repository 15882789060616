import { Injectable } from '@angular/core';
import {
  EmployeeSignatureService,
  EmployeeSignatureViewModel,
  EmployeeViewModel,
  ScanSignatureEntry,
  ScanSignatureResponse
} from 'chronos-core-client';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmployeeSignatureDsService {
  constructor(private employeeSignatureService: EmployeeSignatureService) {}

  public scanSignature(badgeId: string, requireEmployeeRegistration: boolean, workCenterId: number): Observable<ScanSignatureResponse> {
    const scanSignatureEntry: ScanSignatureEntry = {
      badgeId,
      requireEmployeeRegistration,
      workCenterId
    };

    return this.employeeSignatureService.scanSignature({
      body: scanSignatureEntry
    });
  }

  public setSignatureForQaChecks(
    comment: string | null,
    employeeId: number,
    isManualInput: boolean,
    productionQaCheckIds: Array<number>,
    requireEmployeeRegistration: boolean,
    timestamp: string,
    workCenterId: number
  ): Observable<void> {
    return this.employeeSignatureService.setSignatureForQaChecks({
      body: {
        comment,
        employeeId,
        isManualInput,
        productionQaCheckIds,
        requireEmployeeRegistration,
        timestamp,
        workCenterId
      }
    });
  }

  public resetSignature(employeeSignatureId: number, invalidateSignatureOnly: boolean, workCenterId: number): Observable<void> {
    return this.employeeSignatureService.resetSignature({
      body: {
        employeeSignatureId,
        invalidateSignatureOnly,
        workCenterId
      }
    });
  }

  public getEmployeeSignature(employeeSignatureId: number): Observable<EmployeeSignatureViewModel> {
    return this.employeeSignatureService.getEmployeeSignature({ employeeSignatureId });
  }

  public getEmployeesForSignature(workCenterId?: number, requireEmployeeRegistration?: boolean): Observable<EmployeeViewModel> {
    return this.employeeSignatureService.getEmployeesForSignature({
      RequireEmployeeRegistration: requireEmployeeRegistration,
      WorkCenterId: workCenterId
    });
  }
}
