import { Component, OnDestroy, OnInit } from '@angular/core';
import { OrderListService } from '@app/modules/orders/services';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { ProductionOrderListQuery } from '@app/core/global-state';
import { map, tap } from 'rxjs/operators';
import { MachineSchedule, ManualMachineMode, ProductionOrderStatus, ShiftFilter, WorkCenterProductionMode } from 'chronos-core-client';
import { ShiftFilterType } from '@app/shared/models';
import { OrderListView } from './order-list-view';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit, OnDestroy {
  private readonly SEARCH_FILTER_CHAR_LENGTH = 3;

  public selectedShift = ShiftFilterType.All;
  public isShowingAll = false;
  public isExpandCollapseAll = false;
  public automaticPeriodSelectionOnProductionOrderStart = false;
  public isLoading$ = this.productionOrderListQuery.isLoading$;

  private orderListFilterSubject = new BehaviorSubject<string>('');
  private orderListFilter$: Observable<string> = this.orderListFilterSubject.asObservable();

  public orderList$: Observable<OrderListView> = combineLatest([this.orderListFilter$, this.productionOrderListQuery.selectAll()]).pipe(
    map(([filter, orderList]) => ({
      filteredList: this.filterOrderList(orderList, filter),
      hasActiveOrder: orderList.some((order) => order.status === ProductionOrderStatus.Active)
    }))
  );
  public shiftFilters$: Observable<ShiftFilter[]>;

  private subscriptions = new Subscription();
  public manualMachineMode: ManualMachineMode;

  constructor(private orderListService: OrderListService, private productionOrderListQuery: ProductionOrderListQuery) {}

  public ngOnInit(): void {
    this.showOrders(false);
    this.shiftFilters$ = this.orderListService.getShiftFilters();

    this.subscriptions.add(
      this.orderListService
        .getWorkCenterProductionMode()
        .pipe(
          tap((mode) => {
            this.checkManualMode(mode);
          })
        )
        .subscribe()
    );

    this.subscriptions.add(
      this.orderListService
        .automaticPeriodSelectionOnProductionOrderStart()
        .pipe(
          tap((setting) => {
            this.setAutomaticPeriodSelectionOnProductionOrderStartSettoing(setting);
          })
        )
        .subscribe()
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public showOrders(showAllOrders: boolean): void {
    this.subscriptions.add(this.orderListService.getProductionOrderListByShift(showAllOrders, this.selectedShift).subscribe());
  }

  public trackByFunction(index: number, productionOrder: MachineSchedule): number {
    return productionOrder.productionOrderId;
  }

  public searchFilterOrderList(filterValue: string): void {
    this.orderListFilterSubject.next(filterValue);
  }

  public filterShift(): void {
    this.showOrders(this.isShowingAll);
  }

  private checkManualMode(mode: WorkCenterProductionMode): void {
    if (mode === WorkCenterProductionMode.Manual) {
      this.subscriptions.add(
        this.orderListService
          .getManualMachineMode()
          .pipe(
            tap((manualMachineMode) => {
              this.manualMachineMode = manualMachineMode;
            })
          )
          .subscribe()
      );
    }
  }

  private filterOrderList(orderList: MachineSchedule[], filter: string): MachineSchedule[] {
    if (filter.length >= this.SEARCH_FILTER_CHAR_LENGTH) {
      return orderList.filter((order) => order.externalProductionOrderId.toLowerCase().includes(filter.toLowerCase()));
    } else {
      return orderList;
    }
  }

  private setAutomaticPeriodSelectionOnProductionOrderStartSettoing(setting: boolean): void {
    this.automaticPeriodSelectionOnProductionOrderStart = setting;
  }
}
