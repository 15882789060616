/* tslint:disable */
/* eslint-disable */
export enum RemarkType {
  VirtualContainerInfo = 'VirtualContainerInfo',
  ProductionOrderInfoPL = 'ProductionOrderInfoPL',
  ProductionJobInfoPL = 'ProductionJobInfoPL',
  ProductionOrderInfoShopFloor = 'ProductionOrderInfoShopFloor',
  ProductionKpiInfo = 'ProductionKpiInfo',
  ShiftReportInfo = 'ShiftReportInfo'
}
