import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActiveOrderDsService } from '@app/core/data-services';
import { DowntimeCodeOptions, TaskService } from '@app/core/global-state';
import { nav } from '@app/shared/utils';
import { KpiActionBase } from './kpi-action-base';
import { KpiIdentifier } from 'chronos-core-client';

@Injectable({
  providedIn: 'root'
})
export class KpiActionDowntimeService extends KpiActionBase {
  constructor(private taskService: TaskService, private activeOrderDsService: ActiveOrderDsService, private router: Router) {
    super();
  }

  public execute(): void {
    this.taskService.updateFilters({
      downtimeCodeAssignation: DowntimeCodeOptions.All,
      taskType: KpiIdentifier.Downtime,
      productionOrder: this.activeOrderDsService.getActiveOrder().externalProductionOrderId,
      textQuery: ''
    });

    this.router.navigate([nav.routes.tasks]);
  }
}
