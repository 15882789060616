<section class="toolcheckout page-padding">
  <header>
    <h1 class="page-header">
      {{ 'FINISH_PHASE.TOOL_CHECKOUT' | translate }}
    </h1>
  </header>

  <div class="tool-kpi-wrapper">
    <section *ngIf="toolCheckoutInfo?.length === 0">
      <div class="no-tool-check" [routerLink]="navigation.routes.mountingSecondary">
        {{ 'FINISH_PHASE.NO_TOOL_CHECKOUT' | translate }}
      </div>
    </section>

    <section *ngIf="toolCheckoutInfo?.length !== 0">
      <div class="scrollable-div">
        <div *ngFor="let toolCheckout of toolCheckoutInfo">
          <div class="title-article">
            [ {{ toolCheckout.externalArticleId }} - {{ toolCheckout.serialNumber }} ] {{ 'END_TOOL_CHECKOUT.COMMENT' | translate }}
          </div>
          <div class="p-grid">
            <div class="p-col-3">
              <textarea
                rows="5"
                pInputTextarea
                autoResize
                [(ngModel)]="toolCheckout.comment"
                class="comment-textarea"
                maxlength="250"
                (blur)="onCommentBlur(toolCheckout.comment, toolCheckout.externalArticleId)"
              ></textarea>
            </div>
            <div class="p-col-3">
              <app-kpi-tile [kpi]="toolCheckout.setupsCount"></app-kpi-tile>
            </div>
            <div class="p-col-3">
              <app-kpi-tile [kpi]="toolCheckout.qualityProduced"></app-kpi-tile>
            </div>
            <div class="p-col-3">
              <div class="kpi-column-body">
                <div class="kpi-column-body__title freetext">
                  {{ 'END_TOOL_CHECKOUT.FREE_FOR_PRODUCTION' | translate }}
                  <div class="result-select-button">
                    <div (click)="containsForwardedWaste(TOOL_STATUS.Ok, toolCheckout.externalArticleId)">
                      <div [ngClass]="getClassByEnumValue(toolCheckout.checkResult, true)" class="result-select-button__item">
                        {{ 'MODAL_FOOTER.YES' | translate }}
                      </div>
                    </div>
                    <div (click)="containsForwardedWaste(TOOL_STATUS.Nok, toolCheckout.externalArticleId)">
                      <input type="radio" name="waste" [value]="toolCheckout.checkResult" style="display: none" />
                      <div [ngClass]="getClassByEnumValue(toolCheckout.checkResult, false)" class="result-select-button__item">
                        {{ 'MODAL_FOOTER.NO' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</section>
