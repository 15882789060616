import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BillOfMaterial, ProductionOrderService, SplitBillOfMaterialData, SplitBillOfMaterialRowEntry } from 'chronos-core-client';

@Injectable({
  providedIn: 'root'
})
export class BillOfMaterialDsService {
  constructor(private productionOrderService: ProductionOrderService) {}

  public getBillOfMaterials(productionOrderId: number): Observable<BillOfMaterial[]> {
    return this.productionOrderService.getBillOfMaterials({ productionOrderId });
  }

  public getSplitBillOfMaterialDetails(productionOrderId: number, billOfMaterialId: number): Observable<SplitBillOfMaterialData> {
    return this.productionOrderService.splitBillOfMaterialData({
      productionOrderId,
      billOfMaterialId
    });
  }

  public splitBillOfMaterialRow(body: SplitBillOfMaterialRowEntry, productionOrderId: number): Observable<void> {
    return this.productionOrderService.splitBillOfMaterialRow({
      productionOrderId,
      body
    });
  }
}
