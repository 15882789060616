import { Injectable } from '@angular/core';
import { EmployeeRegistrationHistory, EmployeeRegistrationService } from 'chronos-core-client';
import { HistoryFilters } from './employee-registration-history.model';
import { EmployeeRegistrationHistoryStore } from './employee-registration-history.store';

@Injectable({ providedIn: 'root' })
export class EmployeeRegistrationHistoryService {
  constructor(private employeeRegistrationService: EmployeeRegistrationService, private historyStore: EmployeeRegistrationHistoryStore) {}

  public getHistory(workCenterId: number, shiftIds?: number[]) {
    this.employeeRegistrationService
      .getHistory({
        workCenterId,
        shiftIds
      })
      .subscribe((history: EmployeeRegistrationHistory[]) => {
        this.historyStore.set(history);
      });
  }

  public updateEmployeeHistory(employeeHistory: EmployeeRegistrationHistory[]): void {
    this.historyStore.setLoading(true);
    this.historyStore.update(employeeHistory);
    this.historyStore.setLoading(false);
  }

  public updateFilters(filters: HistoryFilters): void {
    this.historyStore.updateFilters(filters);
  }

  public resetFilters(): void {
    this.historyStore.resetFilters();
  }
}
