<lib-input-number
  [inputId]="inputId"
  [size]="5"
  [(ngModel)]="palletQuantity"
  [disabled]="disabled"
  (ngModelChange)="onQuantityChange($event)"
  (touched)="onTouched()"
  [required]="required"
  [unit]="totalOrderQuantity.unitId | uppercase"
  [showUnit]="false"
></lib-input-number>
<span class="pallet-estimate">
  <span>{{ totalOrderQuantity?.unitId }}</span>
  <span *ngIf="palletQuantity">
    {{
      'SETUP_PARAMS.PALLET_ESTIMATE'
        | translate
          : {
              estimatedPallets: getPalletEstimation(),
              articleClassification: articleClassification,
              totalPallets: totalOrderQuantity | formatQuantity
            }
    }}
  </span>
</span>
